import {
  // Metrics,
  SET_METRIC,
  SET_INTERVAL,
  SET_ALIVE_ONLY,
  SET_PRINTS_ONLY,
  SET_PRICE_LIMIT,
  REQUEST_ARTISTS,
  RECEIVE_ARTISTS,
  RECEIVE_ONE_ARTIST,
  REQUEST_ONE_ARTIST,
  SET_ONE_ARTIST,
} from './actions'

function rootReducer (state,action){
  return ({
    histo: histoReducer(state.histo, action),
    
    // we pass part of teh main histogram state to make the trends in oneArtist view contextual to whatever query was used to sort the histogram
    oneArtist : oneArtistReducer(state.oneArtist,action,{
      printsOnly: state.histo.printsOnly,
      interval: state.histo.interval,
      priceLimit : state.histo.priceLimit,}) 
  })
}

function histoReducer (state, action) {
  switch (action.type) {
    case  SET_METRIC:
      return Object.assign({}, state, {
        metric: action.metric
      })
    case SET_INTERVAL:
      return Object.assign({}, state, {
        interval: action.interval
      })
    case SET_ALIVE_ONLY:
      return Object.assign({}, state, {
        aliveOnly: action.aliveOnly
      })
    case SET_PRINTS_ONLY:
      return Object.assign({}, state, {
        printsOnly: action.printsOnly
      })  
    case SET_PRICE_LIMIT:
      return Object.assign({}, state, {
        priceLimit: action.priceLimit
      })    
    case REQUEST_ARTISTS:
      return Object.assign({}, state, {
        isFetching: true
      })
    case RECEIVE_ARTISTS:
      return Object.assign({}, state, {
        isFetching: false,
        dataset: action.dataset,
        receivedAt : action.receivedAt
      })
    default:
      return state
  }
  // // if we ever get to the end we should return the input state unchanged
  // return state
}


function oneArtistReducer(state, action,{printsOnly,interval,priceLimit}){
  switch (action.type) {
    case SET_ONE_ARTIST:
      return Object.assign({}, state, {
        slug: action.slug,
        cardinality: action.cardinality,
        totalValue : action.totalValue,
        trend: action.trend, // that's the slope of the main linear regression
        bottomTrend: action.bottomTrend, // that's the slope of the regression on the minimums
        sumX : action.sumX,
        printsOnly,
        interval,
        priceLimit,
      })
    case REQUEST_ONE_ARTIST: // we re-assign the slug for deep links not comeing from the bar chart page
      return Object.assign({}, state, {
        slug : action.name,
        isFetching: true
      })
    case RECEIVE_ONE_ARTIST:
      return Object.assign({}, state, {
        isFetching: false,
        dataset: Object.assign(action.dataset, {slug : state.slug}), // to memorize what slug was used for this dataset
        receivedAt: action.receivedAt
      })
    default:
      return state
  }
}



export { histoReducer , rootReducer} 

