import React from 'react'

const NotEnoughData = ({ artistname }) => (
  <div className="container">
    <div className="row mt-5 text-left homeText">
      <div className="col-sm">Not enough data for {artistname}</div>
    </div>
  </div>
)

export { NotEnoughData }
