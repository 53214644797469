/**
 * Created by alaindemour on 5/14/17.
 */

import { connect } from 'react-redux'
import { ControllableArthistog } from './controllable-arthistog'

// Here is the Glue between Redux and React
// This will be called every time the store is updated
// This version if brute force we pass the whole state
const mapStateToProps = (state) => {
  return {
    state: state.histo
  }
}



// Connection between Redux and React here.
// connect "injects" all or some "stuff" from the store into the props of the components
// Here apparently we are doing something inefficient as we are injecting the dispatch and all the properies of the state, TBD what to do.
const ArthistogContainer = connect(
  mapStateToProps,
  // mapDispatchToProps is not needed as it is done by default (too much magic) by connect
)(ControllableArthistog)

export { ArthistogContainer }

// With React Redux, your components never access the store directly - connect does it for you. React Redux gives you two ways to let components dispatch actions:

// By default, a connected component receives props.dispatch and can dispatch actions itself.
// connect can accept an argument called mapDispatchToProps, which lets you create functions that dispatch when called, and pass those functions as props to your component.
// The mapDispatchToProps functions are normally referred to as mapDispatch for short, but the actual variable name used can be whatever you want.