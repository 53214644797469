import * as d3 from 'd3'
import React, { Component } from 'react'

import './year-brush.css'

import { LAYOUT_WIDTH } from './layout-constants'

const margin = {
  top: 20,
  right: 50,
  bottom: 20,
  left: 4,
}

const xsWidth = window.innerWidth

const height = 70 - margin.top - margin.bottom

function recordPosition(d, i, gr) {
  let ev = d3.event
  if (ev.sourceEvent) {
    // we are recording the mouse position so that if it has not moved on mouseup later we can perform specific actions for click without movment
    this.cx = ev.sourceEvent.clientX
    this.cy = ev.sourceEvent.clientY
  }
  // this contains the current DOM element (as per d3 manual)
  // we are recording the start position for the case when the end user does NOT drag at all, in which case
  // the selection will be null on the end event, so we need some kind of pixel position to react then
  this.clickPosition = ev.selection[0]
}

class TimeBrush extends Component {
  constructor() {
    super()
    this.state = {
      realWidth: window.innerWidth - margin.left - margin.right,
    }
    // Creating the horizontal scale for the brush
    this.x = d3
      .scaleTime()
      .domain([new Date(2000, 0, 0), new Date(2019, 0, 0)])
      .rangeRound([0, this.state.realWidth])
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  updateDimensions() {

      this.setState({
        realWidth: window.innerWidth - margin.left - margin.right,
      })
    
  }

  render() {
    return (
      <div
        id="timebrush"
        ref={selfdom => {
          this.selfdom = selfdom // this is to keep the component abstract and not have to do a lookup by CSS id in didMount
          if (selfdom) {
            // can be null in some cases as react keep on rerendering a lot. TBD analye the edge cases when it is null
            this.renderOutsideOfReact(selfdom)
          }
        }}
      />
    )
  }

    renderOutsideOfReact(selfdom) {
        // using "self" to bind this is unusual in ES6 but we do for the d3 "brushed" callback below, it needs a this bound dynamically to the svg node but also access
        // to the "this" of the reactjs component, so we need a way to distinguish them
    
        if (selfdom) {
      
            const self = this
            let svg = d3
                .select(this.selfdom)
              .append('svg')
                 .attr("pointer-events", "all")
                .attr('width', this.state.realWidth + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

            svg
                .append('g')
                .attr('class', 'axis axis--grid')
                .attr('transform', 'translate(0,' + height + ')')
                .call(
                    d3
                        .axisBottom(this.x)
                        .ticks(d3.timeYear)
                        .tickSize(-height)
                        .tickFormat(function () {
                            return null
                        }),
                )
                .selectAll('.tick')

            svg
                .append('g')
                .attr('class', 'axis axis--x')
                .attr('transform', 'translate(0,' + height + ')')
                .call(
                    d3
                        .axisBottom(this.x)
                        .ticks(d3.timeYear)
                        .tickPadding(0),
                )
                .attr('text-anchor', null)
                .selectAll('text')
                .attr('x', 6)

            let theBrush = d3
                .brushX()
                .extent([[0, 0], [this.state.realWidth, height]])
                .on('start', recordPosition)
                .on('end', brushed)

            let theSelectionForTheBrush = svg.append('g').attr('class', 'brush')

            theSelectionForTheBrush.call(theBrush)

            let low = this.props.interval[0]
            let high = this.props.interval[1]

            // Boostrap Magic, we operate the bursh programmatically which will cause the Redux State Change
            let dinit = [new Date(low, 0, 0), new Date(high, 0, 0)]
            let positions = dinit.map(this.x)
            theSelectionForTheBrush.call(theBrush.move, positions)

            // it is important to use a old school javascript function here not a fat arrow function/lambda in order NOT to bind "this", d3 relies on dynamic binding later in the call back
            function brushed(d, i, gr) {
                let ev = d3.event
                if (ev.sourceEvent) {
                    let cx = ev.sourceEvent.clientX
                    let cy = ev.sourceEvent.clientY
                    console.log(`cx : ${cx} cy : ${cy}`)
                }
                // this contains the current DOM elem
                // that's when the user does NOT drag at all
                // faking a 0 pixel drag
                // the conditin is either a click without movement outside of seletion (null) or a click without movment inside the selection (x and y haven not moved)
                if (
                    ev.selection === null ||
                    (ev.selection &&
                        this.cx &&
                        ev.sourceEvent.clientX === this.cx &&
                        this.cy === ev.sourceEvent.clientY)
                ) {
                    if (ev.selection === null) {
                        ev.selection = [this.clickPosition, this.clickPosition]
                    } else {
                        // transform the mouse click coordinate into coordinate relative to the brush itself, only keep the x coordinate
                        let p = d3.mouse(this)[0]
                        ev.selection = [p, p]
                    }
                }

                // This is a bit cryptic but has to do with how d3 raises events
                // because we adjust the brush to end on scale boundary, a second " brush end" event is generated
                // but it has a different source, it is the brush itself, the first as "mouse up" as type, the second "end".
                // The second event will cause a network call to fetch the data
                let d0 = ev.selection.map(self.x.invert)
                let d1 = d0.map(d3.timeYear.round)

                // If empty when rounded, use floor instead.
                if (d1[0] >= d1[1]) {
                    d1[0] = d3.timeYear.floor(d0[0])
                    d1[1] = d3.timeYear.offset(d1[0])
                }

                // even more cryptic ev.sourceEvent == null is the initial setting of the brush, we do it programmatically so there is not source event
                // ev.sourceEvent.type === end is after a mouse up in the middle of the scale, which causes a secondary brush event when we adjust the brush
                // the secondary event has type end instead of mouseup

                if (ev.sourceEvent === null || ev.sourceEvent.type === 'end') {
                    let fy = d1[0].getFullYear()
                    let ty = d1[1].getFullYear()

                    // "self" is bound to the component, as the this in this function scope is a dom node
                    self.props.dispatch([fy, ty])
                    // return to avoid infinite event loop
                    return
                }

                // It is important this call stays here other an invite loop (same event retriggering again and again will happen)
                // "this" here will be bound to the svg node, NOT THE COMPONENT
                d3.select(this).call(d3.event.target.move, d1.map(this.x))
            }
        }
    }
}

export { TimeBrush }
