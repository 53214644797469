// const URL_PREFIX = '/api/v0'
// const URL_PREFIX = 'https://api.pryph.org/api/v0'
// // const URL_PREFIX ='https://api.pryph.org/v1'

import { URL_PREFIX } from '../components/remote/urlpaths'

const REQUEST_URL = `${URL_PREFIX}/artists`

export const SET_METRIC = 'SET_METRIC'
export const SET_INTERVAL = 'SET_INTERVAL'
export const SET_ALIVE_ONLY = 'SET_ALIVE_ONLY'
export const REQUEST_ARTISTS = 'REQUEST_ARTISTS'
export const RECEIVE_ARTISTS = 'RECEIVE_ARTISTS'
export const RECEIVE_FAILURE = 'RECEIVE_FAILURE'
export const SET_PRINTS_ONLY = 'SET_PRINTS_ONLY'
export const SET_PRICE_LIMIT = 'SET_PRICE_LIMIT'
export const REQUEST_ONE_ARTIST = 'REQUEST_ONE_ARTISTS'
export const RECEIVE_ONE_ARTIST = 'RECEIVE_ONE_ARTISTS'
export const RECEIVE_ONE_ARTIST_FAILURE = 'RECEIVE_ONE_ARTIST_FAILURE'
export const SET_ONE_ARTIST = 'SET_ONE_ARTIST'

export const Metrics = {
  PER_SQ_CM: 'PER_SQ_CM',
  TOTAL_VALUE: 'TOTAL_VALUE',
  UNITS: 'UNITS',
  AVG: 'AVG',
  SLOPE: 'SLOPE',
  BSLOPE: 'BSLOPE',
}

// Synchronous and local actions
export function setMetric(metric) {
  return { type: SET_METRIC, metric }
}

export function setInterval(interval) {
  return { type: SET_INTERVAL, interval }
}

export function setAliveOnly(aliveOnly) {
  return { type: SET_ALIVE_ONLY, aliveOnly }
}

export function setPrintsOnly(printsOnly) {
  return { type: SET_PRINTS_ONLY, printsOnly }
}
export function setPriceLimit(priceLimit) {
  return { type: SET_PRICE_LIMIT, priceLimit }
}
export function setOneArtist(aprof) {
  // the artist profile is everything we get from the main histogram, however on deep link some wil be missing
  return {
    type: SET_ONE_ARTIST,
    slug: aprof.slug,
    cardinality: aprof.cardinality,
    totalValue: aprof.totalValue,
    trend: aprof.trend,
    bottomTrend: aprof.bottomTrend,
    sumX: aprof.sumX,
  }
}

//  network actions
export function requestArtists(bod) {
  return {
    type: REQUEST_ARTISTS,
    bod,
  }
}

export function receiveArtists(bod, json) {
  return {
    type: RECEIVE_ARTISTS,
    bod,
    dataset: json,
    receivedAt: Date.now(),
  }
}

export function fetchArtists(state) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  // The signature of the thunk below (dispatch, getState) is part of the redux "spec", those 2 are expected, getState is optional though
  return function(dispatch, getState) {
    // let state = getState()

    let bod = {
      from: state.interval[0],
      to: state.interval[1],
      alive: state.aliveOnly,
      editions: state.printsOnly,
      pricelimit: state.priceLimit === 'none' ? null : state.priceLimit,
    }

    dispatch(requestArtists(bod))

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    // query send to elastic search
    let req = new Request(REQUEST_URL, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(bod),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return fetch(req)
      .then(resp => {
        return resp.json()
      })
      .then(json => {
        dispatch(receiveArtists(bod, json))
      })
      .catch(err => {
        console.log('badabing')
        console.log(err)
      })

    // In a real world app, you also want to
    // catch any error in the network call.
  }
}

export function requestOneArtist({ slug, trend, bottomTrend, sumX }) {
  return {
    type: REQUEST_ONE_ARTIST,
    name: slug,
    trend,
    bottomTrend,
    sumX,
  }
}

export function receiveOneArtist(json) {
  return {
    type: RECEIVE_ONE_ARTIST,
    dataset: json,
    receivedAt: Date.now(),
  }
}

export function fetchOneArtist(slug) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.

  // The signature of the tunk below (dispatch, getState) is part of the redux "spec", those 2 are expected, getState is optional though
  return function(dispatch) {
    // const slug = state.slug

    dispatch(requestOneArtist({ slug }))

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.

    // query send to elastic search
    let req = new Request(`${REQUEST_URL}/${slug}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return fetch(req)
      .then(resp => {
        return resp.json()
      })
      .then(json => {
        dispatch(receiveOneArtist(json))
      })
      .catch(err => {
        console.log('badabing')
        console.log(err)
      })
  }
}
