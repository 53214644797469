import React, { Component} from 'react'
import {Checkbox} from './checkbox'

class PrintsOnlyCheck extends Component {

  componentDidUpdate(prevProps, prevState) {
        let isCheckedBefore = prevProps.checked
        let isCheckedNow = this.props.checked
         if (isCheckedBefore !== isCheckedNow) {
          this.props.reactTo() // network call, we chain state transitions without user input
         }
      }

  render() {
    return <div id="printsonlycheckbox" className="Small-filter">
      <Checkbox checked={this.props.checked} dispatch={this.props.dispatch} lab={"prints & multiples"}/>
    </div>
  }
}

export { PrintsOnlyCheck }