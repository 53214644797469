import React from 'react'

function NavItem(props) {
    const pageURI = window.location.pathname // we never use query strings in pryhp
    const liClasses = (props.path === pageURI) ? "nav-link px-xl-5 px-lg-5 active" : "nav-link px-xl-5 px-lg-5";
    return (
        <li className="nav-item">
            <a className={liClasses} href={props.path} >{props.name}
            </a>
      </li>
    )
}
  
export default NavItem

// export default React.memo(NavItem)


