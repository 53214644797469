const YAYOI = {
  took: 15,
  timed_out: false,
  _shards: {
    total: 5,
    successful: 5,
    skipped: 0,
    failed: 0,
  },
  hits: {
    total: 471,
    max_score: null,
    hits: [
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2005.1598.142.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d44567/d4456792r.jpg',
          year: 2005,
          artist: 'yayoi-kusama',
          lot: 142,
          auctionHouse: 'christies',
          high: 180000,
          low: 120000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: '',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 120,000 - USD 180,000',
          birth: 1929,
          realized: 'USD 168,000',
          real: 168000,
          timexcoord: '2005-03-01',
          sale: 1598,
          month: 3,
          name: 'Pacific Ocean \r\n',
          x: 61,
          y: 76.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPacific Ocean \r\nsigned and dated '58 YAYOI KUSAMA' (lower left); signed again, titled and dated again 'Yayoi Kusama 1958 PACIFIC OCEAN' (on the stretcher)\r\n\r\noil on canvas \r\n24 x 30 in. (61 x 76.2 cm.) \r\nPainted in 1958. \r\n",
          cid: '1598.142',
        },
        fields: {
          ymonths: [62],
        },
        sort: [1109635200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2005.1549.170.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d45611/d4561104r.jpg',
          year: 2005,
          artist: 'yayoi-kusama',
          lot: 170,
          auctionHouse: 'christies',
          high: 20000,
          low: 15000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: '',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 15,000 - USD 20,000',
          birth: 1929,
          realized: 'USD 26,400',
          real: 26400,
          timexcoord: '2005-09-01',
          sale: 1549,
          month: 9,
          name: 'Accumulation \r\n',
          x: 35.5,
          y: 28.5,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nAccumulation \r\nsigned and dated '1953 Yayoi Kusama' (lower left); signed and dated again '1953 Yayoi Kusama' (on the reverse)\r\nink on paper \r\n14 x 11¼ in. (35.5 x 28.5 cm.) \r\nPainted in 1953. \r\n",
          cid: '1549.170',
        },
        fields: {
          ymonths: [68],
        },
        sort: [1125532800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2005.1549.250.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d45611/d4561184r.jpg',
          year: 2005,
          artist: 'yayoi-kusama',
          lot: 250,
          auctionHouse: 'christies',
          high: 20000,
          low: 15000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: '',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 15,000 - USD 20,000',
          birth: 1929,
          realized: 'USD 36,000',
          real: 36000,
          timexcoord: '2005-09-01',
          sale: 1549,
          month: 9,
          name: 'Rain \r\n',
          x: 91.4,
          y: 116.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nRain \r\nsigned and dated 'Yayoi Kusama 1993' (on the reverse) \r\nacrylic on canvas \r\n36 x 46 in. (91.4 x 116.8 cm.) \r\nPainted in 1993. \r\n",
          cid: '1549.250',
        },
        fields: {
          ymonths: [68],
        },
        sort: [1125532800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2006.1637.250.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d46698/d4669885r.jpg',
          year: 2006,
          artist: 'yayoi-kusama',
          lot: 250,
          auctionHouse: 'christies',
          high: 20000,
          low: 15000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'first open - Post War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 15,000 - USD 20,000',
          birth: 1929,
          realized: 'USD 204,000',
          real: 204000,
          timexcoord: '2006-03-01',
          sale: 1637,
          month: 3,
          name: 'Accumulation of Nets \r\n',
          x: 61.5,
          y: 73.6,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nAccumulation of Nets \r\nsigned and dated '1961 YAYOI KUSAMA' (lower right); signed again, titled, inscribed and dated again 'Yayoi Kusama 1961 ACCUMULATION OF NETS (N.O.C.O.) (BY PHOTOS OF MY PAINTINGS)' (on a paper label affixed to the reverse)\r\ngelatin silver prints and ink collage on board \r\n24¼ x 29 in. (61.5 x 73.6 cm.) \r\nExecuted in 1961. \r\n",
          cid: '1637.250',
        },
        fields: {
          ymonths: [74],
        },
        sort: [1141171200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2006.1704.185.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d47624/d4762455r.jpg',
          year: 2006,
          artist: 'yayoi-kusama',
          lot: 185,
          auctionHouse: 'christies',
          high: 9000,
          low: 7000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b.1929) \r\n',
          salesTitle: 'First Open Post War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 7,000 - USD 9,000',
          birth: 1929,
          realized: 'USD 8,400',
          real: 8400,
          timexcoord: '2006-09-01',
          sale: 1704,
          month: 9,
          name: 'Untitled (dress) \r\n',
          x: 73.66,
          y: 50.8,
          stuff:
            "Yayoi Kusama (b.1929) \r\nUntitled (dress) \r\nsigned 'KUSAMA' (lower right) \r\ndouble sided--acrylic on polyester fabric \r\n29 x 20 in. (73.66 x 50.8 cm.) \r\nExecuted circa 1960s. \r\n",
          cid: '1704.185',
        },
        fields: {
          ymonths: [80],
        },
        sort: [1157068800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2006.1702.112.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d47771/d4777170r.jpg',
          year: 2006,
          artist: 'yayoi-kusama',
          edition: 94,
          lot: 112,
          auctionHouse: 'christies',
          high: 800,
          low: 600,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Japanese and Korean Art',
          editionOf: 120,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 600 - USD 800',
          birth: 1929,
          realized: 'USD 2,280',
          real: 2280,
          timexcoord: '2006-09-01',
          sale: 1702,
          month: 9,
          name: 'A Pumpkin (BY), 2004\r\nA Pumpkin (BT), 2004\r\n',
          x: 38.2,
          y: 45.1,
          stuff:
            'Yayoi Kusama (b. 1929) \r\nA Pumpkin (BY), 2004\r\nA Pumpkin (BT), 2004\r\nTwo silkscreens, each titled and dated in pencil in Roman print as above, each signed in pencil in Roman script Yayoi Kusama, each numbered 94/120--very good impressions, color and condition\r\n38.2 x 45.1cm. (15 1/16 x 17¾in.), first; 45.3 x 38.1cm. (17 7/8 x 15in.), second \t (2)\r\n',
          cid: '1702.112',
        },
        fields: {
          ymonths: [80],
        },
        sort: [1157068800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2007.7358.219.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d48654/d4865443r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 219,
          auctionHouse: 'christies',
          high: 60000,
          low: 45000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 30,000 - GBP 40,000',
          birth: 1929,
          realized: 'GBP 50,400',
          real: 75600,
          timexcoord: '2007-02-01',
          sale: 7358,
          month: 2,
          name: 'Infinity Nets (Z, Q, O) \r\n',
          x: 162,
          y: 130,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets (Z, Q, O) \r\nsigned, titled and dated 'Yayoi Kusama 1989 Infinity Nets (Z, Q, O)' (on the reverse)\r\nacrylic on canvas \r\n63¾ x 51¼in. (162 x 130cm.) \r\nPainted in 1989 \r\n",
          cid: '7358.219',
        },
        fields: {
          ymonths: [85],
        },
        sort: [1170288000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2007.1806.377.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d48683/d4868350r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 377,
          auctionHouse: 'christies',
          high: 6000,
          low: 4000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 4,000 - USD 6,000',
          birth: 1929,
          realized: 'USD 4,800',
          real: 4800,
          timexcoord: '2007-02-01',
          sale: 1806,
          month: 2,
          name: 'Tree Leaf \r\n',
          x: 27.3,
          y: 24.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nTree Leaf \r\nstamped with Japanese character 'Yayoi' (lower left); signed, titled and dated in Japanese 'Yayoi Kusama 1991 Tree Leaf' (on the reverse)\r\nacrylic, enamel and gold leaf on board \r\n10¾ x 9½ in. (27.3 x 24.1 cm.) \r\nPainted in 1991. \r\n",
          cid: '1806.377',
        },
        fields: {
          ymonths: [85],
        },
        sort: [1170288000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2007.7358.220.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d48654/d4865444r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 220,
          auctionHouse: 'christies',
          high: 60000,
          low: 45000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 30,000 - GBP 40,000',
          birth: 1929,
          realized: 'GBP 45,600',
          real: 68400,
          timexcoord: '2007-02-01',
          sale: 7358,
          month: 2,
          name: 'Dots \r\n',
          x: 162,
          y: 153,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nDots \r\nsigned, titled and dated 'Yayoi Kusama 1989 Dots' and inscribed in Japanese (on the reverse)\r\nacrylic on canvas \r\n63¾ x 51¼in. (162 x 153cm.) \r\nPainted in 1989 \r\n",
          cid: '7358.220',
        },
        fields: {
          ymonths: [85],
        },
        sort: [1170288000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.3.2007.NY000107.291.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY000107/291/605/550/false/false/false',
          year: 2007,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 2500,
          low: 1500,
          currency: 'USD',
          id: 'phillips.3.2007.NY000107.291.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY000107/291',
          salesTitle: 'saturday@phillips',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 4800,
          timexcoord: '2007-03-01',
          month: 3,
          name: 'Untitled',
          x: 24,
          y: 27,
          location: 'new york',
          stuff:
            'Ink and spraypaint on paper. ^ Signed and dated on verso. This work is unique. ^ 9 1/2 x 10 3/4 in. (24.1 x 27.3 cm). ^ null ^ 3',
          cid: 'NY000107.291',
        },
        fields: {
          ymonths: [86],
        },
        sort: [1172707200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2007.UK010407.320.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/UK010407/320/605/550/false/false/false',
          year: 2007,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 120000,
          provenance: 'OTA Fine Arts, Tokyo',
          low: 80000,
          currency: 'GBP',
          id: 'phillips.10.2007.UK010407.320.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010407/320',
          salesTitle: 'contemporary art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 270000,
          timexcoord: '2007-10-01',
          month: 10,
          name: 'Galaxy (Red) A, B, C',
          x: 117,
          y: 92,
          location: 'london',
          stuff:
            'Triptych: Oil on canvas. ^ Signed and dated “Yayoi Kusama 1991” on the reverse of each panel in English and Japanese characters. ^ 46 x 36 in. (116.8 x 91.5 cm) each. ^ OTA Fine Arts, Tokyo ^ 10',
          cid: 'UK010407.320',
        },
        fields: {
          ymonths: [93],
        },
        sort: [1191196800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.151.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021833r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 151,
          auctionHouse: 'christies',
          high: 22500,
          low: 15000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 10,000 - GBP 15,000',
          birth: 1929,
          realized: 'GBP 30,500',
          real: 45750,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Sunset Clouds \r\n',
          x: 50.2,
          y: 65,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nSunset Clouds \r\nsigned and dated '1978 YAYOI KUSAMA' (lower left); signed and dated 'Yayoi Kusama 1978' (on the reverse)\r\nacrylic on canvas \r\n19¾ x 25 5/8in. (50.2 x 65cm.) \r\nExecuted in 1978 \r\n",
          cid: '5324.151',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.148.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021830r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 148,
          auctionHouse: 'christies',
          high: 9000,
          low: 6000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 4,000 - GBP 6,000',
          birth: 1929,
          realized: 'GBP 13,700',
          real: 20550,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Birds \r\n',
          x: 68,
          y: 50.9,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nBirds \r\nsigned and dated '1983 Yayoi Kusama' (lower right), signed and dated '1983 Yayoi Kusama' (on the reverse)\r\ncollage, acrylic and metalic paint on paper \r\n26¾ x 20in. (68 x 50.9cm.) \r\nExecuted in 1983 \r\n",
          cid: '5324.148',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.146.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021828r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 146,
          auctionHouse: 'christies',
          high: 6750,
          low: 5250,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 3,500 - GBP 4,500',
          birth: 1929,
          realized: 'GBP 6,875',
          real: 10312.5,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Untitled \r\n',
          x: 24.1,
          y: 27.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\n(i) signed and dated twice 'Yayoi Kusama, 1980' (on the reverse)\r\n(ii) signed and dated 'Yayoi Kusama, 1980' (on the reverse)\r\nspray-paint on card \r\n(i) 9½ x 10¾in. (24.1 x 27.1cm.)\r\n(ii) 10 5/8 x 9½in. (27.1 x 24.1cm.)\r\nExecuted in 1980 \t (2)\r\n",
          cid: '5324.146',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.154.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021836r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 154,
          auctionHouse: 'christies',
          high: 3000,
          low: 2250,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 1,500 - GBP 2,000',
          birth: 1929,
          realized: 'GBP 3,250',
          real: 4875,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Autumn Wave \r\n',
          x: 27.2,
          y: 24.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nAutumn Wave \r\nsigned and dated 'YAYOI, 1978, KUSAMA' (upper right); signed and dated 'yayoi kusama, 1978' (on the reverse)\r\nspray-paint, gouache and metallic tape on card \r\n10¾ x 9¾in. (27.2 x 24.2cm.) \r\nExecuted in 1978 \r\n",
          cid: '5324.154',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.152.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021834r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 152,
          auctionHouse: 'christies',
          high: 3000,
          low: 2250,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 1,500 - GBP 2,000',
          birth: 1929,
          realized: 'GBP 2,750',
          real: 4125,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Moon Night \r\n',
          x: 24.1,
          y: 27.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nMoon Night \r\nsigned and dated 'YAYOI, KUSAMA, 1979' (upper left); signed and dated '1979, yayoi Kusama' (on the reverse)\r\nspray-paint and metallic paint on card \r\n9½ x 10¾in. (24.1 x 27.2cm.) \r\nExecuted in 1979 \r\n",
          cid: '5324.152',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.155.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021837r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 155,
          auctionHouse: 'christies',
          high: 30000,
          low: 22500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b.1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 15,000 - GBP 20,000',
          birth: 1929,
          realized: 'GBP 23,300',
          real: 34950,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Accumulation \r\n',
          x: 42.8,
          y: 29.9,
          stuff:
            "Yayoi Kusama (b.1929) \r\nAccumulation \r\nsigned and dated '1953, yayoi kusama' (lower left); signed and dated '1953, yayoi kusama' (on the reverse)\r\nink on paper \r\n16 7/8 x 11 7/8in. (42.8 x 29.9cm.) \r\nExecuted in 1953 \r\n",
          cid: '5324.155',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.150.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021832r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 150,
          auctionHouse: 'christies',
          high: 27000,
          low: 18000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 12,000 - GBP 18,000',
          birth: 1929,
          realized: 'GBP 38,900',
          real: 58350,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Love Forever \r\n',
          x: 45.7,
          y: 38.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nLove Forever \r\nsigned and dated 'Yayoi Kusama, 1988' (on the reverse) \r\nacrylic on canvas \r\n18 x 15in. (45.7 x 38.1cm.) \r\nPainted in 1988 \r\n",
          cid: '5324.150',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.153.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021835r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 153,
          auctionHouse: 'christies',
          high: 3000,
          low: 2250,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 1,500 - GBP 2,000',
          birth: 1929,
          realized: 'GBP 3,000',
          real: 4500,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Pacific Ocean in the Early Spring \r\n',
          x: 26.9,
          y: 23.9,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPacific Ocean in the Early Spring \r\nsigned and dated 'Yayoi Kusama, 1978' (on the reverse) \r\nacrylic and silver spray-paint on card \r\n10 5/8 x 9 3/8in. (26.9 x 23.9cm.) \r\nExecuted in 1998 \r\n",
          cid: '5324.153',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.156.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021838r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 156,
          auctionHouse: 'christies',
          high: 9000,
          low: 6000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 4,000 - GBP 6,000',
          birth: 1929,
          realized: 'GBP 9,375',
          real: 14062.5,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Untitled (Night) \r\n',
          x: 65.1,
          y: 50.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled (Night) \r\nsigned and dated '1980, Yayoi Kusama' (lower right); signed and dated '1980, Yayoi Kusama' (on the reverse)\r\ngouache, pastel, collage, gold paint and glitter on paper \r\n25 5/8 x 20in. (65.1 x 50.8cm.) \r\nExecuted in 1980 \r\n",
          cid: '5324.156',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.149.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021831r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 149,
          auctionHouse: 'christies',
          high: 10500,
          low: 7500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 5,000 - GBP 7,000',
          birth: 1929,
          realized: 'GBP 16,100',
          real: 24150,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Red Bird \r\n',
          x: 63.5,
          y: 49.3,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nRed Bird \r\nsigned and dated 'Yayoi Kusama, 1981' (lower right); signed and dated 'Yayoi Kusama, 1981' (on the reverse)\r\ngouache, gold paint, fabric and collage on paper \r\n25 x 19 3/8in. (63.5 x 49.3cm.) \r\nExecuted in 1981 \r\n",
          cid: '5324.149',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.157.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021839r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 157,
          auctionHouse: 'christies',
          high: 7500,
          low: 4500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 3,000 - GBP 5,000',
          birth: 1929,
          realized: 'GBP 48,500',
          real: 72750,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Flower \r\n',
          x: 37.7,
          y: 45.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nFlower \r\nsigned and dated '1979, yayoi kusama' (on the reverse) \r\nacrylic on canvas \r\n14 7/8 x 17¾in. (37.7 x 45.2cm.) \r\nExecuted in 1979 \r\n",
          cid: '5324.157',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5486.21.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50259/d5025912r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 21,
          auctionHouse: 'christies',
          high: 540000,
          low: 420000,
          currency: 'EUR',
          brand: 'YAYOI KUSAMA (NEE EN 1929) \r\n',
          salesTitle: "Art d'Après-Guerre et Contemporain",
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'EUR 350,000 - EUR 450,000',
          birth: 1929,
          realized: 'EUR 372,250',
          real: 446700,
          timexcoord: '2007-12-01',
          sale: 5486,
          month: 12,
          name: 'Infinity Nets \r\n',
          x: 132.1,
          y: 132.1,
          stuff:
            'YAYOI KUSAMA (NEE EN 1929) \r\nInfinity Nets \r\nhuile sur toile \r\n132.1 x 132.1 cm. (52 x 52 in.) \r\nPeint en 1962. \r\n',
          cid: '5486.21',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.145.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021827r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 145,
          auctionHouse: 'christies',
          high: 18000,
          low: 12000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 8,000 - GBP 12,000',
          birth: 1929,
          realized: 'GBP 31,700',
          real: 47550,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Infinity Nets \r\n',
          x: 45.4,
          y: 37.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets \r\nsigned and dated 'Yayoi Kusama, 1990' (on the reverse) \r\nacrylic on canvas \r\n17 7/8 x 14 7/8in. (45.4 x 37.8cm.) \r\nPainted in 1990 \r\n",
          cid: '5324.145',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2007.5324.147.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50218/d5021829r.jpg',
          year: 2007,
          artist: 'yayoi-kusama',
          lot: 147,
          auctionHouse: 'christies',
          high: 6750,
          low: 5250,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 3,500 - GBP 4,500',
          birth: 1929,
          realized: 'GBP 7,250',
          real: 10875,
          timexcoord: '2007-12-01',
          sale: 5324,
          month: 12,
          name: 'Untitled \r\n',
          x: 27.2,
          y: 24.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\n(i) signed and dated 'YAYOI KUSAMA, 1978' (upper left); signed and dated again '1978 Yayoi Kusama' (on the reverse)\r\n(ii) signed twice, dated and indistinctly dated again 'Yayoi Kusama, 1980' (on the reverse)\r\nspray-paint on card \r\neach: 10¾ x 9½in. (27.2 x 24.2cm.) \r\n(i) Executed in 1978\r\n(ii) Executed in 1980 \t (2)\r\n",
          cid: '5324.147',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.12.2007.NY000507.160.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY000507/160/605/550/false/false/false',
          year: 2007,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 3000,
          low: 2000,
          currency: 'USD',
          id: 'phillips.12.2007.NY000507.160.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY000507/160',
          salesTitle: 'saturday@phillips',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 2125,
          timexcoord: '2007-12-01',
          month: 12,
          name: 'Five works: Book to Read at Night',
          x: 16,
          y: 23,
          location: 'new york',
          stuff:
            'Screenprint on paper. ^ Each signed, dated, numbered and inscribed lower edge. This work is an HC aside from an edition of 95. ^ Each 6 3/8 x 9 in. (16.2 x 22.9 cm). ^ null ^ 12',
          cid: 'NY000507.160',
        },
        fields: {
          ymonths: [95],
        },
        sort: [1196467200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2008.7566.198.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d50365/d5036574r.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          lot: 198,
          auctionHouse: 'christies',
          high: 60000,
          low: 45000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle:
            'Post-War and Contemporary Art  Day Sale (Morning Session)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 30,000 - GBP 40,000',
          birth: 1929,
          realized: 'GBP 42,500',
          real: 63750,
          timexcoord: '2008-02-01',
          sale: 7566,
          month: 2,
          name: 'Dots Infinity-OQOT \r\n',
          x: 80.3,
          y: 100,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nDots Infinity-OQOT \r\nsigned in Japanese and English, titled and dated 'Yayoi Kusama 2004 Dots-Infinity OQOT' (on the reverse)\r\nacrylic on canvas \r\n31 5/8 x 39 3/8in. (80.3 x 100cm.) \r\nPainted in 2004 \r\n",
          cid: '7566.198',
        },
        fields: {
          ymonths: [97],
        },
        sort: [1201824000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-399',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 8000,
          low: 6000,
          currency: 'GBP',
          id:
            'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-399',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-auction-l08021/lot.399.html',
          salesTitle: 'contemporary-art-day-auction-l08021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(36500)',
          real: 54750,
          timexcoord: '2008-02-01',
          month: 2,
          name: 'MUSHROOMS',
          x: 25,
          y: 34,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                MUSHROOMSƎ                Ǝ                    Ǝ                        signed; titled and dated 2005 on the reverseacrylic on canvas24.5 by 33.5cm.; 9 5/8  by 13 1/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Galleria Il Cerchio; MilanƎ',
          cid: 'contemporary-art-day-auction-l08021-399',
        },
        fields: {
          ymonths: [97],
        },
        sort: [1201824000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-401',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 25000,
          low: 18000,
          currency: 'GBP',
          id:
            'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-401',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-auction-l08021/lot.401.html',
          salesTitle: 'contemporary-art-day-auction-l08021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(29300)',
          real: 43950,
          timexcoord: '2008-02-01',
          month: 2,
          name: 'BUDS (A)',
          x: 52,
          y: 44,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                BUDS (A)Ǝ                Ǝ                    Ǝ                        titled on the reverse; signed; titled in Japanese and dated 1988 on the stretcheracrylic on canvas51.5 by 44cm.; 20 1/4  by 17 3/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-l08021-401',
        },
        fields: {
          ymonths: [97],
        },
        sort: [1201824000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-402',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 25000,
          low: 18000,
          currency: 'GBP',
          id:
            'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-402',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-auction-l08021/lot.402.html',
          salesTitle: 'contemporary-art-day-auction-l08021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(34100)',
          real: 51150,
          timexcoord: '2008-02-01',
          month: 2,
          name: 'INFINITY NETS',
          x: 73,
          y: 61,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 1990 on the reverseacrylic on canvas73 by 60.5cm.; 28 3/4  by 23 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-l08021-402',
        },
        fields: {
          ymonths: [97],
        },
        sort: [1201824000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-403',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 25000,
          low: 18000,
          currency: 'GBP',
          id:
            'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-403',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-auction-l08021/lot.403.html',
          salesTitle: 'contemporary-art-day-auction-l08021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(22100)',
          real: 33150,
          timexcoord: '2008-02-01',
          month: 2,
          name: 'INFINITY NETS',
          x: 46,
          y: 38,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        signed and dated 1988 on the reverseoil on canvas45.8 by 38cm.; 18 by 15in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-l08021-403',
        },
        fields: {
          ymonths: [97],
        },
        sort: [1201824000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-404',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'GBP',
          id:
            'sothebys.2.2008.yayoi-kusama.contemporary-art-day-auction-l08021-404',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-auction-l08021/lot.404.html',
          salesTitle: 'contemporary-art-day-auction-l08021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(41300)',
          real: 61950,
          timexcoord: '2008-02-01',
          month: 2,
          name: 'DOTS INFINITY',
          x: 91,
          y: 72,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                DOTS INFINITYƎ                Ǝ                    Ǝ                        signed; titled and dated 2004 on the reverseacrylic on canvas91 by 72cm.; 35 7/8  by 28 3/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-art-day-auction-l08021-404',
        },
        fields: {
          ymonths: [97],
        },
        sort: [1201824000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2008.NY000108.122.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1535493390/auctions/NY000108/122_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 3000,
          low: 2000,
          currency: 'USD',
          id: 'phillips.4.2008.NY000108.122.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY000108/122',
          salesTitle: 'saturday@phillips',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 2500,
          timexcoord: '2008-04-01',
          month: 4,
          name: 'Five Works: Morning is Here',
          x: 16,
          y: 23,
          location: 'new york',
          stuff:
            'Screenprint on paper. ^ Each signed, dated, numbered and inscribed lower edge. This work is an HC aside from an edition of 95. ^ Each 6 1/4 x 9 in. (15.9 x 22.9 cm). ^ null ^ 4',
          cid: 'NY000108.122',
        },
        fields: {
          ymonths: [99],
        },
        sort: [1207008000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2008.UK000208.102.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK000208/102_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 35000,
          provenance: 'Ota Fine Arts, Tokyo',
          low: 25000,
          currency: 'GBP',
          id: 'phillips.4.2008.UK000208.102.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK000208/102',
          salesTitle: 'kyobai',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 54750,
          timexcoord: '2008-04-01',
          month: 4,
          name: 'Original Infinity Nets',
          x: 73,
          y: 61,
          location: 'london',
          stuff:
            'Acrylic on canvas. ^ Signed, titled and dated on the reverse. Accompanied by registration card from the Kusama Studio. ^ 73 x 61 cm. (28 3/4 x 24 in). ^ Ota Fine Arts, Tokyo ^ 4',
          cid: 'UK000208.102',
        },
        fields: {
          ymonths: [99],
        },
        sort: [1207008000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2008.UK000208.269.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK000208/269_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 12000,
          low: 8000,
          currency: 'GBP',
          id: 'phillips.4.2008.UK000208.269.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK000208/269',
          salesTitle: 'kyobai',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 15937.5,
          timexcoord: '2008-04-01',
          month: 4,
          name: 'Pumpkin',
          x: 18,
          y: 14,
          location: 'london',
          stuff:
            'Acrylic on canvas. ^ Accompanied by registration card from the Kusama Studio. ^ 18 x 14 cm. (7 1/8 x 5 1/2 in). ^ null ^ 4',
          cid: 'UK000208.269',
        },
        fields: {
          ymonths: [99],
        },
        sort: [1207008000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2008.UK000208.224.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK000208/224_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 35000,
          provenance: 'Ota Fine Arts, Tokyo',
          low: 25000,
          currency: 'GBP',
          id: 'phillips.4.2008.UK000208.224.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK000208/224',
          salesTitle: 'kyobai',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 58350,
          timexcoord: '2008-04-01',
          month: 4,
          name: 'Evening Glow',
          x: 91,
          y: 73,
          location: 'london',
          stuff:
            'Acrylic on canvas. ^ Signed, titled and dated ‘Evening Glow (in Japanese)Yayoi Kusama 2000’ on the reverse. Accompanied by registration card from the Kusama Studio. ^ 91 x 73 cm. (35 3/4 x 28 3/4 in). ^ Ota Fine Arts, Tokyo ^ 4',
          cid: 'UK000208.224',
        },
        fields: {
          ymonths: [99],
        },
        sort: [1207008000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2008.UK000208.123.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK000208/123_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 30000,
          provenance: 'I.A.O Fine Arts, Tokyo',
          low: 20000,
          currency: 'GBP',
          id: 'phillips.4.2008.UK000208.123.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK000208/123',
          salesTitle: 'kyobai',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 51150,
          timexcoord: '2008-04-01',
          month: 4,
          name: 'Crowd of Stars',
          x: 65,
          y: 53,
          location: 'london',
          stuff:
            'Acrylic on canvas. ^ Signed, titled and dated ‘Yayoi Kusama (in English) 1992 Crowd of Stars (in Japanese)’ on the reverse. Accompanied by registration card from Kusama Studio. ^ 65 x 53.2 cm. (25 1/2 x 21 in). ^ I.A.O Fine Arts, Tokyo ^ 4',
          cid: 'UK000208.123',
        },
        fields: {
          ymonths: [99],
        },
        sort: [1207008000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.7.2008.yayoi-kusama.contemporary-art-day-sale-l08023-458',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'GBP',
          id:
            'sothebys.7.2008.yayoi-kusama.contemporary-art-day-sale-l08023-458',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-sale-l08023/lot.458.html',
          salesTitle: 'contemporary-art-day-sale-l08023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(18750)',
          real: 28125,
          timexcoord: '2008-07-01',
          month: 7,
          name: 'NETS 21',
          x: 45,
          y: 53,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NETS 21Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1997 on the reverseacrylic on canvas45.3 by 53cm.; 17 7/8  by 20 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkƎ',
          cid: 'contemporary-art-day-sale-l08023-458',
        },
        fields: {
          ymonths: [102],
        },
        sort: [1214870400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.7.2008.yayoi-kusama.contemporary-art-day-sale-l08023-464',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'GBP',
          id:
            'sothebys.7.2008.yayoi-kusama.contemporary-art-day-sale-l08023-464',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-sale-l08023/lot.464.html',
          salesTitle: 'contemporary-art-day-sale-l08023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(26250)',
          real: 39375,
          timexcoord: '2008-07-01',
          month: 7,
          name: 'ADOLESCENCE',
          x: 41,
          y: 32,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                ADOLESCENCEƎ                Ǝ                    Ǝ                        dated 1988 on the reverse acrylic on canvas41.3 by 31.5cm.; 16 1/4  by 12 3/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-art-day-sale-l08023-464',
        },
        fields: {
          ymonths: [102],
        },
        sort: [1214870400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.7.2008.yayoi-kusama.contemporary-art-day-sale-l08023-465',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'GBP',
          id:
            'sothebys.7.2008.yayoi-kusama.contemporary-art-day-sale-l08023-465',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-sale-l08023/lot.465.html',
          salesTitle: 'contemporary-art-day-sale-l08023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(20000)',
          real: 30000,
          timexcoord: '2008-07-01',
          month: 7,
          name: 'NETS 25',
          x: 46,
          y: 53,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NETS 25Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1998 on the reverseacrylic on canvas45.7 by 53.1cm.; 18 by 20 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkƎ',
          cid: 'contemporary-art-day-sale-l08023-465',
        },
        fields: {
          ymonths: [102],
        },
        sort: [1214870400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2008.2022.102.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51087/d5108752r.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          lot: 102,
          auctionHouse: 'christies',
          high: 60000,
          low: 40000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post -War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 40,000 - USD 60,000',
          birth: 1929,
          realized: 'USD 37,500',
          real: 37500,
          timexcoord: '2008-09-01',
          sale: 2022,
          month: 9,
          name: 'Infinity Nets \r\n',
          x: 91.4,
          y: 60.3,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets \r\nsigned, titled in Japanese and dated 'Yayoi Kusama 1991 Infinity Net' (on the reverse)\r\nacrylic on canvas \r\n36 x 23¾ in. (91.4 x 60.3 cm.) \r\nPainted in 1991. \r\n",
          cid: '2022.102',
        },
        fields: {
          ymonths: [104],
        },
        sort: [1220227200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2008.yayoi-kusama.contemporary-art-day-auction-l08026-159',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 65000,
          low: 45000,
          currency: 'GBP',
          id:
            'sothebys.10.2008.yayoi-kusama.contemporary-art-day-auction-l08026-159',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-auction-l08026/lot.159.html',
          salesTitle: 'contemporary-art-day-auction-l08026',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(49250)',
          real: 73875,
          timexcoord: '2008-10-01',
          month: 10,
          name: 'INFINITY-NETS',
          x: 91,
          y: 73,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 2006 on the reverseacrylic on canvas91.2 by 73cm.; 35 7/8  by 28 3/4 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ                    ExhibitedƎ                        Fukuoka; Media of Modern art Contemporary; YAYOI KUSAMA Infinity Nets 2006; 2007Ǝ',
          cid: 'contemporary-art-day-auction-l08026-159',
        },
        fields: {
          ymonths: [105],
        },
        sort: [1222819200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2008.yayoi-kusama.contemporary-art-day-auction-l08026-302',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'GBP',
          id:
            'sothebys.10.2008.yayoi-kusama.contemporary-art-day-auction-l08026-302',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-auction-l08026/lot.302.html',
          salesTitle: 'contemporary-art-day-auction-l08026',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(25000)',
          real: 37500,
          timexcoord: '2008-10-01',
          month: 10,
          name: 'INFINITY-NETS',
          x: 65,
          y: 53,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 2006 on the reverseƎ acrylic on canvas65.2 by 53.2cm.; 25 5/8  by 21in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ                    ExhibitedƎ                        Fukuoka; Media of Modern art Contemporary; YAYOI KUSAMA Infinity Nets 2006; 2007Ǝ',
          cid: 'contemporary-art-day-auction-l08026-302',
        },
        fields: {
          ymonths: [105],
        },
        sort: [1222819200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2008.NY010608.261.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010608/261_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 40000,
          provenance: 'MOMA Contemporary, Fukuoka',
          low: 30000,
          currency: 'USD',
          id: 'phillips.11.2008.NY010608.261.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010608/261',
          salesTitle: 'contemporary art part ii',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 42500,
          timexcoord: '2008-11-01',
          month: 11,
          name: 'Infinity Nets OQHTN',
          x: 45,
          y: 53,
          location: 'new york',
          stuff:
            'Acrylic on canvas.  ^ Signed on the reverse. ^ 17 7/8 x 20 7/8 in. (45.4 x 53 cm). ^ MOMA Contemporary, Fukuoka ^ 11',
          cid: 'NY010608.261',
        },
        fields: {
          ymonths: [106],
        },
        sort: [1225497600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2008.NY010608.352.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010608/352_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 18000,
          provenance:
            'Acquired directly from the artist; Private collection, USA',
          low: 15000,
          currency: 'USD',
          id: 'phillips.11.2008.NY010608.352.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010608/352',
          salesTitle: 'contemporary art part ii',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 25000,
          timexcoord: '2008-11-01',
          month: 11,
          name: 'Infinity Nets (ZYA)',
          x: 45,
          y: 38,
          location: 'new york',
          stuff:
            "Acrylic on canvas.  ^ Signed, titled and dated 'Yayoi Kusama Infinity Nets (ZYA) [in English and Japanese] 1999' on the reverse.  ^ 17 3/4 x 14 3/4 in. (45.1 x 37.5 cm). ^ Acquired directly from the artist; Private collection, USA ^ 11",
          cid: 'NY010608.352',
        },
        fields: {
          ymonths: [106],
        },
        sort: [1225497600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2008.NY010608.353.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010608/353_001.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 40000,
          provenance:
            'Acquired directly from the artist; Private collection, Tokyo',
          low: 30000,
          currency: 'USD',
          id: 'phillips.11.2008.NY010608.353.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010608/353',
          salesTitle: 'contemporary art part ii',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 25000,
          timexcoord: '2008-11-01',
          month: 11,
          name: 'The Pearl',
          x: 46,
          y: 38,
          location: 'new york',
          stuff:
            "Acrylic on canvas.  ^ Signed and dated 'Yayoi Kusama [in English and Japanese] 1990' on the reverse.  ^ 18 x 15 in. (45.7 x 38.1 cm). ^ Acquired directly from the artist; Private collection, Tokyo ^ 11",
          cid: 'NY010608.353',
        },
        fields: {
          ymonths: [106],
        },
        sort: [1225497600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2008.yayoi-kusama.contemporary-art-day-sale-n08490-120',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.11.2008.yayoi-kusama.contemporary-art-day-sale-n08490-120',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-sale-n08490/lot.120.html',
          salesTitle: 'contemporary-art-day-sale-n08490',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(27500)',
          real: 27500,
          timexcoord: '2008-11-01',
          month: 11,
          name: 'SKY NO. 1',
          x: 34,
          y: 25,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                SKY NO. 1Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1953 on the reversewatercolor and gouache on paper13 1/2  by 9 3/4  in.   34.3 by 24.8  cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Richard Castellane Gallery; New YorkAcquired by the present owner from the above in 1997Ǝ',
          cid: 'contemporary-art-day-sale-n08490-120',
        },
        fields: {
          ymonths: [106],
        },
        sort: [1225497600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2008.yayoi-kusama.contemporary-art-day-sale-n08490-300',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2008.yayoi-kusama.contemporary-art-day-sale-n08490-300',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2008/contemporary-art-day-sale-n08490/lot.300.html',
          salesTitle: 'contemporary-art-day-sale-n08490',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(62500)',
          real: 62500,
          timexcoord: '2008-11-01',
          month: 11,
          name: 'ORIGINAL INFINITY NETS',
          x: 73,
          y: 61,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                ORIGINAL INFINITY NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 2000 on the reverse acrylic on canvas28 5/8  by 23 7/8  in.   72.7 by 60.6  cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n08490-300',
        },
        fields: {
          ymonths: [106],
        },
        sort: [1225497600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2008.5396.340.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51634/d5163429r.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          lot: 340,
          auctionHouse: 'christies',
          high: 30000,
          low: 22500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 15,000 - GBP 20,000',
          birth: 1929,
          realized: 'GBP 16,250',
          real: 24375,
          timexcoord: '2008-12-01',
          sale: 5396,
          month: 12,
          name: 'Pumpkins \r\n',
          x: 38.1,
          y: 45.7,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPumpkins \r\nsigned and dated 'yayoi Kusama 1987' (on the reverse) \r\nacrylic on canvas \r\n15 x 18in. (38.1 x 45.7cm.) \r\nPainted in 1987 \r\n",
          cid: '5396.340',
        },
        fields: {
          ymonths: [107],
        },
        sort: [1228089600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2008.5396.428.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51635/d5163517r.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          lot: 428,
          auctionHouse: 'christies',
          high: 3750,
          low: 2250,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 1,500 - GBP 2,500',
          birth: 1929,
          realized: 'GBP 1,875',
          real: 2812.5,
          timexcoord: '2008-12-01',
          sale: 5396,
          month: 12,
          name: 'Blackhole of the Universe \r\n',
          x: 24.1,
          y: 27,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nBlackhole of the Universe \r\nsigned and dated 'YAYOI, KUSAMA, 1979' (upper left); signed and dated '1979, yayoi Kusama' (on the reverse)\r\npaint and metallic paints on Japanese autograph paper laid down on board\r\n9½ x 10 5/8in. (24.1 x 27cm.) \r\nExecuted in 1979 \r\n",
          cid: '5396.428',
        },
        fields: {
          ymonths: [107],
        },
        sort: [1228089600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2008.5396.430.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51635/d5163519r.jpg',
          year: 2008,
          artist: 'yayoi-kusama',
          lot: 430,
          auctionHouse: 'christies',
          high: 22500,
          low: 15000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 10,000 - GBP 15,000',
          birth: 1929,
          realized: 'GBP 8,750',
          real: 13125,
          timexcoord: '2008-12-01',
          sale: 5396,
          month: 12,
          name: 'Seascape in the Afternoon \r\n',
          x: 41.2,
          y: 32,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nSeascape in the Afternoon \r\nsigned and dated 'YAYOI KUSAMA 1988' (on the reverse) \r\nacrylic on canvas \r\n16¼ x 12 5/8in. (41.2 x 32cm.) \r\nPainted in 1988 \r\n",
          cid: '5396.430',
        },
        fields: {
          ymonths: [107],
        },
        sort: [1228089600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2009.7706.145.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51739/d5173919r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 145,
          auctionHouse: 'christies',
          high: 22500,
          low: 15000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art  Day Sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 10,000 - GBP 15,000',
          birth: 1929,
          realized: 'GBP 15,625',
          real: 23437.5,
          timexcoord: '2009-02-01',
          sale: 7706,
          month: 2,
          name: 'Nets 40 \r\n',
          x: 32,
          y: 41,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nNets 40 \r\nsigned, titled and dated 'yayoi kusama 1997 Nets 40' (on the reverse) \r\nacrylic on canvas \r\n12 5/8 x 16 1/8in. (32 x 41cm.) \r\nPainted in 1997 \r\n",
          cid: '7706.145',
        },
        fields: {
          ymonths: [109],
        },
        sort: [1233446400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2009.yayoi-kusama.contemporary-art-day-sale-l09621-194',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 180000,
          low: 120000,
          currency: 'GBP',
          id:
            'sothebys.2.2009.yayoi-kusama.contemporary-art-day-sale-l09621-194',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-art-day-sale-l09621/lot.194.html',
          salesTitle: 'contemporary-art-day-sale-l09621',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(205250)',
          real: 307875,
          timexcoord: '2009-02-01',
          month: 2,
          name:
            '(I) DOTS ACCUMULATION (II) SEASON OF THE GREEN (III) DOTS ACCUMULATION (IV) THE EVENING GLOW',
          x: 90,
          y: 70,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                (I) DOTS ACCUMULATION (II) SEASON OF THE GREEN (III) DOTS ACCUMULATION (IV) THE EVENING GLOWƎ                Ǝ                    Ǝ                        each: signed; titled and dated 1999 on the reverseeach: acrylic on canvaseach: 90 by 70cm.; 35 3/8  by 27 1/2 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        OTA Fine Arts; TokyoLeo Castelli Gallery; New YorkAcquired directly from the above by the present owner Ǝ',
          cid: 'contemporary-art-day-sale-l09621-194',
        },
        fields: {
          ymonths: [109],
        },
        sort: [1233446400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.2.2009.yayoi-kusama.contemporary-evening-sale-l09620-8',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 200000,
          low: 150000,
          currency: 'GBP',
          id: 'sothebys.2.2009.yayoi-kusama.contemporary-evening-sale-l09620-8',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-evening-sale-l09620/lot.8.html',
          salesTitle: 'contemporary-evening-sale-l09620',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(217250)',
          real: 325875,
          timexcoord: '2009-02-01',
          month: 2,
          name: 'WHITE NETS',
          x: 162,
          y: 162,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                WHITE NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the reverse acrylic on canvas162 by 162cm.63 3/4  by 63 3/4 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        OTA Fine Arts; TokyoAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-evening-sale-l09620-8',
        },
        fields: {
          ymonths: [109],
        },
        sort: [1233446400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2009.2150.135.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51794/d5179495r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 135,
          auctionHouse: 'christies',
          high: 9000,
          low: 7000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 7,000 - USD 9,000',
          birth: 1929,
          realized: 'USD 10,625',
          real: 10625,
          timexcoord: '2009-03-01',
          sale: 2150,
          month: 3,
          name: 'Untitled \r\n',
          x: 18.1,
          y: 14.3,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\nsigned and dated 'YAYOI KUSAMA 1997' (on the reverse) \r\nacrylic on canvas \r\n7 1/8 x 5 5/8 in. (18.1 x 14.3 cm.) \r\nPainted in 1997. \r\n",
          cid: '2150.135',
        },
        fields: {
          ymonths: [110],
        },
        sort: [1235865600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2009.5837.219.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51972/d5197296r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 219,
          auctionHouse: 'christies',
          high: 3000,
          low: 2250,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 1,500 - GBP 2,000',
          birth: 1929,
          realized: 'GBP 2,250',
          real: 3375,
          timexcoord: '2009-04-01',
          sale: 5837,
          month: 4,
          name: 'Silver in Pupil \r\n',
          x: 27.3,
          y: 24.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nSilver in Pupil \r\nsigned and dated '1978 YAYOI KUSAMA' (lower left); signed and dated '1978 yayoi Kusama' (on the reverse)\r\nacrylic and metallic spray paint on card \r\n10¾ x 9½in. (27.3 x 24.1cm.) \r\nExecuted in 1978 \r\n",
          cid: '5837.219',
        },
        fields: {
          ymonths: [111],
        },
        sort: [1238544000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2009.5837.305.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51973/d5197381r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 305,
          auctionHouse: 'christies',
          high: 6000,
          low: 4500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 3,000 - GBP 4,000',
          birth: 1929,
          realized: 'GBP 3,000',
          real: 4500,
          timexcoord: '2009-04-01',
          sale: 5837,
          month: 4,
          name: '(i) Cumulonimbus\r\n(ii) Towards Lake Autumn\r\n',
          x: 27,
          y: 24.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\n(i) Cumulonimbus\r\n(ii) Towards Lake Autumn\r\n(i) signed and dated 'YAYOI KUSAMA 1979' (upper left); signed and dated '1979 yayoi Kusama' (on the reverse)\r\n(ii) signed and dated 'YAYOI KUSAMA 1978 (upper left); 'yayoi Kusama, 1978' (on the reverse)\r\nacrylic and metallic spray paint on card \r\neach: 10 5/8 x 9½in. (27 x 24.1cm.) \r\nExecuted in 1979 \t (2)\r\n",
          cid: '5837.305',
        },
        fields: {
          ymonths: [111],
        },
        sort: [1238544000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2009.5837.279.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51973/d5197355r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 279,
          auctionHouse: 'christies',
          high: 10500,
          low: 7500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 5,000 - GBP 7,000',
          birth: 1929,
          realized: 'GBP 5,000',
          real: 7500,
          timexcoord: '2009-04-01',
          sale: 5837,
          month: 4,
          name: 'Bottom of The Sea \r\n',
          x: 65.5,
          y: 51.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nBottom of The Sea \r\nsigned and dated '1980 yayoi Kusama' (lower right) \r\ngouache, metallic paint, paper and solid glitter paper collage on paper\r\n25¾ x 20 1/8in. (65.5 x 51.1cm.) \r\nExecuted in 1980 \r\n",
          cid: '5837.279',
        },
        fields: {
          ymonths: [111],
        },
        sort: [1238544000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2009.5837.256.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51973/d5197332r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 256,
          auctionHouse: 'christies',
          high: 5250,
          low: 3750,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 2,500 - GBP 3,500',
          birth: 1929,
          realized: 'GBP 3,500',
          real: 5250,
          timexcoord: '2009-04-01',
          sale: 5837,
          month: 4,
          name: '(i) Rain River\r\n(ii) Autumn Lake\r\n',
          x: 27,
          y: 24.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\n(i) Rain River\r\n(ii) Autumn Lake\r\n(i) signed and dated '1983 YAYOI KUSAMA' (lower left)\r\n(ii) signed and dated 'YAYOI KUSAMA 1983' (upper left); signed and dated '1983 yayoi Kusama' (on the reverse)\r\nacrylic and metallic spray paint on card \r\neach: 10 5/8 x 9½in. (27 x 24.1cm.) \r\nExecuted in 1983 \t (2)\r\n",
          cid: '5837.256',
        },
        fields: {
          ymonths: [111],
        },
        sort: [1238544000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2009.5837.281.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d51973/d5197357r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 281,
          auctionHouse: 'christies',
          high: 10500,
          low: 7500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 5,000 - GBP 7,000',
          birth: 1929,
          realized: 'GBP 5,000',
          real: 7500,
          timexcoord: '2009-04-01',
          sale: 5837,
          month: 4,
          name: 'Flower \r\n',
          x: 65.7,
          y: 51.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nFlower \r\nsigned and dated 'yayoi Kusama 1981' (lower left) \r\ngouache, metallic paint, paper collage and glitter on paper \r\n25 7/8 x 20 1/8in. (65.7 x 51.1cm.) \r\nExecuted in 1981 \r\n",
          cid: '5837.281',
        },
        fields: {
          ymonths: [111],
        },
        sort: [1238544000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2009.NY010309.112.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY010309/112/605/550/false/false/false',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 45000,
          provenance: 'Private collection, Japan',
          low: 35000,
          currency: 'USD',
          id: 'phillips.5.2009.NY010309.112.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010309/112',
          salesTitle: 'contemporary art part ii',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 74500,
          timexcoord: '2009-05-01',
          month: 5,
          name: 'Infinity Nets',
          x: 91,
          y: 60,
          location: 'new york',
          stuff:
            "Acrylic on canvas. ^ Signed and dated 'Yayoi Kusama [in English and Japanese] 1991' on the reverse.  This work is accompanied by the Yayoi Kusama Studio artwork registration card. ^ 36 x 23 3/4 in. (91.4 x 60.3 cm). ^ Private collection, Japan ^ 5",
          cid: 'NY010309.112',
        },
        fields: {
          ymonths: [112],
        },
        sort: [1241136000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2009.yayoi-kusama.contemporary-art-day-sale-n08551-144',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 300000,
          low: 200000,
          currency: 'USD',
          id:
            'sothebys.5.2009.yayoi-kusama.contemporary-art-day-sale-n08551-144',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-art-day-sale-n08551/lot.144.html',
          salesTitle: 'contemporary-art-day-sale-n08551',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(182500)',
          real: 182500,
          timexcoord: '2009-05-01',
          month: 5,
          name: 'INFINITY NETS (RRRTOW)',
          x: 194,
          y: 130,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETS (RRRTOW)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the reverseacrylic on canvas76 3/8  by 51 1/8  in.   194 by 130  cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        MOMA Contemporary; FukuokaPrivate Collection; TokyoƎ',
          cid: 'contemporary-art-day-sale-n08551-144',
        },
        fields: {
          ymonths: [112],
        },
        sort: [1241136000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2009.yayoi-kusama.contemporary-art-day-sale-n08551-151',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'USD',
          id:
            'sothebys.5.2009.yayoi-kusama.contemporary-art-day-sale-n08551-151',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-art-day-sale-n08551/lot.151.html',
          salesTitle: 'contemporary-art-day-sale-n08551',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(338500)',
          real: 338500,
          timexcoord: '2009-05-01',
          month: 5,
          name: 'INFINITY NETS',
          x: 194,
          y: 130,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        each signed; titled; dated 2005 and consecutively numbered on the reverseacrylic on canvas; in 4 partsEach: 76 1/4  by 51 1/8  in.   193.6 by 130  cm.Overall: 76 1/4  by 204 3/4  in.   193.6 by 520  cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        MOMA Contemporary; FukuokaPrivate Collection; TokyoƎ                    ExhibitedƎ                        Shimane; Iwami Art Museum; Yayoi Kusama; February - May 2006Ǝ',
          cid: 'contemporary-art-day-sale-n08551-151',
        },
        fields: {
          ymonths: [112],
        },
        sort: [1241136000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.6.2009.2822.124.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52163/d5216397r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 124,
          auctionHouse: 'christies',
          high: 8400,
          low: 6000,
          currency: 'EUR',
          brand: 'Yayoi Kusama (JAPANESE, B. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'EUR 5,000 - EUR 7,000',
          birth: 1929,
          realized: 'EUR 7,500',
          real: 9000,
          timexcoord: '2009-06-01',
          sale: 2822,
          month: 6,
          name: 'Hana (Flower) \r\n',
          x: 18,
          y: 14,
          stuff:
            "Yayoi Kusama (JAPANESE, B. 1929) \r\nHana (Flower) \r\nsigned and dated 'Yayoi/Kusama/1997' (on the reverse) \r\nacrylic on canvas, unframed \r\n18 x 14 cm. \r\n",
          cid: '2822.124',
        },
        fields: {
          ymonths: [113],
        },
        sort: [1243814400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2009.NY030109.167.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY030109/167/605/550/false/false/false',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 1800,
          low: 1200,
          currency: 'USD',
          id: 'phillips.6.2009.NY030109.167.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030109/167',
          salesTitle: 'editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 2500,
          timexcoord: '2009-06-01',
          month: 6,
          name: 'Shoes (pink glitter)',
          x: 60,
          y: 68,
          location: 'new york',
          stuff:
            'Screenprint in colors with glitter, on wove paper, with full margins, ^ signed, dated ‘1999’, annotated in Japanese and numbered 12/60 in pencil, two slight creases at the lower right corner, otherwise in very good condition, framed. ^ I. 17 5/8 x 21 1/8 in. (44.8 x 53.7 cm); S. 23 5/8 x 26 5/8 in. (60 x 67.6 cm) ^ null ^ 6',
          cid: 'NY030109.167',
        },
        fields: {
          ymonths: [113],
        },
        sort: [1243814400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2009.NY030109.168.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY030109/168/605/550/false/false/false',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 1800,
          low: 1200,
          currency: 'USD',
          id: 'phillips.6.2009.NY030109.168.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030109/168',
          salesTitle: 'editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 2125,
          timexcoord: '2009-06-01',
          month: 6,
          name: 'Shoes (green glitter)',
          x: 60,
          y: 68,
          location: 'new york',
          stuff:
            'Screenprint in colors with glitter, on wove paper, with full margins, ^ signed, dated ‘1999’, annotated in Japanese and numbered 22/60 in pencil, a slight crease at the lower right corner, otherwise in very good condition, framed. ^ I. 17 5/8 x 21 1/8 in. (44.8 x 53.7 cm); S. 23 5/8 x 26 5/8 in. (60 x 67.6 cm) ^ null ^ 6',
          cid: 'NY030109.168',
        },
        fields: {
          ymonths: [113],
        },
        sort: [1243814400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.6.2009.yayoi-kusama.contemporary-evening-auction-l09622-24',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 400000,
          low: 300000,
          currency: 'GBP',
          id:
            'sothebys.6.2009.yayoi-kusama.contemporary-evening-auction-l09622-24',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-evening-auction-l09622/lot.24.html',
          salesTitle: 'contemporary-evening-auction-l09622',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(337250)',
          real: 505875,
          timexcoord: '2009-06-01',
          month: 6,
          name: 'INFINITY - NETS (OQABT)',
          x: 304,
          y: 540,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY - NETS (OQABT)Ǝ                Ǝ                    Ǝ                        acrylic on canvas304 by 540cm.119 5/8  by 212 5/8 in.Executed in 2007.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Victoria Miro Gallery; LondonAcquired directly from the above by the present ownerƎ                    ExhibitedƎ                        Rotterdam; Museum Boijmans Van Beuningen; Sydney; Museum of Contemporary Art; Yayoi Kusama Mirrored Years; 2008-09; pp. 5; 6; 15; 17 and 19; illustrated in installation views in colourƎ',
          cid: 'contemporary-evening-auction-l09622-24',
        },
        fields: {
          ymonths: [113],
        },
        sort: [1243814400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.6.2009.yayoi-kusama.contemporary-day-auction-l09623-187',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'GBP',
          id:
            'sothebys.6.2009.yayoi-kusama.contemporary-day-auction-l09623-187',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-day-auction-l09623/lot.187.html',
          salesTitle: 'contemporary-day-auction-l09623',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(91250)',
          real: 136875,
          timexcoord: '2009-06-01',
          month: 6,
          name: 'INFINITY NETS (BCCZY)',
          x: 117,
          y: 91,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETS (BCCZY)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2006 on the reverseoil on canvas117 by 91cm.; 46 by 35 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Moma Contemporary; FukuokaPrivate Collection; TokyoƎ',
          cid: 'contemporary-day-auction-l09623-187',
        },
        fields: {
          ymonths: [113],
        },
        sort: [1243814400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2009.7740.202.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52214/d5221487r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 202,
          auctionHouse: 'christies',
          high: 225000,
          low: 150000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 100,000 - GBP 150,000',
          birth: 1929,
          realized: 'GBP 157,250',
          real: 235875,
          timexcoord: '2009-07-01',
          sale: 7740,
          month: 7,
          name: 'Infinity Nets (W WHOTX) \r\n',
          x: 194.5,
          y: 193.5,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets (W WHOTX) \r\nsigned, titled and dated 'YAYOI KUSAMA 2006 INFINITY-NETS W WHOTX' (on the reverse)\r\nmetallic gold paint and acrylic on canvas \r\n76¼ x 76in. (194.5 x 193.5cm.) \r\nPainted in 2006 \r\n",
          cid: '7740.202',
        },
        fields: {
          ymonths: [114],
        },
        sort: [1246406400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2009.2191.53.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52348/d5234814r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 53,
          auctionHouse: 'christies',
          high: 35000,
          low: 25000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 25,000 - USD 35,000',
          birth: 1929,
          realized: 'USD 40,000',
          real: 40000,
          timexcoord: '2009-09-01',
          sale: 2191,
          month: 9,
          name: 'Coffee Cup \r\n',
          x: 41,
          y: 31.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nCoffee Cup \r\nsigned, titled in Japanese and dated 'Coffee Cup Kusama Yayoi 1979' (on the reverse)\r\nacrylic on canvas \r\n18 x 15 in. (41 x 31.8 cm.) \r\nPainted in 1979. \r\n",
          cid: '2191.53',
        },
        fields: {
          ymonths: [116],
        },
        sort: [1251763200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2009.7756.152.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52509/d5250947r.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          lot: 152,
          auctionHouse: 'christies',
          high: 30000,
          low: 22500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 15,000 - GBP 20,000',
          birth: 1929,
          realized: 'GBP 23,750',
          real: 35625,
          timexcoord: '2009-10-01',
          sale: 7756,
          month: 10,
          name: 'Shinano Road \r\n',
          x: 53.4,
          y: 65.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nShinano Road \r\nsigned, titled and dated in Japanese 'Shinano-road Kusama Yayoi 1983' (on the stretcher)\r\nacrylic on canvas \r\n21 x 25 5/8in. (53.4 x 65.2cm) \r\nPainted in 1983 \r\n",
          cid: '7756.152',
        },
        fields: {
          ymonths: [117],
        },
        sort: [1254355200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2009.NY010409.9.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1535392752/auctions/NY010409/9_001.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 400000,
          provenance: 'OTA Fine Arts, Tokyo',
          low: 300000,
          currency: 'USD',
          id: 'phillips.11.2009.NY010409.9.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010409/9',
          salesTitle: 'contemporary art part i',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 842500,
          timexcoord: '2009-11-01',
          month: 11,
          name: 'Infinity Nets (T.W.A.)',
          x: 194,
          y: 260,
          location: 'new york',
          stuff:
            'Acrylic on canvas. ^ Signed, titled and dated “Yayoi Kusama Infinity Nets (T.W.A.) 2000” on the reverse. ^ 76 1/4 x 102 in. (194 x 260 cm). ^ OTA Fine Arts, Tokyo ^ 11',
          cid: 'NY010409.9',
        },
        fields: {
          ymonths: [118],
        },
        sort: [1257033600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2009.yayoi-kusama.contemporary-art-day-auction-n08593-224',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'USD',
          id:
            'sothebys.11.2009.yayoi-kusama.contemporary-art-day-auction-n08593-224',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-art-day-auction-n08593/lot.224.html',
          salesTitle: 'contemporary-art-day-auction-n08593',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(116500)',
          real: 116500,
          timexcoord: '2009-11-01',
          month: 11,
          name: 'LOVE IN THE NIGHT',
          x: 163,
          y: 131,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                LOVE IN THE NIGHTƎ                Ǝ                    Ǝ                        signed; titled and dated 1988 on the stretcheracrylic on canvas64 by 51 3/8  in.   162.6 by 130.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Fuji Television Gallery; TokyoAcquired by the present owner from the aboveƎ                    ExhibitedƎ                        Tokyo; Fuji Television Gallery; Soul Burning Flashes; June 1988; cat. no. 7; illustrated in colorƎ',
          cid: 'contemporary-art-day-auction-n08593-224',
        },
        fields: {
          ymonths: [118],
        },
        sort: [1257033600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2009.yayoi-kusama.contemporary-art-day-auction-n08593-234',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2009,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id:
            'sothebys.11.2009.yayoi-kusama.contemporary-art-day-auction-n08593-234',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2009/contemporary-art-day-auction-n08593/lot.234.html',
          salesTitle: 'contemporary-art-day-auction-n08593',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(74500)',
          real: 74500,
          timexcoord: '2009-11-01',
          month: 11,
          name: 'INFINITY-NETS (OPQRT)',
          x: 65,
          y: 53,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (OPQRT)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the reverseacrylic on canvas25 5/8  by 20 7/8  in.   65.2 by 53  cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        MOMA Contemporary; FukuokaAquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-auction-n08593-234',
        },
        fields: {
          ymonths: [118],
        },
        sort: [1257033600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2010.yayoi-kusama.contemporary-art-day-auction-l10021-165',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'GBP',
          id:
            'sothebys.2.2010.yayoi-kusama.contemporary-art-day-auction-l10021-165',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-l10021/lot.165.html',
          salesTitle: 'contemporary-art-day-auction-l10021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(27500)',
          real: 41250,
          timexcoord: '2010-02-01',
          month: 2,
          name: 'METEMPSYCHOSIS',
          x: 72,
          y: 60,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                METEMPSYCHOSISƎ                Ǝ                    Ǝ                        signed and dated 1988 on the reverseacrylic on canvas72 by 60cm.; 28 3/8  by 23 5/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired directly from the above by the present ownerƎ Ǝ',
          cid: 'contemporary-art-day-auction-l10021-165',
        },
        fields: {
          ymonths: [121],
        },
        sort: [1264982400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2010.yayoi-kusama.contemporary-art-day-auction-l10021-313',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'GBP',
          id:
            'sothebys.2.2010.yayoi-kusama.contemporary-art-day-auction-l10021-313',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-l10021/lot.313.html',
          salesTitle: 'contemporary-art-day-auction-l10021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(28750)',
          real: 43125,
          timexcoord: '2010-02-01',
          month: 2,
          name: 'PUMPKIN',
          x: 53,
          y: 46,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed and dated 1990 on the reverseacrylic on canvas52.5 by 45.5cm.; 20 3/4  by 18in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Umi Gallery; TokyoPrivate Collection; JapanAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-l10021-313',
        },
        fields: {
          ymonths: [121],
        },
        sort: [1264982400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.2.2010.UK010210.142.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/UK010210/142/605/550/false/false/false',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 9000,
          provenance: 'Private Collection, Tokyo',
          low: 7000,
          currency: 'GBP',
          id: 'phillips.2.2010.UK010210.142.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010210/142',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 13125,
          timexcoord: '2010-02-01',
          month: 2,
          name: 'Night Bird',
          x: 66,
          y: 53,
          location: 'london',
          stuff:
            'Ink, gouache, glitter, pastel and paper collage on paper. ^ Signed and dated ‘Yayoi Kusama 1981’ lower left; signed in Japanese and English and dated ‘Yayoi Kusama 1981’ on the reverse. This work is registered in the Kusama Studio Archive under the number 1166. ^ 65.8 x 52.5 cm (25 7/8 x 20 5/8 in). ^ Private Collection, Tokyo ^ 2',
          cid: 'UK010210.142',
        },
        fields: {
          ymonths: [121],
        },
        sort: [1264982400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2010.2294.161.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52931/d5293154r.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 161,
          auctionHouse: 'christies',
          high: 30000,
          low: 20000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 20,000 - USD 30,000',
          birth: 1929,
          realized: 'USD 32,500',
          real: 32500,
          timexcoord: '2010-03-01',
          sale: 2294,
          month: 3,
          name: 'Nets & Red No. 8 \r\n',
          x: 25.4,
          y: 20.3,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nNets & Red No. 8 \r\nsigned, titled and dated 'YAYOI KUSAMA 1958 NETS & RED' (on the reverse)\r\npastel and netting on paper \r\n10 x 8 in. (25.4 x 20.3 cm.) \r\nExecuted in 1958. \r\n",
          cid: '2294.161',
        },
        fields: {
          ymonths: [122],
        },
        sort: [1267401600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2010.2294.154.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52931/d5293147r.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 154,
          auctionHouse: 'christies',
          high: 30000,
          low: 20000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 20,000 - USD 30,000',
          birth: 1929,
          realized: 'USD 30,000',
          real: 30000,
          timexcoord: '2010-03-01',
          sale: 2294,
          month: 3,
          name: 'Untitled \r\n',
          x: 52.7,
          y: 45,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\nsigned and dated '1970 KUSAMA' (lower edge); signed and dated again 'Yayoi Kusama 1970' (on the reverse)\r\nink on canvas \r\n20¾ x 17¾ in. (52.7 x 45 cm.) \r\nExecuted in 1970. \r\n",
          cid: '2294.154',
        },
        fields: {
          ymonths: [122],
        },
        sort: [1267401600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2010.2294.20.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52930/d5293011r.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 20,
          auctionHouse: 'christies',
          high: 15000,
          low: 10000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 10,000 - USD 15,000',
          birth: 1929,
          realized: 'USD 62,500',
          real: 62500,
          timexcoord: '2010-03-01',
          sale: 2294,
          month: 3,
          name: 'Untitled (No. 19) \r\n',
          x: 33,
          y: 38.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled (No. 19) \r\nsigned, titled and dated '19 Yayoi Kusama 1953' (on the reverse)\r\noil, charcoal and graphite on paper \r\n13 x 15 in. (33 x 38.1 cm.) \r\nPainted in 1953. \r\n",
          cid: '2294.20',
        },
        fields: {
          ymonths: [122],
        },
        sort: [1267401600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2010.2294.153.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52931/d5293146r.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 153,
          auctionHouse: 'christies',
          high: 30000,
          low: 20000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 20,000 - USD 30,000',
          birth: 1929,
          realized: 'USD 35,000',
          real: 35000,
          timexcoord: '2010-03-01',
          sale: 2294,
          month: 3,
          name: 'Hitomi \r\n',
          x: 31.7,
          y: 41.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nHitomi \r\nsigned, titled and dated 'HITOMI 1989 Yayoi Kusama' (on the reverse)\r\nacrylic on canvas \r\n12½ x 16¼ in. (31.7 x 41.2 cm.) \r\nPainted in 1989. \r\n",
          cid: '2294.153',
        },
        fields: {
          ymonths: [122],
        },
        sort: [1267401600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2010.2294.25.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/d52930/d5293016r.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 25,
          auctionHouse: 'christies',
          high: 150000,
          low: 100000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open Post-War and Contemporary Art ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 100,000 - USD 150,000',
          birth: 1929,
          realized: 'USD 818,500',
          real: 818500,
          timexcoord: '2010-03-01',
          sale: 2294,
          month: 3,
          name: 'Repetitive-Vision \r\n',
          x: 50.8,
          y: 83.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nRepetitive-Vision \r\nsigned and dated '1963 yayoi kusama' (lower left edge); signed again, titled and dated again 'KUSAMA 1963 REPETITIVE-VISION' (on the reverse)\r\nstamps on paper \r\n20 x 32¾ in. (50.8 x 83.1 cm.) \r\nExecuted in 1963. \r\n",
          cid: '2294.25',
        },
        fields: {
          ymonths: [122],
        },
        sort: [1267401600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2010.NY010110.124.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY010110/124/605/550/false/false/false',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 300000,
          provenance: 'Ota Fine Arts, Tokyo; Private Collection, Tokyo',
          low: 200000,
          currency: 'USD',
          id: 'phillips.5.2010.NY010110.124.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010110/124',
          salesTitle: 'contemporary art part i',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 422500,
          timexcoord: '2010-05-01',
          month: 5,
          name: 'Infinity Nets (OQ4)',
          x: 162,
          y: 130,
          location: 'new york',
          stuff:
            'Acrylic on canvas. ^ Signed, titled and dated “Yayoi Kusama Infinity-Nets (OQ4) 2000” on the reverse. ^ 63 3/4 x 51 1/4 in. (162 x 130.2 cm). ^ Ota Fine Arts, Tokyo; Private Collection, Tokyo ^ 5',
          cid: 'NY010110.124',
        },
        fields: {
          ymonths: [124],
        },
        sort: [1272672000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2010.NY010210.243.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY010210/243/605/550/false/false/false',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance: 'Robert Miller Gallery, New York',
          low: 15000,
          currency: 'USD',
          id: 'phillips.5.2010.NY010210.243.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010210/243',
          salesTitle: 'contemporary art part ii',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 20000,
          timexcoord: '2010-05-01',
          month: 5,
          name: 'Cloudy Evening',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            'Spray paint on board. ^ Signed and dated “Yayoi Kusama 1978” lower right and again on the reverse. ^ 10 5/8 x 9 3/8 in. (27 x 23.8 cm). ^ Robert Miller Gallery, New York ^ 5',
          cid: 'NY010210.243',
        },
        fields: {
          ymonths: [124],
        },
        sort: [1272672000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2010.yayoi-kusama.contemporary-art-evening-auction-n08636-28',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 1500000,
          low: 1000000,
          currency: 'USD',
          id:
            'sothebys.5.2010.yayoi-kusama.contemporary-art-evening-auction-n08636-28',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-evening-auction-n08636/lot.28.html',
          salesTitle: 'contemporary-art-evening-auction-n08636',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(1202500)',
          real: 1202500,
          timexcoord: '2010-05-01',
          month: 5,
          name: 'THE WEST',
          x: 127,
          y: 121,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                THE WESTƎ                Ǝ                    Ǝ                        signed; titled and dated 1960 on the reverseoil on canvas50 x 47 3/4  in.  127 x 121.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; Washington; D.C (acquired directly from the artist)Acquired by the present owner from the above circa 1970Ǝ                    ExhibitedƎ                        Washington; D.C.; Gres Gallery; Yayoi Kusama; April 1960Pittsburgh; Carnegie Institute; Department of Fine Arts; The1961 Pittsburgh International Exhibition of Contemporary Painting and Sculpture; October 1961 - January 1962; cat. no. 210Philadelphia; Institute of Contemporary Art; University of Pennsylvania; Group Zero; October 1964New York; Center for International Contemporary Arts; Yayoi Kusama: A Retrospective; September 1989 – January 1990Ǝ',
          cid: 'contemporary-art-evening-auction-n08636-28',
        },
        fields: {
          ymonths: [124],
        },
        sort: [1272672000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-140',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-140',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-n08637/lot.140.html',
          salesTitle: 'contemporary-art-day-auction-n08637',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(134500)',
          real: 134500,
          timexcoord: '2010-05-01',
          month: 5,
          name: 'UNTITLED',
          x: 60,
          y: 45,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                UNTITLEDƎ                Ǝ                    Ǝ                        signed and dated 1953gouache on colored paper23 1/2  by 17 1/2  in.   59.7 by 44.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Paula Cooper Gallery. New YorkNewspace Gallery; Los AngelesAcquired by the present owner from the above in December 2000Ǝ Ǝ                    ExhibitedƎ                        New York; Paula Cooper Gallery; Yayoi Kusama; The 1950s and 1960s; Paintings; Sculpture; Works on Paper; May - June 1996; no. 15; illustrated in colorƎ Ǝ',
          cid: 'contemporary-art-day-auction-n08637-140',
        },
        fields: {
          ymonths: [124],
        },
        sort: [1272672000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-145',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 25000,
          low: 18000,
          currency: 'USD',
          id:
            'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-145',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-n08637/lot.145.html',
          salesTitle: 'contemporary-art-day-auction-n08637',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(62500)',
          real: 62500,
          timexcoord: '2010-05-01',
          month: 5,
          name: 'NETS',
          x: 41,
          y: 32,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 1997 on the reverseacrylic on canvas16 1/4  by 12 1/2  in.   41.3 by 31.8 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Anthony Meier Fine Arts; San FranciscoPrivate Collection; ParisSotheby's; New York; September 10; 2008; lot 328Acquired by the present owner from the above saleƎ",
          cid: 'contemporary-art-day-auction-n08637-145',
        },
        fields: {
          ymonths: [124],
        },
        sort: [1272672000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-146',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-146',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-n08637/lot.146.html',
          salesTitle: 'contemporary-art-day-auction-n08637',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(104500)',
          real: 104500,
          timexcoord: '2010-05-01',
          month: 5,
          name: 'DAWNING',
          x: 72,
          y: 90,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                DAWNINGƎ                Ǝ                    Ǝ                        signed; titled and dated 2002 on the reverseacrylic on canvas28 1/2  by 35 1/2  in.   72.4 by 90.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-auction-n08637-146',
        },
        fields: {
          ymonths: [124],
        },
        sort: [1272672000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-231',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 400000,
          low: 300000,
          currency: 'USD',
          id:
            'sothebys.5.2010.yayoi-kusama.contemporary-art-day-auction-n08637-231',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-n08637/lot.231.html',
          salesTitle: 'contemporary-art-day-auction-n08637',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(494500)',
          real: 494500,
          timexcoord: '2010-05-01',
          month: 5,
          name: 'INFINITY-NETS (TOWWH)',
          x: 194,
          y: 194,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (TOWWH)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the reverseacrylic on canvas76 1/4  by 76 1/4  in.   193.7 by 193.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        OTA Fine Arts; TokyoVictoria Miro Gallery; LondonAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-auction-n08637-231',
        },
        fields: {
          ymonths: [124],
        },
        sort: [1272672000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.6.2010.7860.23.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53353/yayoi_kusama_accreations_i_d5335307g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 23,
          auctionHouse: 'christies',
          high: 900000,
          low: 600000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War and Contemporary Art  Evening Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 400,000 - GBP 600,000',
          birth: 1929,
          realized: 'GBP 433,250',
          real: 649875,
          timexcoord: '2010-06-01',
          sale: 7860,
          month: 6,
          name: 'Accreations I \r\n',
          x: 69.8,
          y: 59.6,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nAccreations I \r\nsigned and dated '67 KUSAMA' (on the stretcher of the left canvas); signed and dated 'KUSAMA 67' (on the reverse of the center canvas) and titled 'ACCREATIONS I' (on the stretcher); signed and dated 'Y. KUSAMA 67' (on the reverse of the right canvas)\r\noil on canvas, in three parts \r\neach: 27½ x 23½ in. (69.8 x 59.6 cm.)\r\noverall: 27½ x 70½ in. (69.8 x 179 cm.)\r\nPainted in 1967 \r\n",
          cid: '7860.23',
        },
        fields: {
          ymonths: [125],
        },
        sort: [1275350400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2010.NY030110.383.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030110/383_001.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 1800,
          low: 1200,
          currency: 'USD',
          id: 'phillips.6.2010.NY030110.383.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030110/383',
          salesTitle: 'editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 1875,
          timexcoord: '2010-06-01',
          month: 6,
          name: 'Nami 1 (yellow)',
          x: 71,
          y: 57,
          location: 'new york',
          stuff:
            "Screenprint in colors, on Arches paper, with full margins, ^ signed, dated `1998', annotated `(1)' inscribed in Japanese and numbered 21/30 in pencil, very minor surface soiling, otherwise in very good condition, framed. ^ I. 23 1/2 x 18 7/8 in. (59.7 x 47.9 cm); S. 27 3/4 x 22 1/4 in. (70.5 x 56.5 cm) ^ null ^ 6",
          cid: 'NY030110.383',
        },
        fields: {
          ymonths: [125],
        },
        sort: [1275350400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2010.NY030110.385.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030110/385_001.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 1800,
          low: 1200,
          currency: 'USD',
          id: 'phillips.6.2010.NY030110.385.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030110/385',
          salesTitle: 'editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 1625,
          timexcoord: '2010-06-01',
          month: 6,
          name: 'Pumpkin',
          x: 46,
          y: 63,
          location: 'new york',
          stuff:
            "Etching, on Rives BFK gray paper, with full margins, ^ signed, dated `85', inscribed in Japanese and numbered 25/50 in pencil, in very good condition, framed. ^ I. 11 x 16 5/8 in. (27.9 x 42.2 cm); S. 18 x 24 3/4 in. (45.7 x 62.9 cm) ^ null ^ 6",
          cid: 'NY030110.385',
        },
        fields: {
          ymonths: [125],
        },
        sort: [1275350400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2010.NY030110.386.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030110/386_001.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 800,
          low: 600,
          currency: 'USD',
          id: 'phillips.6.2010.NY030110.386.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030110/386',
          salesTitle: 'editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 1375,
          timexcoord: '2010-06-01',
          month: 6,
          name: 'Town',
          x: 38,
          y: 29,
          location: 'new york',
          stuff:
            "Etching, on wove paper, with full margins, ^ signed dated `1995' inscribed in Japanese and numbered 46/100 in pencil, in excellent condition, framed. ^ I. 8 3/4 x 5 3/4 in. (22.2 x 14.6 cm); S. 14 7/8 x 11 1/4 in. (37.8 x 28.6 cm) ^ null ^ 6",
          cid: 'NY030110.386',
        },
        fields: {
          ymonths: [125],
        },
        sort: [1275350400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2010.7861.260.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53355/yayoi_kusama_interminable_nets_d5335507g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 260,
          auctionHouse: 'christies',
          high: 90000,
          low: 60000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War  and Contemporary Art  Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 40,000 - GBP 60,000',
          birth: 1929,
          realized: 'GBP 49,250',
          real: 73875,
          timexcoord: '2010-07-01',
          sale: 7861,
          month: 7,
          name: 'Interminable Nets (Green no. 53) \r\n',
          x: 116.6,
          y: 91,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInterminable Nets (Green no. 53) \r\nsigned, titled and dated 'GREEN NO 53, 1982, Yayoi Kusama, Interminable Nets' (on the stretcher)\r\nacrylic on canvas \r\n45 7/8 x 35 7/8in. (116.6 x 91cm.) \r\nPainted in 1982 \r\n",
          cid: '7861.260',
        },
        fields: {
          ymonths: [126],
        },
        sort: [1277942400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2010.7861.263.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53355/yayoi_kusama_infinity-cosmos_d5335510g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 263,
          auctionHouse: 'christies',
          high: 105000,
          low: 75000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War  and Contemporary Art  Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 50,000 - GBP 70,000',
          birth: 1929,
          realized: 'GBP 80,450',
          real: 120675,
          timexcoord: '2010-07-01',
          sale: 7861,
          month: 7,
          name: 'INFINITY-COSMOS (TOWH) \r\n',
          x: 194,
          y: 194,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nINFINITY-COSMOS (TOWH) \r\nsigned, titled and dated 'INFINITY-COSMOS (TOWH) YAYOI KUSAMA 2007' (on the stretcher)\r\nurethane resin on canvas \r\n76 3/8 x 76 3/8in. (194 x 194cm.) \r\nExecuted in 2007 \r\n",
          cid: '7861.263',
        },
        fields: {
          ymonths: [126],
        },
        sort: [1277942400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2010.7861.262.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53355/yayoi_kusama_pumpkin_twaa_d5335509g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 262,
          auctionHouse: 'christies',
          high: 18000,
          low: 12000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War  and Contemporary Art  Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 8,000 - GBP 12,000',
          birth: 1929,
          realized: 'GBP 12,500',
          real: 18750,
          timexcoord: '2010-07-01',
          sale: 7861,
          month: 7,
          name: 'PUMPKIN TWAA \r\n',
          x: 15.8,
          y: 22.9,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPUMPKIN TWAA \r\nsigned, titled, inscribed in Japanese and dated 'Yayoi Kusama 2003 PUMPKIN TWAA' (on the reverse)\r\nacrylic on canvas \r\n6¼ x 9in. (15.8 x 22.9cm.) \r\nPainted in 2003 \r\n",
          cid: '7861.262',
        },
        fields: {
          ymonths: [126],
        },
        sort: [1277942400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2010.7861.256.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53355/yayoi_kusama_untitled_d5335503g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 256,
          auctionHouse: 'christies',
          high: 45000,
          low: 30000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War  and Contemporary Art  Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 20,000 - GBP 30,000',
          birth: 1929,
          realized: 'GBP 25,000',
          real: 37500,
          timexcoord: '2010-07-01',
          sale: 7861,
          month: 7,
          name: 'Untitled \r\n',
          x: 60.3,
          y: 50.2,
          stuff:
            'Yayoi Kusama (b. 1929) \r\nUntitled \r\noil on canvas \r\n23¾ x 19¾in. (60.3 x 50.2cm.) \r\nPainted circa 1970 \r\n',
          cid: '7861.256',
        },
        fields: {
          ymonths: [126],
        },
        sort: [1277942400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2010.2341.64.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53516/yayoi_kusama_infinity_dots_d5351686g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 64,
          auctionHouse: 'christies',
          high: 30000,
          low: 20000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 20,000 - USD 30,000',
          birth: 1929,
          realized: 'USD 27,500',
          real: 27500,
          timexcoord: '2010-09-01',
          sale: 2341,
          month: 9,
          name: 'Infinity Dots \r\n',
          x: 45.7,
          y: 38.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Dots \r\nsigned and dated 'Yayoi Kusama 1990' (on the reverse)\r\nacrylic on canvas \r\n18 x 15 in. (45.7 x 38.1 cm.) \r\nPainted in 1990. \r\n",
          cid: '2341.64',
        },
        fields: {
          ymonths: [128],
        },
        sort: [1283299200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2010.2341.61.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53516/yayoi_kusama_infinity-nets_d5351683g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 61,
          auctionHouse: 'christies',
          high: 70000,
          low: 50000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 50,000 - USD 70,000',
          birth: 1929,
          realized: 'USD 92,500',
          real: 92500,
          timexcoord: '2010-09-01',
          sale: 2341,
          month: 9,
          name: 'Infinity-Nets \r\n',
          x: 53.3,
          y: 45.7,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity-Nets \r\nsigned, titled and incorrectly dated by the artist '1959 INFINITY-NETS YAYOI KUSAMA' (on the reverse)\r\noil on canvas \r\n21 x 18 in. (53.3 x 45.7 cm.) \r\nPainted circa 1979-1981. \r\n",
          cid: '2341.61',
        },
        fields: {
          ymonths: [128],
        },
        sort: [1283299200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2010.2341.78.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D53517/yayoi_kusama_untitled_d5351700g.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          lot: 78,
          auctionHouse: 'christies',
          high: 12000,
          low: 8000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 8,000 - USD 12,000',
          birth: 1929,
          realized: 'USD 21,250',
          real: 21250,
          timexcoord: '2010-09-01',
          sale: 2341,
          month: 9,
          name: 'Untitled \r\n',
          x: 90.4,
          y: 68.2,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\nsigned and dated '1981 Yayoi Kusama' (lower right); signed again and dated again '1981 Yayoi Kusama' (on the reverse)\r\ncharcoal, ink, oil, silk and printed paper collage on paper \r\n35 5/8 x 26 7/8 in. (90.4 x 68.2 cm.) \r\nExecuted in 1981. \r\n",
          cid: '2341.78',
        },
        fields: {
          ymonths: [128],
        },
        sort: [1283299200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2010.yayoi-kusama.contemporary-art-n08662-55',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'USD',
          id: 'sothebys.9.2010.yayoi-kusama.contemporary-art-n08662-55',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-n08662/lot.55.html',
          salesTitle: 'contemporary-art-n08662',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(10000)',
          real: 10000,
          timexcoord: '2010-09-01',
          month: 9,
          name: 'THE STALK',
          x: 23,
          y: 16,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                THE STALKƎ                Ǝ                    Ǝ                        signed and dated 1952; titled; stamped with the artist's name and address in Japanese and the date 1952 on the reversewatercolor and gouache on paper9 by 6 3/8  in.22.9 by 16.2 cmƎ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gertrude Stein Gallery; New YorkPrivate Collection; New YorkAcquired by the present owner from the aboveƎ",
          cid: 'contemporary-art-n08662-55',
        },
        fields: {
          ymonths: [128],
        },
        sort: [1283299200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2010.yayoi-kusama.contemporary-art-n08662-194',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'USD',
          id: 'sothebys.9.2010.yayoi-kusama.contemporary-art-n08662-194',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-n08662/lot.194.html',
          salesTitle: 'contemporary-art-n08662',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(12500)',
          real: 12500,
          timexcoord: '2010-09-01',
          month: 9,
          name: 'MORNING',
          x: 66,
          y: 51,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                MORNINGƎ                Ǝ                    Ǝ                        signed and dated 1980; signed and dated 1980 on the reversecollage; gouache and pastel on paper25 7/8  by 20 1/4  in.65.7 by 51.3 cmƎ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        MOMA Contemporary; FukuokaPrivate Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-n08662-194',
        },
        fields: {
          ymonths: [128],
        },
        sort: [1283299200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2010.yayoi-kusama.contemporary-art-day-auction-n08679-323',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.11.2010.yayoi-kusama.contemporary-art-day-auction-n08679-323',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-n08679/lot.323.html',
          salesTitle: 'contemporary-art-day-auction-n08679',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(46875)',
          real: 46875,
          timexcoord: '2010-11-01',
          month: 11,
          name: 'UNTITLED',
          x: 43,
          y: 31,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                UNTITLEDƎ                Ǝ                    Ǝ                        signed and dated 1951; signed and dated 1951 on the backing boardoil and pastel on paper17 by 12 in.;  43.2 by 30.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gallery Te; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-auction-n08679-323',
        },
        fields: {
          ymonths: [130],
        },
        sort: [1288569600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2010.yayoi-kusama.contemporary-art-day-auction-n08679-325',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2010,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.11.2010.yayoi-kusama.contemporary-art-day-auction-n08679-325',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2010/contemporary-art-day-auction-n08679/lot.325.html',
          salesTitle: 'contemporary-art-day-auction-n08679',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(62500)',
          real: 62500,
          timexcoord: '2010-11-01',
          month: 11,
          name: 'HEART',
          x: 51,
          y: 51,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                HEARTƎ                Ǝ                    Ǝ                        signed; titled and dated 2005 on the reverseacrylic on canvas20 1/4  by 20 1/4  in.   51.4 by 51.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Fuji Television Gallery; TokyoPrivate Collection; TokyoAcquired by the present owner from the aboveƎ Ǝ',
          cid: 'contemporary-art-day-auction-n08679-325',
        },
        fields: {
          ymonths: [130],
        },
        sort: [1288569600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2011.7956.222.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D54071/yayoi_kusama_infinity_nets_cpw_d5407168g.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          lot: 222,
          auctionHouse: 'christies',
          high: 375000,
          low: 225000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art  Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 150,000 - GBP 250,000',
          birth: 1929,
          realized: 'GBP 169,250',
          real: 253875,
          timexcoord: '2011-02-01',
          sale: 7956,
          month: 2,
          name: 'Infinity Nets CPW \r\n',
          x: 162,
          y: 162,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets CPW \r\nsigned, titled in English and Japanese and dated 'CPW YAYOI KUSAMA 2007 INFINITY-NETS' (on the reverse)\r\nacrylic on canvas \r\n63 5/8 x 63 5/8in. (162 x 162cm.) \r\nExectued in 2007 \r\n",
          cid: '7956.222',
        },
        fields: {
          ymonths: [133],
        },
        sort: [1296518400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2011.yayoi-kusama.contemporary-art-day-auction-l11021-235',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'GBP',
          id:
            'sothebys.2.2011.yayoi-kusama.contemporary-art-day-auction-l11021-235',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-art-day-auction-l11021/lot.235.html',
          salesTitle: 'contemporary-art-day-auction-l11021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(45650)',
          real: 68475,
          timexcoord: '2011-02-01',
          month: 2,
          name: 'PUMPKIN',
          x: 46,
          y: 37,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed and dated 1992 on the reverseacrylic on canvas45.7 by 37.4cm.; 18 by 14 3/4 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanSale: Sotheby's London; Contemporary Art; 25 March 1999; Lot 21Private Collection; LondonSale: Christie's London; Post-War and Contemporary Art; 7 February 2008; Lot 570Private Collection; SwitzerlandƎ",
          cid: 'contemporary-art-day-auction-l11021-235',
        },
        fields: {
          ymonths: [133],
        },
        sort: [1296518400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2011.5452.135.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D54285/yayoi_kusama_pistils_and_stamens_d5428529g.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          lot: 135,
          auctionHouse: 'christies',
          high: 10500,
          low: 7500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 5,000 - GBP 7,000',
          birth: 1929,
          realized: 'GBP 6,000',
          real: 9000,
          timexcoord: '2011-04-01',
          sale: 5452,
          month: 4,
          name: 'Pistils and Stamens \r\n',
          x: 50.8,
          y: 65.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPistils and Stamens \r\nsigned and dated '1980 yayoi Kusama' (lower right); signed and dated '1980 yayoi Kusama' (on the reverse)\r\npaper collage, holographic paper, acrylic, pastel and metallic paint on paper\r\n20 x 25 7/8in. (50.8 x 65.8cm.) \r\nExecuted in 1980 \r\n",
          cid: '5452.135',
        },
        fields: {
          ymonths: [135],
        },
        sort: [1301616000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2011.NY010211.177.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY010211/177/605/550/false/false/false',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance: 'Private collection, New York',
          low: 15000,
          currency: 'USD',
          id: 'phillips.5.2011.NY010211.177.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010211/177',
          salesTitle: 'contemporary art part ii',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 18750,
          timexcoord: '2011-05-01',
          month: 5,
          name: 'Seven Petal Flower',
          x: 36,
          y: 30,
          location: 'new york',
          stuff:
            "Pastel, ink and gouache on paper ^ Signed, titled in Japanese and dated 'Yayoi Kusama 1953' on a label adhered to the reverse. ^ 14 x 11 7/8 in. (35.6 x 30.2 cm.) ^ Private collection, New York ^ 5",
          cid: 'NY010211.177',
        },
        fields: {
          ymonths: [136],
        },
        sort: [1304208000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2011.NY010211.222.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY010211/222/605/550/false/false/false',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 70000,
          provenance:
            "Private collection; Sale: New York, Sotheby's, Contemporary Art, November 10, 2004, Lot 585",
          low: 50000,
          currency: 'USD',
          id: 'phillips.5.2011.NY010211.222.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010211/222',
          salesTitle: 'contemporary art part ii',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 206500,
          timexcoord: '2011-05-01',
          month: 5,
          name: 'Gold Accumulation 2',
          x: 117,
          y: 91,
          location: 'new york',
          stuff:
            "Acrylic on canvas. ^ Signed and dated 'Yayoi Kusama, 1999' on the reverse. ^ 46 x 36 in. (116.8 x 91.4 cm.) ^ Private collection; Sale: New York, Sotheby's, Contemporary Art, November 10, 2004, Lot 585 ^ 5",
          cid: 'NY010211.222',
        },
        fields: {
          ymonths: [136],
        },
        sort: [1304208000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2011.yayoi-kusama.contemporary-i-n08744-57',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 400000,
          low: 300000,
          currency: 'USD',
          id: 'sothebys.5.2011.yayoi-kusama.contemporary-i-n08744-57',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-i-n08744/lot.57.html',
          salesTitle: 'contemporary-i-n08744',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(662500)',
          real: 662500,
          timexcoord: '2011-05-01',
          month: 5,
          name: 'RED-NETS NO. 2.A.3',
          x: 71,
          y: 56,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                RED-NETS NO. 2.A.3Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1960 on the reverseoil on canvas28 x 22 in.  71.1 x 55.9 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; New York (acquired directly from the artist circa 1965)Thence by descent to the present owner from the aboveƎ',
          cid: 'contemporary-i-n08744-57',
        },
        fields: {
          ymonths: [136],
        },
        sort: [1304208000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2011.yayoi-kusama.contemporary-ii-n08745-208',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 40000,
          low: 30000,
          currency: 'USD',
          id: 'sothebys.5.2011.yayoi-kusama.contemporary-ii-n08745-208',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-ii-n08745/lot.208.html',
          salesTitle: 'contemporary-ii-n08745',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(86500)',
          real: 86500,
          timexcoord: '2011-05-01',
          month: 5,
          name: 'PUMPKIN',
          x: 53,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled and dated 1981 on the reverse; signed; titled and dated 1981 on the stretcheracrylic on canvas21 by 18 in.   53.3 by 45.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-ii-n08745-208',
        },
        fields: {
          ymonths: [136],
        },
        sort: [1304208000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2011.yayoi-kusama.contemporary-ii-n08745-204',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 350000,
          low: 250000,
          currency: 'USD',
          id: 'sothebys.5.2011.yayoi-kusama.contemporary-ii-n08745-204',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-ii-n08745/lot.204.html',
          salesTitle: 'contemporary-ii-n08745',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(434500)',
          real: 434500,
          timexcoord: '2011-05-01',
          month: 5,
          name: 'INFINITY-NETS (QASTW)',
          x: 194,
          y: 194,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (QASTW)Ǝ                Ǝ                    Ǝ                        signed; titled twice and dated 2007 on the reverseacrylic on canvas76 3/4  by 76 3/4  in.   194.4 by 194.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-ii-n08745-204',
        },
        fields: {
          ymonths: [136],
        },
        sort: [1304208000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2011.2465.200.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D54780/yayoi_kusama_airmail--accumulation_d5478020g.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          lot: 200,
          auctionHouse: 'christies',
          high: 200000,
          low: 150000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open  Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 150,000 - USD 200,000',
          birth: 1929,
          realized: 'USD 158,500',
          real: 158500,
          timexcoord: '2011-09-01',
          sale: 2465,
          month: 9,
          name: 'Airmail--Accumulation \r\n',
          x: 23.5,
          y: 30.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nAirmail--Accumulation \r\nsigned, titled, and dated '1963 YAYOI KUSAMA AIRMAIL-ACCUMULATION' (on the reverse)\r\nstamps on paper \r\n9¼ x 12 1/8 in. (23.5 x 30.8 cm.) \r\nExecuted in 1963. \r\n",
          cid: '2465.200',
        },
        fields: {
          ymonths: [140],
        },
        sort: [1314835200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2011.2465.11.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D54778/yayoi_kusama_untitled_d5477831g.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          lot: 11,
          auctionHouse: 'christies',
          high: 20000,
          low: 15000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open  Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 15,000 - USD 20,000',
          birth: 1929,
          realized: 'USD 21,250',
          real: 21250,
          timexcoord: '2011-09-01',
          sale: 2465,
          month: 9,
          name: 'Untitled \r\n',
          x: 36.8,
          y: 33,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\nsigned and dated '1952 YAYOI KUSAMA' (on the reverse) \r\nink, watercolor and colored pencil on paper \r\n14½ x 13 in. (36.8 x 33 cm.) \r\nPainted in 1952. \r\n",
          cid: '2465.11',
        },
        fields: {
          ymonths: [140],
        },
        sort: [1314835200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2011.NY000411.98.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/NY000411/98/605/550/false/false/false',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 15000,
          provenance: 'Gallery Te, Tokyo Acquired from the above, 1994',
          low: 10000,
          currency: 'USD',
          id: 'phillips.9.2011.NY000411.98.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY000411/98',
          salesTitle: 'under the influence',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 12500,
          timexcoord: '2011-09-01',
          month: 9,
          name: 'Muroto Peninsula',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            "Spray paint on card. ^ Signed and dated 'YAYOI KUSAMA 1978' upper left. Also signed, inscribed in Japanese and dated '1978 Yayoi Kusama' on the reverse. ^ 10 3/4 x 9 1/2 in. (27.3 x 24.1 cm) ^ Gallery Te, Tokyo Acquired from the above, 1994 ^ 9",
          cid: 'NY000411.98',
        },
        fields: {
          ymonths: [140],
        },
        sort: [1314835200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2011.7991.275.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D54860/yayoi_kusama_untitled_d5486067g.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          lot: 275,
          auctionHouse: 'christies',
          high: 45000,
          low: 30000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War & Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 20,000 - GBP 30,000',
          birth: 1929,
          realized: 'GBP 37,250',
          real: 55875,
          timexcoord: '2011-10-01',
          sale: 7991,
          month: 10,
          name: 'Untitled \r\n',
          x: 53.3,
          y: 46.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\ninscribed in Japanese; signed and dated 'Yayoi Kusama 1990' (on the reverse)\r\nacrylic on canvas \r\n21 x 18 1/8in. (53.3 x 46.1cm.) \r\nPainted in 1990 \r\n",
          cid: '7991.275',
        },
        fields: {
          ymonths: [141],
        },
        sort: [1317427200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2011.UK010711.325.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://www.phillips.com/xigen/lotimg/yayoi-kusama/UK010711/325/605/550/false/false/false',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 12000,
          provenance: 'Private Collection, Europe',
          low: 8000,
          currency: 'GBP',
          id: 'phillips.10.2011.UK010711.325.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010711/325',
          salesTitle: 'contemporary art day...',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 24375,
          timexcoord: '2011-10-01',
          month: 10,
          name: 'Upward Vision (No. 1)',
          x: 41,
          y: 33,
          location: 'london',
          stuff:
            "Graphite, ink, gouache and pastel on paper. ^ Signed and titled in an unidentifiable hand 'Upward Vision No 1 YAYOI KUSAMA' on the reverse. ^ 41 x 33.3 cm (16 1/8 x 13 1/8 in). ^ Private Collection, Europe ^ 10",
          cid: 'UK010711.325',
        },
        fields: {
          ymonths: [141],
        },
        sort: [1317427200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.10.2011.yayoi-kusama.contemporary-art-day-sale--124',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 45000,
          low: 35000,
          currency: 'GBP',
          id: 'sothebys.10.2011.yayoi-kusama.contemporary-art-day-sale--124',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-art-day-sale-/lot.124.html',
          salesTitle: 'contemporary-art-day-sale-',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(46850)',
          real: 70275,
          timexcoord: '2011-10-01',
          month: 10,
          name: 'INFINITY NETS',
          x: 91,
          y: 73,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        signed; tittled and dated 1990 on the reverseacrylic on canvas91 by 73cm.; 35 7/8  by 28 3/4 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Acquired directly from the artist by the present owner in 1990Ǝ',
          cid: 'contemporary-art-day-sale--124',
        },
        fields: {
          ymonths: [141],
        },
        sort: [1317427200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-180',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 200000,
          low: 150000,
          currency: 'USD',
          id:
            'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-180',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-art-day-sale-n08792/lot.180.html',
          salesTitle: 'contemporary-art-day-sale-n08792',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(278500)',
          real: 278500,
          timexcoord: '2011-11-01',
          month: 11,
          name: 'INFINITY NETS (B.A.Y.)',
          x: 100,
          y: 100,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETS (B.A.Y.)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2001 on the reverseacrylic on canvas39 3/8  by 39 3/8  in.   100 by 100 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Roslyn Oxley9 Gallery; SidneyOta Fine Arts; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n08792-180',
        },
        fields: {
          ymonths: [142],
        },
        sort: [1320105600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-186',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-186',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-art-day-sale-n08792/lot.186.html',
          salesTitle: 'contemporary-art-day-sale-n08792',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(104500)',
          real: 104500,
          timexcoord: '2011-11-01',
          month: 11,
          name: 'NETS',
          x: 33,
          y: 26,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NETSƎ                Ǝ                    Ǝ                        signed; signed; titled and dated 1957 on the reverseoil on paper13 by 10 1/4  in.   33 by 26 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Richard Castellane; New YorkPrivate Collection; NagoyaƎ                    ExhibitedƎ                        New York; Center for International Contemporary Arts; Yayoi Kusama: Retrospective; September 1989 - January 1990; cat. no.12; p. 42; illustrated in colorƎ',
          cid: 'contemporary-art-day-sale-n08792-186',
        },
        fields: {
          ymonths: [142],
        },
        sort: [1320105600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-185',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 90000,
          low: 70000,
          currency: 'USD',
          id:
            'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-185',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-art-day-sale-n08792/lot.185.html',
          salesTitle: 'contemporary-art-day-sale-n08792',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(92500)',
          real: 92500,
          timexcoord: '2011-11-01',
          month: 11,
          name: 'NETS 9',
          x: 61,
          y: 73,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NETS 9Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1997 on the reverseacrylic on canvas23 7/8  by 28 5/8  in.   60.6 by 72.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gabian und Claude Walter Galerie; ZurichRobert Miller Gallery; New YorkPrivate Collection; SwitzerlandSotheby's; New York; November 15; 2006; lot 298Acquired by the present owner from the above saleƎ",
          cid: 'contemporary-art-day-sale-n08792-185',
        },
        fields: {
          ymonths: [142],
        },
        sort: [1320105600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-187',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2011,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.11.2011.yayoi-kusama.contemporary-art-day-sale-n08792-187',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2011/contemporary-art-day-sale-n08792/lot.187.html',
          salesTitle: 'contemporary-art-day-sale-n08792',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(86500)',
          real: 86500,
          timexcoord: '2011-11-01',
          month: 11,
          name: 'WHITE DOTS',
          x: 73,
          y: 61,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                WHITE DOTSƎ                Ǝ                    Ǝ                        signed; titled and dated 1990 on the reverseacrylic on canvas28 3/4  by 24 in.   73 by 61 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gallery HAM; NagoyaAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n08792-187',
        },
        fields: {
          ymonths: [142],
        },
        sort: [1320105600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2012.4885.35.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D55451/yayoi_kusama_fish_d5545124g.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          lot: 35,
          auctionHouse: 'christies',
          high: 10500,
          low: 7500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 5,000 - GBP 7,000',
          birth: 1929,
          realized: 'GBP 13,750',
          real: 20625,
          timexcoord: '2012-03-01',
          sale: 4885,
          month: 3,
          name: 'Fish \r\n',
          x: 65.7,
          y: 51.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nFish \r\nsigned and dated 'yayoi Kusama 1980' (lower right); signed, titled in Japanese and dated '1980 yayoi Kusama' (on the reverse)\r\npaper collage, holographic paper, charcoal, gouache, ink and metallic paint on paper\r\n25 7/8 x 20 1/8in. (65.7 x 51.1cm.) \r\nExecuted in 1980 \r\n",
          cid: '4885.35',
        },
        fields: {
          ymonths: [146],
        },
        sort: [1330560000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2012.4885.31.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D55451/yayoi_kusama_yellow_flower_d5545120g.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          lot: 31,
          auctionHouse: 'christies',
          high: 15000,
          low: 10500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 7,000 - GBP 10,000',
          birth: 1929,
          realized: 'GBP 17,500',
          real: 26250,
          timexcoord: '2012-03-01',
          sale: 4885,
          month: 3,
          name: 'Yellow Flower \r\n',
          x: 51.4,
          y: 65.7,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nYellow Flower \r\nsigned and dated 'YAYOI KUSAMA 1979' (upper right); signed, titled in Japanese and dated 'YAYOI KUSAMA 1979' (on the reverse)\r\ngouache and felt tip pen on paper \r\n20¼ x 25 7/8in. (51.4 x 65.7cm.) \r\nExecuted in 1979 \r\n",
          cid: '4885.31',
        },
        fields: {
          ymonths: [146],
        },
        sort: [1330560000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2012.2543.294.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D55447/yayoi_kusama_selected_images_and_ephemera_of_kusama_happenings_new_yor_d5544722g.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          lot: 294,
          auctionHouse: 'christies',
          high: 12000,
          low: 8000,
          currency: 'USD',
          brand: 'YAYOI KUSAMA (B. 1929) \r\n',
          salesTitle: 'Photographs',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 8,000 - USD 12,000',
          birth: 1929,
          realized: 'USD 15,000',
          real: 15000,
          timexcoord: '2012-04-01',
          sale: 2543,
          month: 4,
          name:
            "Selected images and ephemera of Kusama 'happenings', New York, 1967-68 \r\n",
          x: 11.6,
          y: 19.5,
          stuff:
            "YAYOI KUSAMA (B. 1929) \r\nSelected images and ephemera of Kusama 'happenings', New York, 1967-68 \r\n15 gelatin silver prints; accompanied by a number of contemporary posters, including covers from Screw and Orgy magazines; as well as a great deal of other ephemera\r\none with credit, date, extensive annotation by Bob Sabin in ink and six with Shunk Kender, Paris credit stamp (on the verso)\r\nvarious dimensions (gelatin silver prints), image: 4 5/8 x 7¾in. (11.6 x 19.5cm.) to 10¼ x 13¼in. 25 x 33.5cm.)\r\nsheet: 5 x 8¼in. (12.5 x 20.5cm.) to 11 x 14in. (28 x 35.4cm.) \t (27)\r\n",
          cid: '2543.294',
        },
        fields: {
          ymonths: [147],
        },
        sort: [1333238400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2012.NY010212.22.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010212/22_001.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 750000,
          provenance: 'Gagosian Gallery, New York',
          low: 550000,
          currency: 'USD',
          id: 'phillips.5.2012.NY010212.22.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010212/22',
          salesTitle: 'contemporary art evening',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 662500,
          timexcoord: '2012-05-01',
          month: 5,
          name: 'Infinity Nets OPQR',
          x: 259,
          y: 194,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ null ^ 102 x 76 3/8 in. (259.1 x 194 cm) ^ Gagosian Gallery, New York ^ 5',
          cid: 'NY010212.22',
        },
        fields: {
          ymonths: [148],
        },
        sort: [1335830400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2012.NY010312.164.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010312/164_001.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 200000,
          provenance: 'Studio Guenzani, Milan',
          low: 150000,
          currency: 'USD',
          id: 'phillips.5.2012.NY010312.164.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010312/164',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 326500,
          timexcoord: '2012-05-01',
          month: 5,
          name: 'Nets Infinity',
          x: 100,
          y: 100,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled, and dated 'Yayoi Kusama, 2002, Nets Infinity' on the reverse. ^ 39 3/8 x 39 3/8 in. (100 x 100 cm) ^ Studio Guenzani, Milan ^ 5",
          cid: 'NY010312.164',
        },
        fields: {
          ymonths: [148],
        },
        sort: [1335830400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2012.NY010312.174.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010312/174_001.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 180000,
          provenance: 'OTA Fine Art, Tokyo Studio Guenzani, Milan',
          low: 120000,
          currency: 'USD',
          id: 'phillips.5.2012.NY010312.174.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010312/174',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 284500,
          timexcoord: '2012-05-01',
          month: 5,
          name: 'Nets Infinity QTA',
          x: 112,
          y: 146,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled, and dated 'Yayoi Kusama, 2004, Nets-Infinity QTA' on the reverse. ^ 44 1/8 x 57 1/2 in. (112 x 146 cm) ^ OTA Fine Art, Tokyo Studio Guenzani, Milan ^ 5",
          cid: 'NY010312.174',
        },
        fields: {
          ymonths: [148],
        },
        sort: [1335830400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2012.UK010412.123.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010412/123_001.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 80000,
          provenance: 'Private Collection, London',
          low: 60000,
          currency: 'GBP',
          id: 'phillips.6.2012.UK010412.123.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010412/123',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 196275,
          timexcoord: '2012-06-01',
          month: 6,
          name: 'Heart',
          x: 117,
          y: 91,
          location: 'london',
          stuff:
            'acrylic on canvas ^ Signed, titled and dated ‘1999 HEART YAYOI KUSAMA’ on the reverse ^ 117 × 91 cm (46 1/8 × 35 7/8 in) ^ Private Collection, London ^ 6',
          cid: 'UK010412.123',
        },
        fields: {
          ymonths: [149],
        },
        sort: [1338508800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.6.2012.yayoi-kusama.contemporary-art-day-auction-l12023-334',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'GBP',
          id:
            'sothebys.6.2012.yayoi-kusama.contemporary-art-day-auction-l12023-334',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-l12023/lot.334.html',
          salesTitle: 'contemporary-art-day-auction-l12023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(73250)',
          real: 109875,
          timexcoord: '2012-06-01',
          month: 6,
          name: 'NETS 15',
          x: 65,
          y: 53,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                NETS 15Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1998 on the reverseacrylic on canvas65.2 by 53cm.; 25 3/4  by 20 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkFabian & Claude Walter Galerie; ZürichAcquired directly from the above by the present owner in 2004Ǝ',
          cid: 'contemporary-art-day-auction-l12023-334',
        },
        fields: {
          ymonths: [149],
        },
        sort: [1338508800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2012.NY000412.37.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY000412/37_001.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 35000,
          provenance:
            "Sale: Sotheby's, London, Contemporary Art, June 23, 2005, lot 438 Acquired at the above sale by the present owner",
          low: 25000,
          currency: 'USD',
          id: 'phillips.9.2012.NY000412.37.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY000412/37',
          salesTitle: 'under the influence',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 74500,
          timexcoord: '2012-09-01',
          month: 9,
          name: 'Sunshine',
          x: 46,
          y: 38,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed and dated '1988 Yayoi Kusama' on the reverse. ^ 18 x 15 1/8 in. (45.7 x 38.4 cm) ^ Sale: Sotheby's, London, Contemporary Art, June 23, 2005, lot 438 Acquired at the above sale by the present owner ^ 9",
          cid: 'NY000412.37',
        },
        fields: {
          ymonths: [152],
        },
        sort: [1346457600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2012.5710.219.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56083/yayoi_kusama_infinity_nets_d5608346g.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          lot: 219,
          auctionHouse: 'christies',
          high: 60000,
          low: 45000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 30,000 - GBP 40,000',
          birth: 1929,
          realized: 'GBP 91,250',
          real: 136875,
          timexcoord: '2012-10-01',
          sale: 5710,
          month: 10,
          name: 'Infinity Nets (0902F) \r\n',
          x: 72.7,
          y: 60.7,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets (0902F) \r\nsigned, titled and dated 'Yayoi Kusama 2000 infinity nets (0902F)' (on the reverse)\r\nacrylic on canvas \r\n28 5/8 x 23 7/8in. (72.7 x 60.7cm.) \r\nPainted in 2000 \r\n",
          cid: '5710.219',
        },
        fields: {
          ymonths: [153],
        },
        sort: [1349049600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2012.UK010612.139.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010612/139_001.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 150000,
          provenance:
            'Sotheby’s, London, ‘Contemporary Art Day Auction’, 30 June 2011, lot 228 Acquired from the above sale by the present owner',
          low: 100000,
          currency: 'GBP',
          id: 'phillips.10.2012.UK010612.139.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010612/139',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 181875,
          timexcoord: '2012-10-01',
          month: 10,
          name: 'Infinity Nets LNXA',
          x: 97,
          y: 130,
          location: 'london',
          stuff:
            'acrylic on canvas ^ Signed, titled and dated ‘2011’ on the reverse. ^ 97 x 130.3 cm (38 1/4 x 51 1/4) ^ Sotheby’s, London, ‘Contemporary Art Day Auction’, 30 June 2011, lot 228 Acquired from the above sale by the present owner ^ 10',
          cid: 'UK010612.139',
        },
        fields: {
          ymonths: [153],
        },
        sort: [1349049600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2012.UK010612.143.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010612/143_001.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 30000,
          provenance: 'Gallery Sho, Tokyo Private Collection, Dublin',
          low: 20000,
          currency: 'GBP',
          id: 'phillips.10.2012.UK010612.143.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010612/143',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 68475,
          timexcoord: '2012-10-01',
          month: 10,
          name: 'Petals',
          x: 53,
          y: 45,
          location: 'london',
          stuff:
            'acrylic on canvas ^ Signed and dated ‘Yayoi Kusama 1988’ on the reverse. ^ 53 x 45 cm (20 7/8 x 17 3/4 in) ^ Gallery Sho, Tokyo Private Collection, Dublin ^ 10',
          cid: 'UK010612.143',
        },
        fields: {
          ymonths: [153],
        },
        sort: [1349049600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2012.yayoi-kusama.contemporary-art-evening-auction-l12024-53',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 150000,
          low: 100000,
          currency: 'GBP',
          id:
            'sothebys.10.2012.yayoi-kusama.contemporary-art-evening-auction-l12024-53',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-evening-auction-l12024/lot.53.html',
          salesTitle: 'contemporary-art-evening-auction-l12024',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(87650)',
          real: 131475,
          timexcoord: '2012-10-01',
          month: 10,
          name: 'NIGHT OF THE STARS (TWOSA)',
          x: 194,
          y: 194,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NIGHT OF THE STARS (TWOSA)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the stretcherurethane resin on canvas194 by 194cm.76 3/8  by 76 3/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Victoria Miro Gallery; LondonPrivate CollectionAcquired directly from the above by the present ownerƎ                    ExhibitedƎ                        London; Victoria Miro Gallery; Yayoi Kusama; 2008; n.p.; illustrated in colourƎ',
          cid: 'contemporary-art-evening-auction-l12024-53',
        },
        fields: {
          ymonths: [153],
        },
        sort: [1349049600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-236',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-236',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.236.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(53125)',
          real: 53125,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'ACUMURETION EARTH',
          x: 31,
          y: 35,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                ACUMURETION EARTHƎ                Ǝ                    Ǝ                        signed; titled and dated 1952 on the reversegouache and pastel on paper12 by 13 7/8  in.   30.5 by 35.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n08901-236',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-237',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 180000,
          low: 120000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-237',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.237.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(176500)',
          real: 176500,
          timexcoord: '2012-11-01',
          month: 11,
          name: "A CIRCUS RIDER'S DREAM",
          x: 75,
          y: 65,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                A CIRCUS RIDER'S DREAMƎ                Ǝ                    Ǝ                        signed and dated 1955gouache on paper29 1/2  by 25 3/8  in.   74.9 by 64.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; CanadaPrivate Collection; JapanƎ",
          cid: 'contemporary-art-day-auction-n08901-237',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-240',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-240',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.240.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(170500)',
          real: 170500,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'INFINITY NETS',
          x: 90,
          y: 72,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        signed and dated 1989 on the reverseacrylic on canvas35 1/2  by 28 1/2  in.   90.2 by 72.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n08901-240',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-241',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-241',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.241.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(98500)',
          real: 98500,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'SEA OF DAWN',
          x: 65,
          y: 80,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                SEA OF DAWNƎ                Ǝ                    Ǝ                        signed and dated 1978; signed; titled in Japanese and dated 1978 on the reverseoil and spray paint on canvas25 1/2  by 31 1/2  in.   64.8 by 80 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Umi Gallery; TokyoPrivate Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n08901-241',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-242',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 250000,
          low: 200000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-242',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.242.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(494500)',
          real: 494500,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'PUMPKIN',
          x: 72,
          y: 91,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed on the stretcher; titled in Japanese and dated 1981 on the reverseacrylic and collage on canvas28 1/2  by 36 in.   72.4 by 91.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n08901-242',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-244',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-244',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.244.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(86500)',
          real: 86500,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'WATCHING THE SEA',
          x: 46,
          y: 38,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                WATCHING THE SEAƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1989 on the reverseacrylic on canvas18 by 15 in.   45.7 by 38.1 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n08901-244',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-243',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-243',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.243.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(104500)',
          real: 104500,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'SHOE LEFT IN THE FIELD',
          x: 38,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                SHOE LEFT IN THE FIELDƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1989 on the reverseacrylic on canvas15 by 18 in.   38.1 by 45.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n08901-243',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-245',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-245',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.245.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(92500)',
          real: 92500,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'IN THE FOREST',
          x: 66,
          y: 50,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                IN THE FORESTƎ                Ǝ                    Ǝ                        signed and dated 1981; titled in Japanese and inscribed 1961 on the reversegouache; pastel; paper collage and glitter on paper26 by 19 3/4  in.   66 by 50.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n08901-245',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-249',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 40000,
          low: 30000,
          currency: 'USD',
          id:
            'sothebys.11.2012.yayoi-kusama.contemporary-art-day-auction-n08901-249',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/contemporary-art-day-auction-n08901/lot.249.html',
          salesTitle: 'contemporary-art-day-auction-n08901',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(56250)',
          real: 56250,
          timexcoord: '2012-11-01',
          month: 11,
          name: 'STARS',
          x: 41,
          y: 32,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                STARSƎ                Ǝ                    Ǝ                        signed and dated 1994 on the reverseacrylic on canvas16 1/8  by 12 5/8  in.   41 by 32 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gallery Halcyon; OsakaPrivate Collection; Los Angeles (acquired from the above in 1998)Ǝ',
          cid: 'contemporary-art-day-auction-n08901-249',
        },
        fields: {
          ymonths: [154],
        },
        sort: [1351728000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.12.2012.yayoi-kusama.art-contemporain-pf1215-24',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/PF1/PF1215/209PF1215_6MMD6_1.jpg',
          year: 2012,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 200000,
          low: 150000,
          currency: 'EUR',
          id: 'sothebys.12.2012.yayoi-kusama.art-contemporain-pf1215-24',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/art-contemporain-pf1215/lot.24.html',
          salesTitle: 'art-contemporain-pf1215',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(312750)',
          real: 375300,
          timexcoord: '2012-12-01',
          month: 12,
          name:
            'INFINITY-NETS (SSOWH)SIGNED; TITLED AND DATED 2006 ON THE BACK; ACRYLIC ON CANVAS.',
          x: 100,
          y: 100,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N.1929Ǝ                INFINITY-NETS (SSOWH)SIGNED; TITLED AND DATED 2006 ON THE BACK; ACRYLIC ON CANVAS.Ǝ                Ǝ                    Ǝ                        signé; titré et daté 2006 au dosacrylique sur toile100 x 100 cm; 39  3/8  x 39  3/9  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         Un certificat d'authenticité de l'oeuvre émis par le Yayoi Kusama Studio sera remis à l'acquéreur.ƎƎ                        ProvenanceƎ                        Vente: The Market Auction; Tokyo; 14 juin 2008; lot 35Acquis lors de cette vente par le propriétaire actuelƎ",
          cid: 'art-contemporain-pf1215-24',
        },
        fields: {
          ymonths: [155],
        },
        sort: [1354320000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2013.1106.6.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56523/yayoi_kusama_pumpkin_d5652373g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 6,
          auctionHouse: 'christies',
          high: 300000,
          low: 225000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art (Evening Auction)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 150,000 - GBP 200,000',
          birth: 1929,
          realized: 'GBP 505,250',
          real: 757875,
          timexcoord: '2013-02-01',
          sale: 1106,
          month: 2,
          name: 'Pumpkin \r\n',
          x: 130.5,
          y: 162.6,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPumpkin \r\nsigned, titled in Japanese and dated 'YAYOI KUSAMA 1980' (on the reverse); signed and dated 'Yayoi Kusama 1980' (on the stretcher)\r\nacrylic, graphite and fabric collage on canvas \r\n51 3/8 x 64in. (130.5 x 162.6cm.) \r\nExecuted in 1980 \r\n",
          cid: '1106.6',
        },
        fields: {
          ymonths: [157],
        },
        sort: [1359676800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2013.2683.38.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56529/yayoi_kusama_pumpkin_d5652984g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 38,
          auctionHouse: 'christies',
          high: 8000,
          low: 6000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 6,000 - USD 8,000',
          birth: 1929,
          realized: 'USD 30,000',
          real: 30000,
          timexcoord: '2013-03-01',
          sale: 2683,
          month: 3,
          name: 'Pumpkin \r\n',
          x: 24.1,
          y: 27.3,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPumpkin \r\nsigned twice, stamped and dated twice 'Yayoi Kusama 1980' (upper left) and '1980 Yayoi Kusama' (on the reverse)\r\nwatercolor on cardboard \r\n9½ x 10¾ in. (24.1 x 27.3 cm.) \r\nPainted in 1980. \r\n",
          cid: '2683.38',
        },
        fields: {
          ymonths: [158],
        },
        sort: [1362096000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2013.2683.37.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56529/yayoi_kusama_early_summer_d5652983g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 37,
          auctionHouse: 'christies',
          high: 12000,
          low: 8000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 8,000 - USD 12,000',
          birth: 1929,
          realized: 'USD 18,750',
          real: 18750,
          timexcoord: '2013-03-01',
          sale: 2683,
          month: 3,
          name: 'Early Summer \r\n',
          x: 40,
          y: 33,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nEarly Summer \r\nsigned 'Yayoi Kusama 2002' (lower right) \r\nacrylic, ink and pastel on paper \r\n15¾ x 13 in. (40 x 33 cm.) \r\nPainted in 2002. \r\n",
          cid: '2683.37',
        },
        fields: {
          ymonths: [158],
        },
        sort: [1362096000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2013.yayoi-kusama.single-owner-ectp-march-n08962-27',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 150000,
          low: 100000,
          currency: 'USD',
          id: 'sothebys.3.2013.yayoi-kusama.single-owner-ectp-march-n08962-27',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/single-owner-ectp-march-n08962/lot.27.html',
          salesTitle: 'single-owner-ectp-march-n08962',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(338500)',
          real: 338500,
          timexcoord: '2013-03-01',
          month: 3,
          name: 'COSMIC DOOR',
          x: 130,
          y: 97,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                COSMIC DOORƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1995 on the reverseacrylic on canvas51 1/4  by 38 1/4  in.   130.2 by 97.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Victoria Miro Gallery; LondonAcquired by the present owner from the above in October 2000Ǝ                    LiteratureƎ                        Arlène Bonnant; CAP Collection; Switzerland; 2005; p. 157; illustrated in colorƎ',
          cid: 'single-owner-ectp-march-n08962-27',
        },
        fields: {
          ymonths: [158],
        },
        sort: [1362096000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2013.yayoi-kusama.single-owner-ectp-march-n08962-28',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'USD',
          id: 'sothebys.3.2013.yayoi-kusama.single-owner-ectp-march-n08962-28',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2012/single-owner-ectp-march-n08962/lot.28.html',
          salesTitle: 'single-owner-ectp-march-n08962',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(134500)',
          real: 134500,
          timexcoord: '2013-03-01',
          month: 3,
          name: 'GRASS B',
          x: 117,
          y: 91,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                GRASS BƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1991 on the reverseacrylic on canvas46 by 35 7/8  in.   116.8 by 91 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Victoria Miro Gallery; LondonAcquired by the present owner from the above in October 2000Ǝ                    LiteratureƎ                        Arlène Bonnant; CAP Collection; Switzerland; 2005; p. 156; illustrated in colorƎ',
          cid: 'single-owner-ectp-march-n08962-28',
        },
        fields: {
          ymonths: [158],
        },
        sort: [1362096000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2013.NY030013.183.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030013/183_001.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 5000,
          low: 3000,
          currency: 'USD',
          id: 'phillips.4.2013.NY030013.183.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030013/183',
          salesTitle: 'editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 2250,
          timexcoord: '2013-04-01',
          month: 4,
          name: 'Mirror Box (Type A); and Mirror Box (Type B)',
          x: 14,
          y: 17,
          location: 'new york',
          stuff:
            'Two screenprinted boxes with mirror, plexi glass, metal balls and lenses, ^ both signed in silver ink, numbered 58/280 and 97/280 in orange pencil and black ink respectively, published by Okabe Print Editions Ltd., Kanagawa, Japan, occasional minor soiling and wear, one lacking the outer lens, otherwise both in very good condition. ^ both 5 1/2 x 6 3/4 in (14 x 17.1 cm) ^ null ^ 4',
          cid: 'NY030013.183',
        },
        fields: {
          ymonths: [159],
        },
        sort: [1364774400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2013.3207.102.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56914/yayoi_kusama_pumpkins_d5691448g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 102,
          auctionHouse: 'christies',
          high: 65000,
          low: 52000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (b. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 400,000 - HKD 500,000',
          birth: 1929,
          realized: 'HKD 1,050,000',
          real: 136500,
          timexcoord: '2013-05-01',
          sale: 3207,
          month: 5,
          name: 'Pumpkins \r\n',
          x: 38,
          y: 45,
          stuff:
            "YAYOI KUSAMA (b. 1929) \r\nPumpkins \r\nsigned 'Yayoi Kusama' in English and Japanese; and dated '1989' (on the reverse)\r\nPainted in 1989\r\nacrylic on canvas \r\n38 x 45 cm. (14 7/8 x 17 3/4 in.) \r\nPainted in 1989 \r\n1 \r\n",
          cid: '3207.102',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2013.3207.170.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56915/yayoi_kusama_hitomi_d5691515g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 170,
          auctionHouse: 'christies',
          high: 39000,
          low: 26000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (b. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 200,000 - HKD 300,000',
          birth: 1929,
          realized: 'HKD 990,000',
          real: 128700,
          timexcoord: '2013-05-01',
          sale: 3207,
          month: 5,
          name: 'Hitomi \r\n',
          x: 31.7,
          y: 41.2,
          stuff:
            "YAYOI KUSAMA (b. 1929) \r\nHitomi \r\nsigned, titled and dated 'HITOMI 1989 Yayoi Kusama' (on the reverse)\r\nPainted in 1989\r\nacrylic on canvas \r\n31.7 x 41.2 cm. (12 1/2 x 16 1/4 in.) \r\nPainted in 1989 \r\n",
          cid: '3207.170',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2013.3207.410.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: 'http://www.christies.com/lotfinderimages/D56917/_d5691754g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 410,
          auctionHouse: 'christies',
          high: 32500,
          low: 19500,
          currency: 'HKD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 150,000 - HKD 250,000',
          birth: 1929,
          realized: 'HKD 600,000',
          real: 78000,
          timexcoord: '2013-05-01',
          sale: 3207,
          month: 5,
          name: 'Amour pour Toujours (set of 10) \r\n',
          x: 36.2,
          y: 41.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nAmour pour Toujours (set of 10) \r\nExecuted in 2000\r\nedition of 3/12\r\ncover. signed in English; dated '2000' (lower right)\r\n1. signed in English (lower right); titled 'Chapeau (I)'; dated '2000' (lower middle)\r\n2. titled 'Chapeau (II); dated '2000' (lower middle); signed in English (lower right)\r\n3. signed in English (lower right); titled 'Panier de Fruits (II)'; dated '2000' (lower middle)\r\n4. signed in English (lower right); titled 'Panier de Fruits (I)'; dated '2000' (lower middle)\r\n5. signed in English (lower right); titled 'Tulipe (I)'; dated '2000' (lower middle)\r\n6. signed in English; dated '2000' (lower middle; titled 'Tulipe (II)' (lower left)\r\n7. signed in English (lower right); titled 'Papillon (II)'; dated '2000' (lower middle)\r\n8. signed in English; titled 'Papillon (I)'; dated '2000'\r\n9. signed in English (lower right); titled 'Citrouille (I)'; dated '2000' (lower middle)\r\n10. signed in English (lower right); titled 'Ctrouille (II)'; dated '2000' (lower middle)\r\nten screenprint \r\n36.2 x 41.8 cm (22 1/8 x 16 1/8 in.); 2. 38 x 45.5 cm (15 x 17 7/8 in.); 37.9 x 45.5 cm (14. 7/8 x 17 7/8 in.); 4. 37.7 x 45.6 cm (14 7/8 x 17 7/8 in.); .38 x 45.6 cm (14 7/8 x 17 7/8 in.); 45. 2 x 38 cm (17 7/8 x 14 7/8 in.); 45.3 x 38 cm (17 7/8 x 14 7/8 in.); 38 x 45.3 cm (14 7/8 x 17 7/8 in.); 38 x 45.3 cm (14 7/8 x 17 7/8 in.);  37. 7 x 45 cm (14 7/8 x 17 7/8 in.); & 37.3 x 45 cm (14 7/8 x 17 7/8 in.)\r\n21st Century \t (10)\r\n",
          cid: '3207.410',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2013.3207.166.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56915/yayoi_kusama_the_death_of_youth_d5691511g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 166,
          auctionHouse: 'christies',
          high: 65000,
          low: 45500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (b. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 350,000 - HKD 500,000',
          birth: 1929,
          realized: 'HKD 1,350,000',
          real: 175500,
          timexcoord: '2013-05-01',
          sale: 3207,
          month: 5,
          name: 'The Death of Youth \r\n',
          x: 45,
          y: 37.8,
          stuff:
            "YAYOI KUSAMA (b. 1929) \r\nThe Death of Youth \r\nsigned 'Yayoyi Kusama' in English; dated '1989'; titled in Japanese (on the reverse)\r\nPainted in 1989\r\nacrylic on canvas \r\n45 x 37.8 cm. (17 3/4 x 14 7/8 in.) \r\nPainted in 1989 \r\n",
          cid: '3207.166',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2013.3207.101.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D56914/yayoi_kusama_flowers_d5691447g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 101,
          auctionHouse: 'christies',
          high: 58500,
          low: 45500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA(B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 350,000 - HKD 450,000',
          birth: 1929,
          realized: 'HKD 475,000',
          real: 61750,
          timexcoord: '2013-05-01',
          sale: 3207,
          month: 5,
          name: 'Flowers \r\n',
          x: 46,
          y: 38.5,
          stuff:
            'YAYOI KUSAMA(B. 1929) \r\nFlowers \r\nPainted in 1989 \r\nacrylic on canvas \r\n46 x 38.5 cm. (18 1/8 x 15 1/4 in.) \r\nPainted in 1989 \r\n',
          cid: '3207.101',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-178',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'USD',
          id:
            'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-178',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/may-2013-contemporary-day-n08992/lot.178.html',
          salesTitle: 'may-2013-contemporary-day-n08992',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(581000)',
          real: 581000,
          timexcoord: '2013-05-01',
          month: 5,
          name: 'WHITE NETS',
          x: 162,
          y: 162,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                WHITE NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the reverseacrylic on canvas63 3/4  by 63 3/4  in.   161.9 by 161.9 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        OTA Fine Arts; TokyoPrivate Collection; GenevaSotheby's; London; February 5; 2009; lot 8Acquired by the present owner from the above saleƎ",
          cid: 'may-2013-contemporary-day-n08992-178',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-180',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'USD',
          id:
            'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-180',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/may-2013-contemporary-day-n08992/lot.180.html',
          salesTitle: 'may-2013-contemporary-day-n08992',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(557000)',
          real: 557000,
          timexcoord: '2013-05-01',
          month: 5,
          name: 'NET-OBSESSION',
          x: 51,
          y: 41,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NET-OBSESSIONƎ                Ǝ                    Ǝ                        signed twice; titled twice and dated twice 1964 on the reverse; titled on the stretcheroil on canvas20 1/4  by 16 1/8  in.   51.4 by 41 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Acquired directly from the artist circa 1966Ǝ',
          cid: 'may-2013-contemporary-day-n08992-180',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-220',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'USD',
          id:
            'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-220',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/may-2013-contemporary-day-n08992/lot.220.html',
          salesTitle: 'may-2013-contemporary-day-n08992',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(21250)',
          real: 21250,
          timexcoord: '2013-05-01',
          month: 5,
          name: 'FALLING RAIN IN THE OCEAN',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                FALLING RAIN IN THE OCEANƎ                Ǝ                    Ǝ                        signed and dated 1978; signed; titled and dated 1978 on the reverseenamel on paperboard10 1/2  by 9 1/2  in.   26.7 by 24.1 cm.Executed in 1978; this work is accompanied with an artwork registration card issued by the studio.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'may-2013-contemporary-day-n08992-220',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-221',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'USD',
          id:
            'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-221',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/may-2013-contemporary-day-n08992/lot.221.html',
          salesTitle: 'may-2013-contemporary-day-n08992',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(15000)',
          real: 15000,
          timexcoord: '2013-05-01',
          month: 5,
          name: 'SEA',
          x: 24,
          y: 27,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                SEAƎ                Ǝ                    Ǝ                        signed and dated 1979; signed; titled and dated 1979 on the reverseenamel and ink on paperboard9 1/2  by 10 1/2  in.   24.1 by 26.7 cm.Executed in 1979; this work is accompanied with an artwork registration card issued by the studio.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'may-2013-contemporary-day-n08992-221',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-219',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.5.2013.yayoi-kusama.may-2013-contemporary-day-n08992-219',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/may-2013-contemporary-day-n08992/lot.219.html',
          salesTitle: 'may-2013-contemporary-day-n08992',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(75000)',
          real: 75000,
          timexcoord: '2013-05-01',
          month: 5,
          name: 'THE PACIFIC OCEAN',
          x: 72,
          y: 60,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                THE PACIFIC OCEANƎ                Ǝ                    Ǝ                        signed and dated 1959; signed; titled and dated 1959 on the reversemixed media on paper28 1/2  by 23 3/4  in.   72.4 by 60.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkEthan Cohen Fine Arts; New YorkAcquired by the present owner from the aboveƎ',
          cid: 'may-2013-contemporary-day-n08992-219',
        },
        fields: {
          ymonths: [160],
        },
        sort: [1367366400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.6.2013.1135.195.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57001/yayoi_kusama_infinity_nets_d5700153g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 195,
          auctionHouse: 'christies',
          high: 105000,
          low: 75000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 50,000 - GBP 70,000',
          birth: 1929,
          realized: 'GBP 121,875',
          real: 182812.5,
          timexcoord: '2013-06-01',
          sale: 1135,
          month: 6,
          name: 'Infinity Nets \r\n',
          x: 91,
          y: 72.7,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets \r\nsigned, titled and dated 'yayoi Kusama 1990 Infinity Nets' (on the reverse)\r\nacrylic on canvas \r\n35 7⁄8 x 28 5⁄8in. (91 x 72.7cm.) \r\nPainted in 1990 \r\n",
          cid: '1135.195',
        },
        fields: {
          ymonths: [161],
        },
        sort: [1370044800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.6.2013.yayoi-kusama.contemporary-art-day-auction-l13023-334',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 200000,
          low: 150000,
          currency: 'GBP',
          id:
            'sothebys.6.2013.yayoi-kusama.contemporary-art-day-auction-l13023-334',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/contemporary-art-day-auction-l13023/lot.334.html',
          salesTitle: 'contemporary-art-day-auction-l13023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(326500)',
          real: 489750,
          timexcoord: '2013-06-01',
          month: 6,
          name: 'PUMPKIN',
          x: 91,
          y: 117,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled and dated 1993 on the reverseacrylic on canvas90.9 by 116.8cm.; 35 7/8  by 46in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private CollectionSale: Christie's; London; Post-War and Contemporary Art; 5 December 2005; Lot 80Acquired directly from the above by the present ownerƎ",
          cid: 'contemporary-art-day-auction-l13023-334',
        },
        fields: {
          ymonths: [161],
        },
        sort: [1370044800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.6.2013.yayoi-kusama.contemporary-art-day-auction-l13023-393',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 40000,
          low: 30000,
          currency: 'GBP',
          id:
            'sothebys.6.2013.yayoi-kusama.contemporary-art-day-auction-l13023-393',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/contemporary-art-day-auction-l13023/lot.393.html',
          salesTitle: 'contemporary-art-day-auction-l13023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(80500)',
          real: 120750,
          timexcoord: '2013-06-01',
          month: 6,
          name: 'NETS 34',
          x: 38,
          y: 46,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                NETS 34Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1998 on the reverseoil on canvas38.1 by 45.6cm.; 15 by 18in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-l13023-393',
        },
        fields: {
          ymonths: [161],
        },
        sort: [1370044800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2013.2727.33.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57182/yayoi_kusama_infinity_nets_d5718265g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 33,
          auctionHouse: 'christies',
          high: 40000,
          low: 30000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 30,000 - USD 40,000',
          birth: 1929,
          realized: 'USD 50,000',
          real: 50000,
          timexcoord: '2013-09-01',
          sale: 2727,
          month: 9,
          name: 'Infinity Nets \r\n',
          x: 41.3,
          y: 30.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets \r\nsigned, titled in English and Japanese and dated 'Yayoi Kusama 1996 Infinity-Nets' (on the reverse)\r\nacrylic on canvas \r\n16¼ x 12 1/8 in. (41.3 x 30.8 cm.) \r\nPainted in 1996. \r\n",
          cid: '2727.33',
        },
        fields: {
          ymonths: [164],
        },
        sort: [1377993600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2013.yayoi-kusama.contemporary-art-n09010-308',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09010/443N09010_74T22.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 90000,
          low: 70000,
          currency: 'USD',
          id: 'sothebys.9.2013.yayoi-kusama.contemporary-art-n09010-308',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/contemporary-art-n09010/lot.308.html',
          salesTitle: 'contemporary-art-n09010',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(81250)',
          real: 81250,
          timexcoord: '2013-09-01',
          month: 9,
          name: 'NO.M.F.',
          x: 61,
          y: 70,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NO.M.F.Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1959 on the reverseink and gouache on paper24 by 27 1/2  in.   61 by 69.9 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Collection of Stella Waitzkin (gift of the artist)Gift of the above to the present ownerƎ',
          cid: 'contemporary-art-n09010-308',
        },
        fields: {
          ymonths: [164],
        },
        sort: [1377993600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2013.yayoi-kusama.contemporary-art-n09010-309',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09010/442N09010_74T23_web.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 300000,
          low: 200000,
          currency: 'USD',
          id: 'sothebys.9.2013.yayoi-kusama.contemporary-art-n09010-309',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/contemporary-art-n09010/lot.309.html',
          salesTitle: 'contemporary-art-n09010',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(245000)',
          real: 245000,
          timexcoord: '2013-09-01',
          month: 9,
          name: 'ACCUMULATION OF LETTERS',
          x: 63,
          y: 74,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                ACCUMULATION OF LETTERSƎ                Ǝ                    Ǝ                        signed and dated 1961; signed on the reverse; signed; titled and dated 1961 on a label affixed to the reverseink and printed paper collage on paper24 5/8  by 29 1/4  in.   62.5 by 74.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Collection of Stella Waitzkin (gift of the artist)Gift of the above to the present ownerƎ',
          cid: 'contemporary-art-n09010-309',
        },
        fields: {
          ymonths: [164],
        },
        sort: [1377993600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2013.1151.338.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57270/yayoi_kusama_japanese_radish_d5727019g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 338,
          auctionHouse: 'christies',
          high: 52500,
          low: 37500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 25,000 - GBP 35,000',
          birth: 1929,
          realized: 'GBP 60,000',
          real: 90000,
          timexcoord: '2013-10-01',
          sale: 1151,
          month: 10,
          name: 'Japanese Radish \r\n',
          x: 32,
          y: 41,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nJapanese Radish \r\nsigned, dated and inscribed 'Yayoi Kusama 1981 6F' and titled in Japanese (on the reverse)\r\nacrylic on canvas \r\n12½ x 16 1/8in. (32 x 41cm.) \r\nPainted in 1981 \r\n",
          cid: '1151.338',
        },
        fields: {
          ymonths: [165],
        },
        sort: [1380585600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2013.1151.337.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57270/yayoi_kusama_infinity_nets_no_39_d5727018g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 337,
          auctionHouse: 'christies',
          high: 67500,
          low: 52500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 35,000 - GBP 45,000',
          birth: 1929,
          realized: 'GBP 56,250',
          real: 84375,
          timexcoord: '2013-10-01',
          sale: 1151,
          month: 10,
          name: 'Infinity Nets No. 39 \r\n',
          x: 45.7,
          y: 38.3,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets No. 39 \r\nsigned, numbered, inscribed and dated 'Yayoi Kusama 1997 oil No. 39' (on the reverse)\r\noil on canvas \r\n17 7/8 x 15in. (45.7 x 38.3cm.) \r\nPainted in 1997 \r\n",
          cid: '1151.337',
        },
        fields: {
          ymonths: [165],
        },
        sort: [1380585600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2013.1151.221.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57269/yayoi_kusama_dots_d5726902g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 221,
          auctionHouse: 'christies',
          high: 375000,
          low: 270000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 180,000 - GBP 250,000',
          birth: 1929,
          realized: 'GBP 326,500',
          real: 489750,
          timexcoord: '2013-10-01',
          sale: 1151,
          month: 10,
          name: 'Dots \r\n',
          x: 162,
          y: 130.5,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nDots \r\nsigned, titled and dated 'Dots Yayoi Kusama 1996' (on the reverse) \r\nacrylic on canvas \r\n63¾ x 53 3/8in. (162 x 130.5cm.) \r\nExecuted in 1996 \r\n",
          cid: '1151.221',
        },
        fields: {
          ymonths: [165],
        },
        sort: [1380585600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2013.1150.42.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57272/yayoi_kusama_infinity_nets_d5727228g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 42,
          auctionHouse: 'christies',
          high: 1050000,
          low: 750000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Evening Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 500,000 - GBP 700,000',
          birth: 1929,
          realized: 'GBP 1,314,500',
          real: 1971750,
          timexcoord: '2013-10-01',
          sale: 1150,
          month: 10,
          name: 'Infinity Nets (T.W.A) \r\n',
          x: 194,
          y: 259,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity Nets (T.W.A) \r\nsigned, titled and dated 'Yayoi Kusama 2000 Infinity Nets (T.W.A)' (on the reverse)\r\nacrylic on canvas \r\n76¼ x 102in. (194 x 259cm.) \r\nPainted in 2000 \r\n",
          cid: '1150.42',
        },
        fields: {
          ymonths: [165],
        },
        sort: [1380585600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2013.yayoi-kusama.contemporary-art-day-auction-l13025-160',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 8000,
          low: 6000,
          currency: 'GBP',
          id:
            'sothebys.10.2013.yayoi-kusama.contemporary-art-day-auction-l13025-160',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/contemporary-art-day-auction-l13025/lot.160.html',
          salesTitle: 'contemporary-art-day-auction-l13025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(12500)',
          real: 18750,
          timexcoord: '2013-10-01',
          month: 10,
          name: 'INFINITY NET; THE COLOUR OF AUTUMN',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NET; THE COLOUR OF AUTUMNƎ                Ǝ                    Ǝ                        signed and dated 1999; signed; dated 1999 and variously inscribed on the reverseacrylic on cardboard27.2 by 24.2cm.; 10 5/8  by 9 1/2 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Olyvia Fine Art; LondonAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-l13025-160',
        },
        fields: {
          ymonths: [165],
        },
        sort: [1380585600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7189.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57501/yayoi_kusama_memory_of_hydrangea_d5750101g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7189,
          auctionHouse: 'christies',
          high: 31200,
          low: 20800,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 160,000 - HKD 240,000',
          birth: 1929,
          realized: 'HKD 562,500',
          real: 73125,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Memory of Hydrangea \r\n',
          x: 65,
          y: 51,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nMemory of Hydrangea \r\nsigned and dated 'Yayoi Kusama 1977'; titled in Japanese (on the original backing board)\r\nmixed media on paper, accompanied by signed original backing board \r\n65 x 51 cm. (25 1/5 x 20 1/8 in.) \r\nPainted in 1977 \r\n",
          cid: '3259.7189',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7188.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57501/yayoi_kusama_a_girl_looking_at_sunset_d5750100g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7188,
          auctionHouse: 'christies',
          high: 54600,
          low: 28600,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 220,000 - HKD 420,000',
          birth: 1929,
          realized: 'HKD 525,000',
          real: 68250,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'A Girl Looking at Sunset \r\n',
          x: 54.5,
          y: 42.5,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nA Girl Looking at Sunset \r\nsigned and dated 'Yayoi Kusama 1977' (lower left); signed and dated 'Yayoi Kusama 1977'; titled and signed in Japanese (on the reverse)\r\nmixed media on paper \r\n54.5 x 42.5 cm. (21 1/2 x 16 3/4 in.) \r\nPainted in 1977 \r\n",
          cid: '3259.7188',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7182.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57500/yayoi_kusama_summer_night_d5750094g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7182,
          auctionHouse: 'christies',
          high: 104000,
          low: 78000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 600,000 - HKD 800,000',
          birth: 1929,
          realized: 'HKD 812,500',
          real: 105625,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Summer Night \r\n',
          x: 51,
          y: 64.5,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nSummer Night \r\nsigned and dated 'Yayoi Kusama 1979' (upper left); signed and dated 'Yayoi Kusama 1979'; titled in Japanese (on original backing board & on a label affixed on the reverse)\r\none seal of the artist\r\nacrylic on paper, accompanied by signed original backing board \r\n51 x 64.5 cm. (20 x 25 3/4 in.) \r\nPainted in 1979 \r\n",
          cid: '3259.7182',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7183.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57500/yayoi_kusama_handbag_d5750095g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7183,
          auctionHouse: 'christies',
          high: 36400,
          low: 26000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 200,000 - HKD 280,000',
          birth: 1929,
          realized: 'HKD 812,500',
          real: 105625,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Handbag \r\n',
          x: 51,
          y: 66,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nHandbag \r\nsigned and dated 'Yayoi Kusama 1980' (upper right); titled in Japanese; signed and dated 'Yayoi Kusama 1980' (on the reverse affixed sticker)\r\nwatercolour on paper \r\n51 x 66 cm. (20 1/8 x 26 in.) \r\nPainted in 1980 \r\n",
          cid: '3259.7183',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7191.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57501/yayoi_kusama_abstract_d5750103g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7191,
          auctionHouse: 'christies',
          high: 20800,
          low: 15600,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 120,000 - HKD 160,000',
          birth: 1929,
          realized: 'HKD 400,000',
          real: 52000,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Abstract \r\n',
          x: 54,
          y: 39.4,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nAbstract \r\nsigned and dated 'Yayoi Kusama 1975' (lower left) \r\nmixed media on paper \r\n54 x 39.4 cm. (21 1/4 x 15 1/2 in.) \r\nPainted in 1975 \r\n",
          cid: '3259.7191',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3257.401.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57535/yayoi_kusama_death_of_a_doll_d5753594g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 401,
          auctionHouse: 'christies',
          high: 84500,
          low: 58500,
          currency: 'HKD',
          brand: 'Yayoi Kusama (B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 450,000 - HKD 650,000',
          birth: 1929,
          realized: 'HKD 875,000',
          real: 113750,
          timexcoord: '2013-11-01',
          sale: 3257,
          month: 11,
          name: 'Death of a Doll \r\n',
          x: 45.5,
          y: 38,
          stuff:
            "Yayoi Kusama (B. 1929) \r\nDeath of a Doll \r\nsigned 'Yayoi Kusama' in English; dated '1989'; titled in Japanese (on the reverse)\r\nacrylic and oil on canvas \r\n45.5 x 38 cm. (17 7/8 x 15 in.) \r\nExecuted in 1989 \r\n",
          cid: '3257.401',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7185.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57500/yayoi_kusama_flower_d5750097g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7185,
          auctionHouse: 'christies',
          high: 78000,
          low: 52000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 400,000 - HKD 600,000',
          birth: 1929,
          realized: 'HKD 875,000',
          real: 113750,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Flower \r\n',
          x: 60,
          y: 45,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nFlower \r\nsigned and dated 'Yayoi Kusama 1956' (on the reverse affixed label) \r\nmixed media on paper \r\n60 x 45 cm. (23 5/8 x 17 3/4 in.) \r\nPainted in 1956 \r\n",
          cid: '3259.7185',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7187.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57500/yayoi_kusama_night_d5750099g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7187,
          auctionHouse: 'christies',
          high: 33800,
          low: 26000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 200,000 - HKD 260,000',
          birth: 1929,
          realized: 'HKD 212,500',
          real: 27625,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Night \r\n',
          x: 66,
          y: 51.5,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nNight \r\nsigned and dated 'Yayoi Kusama 1981' (lower left); signed and dated 'Yayoi Kusama 1981'; titled in Japanese (on the reverse)\r\nmixed media on paper \r\n66 x 51.5 cm. (26 x 20 1/4 in.) \r\nPainted in 1981 \r\n",
          cid: '3259.7187',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3257.402.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57535/yayoi_kusama_white_pumpkin_d5753595g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 402,
          auctionHouse: 'christies',
          high: 390000,
          low: 260000,
          currency: 'HKD',
          brand: 'Yayoi Kusama (B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 2,000,000 - HKD 3,000,000',
          birth: 1929,
          realized: 'HKD 2,920,000',
          real: 379600,
          timexcoord: '2013-11-01',
          sale: 3257,
          month: 11,
          name: 'White Pumpkin \r\n',
          x: 91.2,
          y: 73.8,
          stuff:
            "Yayoi Kusama (B. 1929) \r\nWhite Pumpkin \r\nsigned 'yayoi Kusama' in English; dated '1991'; titled in Japanese (on the reverse)\r\nacrylic on canvas \r\n91.2 x 73.8 cm. (35 7/8 x 29 in.) \r\nPainted in 1991 \r\n",
          cid: '3257.402',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3257.403.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57535/yayoi_kusama_lemon_tea_d5753596g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 403,
          auctionHouse: 'christies',
          high: 455000,
          low: 325000,
          currency: 'HKD',
          brand: 'Yayoi Kusama (B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 2,500,000 - HKD 3,500,000',
          birth: 1929,
          realized: 'HKD 3,640,000',
          real: 473200,
          timexcoord: '2013-11-01',
          sale: 3257,
          month: 11,
          name: 'Lemon Tea \r\n',
          x: 117,
          y: 90.5,
          stuff:
            "Yayoi Kusama (B. 1929) \r\nLemon Tea \r\nsigned 'YAYOI KUSAMA' in English and Japanese; entitled in Japanese; dated '1981' (on the reverse)\r\noil on canvas \r\n117 x 90.5 cm. (46 x 35 5/8 in.) \r\nPainted in 1981 \r\n",
          cid: '3257.403',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7186.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57500/yayoi_kusama_green_earing_d5750098g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7186,
          auctionHouse: 'christies',
          high: 65000,
          low: 39000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 300,000 - HKD 500,000',
          birth: 1929,
          realized: 'HKD 875,000',
          real: 113750,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Green Earing \r\n',
          x: 65,
          y: 51,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nGreen Earing \r\nsigned and dated 'Yayoi Kusama 1977' (lower right); signed and dated 'Yayoi Kusama 1977'; titled in Japanese (on original backing board)\r\nmixed media on paper, accompanied by signed original backing board \r\n65 x 51 cm. (25 5/8 x 20 1/8 in.) \r\nPainted in 1977 \r\n",
          cid: '3259.7186',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2013.3259.7190.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57501/yayoi_kusama_abstract_d5750102g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 7190,
          auctionHouse: 'christies',
          high: 33800,
          low: 26000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle:
            'A Special Selection of Asian 20th Century & Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 200,000 - HKD 260,000',
          birth: 1929,
          realized: 'HKD 300,000',
          real: 39000,
          timexcoord: '2013-11-01',
          sale: 3259,
          month: 11,
          name: 'Abstract \r\n',
          x: 54,
          y: 39.4,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nAbstract \r\nsigned and dated 'Yayoi Kusama 1975' (lower left) \r\nmixed media on paper \r\n54 x 39.4 cm. (21 1/4 x 15 1/2 in.) \r\nPainted in 1975 \r\n",
          cid: '3259.7190',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2013.NY010713.25.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010713/25_001.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 400000,
          provenance:
            "Jean Art Gallery, Seoul Dado Art Gallery, Seoul Christie's, New York, Post-War and Contemporary Art Day Sale, November 11, 2009, lot 199 Acquired from the above sale by the present owner",
          low: 300000,
          currency: 'USD',
          id: 'phillips.11.2013.NY010713.25.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010713/25',
          salesTitle: 'contemporary art evening',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 437000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'Infinity Nets (Opreta)',
          x: 161,
          y: 130,
          location: 'new york',
          stuff:
            "oil on canvas ^ Signed, titled and dated 'OPRETA INFINITY NETS Yayoi Kusama 2007' on the reverse. ^ 63 1/2 x 51 1/8 in. (161.3 x 129.9 cm.) ^ Jean Art Gallery, Seoul Dado Art Gallery, Seoul Christie's, New York, Post-War and Contemporary Art Day Sale, November 11, 2009, lot 199 Acquired from the above sale by the present owner ^ 11",
          cid: 'NY010713.25',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-201',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 350000,
          low: 250000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-201',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.201.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(509000)',
          real: 509000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'INFINITY NETS (SSOWTN)',
          x: 130,
          y: 130,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETS (SSOWTN)Ǝ                Ǝ                    Ǝ                        signed; titled in English and Japanese and dated 2008 on the reverseacrylic on canvas51 1/4  by 51 1/4  in.   130.2 by 130.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts; TokyoGagosian Gallery; New YorkAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-201',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-203',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 150000,
          low: 100000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-203',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.203.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(281000)',
          real: 281000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'PUMPKIN',
          x: 53,
          y: 65,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1991 on the reverseacrylic on canvas 21 by 25 3/4  in.   53.3 by 65.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'nov-2013-contemporary-day-n09038-203',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-204',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-204',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.204.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(209000)',
          real: 209000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'STARS - INFINITY (BC16)',
          x: 117,
          y: 91,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                STARS - INFINITY (BC16)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2003 on the reverseacrylic on canvas46 by 35 7/8  in.   116.8 by 91.1 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the above in 2006Ǝ',
          cid: 'nov-2013-contemporary-day-n09038-204',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-205',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-205',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.205.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(137000)',
          real: 137000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'DOTS',
          x: 73,
          y: 61,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                DOTSƎ                Ǝ                    Ǝ                        signed; titled in English and Japanese and dated 1990 on the reverseacrylic on canvas28 3/4  by 24 in.   73 by 61 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the above in 2006Ǝ',
          cid: 'nov-2013-contemporary-day-n09038-205',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-206',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-206',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.206.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(100000)',
          real: 100000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'INFINITY NETS (HZT)',
          x: 53,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETS (HZT)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1998 on the reverseacrylic on canvas20 3/4  by 18 in.   52.7 by 45.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'nov-2013-contemporary-day-n09038-206',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-207',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 30000,
          low: 20000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-207',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.207.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(209000)',
          real: 209000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'PUMPKIN',
          x: 17,
          y: 23,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1994 on the reverseacrylic on canvas6 5/8  by 9 in.   16.8 by 22.9 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-207',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-208',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 45000,
          low: 35000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-208',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.208.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(93750)',
          real: 93750,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'SELF-OBLITERATION',
          x: 53,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                SELF-OBLITERATIONƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1987 on the reverseacrylic on canvas21 by 18 in.   53.3 by 45.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-208',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-210',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-210',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.210.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(161000)',
          real: 161000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'INFINITY-NETS',
          x: 71,
          y: 60,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY-NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 1998 on the reverseacrylic on canvas28 by 23 3/4  in.   71.1 by 60.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; Tokyo (acquired directly from the artist)Christie's; New York; November 14; 2007; lot 252Acquired by the present owner from the above saleƎ",
          cid: 'nov-2013-contemporary-day-n09038-210',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-209',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 40000,
          low: 30000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-209',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.209.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 106250,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'TEARDROP',
          x: 38,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                TEARDROPƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1989 on the reverseacrylic on canvas15 by 18 in.   38.1 by 45.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-209',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-211',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-211',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.211.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 106250,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'QUEENS OF THE NIGHT',
          x: 65,
          y: 51,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                QUEENS OF THE NIGHTƎ                Ǝ                    Ǝ                        signed and dated 1979; signed in Japanese; titled in Japanese and dated 1979 on the reverseacrylic on paper 25 3/4  by 20 in.   65.4 by 50.8 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ                    ExhibitedƎ                        Fukuoka; MOMA Contemporary; Yayoi Kusama; Vision of My Youth; March - April 1996; cat. no. 46; illustrated in colorƎ',
          cid: 'nov-2013-contemporary-day-n09038-211',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-321',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-321',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.321.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(125000)',
          real: 125000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'UNTITLED (NETS)',
          x: 72,
          y: 73,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                UNTITLED (NETS)Ǝ                Ǝ                    Ǝ                        signed and dated 1990 on the reverseacrylic on canvas28 1/4  by 28 3/4  in.   71.8 by 73 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'nov-2013-contemporary-day-n09038-321',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-322',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 30000,
          low: 20000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-322',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.322.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(68750)',
          real: 68750,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'PUMPKIN',
          x: 23,
          y: 16,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1993 on the reverseacrylic on canvas9 by 6 1/4  in.   22.9 by 15.9 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-322',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-324',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-324',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.324.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(121250)',
          real: 121250,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'WAVES',
          x: 26,
          y: 33,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                WAVESƎ                Ǝ                    Ǝ                        signed in Japanese; titled in Japanese and dated 1953 on the reversegouache on paper10 3/8  by 13 in.   26.4 by 33 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-324',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-327',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-327',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.327.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(43750)',
          real: 43750,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'FLASH TOWARDS THE 21ST CENTURY',
          x: 65,
          y: 53,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                FLASH TOWARDS THE 21ST CENTURYƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1988 on the reverseacrylic on canvas25 1/2  by 21 in.   64.8 by 53.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-327',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-329',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 38000,
          low: 28000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-329',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.329.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(40625)',
          real: 40625,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'DUTCH WOMAN',
          x: 64,
          y: 50,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                DUTCH WOMANƎ                Ǝ                    Ǝ                        signed and dated 1977; signed; titled in Japanese and dated 1977 on the reverseink and pastel on paper25 by 19 5/8  in.   63.5 by 49.8 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'nov-2013-contemporary-day-n09038-329',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-328',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 30000,
          low: 20000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-328',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.328.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(40625)',
          real: 40625,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'INSECT',
          x: 51,
          y: 66,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INSECTƎ                Ǝ                    Ǝ                        signed and dated 1980; signed; titled in Japanese and dated 1980 on the reverseacrylic; pastel and collage on paper20 by 26 in.   50.8 by 66 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-328',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-330',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-330',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.330.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(50000)',
          real: 50000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'MORNING ON THE GANGES RIVER',
          x: 45,
          y: 53,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                MORNING ON THE GANGES RIVERƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1988 on the reverseacrylic on canvas17 3/4  by 21 in.   45.1 by 53.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'nov-2013-contemporary-day-n09038-330',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-331',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 12000,
          low: 8000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-331',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.331.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(20000)',
          real: 20000,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'DOLL',
          x: 24,
          y: 25,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                DOLLƎ                Ǝ                    Ǝ                        signed and dated 78; signed in Japanese; titled in Japanese and dated 1978 on the backing boardink and watercolor on paperboard9 1/4  by 10 in.   23.5 by 25.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired by the present owner from the aboveƎ',
          cid: 'nov-2013-contemporary-day-n09038-331',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-332',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'USD',
          id:
            'sothebys.11.2013.yayoi-kusama.nov-2013-contemporary-day-n09038-332',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2013/nov-2013-contemporary-day-n09038/lot.332.html',
          salesTitle: 'nov-2013-contemporary-day-n09038',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(10625)',
          real: 10625,
          timexcoord: '2013-11-01',
          month: 11,
          name: 'LAKE',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                LAKEƎ                Ǝ                    Ǝ                        signed and dated 1980; signed; titled in Japanese and dated 1980 on the reverseenamel on paperboard10 1/2  by 9 1/4  in.   26.7 by 23.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the above in 2012Ǝ',
          cid: 'nov-2013-contemporary-day-n09038-332',
        },
        fields: {
          ymonths: [166],
        },
        sort: [1383264000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.12.2013.3564.34.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57549/yayoi_kusama_infinity_nets_d5754989g.jpg',
          year: 2013,
          artist: 'yayoi-kusama',
          lot: 34,
          auctionHouse: 'christies',
          high: 216000,
          low: 144000,
          currency: 'EUR',
          brand: 'YAYOI KUSAMA (NÉE EN 1929) \r\n',
          salesTitle: 'Art contemporain',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'EUR 120,000 - EUR 180,000',
          birth: 1929,
          realized: 'EUR 217,500',
          real: 261000,
          timexcoord: '2013-12-01',
          sale: 3564,
          month: 12,
          name: 'The sea in the evening glow \r\n',
          x: 130,
          y: 89.5,
          stuff:
            "YAYOI KUSAMA (NÉE EN 1929) \r\nThe sea in the evening glow \r\nsigné, titré en japonais et daté 'Yayoi Kusama 1995' (au dos) \r\nhuile sur toile \r\n130 x 89.5 cm. (51¼ x 35¼ in.) \r\nPeint en 1995. \r\n",
          cid: '3564.34',
        },
        fields: {
          ymonths: [167],
        },
        sort: [1385856000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2014.1511.219.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57683/yayoi_kusama_infinity_nets_no_22_d5768321g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 219,
          auctionHouse: 'christies',
          high: 30000,
          low: 22500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (B.1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 15,000 - GBP 20,000',
          birth: 1929,
          realized: 'GBP 43,750',
          real: 65625,
          timexcoord: '2014-02-01',
          sale: 1511,
          month: 2,
          name: 'Infinity Nets No. 22 \r\n',
          x: 22.9,
          y: 15.9,
          stuff:
            "Yayoi Kusama (B.1929)\r\nInfinity Nets No. 22 \r\nsigned, titled and dated 'Yayoi Kusama 1997 NO. 22' (on the reverse)\r\noil on canvas\r\n9 x 6.1/4in. (22.9 x 15.9cm.)\r\nPainted in 1997\r\n",
          cid: '1511.219',
        },
        fields: {
          ymonths: [169],
        },
        sort: [1391212800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.2.2014.UK010114.11.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010114/11_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 600000,
          provenance: 'Gagosian Gallery, New York',
          low: 400000,
          currency: 'GBP',
          id: 'phillips.2.2014.UK010114.11.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010114/11',
          salesTitle: 'contemporary art evening',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 687750,
          timexcoord: '2014-02-01',
          month: 2,
          name: 'Infinity Nets OPQR',
          x: 259,
          y: 194,
          location: 'london',
          stuff:
            'acrylic on canvas ^ null ^ 259.1 x 194 cm. (102 x 76 3/8 in.) ^ Gagosian Gallery, New York ^ 2',
          cid: 'UK010114.11',
        },
        fields: {
          ymonths: [169],
        },
        sort: [1391212800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2014.yayoi-kusama.contemporary-art-evening-auction-l14020-35',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/L14/L14020/206L14020_7BHRS.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'GBP',
          id:
            'sothebys.2.2014.yayoi-kusama.contemporary-art-evening-auction-l14020-35',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-evening-auction-l14020/lot.35.html',
          salesTitle: 'contemporary-art-evening-auction-l14020',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(482500)',
          real: 723750,
          timexcoord: '2014-02-01',
          month: 2,
          name: 'ENLIGHTENMENT MEANS LIVING A LIFE UNCONCERNEDLY',
          x: 194,
          y: 259,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                ENLIGHTENMENT MEANS LIVING A LIFE UNCONCERNEDLYƎ                Ǝ                    Ǝ                        signed; titled and dated 2008 on the reverseacrylic on canvas194 by 259cm.; 76 3/8  by 102in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gagosian Gallery; New YorkƎAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-evening-auction-l14020-35',
        },
        fields: {
          ymonths: [169],
        },
        sort: [1391212800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2014.yayoi-kusama.contemporary-art-evening-auction-l14020-54',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            '/content/dam/stb/lots/L14/L14020/911L14020_78YQ6_Normal_wall.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 300000,
          low: 200000,
          currency: 'GBP',
          id:
            'sothebys.2.2014.yayoi-kusama.contemporary-art-evening-auction-l14020-54',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-evening-auction-l14020/lot.54.html',
          salesTitle: 'contemporary-art-evening-auction-l14020',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(266500)',
          real: 399750,
          timexcoord: '2014-02-01',
          month: 2,
          name: 'UNIVERSE FIREBALLS',
          x: 194,
          y: 194,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                UNIVERSE FIREBALLSƎ                Ǝ                    Ǝ                        signed; titled and dated 2008 on the reverseacrylic on canvas194 by 194cm.; 76 3/8  by 76 3/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ben Brown Fine Arts; Hong KongƎAcquired directly from the above by the present ownerƎ                    ExhibitedƎ                        Los Angeles; Gagosian Gallery; Yayoi Kusama: Flowers that Bloom at Midnight; 2009ƎMilan; PAC Padiglione d'Arte Contemporanea; Yayoi Kusama: I want to live forever; 2009-10Ǝ",
          cid: 'contemporary-art-evening-auction-l14020-54',
        },
        fields: {
          ymonths: [169],
        },
        sort: [1391212800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.2.2014.yayoi-kusama.contemporary-art-day-auction-l14021-355',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/L14/L14021/627L14021_79VBM.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 30000,
          low: 20000,
          currency: 'GBP',
          id:
            'sothebys.2.2014.yayoi-kusama.contemporary-art-day-auction-l14021-355',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-auction-l14021/lot.355.html',
          salesTitle: 'contemporary-art-day-auction-l14021',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(47500)',
          real: 71250,
          timexcoord: '2014-02-01',
          month: 2,
          name: 'DOTS OBSESSION A.A.A.A.',
          x: 38,
          y: 46,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                DOTS OBSESSION A.A.A.A.Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2004 on the reverseacrylic on canvas38 by 45.5cm.; 15 by 17 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts; TokyoStudio Guenzani; MilanAcquired directly from the above by the present ownerƎ                    ExhibitedƎ                        Milan; Studio Guenzani; Yayoi Kusama; 2005Ǝ',
          cid: 'contemporary-art-day-auction-l14021-355',
        },
        fields: {
          ymonths: [169],
        },
        sort: [1391212800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.3.2014.NY010114.137.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010114/137_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 30000,
          provenance:
            'Acquired directly from the artist, circa 1980 Private Collection',
          low: 20000,
          currency: 'USD',
          id: 'phillips.3.2014.NY010114.137.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010114/137',
          salesTitle: 'under the influence',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 23750,
          timexcoord: '2014-03-01',
          month: 3,
          name: 'BIRD',
          x: 66,
          y: 51,
          location: 'new york',
          stuff:
            "collage, pastel, gouache, ink on paper ^ Signed and dated '1980 Yayoi Kusama' lower right; further signed, titled in Japanese and dated '1980 Yayoi Kusama' on the reverse of the sheet. This work includes Yayoi Kusama's Art Work Registration Card affixed to the reverse of the frame. ^ 26 x 20 in. (66 x 50.8 cm) ^ Acquired directly from the artist, circa 1980 Private Collection ^ 3",
          cid: 'NY010114.137',
        },
        fields: {
          ymonths: [170],
        },
        sort: [1393632000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.3.2014.NY010114.138.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010114/138_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 40000,
          provenance:
            'Galerie Thelen, Essen Phillips de Pury & Company, New York, Contemporary Art, Part II, November 11, 2005, lot 395 Acquired at the above sale by the present owner',
          low: 30000,
          currency: 'USD',
          id: 'phillips.3.2014.NY010114.138.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010114/138',
          salesTitle: 'under the influence',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 87500,
          timexcoord: '2014-03-01',
          month: 3,
          name: 'Nets',
          x: 41,
          y: 32,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'Yayoi Kusama 1997 Nets' on the reverse. This work is accompanied by Yayoi Kusama's Art Work Registration Card. ^ 16 1/8 x 12 1/2 in. (41 x 31.8 cm.) ^ Galerie Thelen, Essen Phillips de Pury & Company, New York, Contemporary Art, Part II, November 11, 2005, lot 395 Acquired at the above sale by the present owner ^ 3",
          cid: 'NY010114.138',
        },
        fields: {
          ymonths: [170],
        },
        sort: [1393632000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-45',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09120/018N09120_76BB7.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-45',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/march-contemporary-curated-n09120/lot.45.html',
          salesTitle: 'march-contemporary-curated-n09120',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(75000)',
          real: 75000,
          timexcoord: '2014-03-01',
          month: 3,
          name: 'THE ISLAND (NO 4)',
          x: 36,
          y: 26,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                THE ISLAND (NO 4)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1953 on the reversecharcoal; ink and gouache on paper14 1/8  by 10 1/8  in.   35.9 by 25.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection (acquired directly from the artist)Acquired by the present owner from the aboveƎ',
          cid: 'march-contemporary-curated-n09120-45',
        },
        fields: {
          ymonths: [170],
        },
        sort: [1393632000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-43',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09120/020N09120_76BB5.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 35000,
          low: 25000,
          currency: 'USD',
          id:
            'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-43',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/march-contemporary-curated-n09120/lot.43.html',
          salesTitle: 'march-contemporary-curated-n09120',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(68750)',
          real: 68750,
          timexcoord: '2014-03-01',
          month: 3,
          name: 'THE NIGHT (2)',
          x: 36,
          y: 25,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                THE NIGHT (2)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1953 on the reverseink and gouache on paper14 1/8  by 10 in.   35.9 by 25.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection (acquired directly from the artist)Acquired by the present owner from the aboveƎ',
          cid: 'march-contemporary-curated-n09120-43',
        },
        fields: {
          ymonths: [170],
        },
        sort: [1393632000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-44',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09120/017N09120_76BB8.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 18000,
          low: 12000,
          currency: 'USD',
          id:
            'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-44',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/march-contemporary-curated-n09120/lot.44.html',
          salesTitle: 'march-contemporary-curated-n09120',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(43750)',
          real: 43750,
          timexcoord: '2014-03-01',
          month: 3,
          name: 'SKY (NO 2)',
          x: 35,
          y: 25,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                SKY (NO 2)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1953 on the reversegouache on paper13 5/8  by 9 7/8  in.   34.6 by 25.1 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection (acquired directly from the artist)Acquired by the present owner from the aboveƎ',
          cid: 'march-contemporary-curated-n09120-44',
        },
        fields: {
          ymonths: [170],
        },
        sort: [1393632000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-302',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09120/512N09120_XXXXX.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.3.2014.yayoi-kusama.march-contemporary-curated-n09120-302',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/march-contemporary-curated-n09120/lot.302.html',
          salesTitle: 'march-contemporary-curated-n09120',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 106250,
          timexcoord: '2014-03-01',
          month: 3,
          name: 'PUMPKIN',
          x: 22,
          y: 27,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed on the reverseacrylic on canvas8 3/4  by 10 3/4  in.   22.2 by 27.3 cm.Executed in 2002.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; KoreaƎ',
          cid: 'march-contemporary-curated-n09120-302',
        },
        fields: {
          ymonths: [170],
        },
        sort: [1393632000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2014.3391.2.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57890/yayoi_kusama_yayoi_kusama_d5789067g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 2,
          auctionHouse: 'christies',
          high: 39200.00000000001,
          low: 25200.000000000004,
          currency: 'CNY',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Asian and Western 20th Century and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 180,000 - CNY 280,000',
          birth: 1929,
          realized: 'CNY 1,170,000',
          real: 163800.00000000003,
          timexcoord: '2014-04-01',
          sale: 3391,
          month: 4,
          name: 'Pumpkin \r\n',
          x: 14,
          y: 17.8,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPumpkin \r\nsigned 'YAYOI KUSAMA' in English; dated '1994';\r\ntitled in Japanese (on the reverse)\r\nacrylic on canvas\r\n14 x 17.8 cm. (5 1/2 x 7 in.) \r\nPainted in 1994 \r\n",
          cid: '3391.2',
        },
        fields: {
          ymonths: [171],
        },
        sort: [1396310400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2014.3391.1.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57890/yayoi_kusama_yayoi_kusama_d5789066g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 1,
          auctionHouse: 'christies',
          high: 39200.00000000001,
          low: 25200.000000000004,
          currency: 'CNY',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Asian and Western 20th Century and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 180,000 - CNY 280,000',
          birth: 1929,
          realized: 'CNY 870,000',
          real: 121800.00000000001,
          timexcoord: '2014-04-01',
          sale: 3391,
          month: 4,
          name: 'Pumpkin \r\n',
          x: 22.5,
          y: 15.5,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nPumpkin \r\nsigned 'YAYOI KUSAMA' in English; dated '1991';titled 'PUMPKIN in English (on the reverse)\r\nacrylic on canvas \r\n22.5 x 15.5 cm. (8 7/8 x 6 1/8 in.) \r\nPainted in 1991 \r\n",
          cid: '3391.1',
        },
        fields: {
          ymonths: [171],
        },
        sort: [1396310400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2014.3046.28.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D57831/yayoi_kusama_untitled_d5783112g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 28,
          auctionHouse: 'christies',
          high: 72000,
          low: 48000,
          currency: 'EUR',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'EUR 40,000 - EUR 60,000',
          birth: 1929,
          realized: 'EUR 63,900',
          real: 76680,
          timexcoord: '2014-04-01',
          sale: 3046,
          month: 4,
          name: 'Untitled \r\n',
          x: 37,
          y: 45,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\nsigned, dated and signed in Japanese 'Yayoi Kusama 1988' (on the reverese)\r\noil on canvas \r\n37 x 45 cm. \r\nPainted in 1988 \r\n",
          cid: '3046.28',
        },
        fields: {
          ymonths: [171],
        },
        sort: [1396310400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2014.NY030114.259.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030114/259_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 10000,
          low: 7000,
          currency: 'USD',
          id: 'phillips.4.2014.NY030114.259.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030114/259',
          salesTitle: 'evening & day editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 21250,
          timexcoord: '2014-04-01',
          month: 4,
          name: 'Flower A; B; and C',
          x: 60,
          y: 72,
          location: 'new york',
          stuff:
            "The complete set of 3 screenprints in colors with gold lamé, on wove paper, with full margins ^ all signed, dated `2005' annotated A-C respectively and numbered 19/50 in pencil, all framed. ^ all I. 20 x 24 in. (50.8 x 61 cm) all S. 23 3/4 x 28 1/8 in. (60.3 x 71.5 cm) ^ null ^ 4",
          cid: 'NY030114.259',
        },
        fields: {
          ymonths: [171],
        },
        sort: [1396310400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2014.UK010314.39.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010314/39_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 9000,
          provenance:
            'Mallet, Japan, Modern and Contemporary Art, 10 June 2011, lot 122 Private Collection, France',
          low: 7000,
          currency: 'GBP',
          id: 'phillips.4.2014.UK010314.39.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010314/39',
          salesTitle: 'under the influence',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 28125,
          timexcoord: '2014-04-01',
          month: 4,
          name: 'Primrose',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            "felt-tip pen on paperboard ^ Signed, dated and stamped 'Yayoi Kusama 1979' upper left. Further signed and dated '1979 Yayoi Kusama' with inscription in Japanese on the reverse. ^ 27.2 x 23.8 cm (10 3/4 x 9 3/8 in.) ^ Mallet, Japan, Modern and Contemporary Art, 10 June 2011, lot 122 Private Collection, France ^ 4",
          cid: 'UK010314.39',
        },
        fields: {
          ymonths: [171],
        },
        sort: [1396310400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2014.3310.746.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58016/yayoi_kusama_infinity_nets_d5801647g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 746,
          auctionHouse: 'christies',
          high: 15600,
          low: 10400,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 80,000 - HKD 120,000',
          birth: 1929,
          realized: 'HKD 112,500',
          real: 14625,
          timexcoord: '2014-05-01',
          sale: 3310,
          month: 5,
          name: 'Infinity Nets (WR, YBL, & YOR) \r\n',
          x: 43,
          y: 35,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929) \r\nInfinity Nets (WR, YBL, & YOR) \r\ntitled 'Infinity Nets (WR)' and dated '2004' (lower left); signed 'yayoi Kusama' (lower right)\r\nthree screenprints \r\neach: 43 x 35 cm. (16 3/4 x 13 3/4 in.) \r\nedition 22/40; 22/40; & 42/80\r\nExecuted in 2004 \t (3)\r\n",
          cid: '3310.746',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2014.3310.501.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58014/yayoi_kusama_pumpkin_d5801403g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 501,
          auctionHouse: 'christies',
          high: 41600,
          low: 28600,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 220,000 - HKD 320,000',
          birth: 1929,
          realized: 'HKD 812,500',
          real: 105625,
          timexcoord: '2014-05-01',
          sale: 3310,
          month: 5,
          name: 'Pumpkin \r\n',
          x: 18,
          y: 14,
          stuff:
            'YAYOI KUSAMA (Japanese, B. 1929) \r\nPumpkin \r\noil canvas \r\n18 x 14 cm. (7 x 5 1/2 in.) \r\nPainted in 1991 \r\n',
          cid: '3310.501',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2014.3310.684.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58015/yayoi_kusama_bird_d5801586g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 684,
          auctionHouse: 'christies',
          high: 26000,
          low: 19500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 150,000 - HKD 200,000',
          birth: 1929,
          realized: 'HKD 325,000',
          real: 42250,
          timexcoord: '2014-05-01',
          sale: 3310,
          month: 5,
          name: 'Bird \r\n',
          x: 65.7,
          y: 51.5,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929) \r\nBird \r\nsigned 'yayoi Kusama' in English, dated '1980' (lower right); signed 'yayoi Kusama' in English, dated '1980' (on the reverse)\r\nink, gouache, felt-tip pen, glitter, charcoal, metallic gold paint and printed paper collage\r\n65.7 x 51.5 cm. (25.9 x 20.3 in.) \r\nExecuted in 1980 \r\n",
          cid: '3310.684',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2014.3310.502.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58014/yayoi_kusama_cup_d5801404g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 502,
          auctionHouse: 'christies',
          high: 91000,
          low: 65000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 500,000 - HKD 700,000',
          birth: 1929,
          realized: 'HKD 1,180,000',
          real: 153400,
          timexcoord: '2014-05-01',
          sale: 3310,
          month: 5,
          name: 'Cup \r\n',
          x: 46,
          y: 38,
          stuff:
            'YAYOI KUSAMA (Japanese, B. 1929) \r\nCup \r\nacrylic canvas \r\n46 x 38 cm. (18 1/8 x 15 in.) \r\nPainted in 1979 \r\n',
          cid: '3310.502',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-evening-sale-n09141-81',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09141/832N09141_7FWFK.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 1200000,
          low: 800000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-evening-sale-n09141-81',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-evening-sale-n09141/lot.81.html',
          salesTitle: 'contemporary-art-evening-sale-n09141',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(3189000)',
          real: 3189000,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'NO. E',
          x: 72,
          y: 90,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NO. EƎ                Ǝ                    Ǝ                        signed; titled and dated 1959 on the reverseoil on canvas28 1/4  x 35 1/2  in.   72 x 90 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Acquired by the present owner from the artist circa 1959Ǝ',
          cid: 'contemporary-art-evening-sale-n09141-81',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-136',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09142/122N09142_76BB9.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 40000,
          low: 30000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-136',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09142/lot.136.html',
          salesTitle: 'contemporary-art-day-sale-n09142',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 106250,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'UNTITLED (4)',
          x: 25,
          y: 36,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                UNTITLED (4)Ǝ                Ǝ                    Ǝ                        signed; signed; dated 1952 and numbered 4 on the reversegouache and charcoal on paper9 7/8  by 14 in.   25.1 by 35.6 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection (acquired directly from the artist)Acquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n09142-136',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-191',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09142/197N09142_7C9D2.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 700000,
          low: 500000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-191',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09142/lot.191.html',
          salesTitle: 'contemporary-art-day-sale-n09142',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(785000)',
          real: 785000,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'PUMPKIN (GLMTS)',
          x: 130,
          y: 162,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKIN (GLMTS)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2012 on the reverseacrylic on canvas51 by 63 3/4  in.   129.5 by 161.9 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n09142-191',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-196',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09142/876N09142_7G58J_web.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-196',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09142/lot.196.html',
          salesTitle: 'contemporary-art-day-sale-n09142',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(665000)',
          real: 665000,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'INFINITY-NETS (ENNO)',
          x: 130,
          y: 130,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (ENNO)Ǝ                Ǝ                    Ǝ                        signed; titled twice in English and once in Japanese and dated 2011 on the reverseacrylic on canvas51 1/4  by 51 1/4  in.   130.2 by 130.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; New YorkƎ',
          cid: 'contemporary-art-day-sale-n09142-196',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-218',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09142/013N09142_73KDD.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 200000,
          low: 150000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-218',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09142/lot.218.html',
          salesTitle: 'contemporary-art-day-sale-n09142',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(245000)',
          real: 245000,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'NETS INFINITY (OPQA)',
          x: 131,
          y: 97,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                NETS INFINITY (OPQA)Ǝ                Ǝ                    Ǝ                        signed; titled in English and Japanese and dated 2004 on the reverseacrylic on canvas51 1/2  by 38 in.   130.8 by 96.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts; JapanAlan Koppel Gallery; ChicagoAcquired by the present owner from the above in May 2007Ǝ',
          cid: 'contemporary-art-day-sale-n09142-218',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-220',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09142/047N09142_78X5Z.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 40000,
          low: 30000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-220',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09142/lot.220.html',
          salesTitle: 'contemporary-art-day-sale-n09142',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 106250,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'PUMPKIN',
          x: 16,
          y: 23,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled and dated 1991 on the reverseacrylic on canvas6 3/8  by 8 7/8  in.   16.2 by 22.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n09142-220',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-222',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09142/124N09142_7BPKM.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 65000,
          low: 45000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-222',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09142/lot.222.html',
          salesTitle: 'contemporary-art-day-sale-n09142',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(137000)',
          real: 137000,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'PUMPKIN (HTT)',
          x: 23,
          y: 16,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKIN (HTT)Ǝ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1999 on the reverseacrylic on canvas8 7/8  by 6 1/8  in.   22.5 by 15.6 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n09142-222',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-137',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09142/123N09142_76BB6.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 40000,
          low: 30000,
          currency: 'USD',
          id:
            'sothebys.5.2014.yayoi-kusama.contemporary-art-day-sale-n09142-137',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09142/lot.137.html',
          salesTitle: 'contemporary-art-day-sale-n09142',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(112500)',
          real: 112500,
          timexcoord: '2014-05-01',
          month: 5,
          name: 'AN ISLAND (15)',
          x: 30,
          y: 31,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                AN ISLAND (15)Ǝ                Ǝ                    Ǝ                        signed; signed; titled and dated 1955 on the reversegouache on paper11 3/4  by 12 in.   29.8 by 30.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection (acquired directly from the artist)Acquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n09142-137',
        },
        fields: {
          ymonths: [172],
        },
        sort: [1398902400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2014.3499.92.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58174/yayoi_kusama_accumulation_d5817495g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          edition: 9,
          lot: 92,
          auctionHouse: 'christies',
          high: 2500,
          low: 1500,
          currency: 'USD',
          brand: 'YAYOI KUSAMA (B. 1929) \r\n',
          salesTitle: 'Forms in Color',
          editionOf: 30,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 1,500 - USD 2,500',
          birth: 1929,
          realized: 'USD 9,375',
          real: 9375,
          timexcoord: '2014-07-01',
          sale: 3499,
          month: 7,
          name: 'Accumulation \r\n',
          x: 45.7,
          y: 62.9,
          stuff:
            "YAYOI KUSAMA (B. 1929) \r\nAccumulation \r\nsigned, dated, titled and numbered '9/30 1953-1984 Accumulation Yayoi Kusama' in pencil (in the lower margin)\r\netching on Arches paper \r\n18 x 24¾ in. (45.7 x 62.9 cm.) \r\nExecuted in 1953-1984. This work is number nine from the edition of thirty. \r\n",
          cid: '3499.92',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2014.1536.129.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58127/yayoi_kusama_infinity_nets_i_n_pqr_d5812792g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 129,
          auctionHouse: 'christies',
          high: 450000,
          low: 300000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (B. 1929) \r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 200,000 - GBP 300,000',
          birth: 1929,
          realized: 'GBP 290,500',
          real: 435750,
          timexcoord: '2014-07-01',
          sale: 1536,
          month: 7,
          name: 'Infinity Nets I. N. PQR  \r\n',
          x: 145.8,
          y: 145.8,
          stuff:
            "Yayoi Kusama (B. 1929) \r\nInfinity Nets I. N. PQR  \r\nsigned, titled, dated and inscribed in Japanese 'Yayoi Kusama Infinity – Nets 2007 I.N. PQR' (on the reverse) \r\nacrylic on canvas  \r\n57 3/8 x 57 3/8in. (145.8 x 145.8cm.)\r\nPainted in 2007\r\n",
          cid: '1536.129',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2014.2867.152.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58177/yayoi_kusama_nets_(an_d_d5817721g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 152,
          auctionHouse: 'christies',
          high: 70000,
          low: 50000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'First Open: Summer Edition',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 50,000 - USD 70,000',
          birth: 1929,
          realized: 'USD 269,000',
          real: 269000,
          timexcoord: '2014-07-01',
          sale: 2867,
          month: 7,
          name: 'Nets (AN, D. \r\n',
          x: 34.6,
          y: 27.9,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nNets (AN, D. \r\nsigned 'KUSAMA' (lower right); signed again, titled and dated 'NETS (AN, D. YAYOI KUSAMA 1957' (on the reverse)\r\ngouache and pastel on paper \r\n13 5/8 x 11 in. (34.6 x 27.9 cm.) \r\nExecuted in 1957. \r\n",
          cid: '2867.152',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2014.3499.88.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58174/yayoi_kusama_polka_dots_d5817491g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          edition: 40,
          lot: 88,
          auctionHouse: 'christies',
          high: 2500,
          low: 1500,
          currency: 'USD',
          brand: 'YAYOI KUSAMA (B. 1929) \r\n',
          salesTitle: 'Forms in Color',
          editionOf: 50,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 1,500 - USD 2,500',
          birth: 1929,
          realized: 'USD 3,750',
          real: 3750,
          timexcoord: '2014-07-01',
          sale: 3499,
          month: 7,
          name: 'Polka Dots \r\n',
          x: 45.4,
          y: 62.9,
          stuff:
            "YAYOI KUSAMA (B. 1929) \r\nPolka Dots \r\nsigned, dated, titled in Japanese and numbered '40/50 1994 Yayoi Kusama' in pencil (in the lower margin)\r\netching on Arches paper \r\n17 7/8 x 24¾ in. (45.4 x 62.9 cm.) \r\nExecuted in 1994. This work is number forty from the edition of fifty. \r\n",
          cid: '3499.88',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2014.3499.89.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58174/yayoi_kusama_city_d5817492g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          edition: 48,
          lot: 89,
          auctionHouse: 'christies',
          high: 2500,
          low: 1500,
          currency: 'USD',
          brand: 'YAYOI KUSAMA (B. 1929) \r\n',
          salesTitle: 'Forms in Color',
          editionOf: 50,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 1,500 - USD 2,500',
          birth: 1929,
          realized: 'USD 4,750',
          real: 4750,
          timexcoord: '2014-07-01',
          sale: 3499,
          month: 7,
          name: 'City \r\n',
          x: 49.2,
          y: 38.1,
          stuff:
            "YAYOI KUSAMA (B. 1929) \r\nCity \r\nsigned, dated, titled in Japanese and numbered '48/50 1993 Yayoi Kusama' in pencil (in the lower margin)\r\netching on Arches paper \r\n19 3/8 x 15 in. (49.2 x 38.1 cm.) \r\nExecuted in 1993. This work is number forty-eight from the edition of fifty. \r\n",
          cid: '3499.89',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2014.3499.126.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58175/yayoi_kusama_untitled_d5817529g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 126,
          auctionHouse: 'christies',
          high: 25000,
          low: 18000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Forms in Color',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 18,000 - USD 25,000',
          birth: 1929,
          realized: 'USD 18,750',
          real: 18750,
          timexcoord: '2014-07-01',
          sale: 3499,
          month: 7,
          name: 'Untitled \r\n',
          x: 27.3,
          y: 24.1,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nUntitled \r\nsigned and dated 'YAYOI KUSAMA 1978' (upper left) \r\nenamel on paperboard \r\n10¾ x 9½ in. (27.3 x 24.1 cm.) \r\nPainted in 1978. \r\n",
          cid: '3499.126',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.7.2014.3499.91.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58174/yayoi_kusama_infinity_nets_d5817494g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          edition: 43,
          lot: 91,
          auctionHouse: 'christies',
          high: 3000,
          low: 2000,
          currency: 'USD',
          brand: 'YAYOI KUSAMA (B. 1929) \r\n',
          salesTitle: 'Forms in Color',
          editionOf: 50,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 2,000 - USD 3,000',
          birth: 1929,
          realized: 'USD 8,750',
          real: 8750,
          timexcoord: '2014-07-01',
          sale: 3499,
          month: 7,
          name: 'Infinity Nets (A . B) \r\n',
          x: 62.8,
          y: 45.8,
          stuff:
            "YAYOI KUSAMA (B. 1929) \r\nInfinity Nets (A . B) \r\nsigned, titled in Japanese, dated and numbered '43/50 Infinity Nets 1994 Yayoi Kusama' in pencil (in the lower margin)\r\netching on Arches paper \r\n24¾ x 18 in. (62.8 x 45.8 cm.) \r\nExecuted in 1994. This work is number thirty-two from the edition of fifty. \r\n",
          cid: '3499.91',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.7.2014.yayoi-kusama.contemporary-art-day-auction-l14023-405',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 30000,
          low: 20000,
          currency: 'GBP',
          id:
            'sothebys.7.2014.yayoi-kusama.contemporary-art-day-auction-l14023-405',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-auction-l14023/lot.405.html',
          salesTitle: 'contemporary-art-day-auction-l14023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(31250)',
          real: 46875,
          timexcoord: '2014-07-01',
          month: 7,
          name: 'DOTS',
          x: 33,
          y: 24,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                DOTSƎ                Ǝ                    Ǝ                        signed; titled and dated 1998 on the reverseacrylic on canvas33 by 24.2cm.; 13 by 9 1/2 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private CollectionSale: SBI Art Auction Co.; Ltd.; Modern & Contemporary Art; 26 April 2013; Lot 160Acquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-l14023-405',
        },
        fields: {
          ymonths: [174],
        },
        sort: [1404172800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2014.NY010614.72.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010614/72_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance: 'Ikon Gallery, Santa Monica',
          low: 15000,
          currency: 'USD',
          id: 'phillips.9.2014.NY010614.72.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010614/72',
          salesTitle: 'under the influence',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 30000,
          timexcoord: '2014-09-01',
          month: 9,
          name: 'Butterfly',
          x: 24,
          y: 27,
          location: 'new york',
          stuff:
            "watercolor on paperboard ^ Signed, stamped and dated 'Yayoi Kusama 1979' lower right; further signed and dated '1979 Yayoi Kusama' on the reverse. This work is accompanied by Yayoi Kusama's Art Work Registration Card. ^ 9 1/2 x 10 5/8 in. (24.1 x 27 cm.) ^ Ikon Gallery, Santa Monica ^ 9",
          cid: 'NY010614.72',
        },
        fields: {
          ymonths: [176],
        },
        sort: [1409529600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2014.yayoi-kusama.contemporary-curated-n09196-217',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 50000,
          low: 30000,
          currency: 'USD',
          id: 'sothebys.9.2014.yayoi-kusama.contemporary-curated-n09196-217',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-curated-n09196/lot.217.html',
          salesTitle: 'contemporary-curated-n09196',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(112500)',
          real: 112500,
          timexcoord: '2014-09-01',
          month: 9,
          name: 'PUMPKIN',
          x: 18,
          y: 14,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed and dated 1990 on the reverseacrylic and pencil on canvas7 1/8  by 5 1/2  in.   18 by 14 cm.Executed in 1990; this work is accompanied by a registration card from the Yayoi Kusama Studio.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private CollectionMallet Japan; Tokyo; September 10; 2010; lot 213Acquired by the present owner from the above saleƎ',
          cid: 'contemporary-curated-n09196-217',
        },
        fields: {
          ymonths: [176],
        },
        sort: [1409529600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2014.3397.229.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58420/yayoi_kusama_infinity-nets_ooaxt_d5842000g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 229,
          auctionHouse: 'christies',
          high: 602000,
          low: 420000.00000000006,
          currency: 'CNY',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Asian and Western 20th Century & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 3,000,000 - CNY 4,300,000',
          birth: 1929,
          realized: 'CNY 3,870,000',
          real: 541800,
          timexcoord: '2014-10-01',
          sale: 3397,
          month: 10,
          name: 'Infinity-Nets OOAXT \r\n',
          x: 130.3,
          y: 130.3,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nInfinity-Nets OOAXT \r\nsigned twice, titled and dated 'OOAXT INFINITY-NETS YAYOI KUSAMA yayoi kusama 2008' (on the reverse)\r\nAMA yayoi kusama 2008' (on the reverse) \r\n130.3 x 130.3 cm. (51 1/4 x 51 1/4 in.) \r\n21st Century \r\n",
          cid: '3397.229',
        },
        fields: {
          ymonths: [177],
        },
        sort: [1412121600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2014.3397.201.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58363/yayoi_kusama_pumpkin_d5836377g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 201,
          auctionHouse: 'christies',
          high: 44800.00000000001,
          low: 30800.000000000004,
          currency: 'CNY',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Asian and Western 20th Century & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 220,000 - CNY 320,000',
          birth: 1929,
          realized: 'CNY 650,000',
          real: 91000.00000000001,
          timexcoord: '2014-10-01',
          sale: 3397,
          month: 10,
          name: 'Pumpkin \r\n',
          x: 14,
          y: 18,
          stuff:
            'Yayoi Kusama (b. 1929) \r\nPumpkin \r\nacrylic on canvas \r\n14 x 18 cm. (5 1/2 x 7 1/8 in.) \r\nPainted in 1997 \r\n',
          cid: '3397.201',
        },
        fields: {
          ymonths: [177],
        },
        sort: [1412121600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2014.yayoi-kusama.contemporary-art-day-auction-l14025-120',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 150000,
          low: 100000,
          currency: 'GBP',
          id:
            'sothebys.10.2014.yayoi-kusama.contemporary-art-day-auction-l14025-120',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-auction-l14025/lot.120.html',
          salesTitle: 'contemporary-art-day-auction-l14025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(170500)',
          real: 255750,
          timexcoord: '2014-10-01',
          month: 10,
          name: 'DOTS OBSESSION',
          x: 91,
          y: 117,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                DOTS OBSESSIONƎ                Ǝ                    Ǝ                        signed; titled and dated 1998 on the reverseacrylic on canvas91 by 117cm.; 35 7/8  by 46 1/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by a registration card issued by the Yayoi Kusama studio.ƎƎ                        ProvenanceƎ                        Robert Miller Gallery; New YorkLars Bohman Gallery; StockholmAcquired directly from the above by the present owner in 2001Ǝ',
          cid: 'contemporary-art-day-auction-l14025-120',
        },
        fields: {
          ymonths: [177],
        },
        sort: [1412121600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2014.yayoi-kusama.contemporary-art-day-auction-l14025-171',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'GBP',
          id:
            'sothebys.10.2014.yayoi-kusama.contemporary-art-day-auction-l14025-171',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-auction-l14025/lot.171.html',
          salesTitle: 'contemporary-art-day-auction-l14025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(43750)',
          real: 65625,
          timexcoord: '2014-10-01',
          month: 10,
          name: 'UNTITLED (NO. 2; Q.S.T)',
          x: 30,
          y: 28,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                UNTITLED (NO. 2; Q.S.T)Ǝ                Ǝ                    Ǝ                        signed and dated 1954; signed; titled and dated 1954 on the reversegouache and pastel on paper30 by 27.5cm.; 11 1/2  by 10 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Galleria Del Naviglio; MilanPrivate CollectionMulier Mulier Gallery; KnokkeAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-l14025-171',
        },
        fields: {
          ymonths: [177],
        },
        sort: [1412121600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2014.3357.65.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58531/yayoi_kusama_infinity-nets_whxotlo_d5853190g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 65,
          auctionHouse: 'christies',
          high: 1950000,
          low: 1560000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (B.1929) \r\n',
          salesTitle: 'Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 12,000,000 - HKD 15,000,000',
          birth: 1929,
          realized: 'HKD 18,040,000',
          real: 2345200,
          timexcoord: '2014-11-01',
          sale: 3357,
          month: 11,
          name: 'Infinity-Nets WHXOTLO \r\n',
          x: 200,
          y: 1000,
          stuff:
            "YAYOI KUSAMA (B.1929) \r\nInfinity-Nets WHXOTLO \r\nsigned 'Yayoi Kusama' in English; dated '2006'; titled 'INFINITY-NETS WHXOTLO' (on the reverse)\r\nacrylic on canvas \r\n200 x 1000 cm. (78 3/4 x 393 3/4 in.) \r\nPainted in 2006 \r\n",
          cid: '3357.65',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2014.3361.770.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58550/yayoi_kusama_a_pumpkin_gb-d_d5855011g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          edition: 29,
          lot: 770,
          auctionHouse: 'christies',
          high: 6500,
          low: 3900,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (B.1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          editionOf: 80,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 30,000 - HKD 50,000',
          birth: 1929,
          realized: 'HKD 81,250',
          real: 10562.5,
          timexcoord: '2014-11-01',
          sale: 3361,
          month: 11,
          name: 'A Pumpkin GB-D \r\n',
          x: 24,
          y: 28.5,
          stuff:
            "YAYOI KUSAMA (B.1929) \r\nA Pumpkin GB-D \r\nnumbered '29/80'; titled 'A PUMPKIN GB-D' in English (lower left); dated '2004'; signed 'yayoi kusama' in English (lower right)\r\nscreenprint \r\nimage: 24 x 28.5 cm. (9 1/2 x 11 1/4 in.)\r\npaper: 28 x 32 cm.  (11 x 12 5/8 in.)\r\nedition 29/80 (AP 8, PP5)\r\nExecuted in 2004 \r\n",
          cid: '3361.770',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2014.3361.502.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58547/yayoi_kusama_mackerel_sky_at_sunset_d5854745g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 502,
          auctionHouse: 'christies',
          high: 156000,
          low: 104000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (B.1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 800,000 - HKD 1,200,000',
          birth: 1929,
          realized: 'HKD 1,240,000',
          real: 161200,
          timexcoord: '2014-11-01',
          sale: 3361,
          month: 11,
          name: 'Mackerel Sky at Sunset \r\n',
          x: 49,
          y: 64.5,
          stuff:
            "YAYOI KUSAMA (B.1929) \r\nMackerel Sky at Sunset \r\nsigned and dated '1978 YAYOI KUSAMA' (lower left); signed and dated 'Yayoi Kusama 1978'; signed and titled in Japanese (on the reverse)\r\nacrylic on canvas \r\n49 x 64.5 cm. (19 1/4 x 25 3/8 in.) \r\nPainted in 1978 \r\n",
          cid: '3361.502',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2014.3361.501.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58547/yayoi_kusama_red_pumpkin_d5854744g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          lot: 501,
          auctionHouse: 'christies',
          high: 97500,
          low: 71500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (B.1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 550,000 - HKD 750,000',
          birth: 1929,
          realized: 'HKD 1,720,000',
          real: 223600,
          timexcoord: '2014-11-01',
          sale: 3361,
          month: 11,
          name: 'Red Pumpkin \r\n',
          x: 38,
          y: 46,
          stuff:
            "YAYOI KUSAMA (B.1929) \r\nRed Pumpkin \r\nsigned 'YAYOI KUSAMA' in English; titled in Japanese (on the reverse) \r\nacrylic on canvas \r\n38 x 46 cm. (15 x 18 1/8 in.) \r\nPainted in 1991 \r\n",
          cid: '3361.501',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2014.3361.769.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58550/yayoi_kusama_a_pumpkin_yb-d_d5855010g.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          edition: 33,
          lot: 769,
          auctionHouse: 'christies',
          high: 6500,
          low: 3900,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (B.1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          editionOf: 120,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 30,000 - HKD 50,000',
          birth: 1929,
          realized: 'HKD 81,250',
          real: 10562.5,
          timexcoord: '2014-11-01',
          sale: 3361,
          month: 11,
          name: 'A Pumpkin YB-D \r\n',
          x: 24,
          y: 28.5,
          stuff:
            "YAYOI KUSAMA (B.1929) \r\nA Pumpkin YB-D \r\ndated '2004'; titled 'A Pumpkin YB-D' in English; numbered '33/120' (lower left); signed 'Yayoi Kusama' in English (lower right)\r\nscreenprint \r\nimages: 24 x 28.5 cm. (9 1/2 x 11 1/4 in.)\r\npaper: 28 x 32 (11 x 12 5/8 in.)\r\nedition 33/120 (AP 12, PP5)\r\nExecuted in 2004 \r\n",
          cid: '3361.769',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2014.NY010714.35.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010714/35_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 800000,
          provenance:
            'David Zwirner, New York Private Collection, South America',
          low: 600000,
          currency: 'USD',
          id: 'phillips.11.2014.NY010714.35.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010714/35',
          salesTitle: 'contemporary art evening',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 605000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'Fear of Death',
          x: 194,
          y: 194,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'Yayoi Kusama 2008 Fear of Death' on the reverse. ^ 76 1/2 x 76 1/2 in. (194.3 x 194.3 cm) ^ David Zwirner, New York Private Collection, South America ^ 11",
          cid: 'NY010714.35',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2014.NY010814.166.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010814/166_001.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 150000,
          provenance:
            'Private Collection The Market Auction, Inc., Toyko, Contemporary and Modern Art, October 25, 2007, lot 21 Private Collection Augur Auction, Tokyo, Sale #15, March 24, 2012, lot 62 Acquired at the above sale by the present owner',
          low: 100000,
          currency: 'USD',
          id: 'phillips.11.2014.NY010814.166.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010814/166',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 100000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'Infinity Nets (TWWP)',
          x: 46,
          y: 53,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'Yayoi Kusama 2006 INFINITY NETS TWWP' on the reverse. This work is accompanied by Yayoi Kusama's Art Work Registration Card. ^ 18 x 21 in. (45.7 x 53.3 cm) ^ Private Collection The Market Auction, Inc., Toyko, Contemporary and Modern Art, October 25, 2007, lot 21 Private Collection Augur Auction, Tokyo, Sale #15, March 24, 2012, lot 62 Acquired at the above sale by the present owner ^ 11",
          cid: 'NY010814.166',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-113',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 650000,
          low: 450000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-113',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.113.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(965000)',
          real: 965000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'INFINITY-NETS (TTOWHON)',
          x: 128,
          y: 159,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (TTOWHON)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the reverseacrylic on canvas50 1/2  by 62 3/4  in.   128.3 by 159.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; Tokyo (acquired in 2007)Ǝ',
          cid: 'contemporary-art-day-sale-n09222-113',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-168',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 150000,
          low: 100000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-168',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.168.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(257000)',
          real: 257000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'ENCOUNTER',
          x: 71,
          y: 58,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                ENCOUNTERƎ                Ǝ                    Ǝ                        signed and dated 1954; signed; titled in Japanese and dated 1954 on the reverseacrylic and gouache on paper28 by 23 in.   71.1 by 58.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Shuzo Takiguchi Collection; TokyoPrivate Collection; JapanƎ',
          cid: 'contemporary-art-day-sale-n09222-168',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-283',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-283',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.283.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(131000)',
          real: 131000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'DOTS - INFINITY (TT2)',
          x: 46,
          y: 53,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                DOTS - INFINITY (TT2)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2004 on the reverseoil on canvas18 by 20 3/4  in.   45.5 by 53 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Galerie Pièce Unique; ParisAcquired by the present owner from the above in October 2004Ǝ',
          cid: 'contemporary-art-day-sale-n09222-283',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-281',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-281',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.281.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(161000)',
          real: 161000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'PUMPKIN',
          x: 24,
          y: 33,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1995 on the reverseacrylic on canvas9 1/2  by 13 in.   24.1 by 33 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-art-day-sale-n09222-281',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-282',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 150000,
          low: 100000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-282',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.282.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(209000)',
          real: 209000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'INFINITY-NETS (OQ6)',
          x: 73,
          y: 61,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (OQ6)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2000 on the reverseacrylic on canvas28 3/4  by 24 in.   73 by 61 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Anthony Meier Fine Art; San FranciscoOta Fine Arts; TokyoPrivate Collection; JapanƎ',
          cid: 'contemporary-art-day-sale-n09222-282',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-284',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-284',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.284.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(112500)',
          real: 112500,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'INFINITY NETS',
          x: 46,
          y: 53,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1999 on the reverseacrylic on canvas18 by 21 in.   45.7 by 53.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Murayama Fine Art; New York (acquired directly from the artist)Private Collection; SacramentoAcquired by the present owner from the above in 2006Ǝ',
          cid: 'contemporary-art-day-sale-n09222-284',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-323',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-323',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.323.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(52500)',
          real: 52500,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'AFTERNOON',
          x: 45,
          y: 52,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                AFTERNOONƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1987 on the reverseacrylic on canvas17 1/2  by 20 1/2  in.   44.5 by 52 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-art-day-sale-n09222-323',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-324',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-324',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.324.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(81250)',
          real: 81250,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'PUMPKIN',
          x: 14,
          y: 18,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1993 on the reverseacrylic on canvas5 5/8  by 7 1/8  in.   14.3 by 18.1 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n09222-324',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-325',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-325',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.325.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(10000)',
          real: 10000,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'WHEREABOUTS OF CLOUD',
          x: 24,
          y: 27,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                WHEREABOUTS OF CLOUDƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1980 on the reverseenamel on paper9 1/2  by 10 5/8  in.   24.1 by 27 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-sale-n09222-325',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-326',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-326',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.326.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(42500)',
          real: 42500,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'FACE NO. 5',
          x: 27,
          y: 20,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                FACE NO. 5Ǝ                Ǝ                    Ǝ                        signed and dated 1953; signed and titled on the reversegouache; Chinese ink and pastel on paper10 3/4  by 8 in.   27.3 by 20.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Faggionato Fine Arts; LondonVictoria Miro Gallery; LondonPrivate Collection; JapanƎ',
          cid: 'contemporary-art-day-sale-n09222-326',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-328',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2014,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'USD',
          id:
            'sothebys.11.2014.yayoi-kusama.contemporary-art-day-sale-n09222-328',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2014/contemporary-art-day-sale-n09222/lot.328.html',
          salesTitle: 'contemporary-art-day-sale-n09222',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(27500)',
          real: 27500,
          timexcoord: '2014-11-01',
          month: 11,
          name: 'CLOUDS IN THE DUSK (RED CLOUDS)',
          x: 51,
          y: 65,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                CLOUDS IN THE DUSK (RED CLOUDS)Ǝ                Ǝ                    Ǝ                        signed and dated 1977; signed; titled in Japanese and dated 1977 on the reversewatercolor; pastel and ink on paper19 7/8  by 25 3/4  in.   50.5 by 65.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-art-day-sale-n09222-328',
        },
        fields: {
          ymonths: [178],
        },
        sort: [1414800000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2015.10337.30.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58715/yayoi_kusama_infinity_nets_d5871580g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 30,
          auctionHouse: 'christies',
          high: 525000,
          low: 375000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Evening Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 250,000 - GBP 350,000',
          birth: 1929,
          realized: 'GBP 482,500',
          real: 723750,
          timexcoord: '2015-02-01',
          sale: 10337,
          month: 2,
          name: 'Infinity Nets (KWOPH)\r\n',
          x: 100,
          y: 100,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nInfinity Nets (KWOPH)\r\nsigned, titled and dated 'INFINITY-NETS Yayoi Kusama 2006 KWOPH' and signed, titled and dated again in Japanese (on the reverse)\r\nacrylic on canvas\r\n39 3/8 x 39 3/8in. (100 x 100cm.)\r\nPainted in 2006\r\n",
          cid: '10337.30',
        },
        fields: {
          ymonths: [181],
        },
        sort: [1422748800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.2.2015.UK010215.153.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010215/153_001.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 70000,
          provenance:
            'Private Collection Claudio Poleschi Arte Contemporanea, Lucca',
          low: 50000,
          currency: 'GBP',
          id: 'phillips.2.2015.UK010215.153.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010215/153',
          salesTitle: 'contemporary art day',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 309750,
          timexcoord: '2015-02-01',
          month: 2,
          name: 'Pumpkin',
          x: 53,
          y: 45,
          location: 'london',
          stuff:
            "acrylic on canvas ^ Signed, numbered and dated 'Yayoi Kusama 1990 9033' on the reverse. This work is registered with the artist's archive under no. 1135. ^ 52.6 x 45.4 cm (20 3/4 x 17 7/8 in.) ^ Private Collection Claudio Poleschi Arte Contemporanea, Lucca ^ 2",
          cid: 'UK010215.153',
        },
        fields: {
          ymonths: [181],
        },
        sort: [1422748800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2015.3402.33.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58756/yayoi_kusama_central_park_in_winter_d5875659g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 33,
          auctionHouse: 'christies',
          high: 32500,
          low: 19500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle: 'Asia+ / First Open',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 150,000 - HKD 250,000',
          birth: 1929,
          realized: 'HKD 225,000',
          real: 29250,
          timexcoord: '2015-03-01',
          sale: 3402,
          month: 3,
          name: 'Central Park in Winter \r\n',
          x: 27.2,
          y: 24.2,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nCentral Park in Winter \r\nsigned and dated 'Yayoi Kusama 1978' (upper right) \r\nenamel on paperboard \r\n27.2 x 24.2 cm. (10 3/4 x 9 1/2 in.) \r\nExecuted in 1978 \r\n",
          cid: '3402.33',
        },
        fields: {
          ymonths: [182],
        },
        sort: [1425168000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2015.10420.22.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58789/yayoi_kusama_infinity_nets_d5878994g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 22,
          auctionHouse: 'christies',
          high: 33000,
          low: 27000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (B.1929)\r\n',
          salesTitle: 'First Open / LDN',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 18,000 - GBP 22,000',
          birth: 1929,
          realized: 'GBP 25,000',
          real: 37500,
          timexcoord: '2015-03-01',
          sale: 10420,
          month: 3,
          name: 'Infinity Nets\r\n',
          x: 18,
          y: 14,
          stuff:
            "Yayoi Kusama (B.1929)\r\nInfinity Nets\r\nsigned, titled in Japanese and dated 'yayoi Kusama 1998' (on the reverse)\r\nacrylic on canvas\r\n7 x 5 ½in. (18 x 14cm.)\r\nPainted in 1998\r\n",
          cid: '10420.22',
        },
        fields: {
          ymonths: [182],
        },
        sort: [1425168000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2015.10420.21.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58789/yayoi_kusama_nets_41_d5878993g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 21,
          auctionHouse: 'christies',
          high: 52500,
          low: 37500,
          currency: 'GBP',
          brand: 'Yayoi Kusama (B. 1929)\r\n',
          salesTitle: 'First Open / LDN',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 25,000 - GBP 35,000',
          birth: 1929,
          realized: 'GBP 43,750',
          real: 65625,
          timexcoord: '2015-03-01',
          sale: 10420,
          month: 3,
          name: 'Nets 41\r\n',
          x: 31.8,
          y: 41,
          stuff:
            "Yayoi Kusama (B. 1929)\r\nNets 41\r\nsigned, titled and dated 'yayoi Kusama 1997 Nets 41' (on the reverse)\r\nacrylic on canvas\r\n12 ½ x 16 1/8in. (31.8 x 41cm.)\r\nPainted in 1997\r\n",
          cid: '10420.21',
        },
        fields: {
          ymonths: [182],
        },
        sort: [1425168000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2015.3402.29.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58756/yayoi_kusama_lemon_squash_d5875655g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 29,
          auctionHouse: 'christies',
          high: 507000,
          low: 377000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\n',
          salesTitle: 'Asia+ / First Open',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 2,900,000 - HKD 3,900,000',
          birth: 1929,
          realized: 'HKD 4,240,000',
          real: 551200,
          timexcoord: '2015-03-01',
          sale: 3402,
          month: 3,
          name: 'Lemon Squash \r\n',
          x: 91,
          y: 72.7,
          stuff:
            "YAYOI KUSAMA\r\n(Japanese, B. 1929)\r\nLemon Squash \r\nsigned and dated 'Yayoi Kusama 1991', titled in Japanese (on the reverse)\r\nacrylic on canvas \r\n91 x 72.7 cm.  (35 7/8 x 28 5/8 in.) \r\nPainted in 1991 \r\n",
          cid: '3402.29',
        },
        fields: {
          ymonths: [182],
        },
        sort: [1425168000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2015.yayoi-kusama.contemporary-curated-n09316-246',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id: 'sothebys.3.2015.yayoi-kusama.contemporary-curated-n09316-246',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-curated-n09316/lot.246.html',
          salesTitle: 'contemporary-curated-n09316',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(137500)',
          real: 137500,
          timexcoord: '2015-03-01',
          month: 3,
          name: 'BLACK BUTTERFLIES',
          x: 54,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                BLACK BUTTERFLIESƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1990 on the reverseacrylic on canvas21 by 18 in.   53.5 by 45.8 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanPrivate Collection; JapanƎ',
          cid: 'contemporary-curated-n09316-246',
        },
        fields: {
          ymonths: [182],
        },
        sort: [1425168000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2015.yayoi-kusama.contemporary-curated-n09316-315',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 20000,
          low: 15000,
          currency: 'USD',
          id: 'sothebys.3.2015.yayoi-kusama.contemporary-curated-n09316-315',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-curated-n09316/lot.315.html',
          salesTitle: 'contemporary-curated-n09316',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(15000)',
          real: 15000,
          timexcoord: '2015-03-01',
          month: 3,
          name: 'BUTTERFLY',
          x: 24,
          y: 28,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                BUTTERFLYƎ                Ǝ                    Ǝ                        signed and dated 1980; signed; titled in Japanese and dated 1980 on the reversewatercolor and pen on paperboard9 1/2  by 10 3/4  in.   24 by 27.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Nicholas Gallery; BelfastGalleria Dep Art; MilanAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-curated-n09316-315',
        },
        fields: {
          ymonths: [182],
        },
        sort: [1425168000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2015.3478.101.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58915/yayoi_kusama_red_pumpkin_d5891570g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 101,
          auctionHouse: 'christies',
          high: 42000.00000000001,
          low: 25900.000000000004,
          currency: 'CNY',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Asian And Western 20th Century & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 185,000 - CNY 300,000',
          birth: 1929,
          realized: 'CNY 375,000',
          real: 52500.00000000001,
          timexcoord: '2015-04-01',
          sale: 3478,
          month: 4,
          name: 'Red Pumpkin \r\n',
          x: 14.2,
          y: 18,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nRed Pumpkin \r\nsigned, titled in Japanese and dated 'Yayoi Kusama 1996' (on the reverse)\r\nacrylic on canvas \r\n14.2 x 18 cm. (5 5/8 x 7 1/8 in.) \r\nPainted in 1996 \r\n",
          cid: '3478.101',
        },
        fields: {
          ymonths: [183],
        },
        sort: [1427846400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2015.3478.102.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58915/yayoi_kusama_nebula_d5891571g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 102,
          auctionHouse: 'christies',
          high: 126000.00000000001,
          low: 84000.00000000001,
          currency: 'CNY',
          brand: 'Yayoi Kusama (b. 1929) \r\n',
          salesTitle: 'Asian And Western 20th Century & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 600,000 - CNY 900,000',
          birth: 1929,
          realized: 'CNY 990,000',
          real: 138600,
          timexcoord: '2015-04-01',
          sale: 3478,
          month: 4,
          name: 'Nebula \r\n',
          x: 65,
          y: 53,
          stuff:
            "Yayoi Kusama (b. 1929) \r\nNebula \r\nSigned in English, titled in Japanese, dated '1989' (on the reverse) \r\nacrylic on canvas \r\n65 x 53 cm. (25 5/8 x 20 7/8 in.) \r\nPainted in 1989 \r\n",
          cid: '3478.102',
        },
        fields: {
          ymonths: [183],
        },
        sort: [1427846400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2015.NY030115.99.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030115/99_001.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id: 'phillips.4.2015.NY030115.99.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030115/99',
          salesTitle: 'editions: including a selection of works on paper',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 62500,
          timexcoord: '2015-04-01',
          month: 4,
          name: 'Amour pour Toujours',
          x: 66,
          y: 51,
          location: 'new york',
          stuff:
            "The complete set of 10 screenprints in colors with glitter, on Rives BFK paper, with full margins, with accompanying poem by d'Alain Jouffroy, ^ signed and dated `2000' in gold ink on the portfolio, all sheets signed, titled, dated `2000' and annotated `A.P.' in pencil (an artist's proof, the edition was 60), published by Edition d'FMR Limited, Paris, all contained in the original handmade paper and embossed leather portfolio. ^ 26 x 20 in. (66 x 50.8 cm) ^ null ^ 4",
          cid: 'NY030115.99',
        },
        fields: {
          ymonths: [183],
        },
        sort: [1427846400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2015.3742.259.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58954/yayoi_kusama_infinity_nets_d5895479g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 259,
          auctionHouse: 'christies',
          high: 150000,
          low: 80000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Day Sale Session I',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 80,000 - USD 150,000',
          birth: 1929,
          realized: 'USD 100,000',
          real: 100000,
          timexcoord: '2015-05-01',
          sale: 3742,
          month: 5,
          name: 'Infinity Nets (ZYA)\r\n',
          x: 45,
          y: 37.4,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nInfinity Nets (ZYA)\r\nsigned, titled and dated 'Yayoi Kusama 1999 Infinity Nets (ZYA)' (in English and Japanese on the reverse)\r\nacrylic on canvas\r\n17 3/4 x 14 3/4 in. (45 x 37.4 cm.)\r\nPainted in 1999.\r\n",
          cid: '3742.259',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2015.3415.102.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59057/yayoi_kusama_pumpkin_green_towht_d5905762g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 102,
          auctionHouse: 'christies',
          high: 195000,
          low: 130000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,000,000 - HKD 1,500,000',
          birth: 1929,
          realized: 'HKD 2,440,000',
          real: 317200,
          timexcoord: '2015-05-01',
          sale: 3415,
          month: 5,
          name: 'Pumpkin Green  [TOWHT] \r\n',
          x: 45.5,
          y: 53,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929) \r\nPumpkin Green  [TOWHT] \r\nsigned 'Yayoi Kusama' in English; dated '2005'; titled 'PUMPKIN TOWHT GREEN' in English (on the reverse)\r\nacrylic on canvas \r\n45.5 x 53 cm. (17 3/4 x 20 7/8 in.) \r\nPainted in 2005 \r\n",
          cid: '3415.102',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2015.3415.240.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59059/yayoi_kusama_mountains_d5905900g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 240,
          auctionHouse: 'christies',
          high: 15600,
          low: 11700,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 90,000 - HKD 120,000',
          birth: 1929,
          realized: 'HKD 137,500',
          real: 17875,
          timexcoord: '2015-05-01',
          sale: 3415,
          month: 5,
          name: 'Mountains \r\n',
          x: 24.2,
          y: 27.2,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929) \r\nMountains \r\nsigned 'YAYOI KUSAMA' in English; dated '1979' (upper left); signed 'Yayoi Kusama'; dated '1979'; titled in Japanese (on the reverse)\r\nmarker pen, ink on paper \r\n24.2 x 27.2 cm. (9 1/2 x 10 3/4 in.) \r\nPainted in 1979 \r\n",
          cid: '3415.240',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2015.3415.309.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59059/yayoi_kusama_flower_d5905969g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          edition: 18,
          lot: 309,
          auctionHouse: 'christies',
          high: 10400,
          low: 6500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          editionOf: 60,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 50,000 - HKD 80,000',
          birth: 1929,
          realized: 'HKD 62,500',
          real: 8125,
          timexcoord: '2015-05-01',
          sale: 3415,
          month: 5,
          name: 'Flower \r\n',
          x: 54,
          y: 45,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929) \r\nFlower \r\nsigned 'Yayoi Kusama' in English; dated '1999'; titled in Japanese; numbered '18/60' (lower edge)\r\nsilkscreen print \r\n54 x 45 cm. (21 1/4 x 17 3/4 in.) \r\nedition 18/60\r\nExecuted in 1999 \r\n",
          cid: '3415.309',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2015.3742.176.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58953/yayoi_kusama_stars_of_the_night_tsaow_d5895396g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 176,
          auctionHouse: 'christies',
          high: 600000,
          low: 400000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Day Sale Session I',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 400,000 - USD 600,000',
          birth: 1929,
          realized: 'USD 509,000',
          real: 509000,
          timexcoord: '2015-05-01',
          sale: 3742,
          month: 5,
          name: 'Stars of the Night TSAOW\r\n',
          x: 194,
          y: 194,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nStars of the Night TSAOW\r\nsigned, titled and dated 'YAYOI KUSAMA 2007 STARS OF THE NIGHT TSAOW' (on the reverse)\r\nacrylic on canvas\r\n76 3/8 x 76 3/8 in. (194 x 194 cm.)\r\nPainted in 2007.\r\n",
          cid: '3742.176',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2015.3740.41.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D58960/yayoi_kusama_untitled_d5896029g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 41,
          auctionHouse: 'christies',
          high: 3000000,
          low: 2000000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Evening Sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 2,000,000 - USD 3,000,000',
          birth: 1929,
          realized: 'USD 3,077,000',
          real: 3077000,
          timexcoord: '2015-05-01',
          sale: 3740,
          month: 5,
          name: 'Untitled\r\n',
          x: 91.4,
          y: 76.2,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nUntitled\r\nsigned and dated 'Kusama 1967' (on the reverse)\r\noil on canvas\r\n36 x 30 in. (91.4 x 76.2 cm.)\r\nPainted in 1967.\r\n",
          cid: '3740.41',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2015.3415.101.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59057/yayoi_kusama_untitled_d5905761g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 101,
          auctionHouse: 'christies',
          high: 78000,
          low: 52000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929) \r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 400,000 - HKD 600,000',
          birth: 1929,
          realized: 'HKD 937,500',
          real: 121875,
          timexcoord: '2015-05-01',
          sale: 3415,
          month: 5,
          name: 'Untitled \r\n',
          x: 53,
          y: 45.5,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929) \r\nUntitled \r\nsigned and dated 1970 Kusama (lower middle); signed and dated again 'Yayoi Kusama 1970' (on the reverse)\r\nmarker pen on canvas \r\n53 x 45.5 cm. (20 7/8 x 17 7/8 in.) \r\nPainted in 1970 \r\n",
          cid: '3415.101',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2015.yayoi-kusama.contemporary-evening-n09345-17',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 7000000,
          low: 5000000,
          currency: 'USD',
          id: 'sothebys.5.2015.yayoi-kusama.contemporary-evening-n09345-17',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-evening-n09345/lot.17.html',
          salesTitle: 'contemporary-evening-n09345',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(5850000)',
          real: 5850000,
          timexcoord: '2015-05-01',
          month: 5,
          name: 'INTERMINABLE NET #3',
          x: 133,
          y: 125,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INTERMINABLE NET #3Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1959 on the reverseoil on canvas52 1/2  x 49 in. 133.4 x 124.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        JFK University Collection; Orinda; CaliforniaAcquired by the present owner in 1990Ǝ                    ExhibitedƎ                        Los Angeles; Los Angeles County Museum of Art; New York; Museum of Modern Art; Minneapolis; The Walker Art Center; Tokyo; The Museum of Contemporary Art; Love Forever: Yayoi Kusama; 1958 - 1968; March 1998 – July 1999; cat. no. 4; p. 92; illustrated in colorƎ',
          cid: 'contemporary-evening-n09345-17',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-103',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 90000,
          low: 70000,
          currency: 'USD',
          id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-103',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-day-n09346/lot.103.html',
          salesTitle: 'contemporary-day-n09346',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(93750)',
          real: 93750,
          timexcoord: '2015-05-01',
          month: 5,
          name: 'EGG (C.B.)',
          x: 26,
          y: 26,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                EGG (C.B.)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1954 on the reverseacrylic and gouache on paper10 1/4  by 10 1/4  in.   26 by 26 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Peter Blum Gallery; New YorkBarbara Mathes Gallery; New YorkMarc Selwyn Fine Art; Los AngelesAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-day-n09346-103',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-203',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 600000,
          low: 400000,
          currency: 'USD',
          id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-203',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-day-n09346/lot.203.html',
          salesTitle: 'contemporary-day-n09346',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(1150000)',
          real: 1150000,
          timexcoord: '2015-05-01',
          month: 5,
          name: 'INFINITY-NETS (TXOB)',
          x: 100,
          y: 100,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (TXOB)Ǝ                Ǝ                    Ǝ                        signed; titled; and dated 2006 on the reverseacrylic on canvas39 3/8  by 39 3/8  in.   100 by 100 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts; TokyoAcquired by the present owner from the aboveƎ                    ExhibitedƎ                        Hong Kong; SƎ2; Hong Kong Blooms In My Mind; May 2012; cat. no. 33; p. 82; illustrated in colorƎ',
          cid: 'contemporary-day-n09346-203',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-204',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 250000,
          low: 150000,
          currency: 'USD',
          id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-204',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-day-n09346/lot.204.html',
          salesTitle: 'contemporary-day-n09346',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(298000)',
          real: 298000,
          timexcoord: '2015-05-01',
          month: 5,
          name: 'PUMPKIN',
          x: 38,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1990 on the reverseacrylic on canvas15 by 18 in.   38 by 45.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; Japan Ǝ',
          cid: 'contemporary-day-n09346-204',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-207',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-207',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-day-n09346/lot.207.html',
          salesTitle: 'contemporary-day-n09346',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(81250)',
          real: 81250,
          timexcoord: '2015-05-01',
          month: 5,
          name: 'STAR',
          x: 38,
          y: 45,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                STARƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1993 on the reverseacrylic on canvas15 by 17 7/8  in.   38.1 by 45.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkGalleri Lars Bohman; StockholmAcquired by the present owner from the above Ǝ',
          cid: 'contemporary-day-n09346-207',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-208',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-208',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-day-n09346/lot.208.html',
          salesTitle: 'contemporary-day-n09346',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(112500)',
          real: 112500,
          timexcoord: '2015-05-01',
          month: 5,
          name: 'PUMPKIN',
          x: 16,
          y: 23,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1991 on the reverseacrylic on canvas6 1/2  by 9 in.   15.8 by 22.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-day-n09346-208',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-209',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'USD',
          id: 'sothebys.5.2015.yayoi-kusama.contemporary-day-n09346-209',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-day-n09346/lot.209.html',
          salesTitle: 'contemporary-day-n09346',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(670000)',
          real: 670000,
          timexcoord: '2015-05-01',
          month: 5,
          name: 'INFINITY-NETS (JAAAS)',
          x: 100,
          y: 100,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS (JAAAS)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2009 on the reverseacrylic on canvas39 3/8  by 39 3/8  in.   100 by 100 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts; TokyoAcquired by the present owner from the aboveƎ                    ExhibitedƎ                        Hong Kong; SƎ2; Hong Kong Blooms In My Mind; May 2012; cat. no. 35; p. 86; illustrated in colorƎ',
          cid: 'contemporary-day-n09346-209',
        },
        fields: {
          ymonths: [184],
        },
        sort: [1430438400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.6.2015.yayoi-kusama.art-contemporain-pf1505-1',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'EUR',
          id: 'sothebys.6.2015.yayoi-kusama.art-contemporain-pf1505-1',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/art-contemporain-pf1505/lot.1.html',
          salesTitle: 'art-contemporain-pf1505',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(159000)',
          real: 190800,
          timexcoord: '2015-06-01',
          month: 6,
          name:
            'STARDUSTSIGNED AND DATED 1991 ON THE REVERSE; ACRYLIC ON CANVAS.  EXECUTED IN 1991.',
          x: 65,
          y: 53,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                STARDUSTSIGNED AND DATED 1991 ON THE REVERSE; ACRYLIC ON CANVAS.  EXECUTED IN 1991.Ǝ                Ǝ                    Ǝ                        signé et daté 1991 au dosacrylique sur toile65;4 x 53;3 cm; 25  3/4  x 21 in.Exécuté en 1991.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkFabian & Claude Walter Galerie; ZürichVente: Sotheby's; Londres; Contemporary Art; 30 juin 2011; lot 272Acquis lors de cette vente par le propriétaire actuelƎ",
          cid: 'art-contemporain-pf1505-1',
        },
        fields: {
          ymonths: [185],
        },
        sort: [1433116800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2015.3761.109.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59326/yayoi_kusama_the_castle_d5932622g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 109,
          auctionHouse: 'christies',
          high: 25000,
          low: 18000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'First Open ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 18,000 - USD 25,000',
          birth: 1929,
          realized: 'USD 50,000',
          real: 50000,
          timexcoord: '2015-09-01',
          sale: 3761,
          month: 9,
          name: 'The Castle\r\n',
          x: 24.6,
          y: 17.8,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nThe Castle\r\nsigned and dated 'YAYOI KUSAMA 1954' (lower right); signed again, titled and dated again '\"THE Castle\" 1954 Yayoi Kusama' (on the reverse); signed again 'Yayoi Kusama' (on the reverse of the backing board)\r\nink, pastel and gouache on paper\r\n9 7/8 x 7 in. (24.6 x 17.8 cm.)\r\nExecuted in 1954.\r\n",
          cid: '3761.109',
        },
        fields: {
          ymonths: [188],
        },
        sort: [1441065600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2015.10421.90.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59283/yayoi_kusama_untitled_d5928361g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 90,
          auctionHouse: 'christies',
          high: 75000,
          low: 45000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (B. 1929)\r\n',
          salesTitle: 'First Open/LDN',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 30,000 - GBP 50,000',
          birth: 1929,
          realized: 'GBP 43,750',
          real: 65625,
          timexcoord: '2015-09-01',
          sale: 10421,
          month: 9,
          name: 'Untitled\r\n',
          x: 18,
          y: 14,
          stuff:
            'Yayoi Kusama (B. 1929)\r\nUntitled\r\nsigned and dated ‘Yayoi Kusama 1990’ (on the reverse)\r\nacrylic and graphite on canvas\r\n7 1/8 x 5 ½in. (18 x 14cm.)\r\nExecuted in 1990\r\n',
          cid: '10421.90',
        },
        fields: {
          ymonths: [188],
        },
        sort: [1441065600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2015.UK010615.27.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010615/27_001.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 350000,
          provenance: 'Ota Fine Arts, Tokyo',
          low: 250000,
          currency: 'GBP',
          id: 'phillips.10.2015.UK010615.27.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010615/27',
          salesTitle: 'contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 795750,
          timexcoord: '2015-10-01',
          month: 10,
          name: 'Infinity-Nets (OPARHJ)',
          x: 131,
          y: 131,
          location: 'london',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'YAYOI KUSAMA 'OPARHJ INFINITY-NETS' 2012' on the reverse. ^ 130.5 x 130.5 cm (51 3/8 x 51 3/8 in.) ^ Ota Fine Arts, Tokyo ^ 10",
          cid: 'UK010615.27',
        },
        fields: {
          ymonths: [189],
        },
        sort: [1443657600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2015.yayoi-kusama.contemporary-art-day-auction-l15025-109',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'GBP',
          id:
            'sothebys.10.2015.yayoi-kusama.contemporary-art-day-auction-l15025-109',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-l15025/lot.109.html',
          salesTitle: 'contemporary-art-day-auction-l15025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 159375,
          timexcoord: '2015-10-01',
          month: 10,
          name: 'INFINITY-NETS (A-B X)',
          x: 42,
          y: 31,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY-NETS (A-B X)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1999 on the reverseoil on canvas42.2 by 31.1cm.; 16 5/8  by 12 1/4 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Galleria Orler; Favaro VenetoAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-l15025-109',
        },
        fields: {
          ymonths: [189],
        },
        sort: [1443657600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2015.yayoi-kusama.contemporary-art-day-auction-l15025-108',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'GBP',
          id:
            'sothebys.10.2015.yayoi-kusama.contemporary-art-day-auction-l15025-108',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-l15025/lot.108.html',
          salesTitle: 'contemporary-art-day-auction-l15025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(143000)',
          real: 214500,
          timexcoord: '2015-10-01',
          month: 10,
          name: 'INFINITY DOTS',
          x: 46,
          y: 53,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY DOTSƎ                Ǝ                    Ǝ                        signed; titled and dated 1989 on the reverseacrylic on canvas46 by 53cm.; 18 1/8  by 20 7/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        OTA Fine Arts; TokyoƎ',
          cid: 'contemporary-art-day-auction-l15025-108',
        },
        fields: {
          ymonths: [189],
        },
        sort: [1443657600000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3455.173.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59591/yayoi_kusama_dots_obsession_d5959178g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          edition: 231,
          lot: 173,
          auctionHouse: 'christies',
          high: 3120,
          low: 2340,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          editionOf: 300,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 18,000 - HKD 24,000',
          birth: 1929,
          realized: 'HKD 37,500',
          real: 4875,
          timexcoord: '2015-11-01',
          sale: 3455,
          month: 11,
          name: 'Dots Obsession\r\n',
          x: 32.5,
          y: 25,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nDots Obsession\r\nsigned 'Y. Kusama', numbered '231/300'; titled and dated 'Dots Obsession 2011'(on the reverse)\r\nmirror with print\r\nmirror: 32.5 x 25 cm. (12 3/4 x 9 7/8 in.)\r\noverall:47.6 x 40 cm.(18 3/4x15 3/4 in.)\r\nedition 231/300\r\nExecuted in 2011\r\n",
          cid: '3455.173',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3455.174.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59591/yayoi_kusama_city_spring_bloom_d5959179g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          edition: 63,
          lot: 174,
          auctionHouse: 'christies',
          high: 6500,
          low: 3900,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          editionOf: 75,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 30,000 - HKD 50,000',
          birth: 1929,
          realized: 'HKD 81,250',
          real: 10562.5,
          timexcoord: '2015-11-01',
          sale: 3455,
          month: 11,
          name: 'City; Spring; & Bloom\r\n',
          x: 27.3,
          y: 21.9,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nCity; Spring; & Bloom\r\nCity: numbered '12/50'; titled in Japanese; dated '1993'(lower left); signed 'Yayoi Kusama' (lower right); Spring: numbered '62/75' (lower left); titled in Japanese' dated '1995'; signed 'Yayoi Kusama' (lower right); & Bloom: numbered '63/75' (lower left)\r\n\r\nthree etching\r\n27.3 x 21.9 cm. (10 3/4 x 8 5/8 in.) ; 22 x 34.3 cm. (8 5/8 x 13 1/2 in.); & 34.3 x 22.1 cm. (13 1/2 x 8 3/4) (3)\r\nedition 12/50; 62/75; & 63/75\r\nExecuted in 1993; 1995; & 1995\r\n",
          cid: '3455.174',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3455.167.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59591/yayoi_kusama_big_river_d5959172g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 167,
          auctionHouse: 'christies',
          high: 234000,
          low: 156000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,200,000 - HKD 1,800,000',
          birth: 1929,
          realized: 'HKD 2,080,000',
          real: 270400,
          timexcoord: '2015-11-01',
          sale: 3455,
          month: 11,
          name: 'Big River\r\n',
          x: 91,
          y: 73,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nBig River\r\nsigned 'Yayoi Kusama' in English; dated '1990'; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n91 x 73 cm. (35 7/8 x 28 3/4 in.)\r\nPainted in 1990\r\n",
          cid: '3455.167',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3450.57.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59588/yayoi_kusama_beyond_the_end_of_the_century_d5958859g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 57,
          auctionHouse: 'christies',
          high: 910000,
          low: 650000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPANESE, B. 1929)\r\n',
          salesTitle: 'Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 5,000,000 - HKD 7,000,000',
          birth: 1929,
          realized: 'HKD 5,440,000',
          real: 707200,
          timexcoord: '2015-11-01',
          sale: 3450,
          month: 11,
          name: 'Beyond the end of the Century\r\n',
          x: 162,
          y: 130.5,
          stuff:
            "YAYOI KUSAMA (JAPANESE, B. 1929)\r\nBeyond the end of the Century\r\ntitled in Japanese; titled 'BEYOND THE END OF THE CENTURY.' in English; signed 'YAYOI KUSAMA' in English; dated '1987'; signed 'Yayoi Kusama' in English (on the reverse)\r\nacrylic on canvas\r\n162 x 130.5 cm. (63 ¾ x 51 3/8 in.)\r\nPainted in 1987\r\n",
          cid: '3450.57',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3455.168.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59591/yayoi_kusama_pumpkin_d5959173g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 168,
          auctionHouse: 'christies',
          high: 36400,
          low: 23400,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 180,000 - HKD 280,000',
          birth: 1929,
          realized: 'HKD 625,000',
          real: 81250,
          timexcoord: '2015-11-01',
          sale: 3455,
          month: 11,
          name: 'Pumpkin\r\n',
          x: 14,
          y: 18,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nPumpkin\r\ntitled in Japanese; dated '1991'; signed 'YAYOI KUSAMA' (on the reverse)\r\nacrylic on canvas\r\n14 x 18 cm. (5 1/2 x 7 1/8 in.)\r\nPainted in 1991\r\n",
          cid: '3455.168',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3455.163.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59591/yayoi_kusama_pumpkin_d5959168g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 163,
          auctionHouse: 'christies',
          high: 325000,
          low: 195000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,500,000 - HKD 2,500,000',
          birth: 1929,
          realized: 'HKD 4,600,000',
          real: 598000,
          timexcoord: '2015-11-01',
          sale: 3455,
          month: 11,
          name: 'Pumpkin\r\n',
          x: 60.6,
          y: 72.7,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nPumpkin\r\nsigned 'Yayoi Kusama' in English; dated '1992'; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n60.6 x 72.7 cm. (23 7/8 x 28 5/8 in.)\r\nPainted in 1992\r\n",
          cid: '3455.163',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3450.58.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59588/yayoi_kusama_infinity_nets_qni_d5958860g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 58,
          auctionHouse: 'christies',
          high: 520000,
          low: 390000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPANESE, B. 1929)\r\n',
          salesTitle: 'Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 3,000,000 - HKD 4,000,000',
          birth: 1929,
          realized: 'HKD 7,000,000',
          real: 910000,
          timexcoord: '2015-11-01',
          sale: 3450,
          month: 11,
          name: 'Infinity Nets Q.N.I\r\n',
          x: 162,
          y: 130,
          stuff:
            "YAYOI KUSAMA (JAPANESE, B. 1929)\r\nInfinity Nets Q.N.I\r\nsigned 'Yayoi Kusama' in English; dated '1989'; titled 'Infinity Nets Q. N. I' in English (on the reverse)\r\nacrylic on canvas\r\n162 x 130 cm. (63 ¾ x 51 1/8 in.)\r\nPainted in 1989\r\n",
          cid: '3450.58',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3455.165.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59591/yayoi_kusama_untitled_d5959170g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 165,
          auctionHouse: 'christies',
          high: 84500,
          low: 58500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 450,000 - HKD 650,000',
          birth: 1929,
          realized: 'HKD 937,500',
          real: 121875,
          timexcoord: '2015-11-01',
          sale: 3455,
          month: 11,
          name: 'Untitled\r\n',
          x: 53,
          y: 45.5,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nUntitled\r\nsigned 'KUSAMA' in English (lower left); signed 'Yayoi Kusama'; dated '1970' (on the reverse)\r\nmarker pen on canvas\r\n53 x 45.5 cm. (20 7/8 x 17 7/8 in.)\r\nPainted in 1970\r\n",
          cid: '3455.165',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2015.3455.164.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59591/yayoi_kusama_aqua_net_d5959169g.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          lot: 164,
          auctionHouse: 'christies',
          high: 260000,
          low: 195000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,500,000 - HKD 2,000,000',
          birth: 1929,
          realized: 'HKD 1,360,000',
          real: 176800,
          timexcoord: '2015-11-01',
          sale: 3455,
          month: 11,
          name: 'Net in Blue\r\n',
          x: 45.5,
          y: 38,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nNet in Blue\r\ndated '1987'; signed 'Yayoi Kusama' in English; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n45.5 x 38 cm. (17 7/8 x 15 in.)\r\nPainted in 1987\r\n",
          cid: '3455.164',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2015.NY010715.1.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010715/1_001.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 900000,
          provenance:
            "Robert Miller Gallery, New York Private Collection, Denver Christie's, New York, Post-War and Contemporary Art Morning Session Including Works from the Collection of Michael Crichton, May 12, 2010, lot 226 Acquired at the above sale by the present owner",
          low: 700000,
          currency: 'USD',
          id: 'phillips.11.2015.NY010715.1.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010715/1',
          salesTitle: '20th century & contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 1325000,
          timexcoord: '2015-11-01',
          month: 11,
          name: 'Infinity Nets OPRT',
          x: 193,
          y: 193,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'OPRT Yayoi Kusama 2004 INFINITY NETS' on the reverse. ^ 76 x 76 in. (193 x 193 cm) ^ Robert Miller Gallery, New York Private Collection, Denver Christie's, New York, Post-War and Contemporary Art Morning Session Including Works from the Collection of Michael Crichton, May 12, 2010, lot 226 Acquired at the above sale by the present owner ^ 11",
          cid: 'NY010715.1',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2015.NY010815.171.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010815/171_001.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 50000,
          provenance:
            "Zoe Dusanne Gallery, Seattle Marshall and Helen Hatch Collection Christie's, New York, First Open: Post-War and Contemporary Art, March 10, 2011, lot 167 Acquired at the above sale by the present owner",
          low: 30000,
          currency: 'USD',
          id: 'phillips.11.2015.NY010815.171.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010815/171',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 62500,
          timexcoord: '2015-11-01',
          month: 11,
          name: 'Rock Spirit',
          x: 27,
          y: 20,
          location: 'new york',
          stuff:
            "ink and gouache on paper ^ Signed and dated 'Yayoi Kusama 1953' lower left. This work is registered by the Yayoi Kusama Studio. ^ 10 3/4 x 8 in. (27.3 x 20.3 cm) ^ Zoe Dusanne Gallery, Seattle Marshall and Helen Hatch Collection Christie's, New York, First Open: Post-War and Contemporary Art, March 10, 2011, lot 167 Acquired at the above sale by the present owner ^ 11",
          cid: 'NY010815.171',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2015.yayoi-kusama.contemporary-art-day-auction-n09421-122',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 1000000,
          low: 700000,
          currency: 'USD',
          id:
            'sothebys.11.2015.yayoi-kusama.contemporary-art-day-auction-n09421-122',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-n09421/lot.122.html',
          salesTitle: 'contemporary-art-day-auction-n09421',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(1690000)',
          real: 1690000,
          timexcoord: '2015-11-01',
          month: 11,
          name: 'INFINITY-NETS [TSSXPZ]',
          x: 160,
          y: 130,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS [TSSXPZ]Ǝ                Ǝ                    Ǝ                        signed twice; titled and dated 2007 on the reverseacrylic on canvas63 by 51 1/4  in.  160 by 130.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoƎ',
          cid: 'contemporary-art-day-auction-n09421-122',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2015.yayoi-kusama.contemporary-art-day-auction-n09421-186',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 200000,
          low: 150000,
          currency: 'USD',
          id:
            'sothebys.11.2015.yayoi-kusama.contemporary-art-day-auction-n09421-186',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-n09421/lot.186.html',
          salesTitle: 'contemporary-art-day-auction-n09421',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(538000)',
          real: 538000,
          timexcoord: '2015-11-01',
          month: 11,
          name: 'BLUE PUMPKIN',
          x: 53,
          y: 46,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                BLUE PUMPKINƎ                Ǝ                    Ǝ                        signed; titled and dated 1989 on the reverseacrylic on canvas20 7/8  by 18 in.   53 by 45.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private CollectionThe Market Auction; Inc.; Tokyo; July 11; 2009; lot 71Private Collection; ParisAcquired by the present owner from the aboveƎ',
          cid: 'contemporary-art-day-auction-n09421-186',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2015.yayoi-kusama.contemporary-art-day-auction-n09421-185',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 600000,
          low: 400000,
          currency: 'USD',
          id:
            'sothebys.11.2015.yayoi-kusama.contemporary-art-day-auction-n09421-185',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-n09421/lot.185.html',
          salesTitle: 'contemporary-art-day-auction-n09421',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(730000)',
          real: 730000,
          timexcoord: '2015-11-01',
          month: 11,
          name: 'INFINITY-NETS BY BLACK',
          x: 130,
          y: 163,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY-NETS BY BLACKƎ                Ǝ                    Ǝ                        signed; titled and dated 2013 on the reverseacrylic on canvas51 1/4  by 64 in.   130.3 by 162.6 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Victoria Miro; LondonAcquired by the present owner from the above in 2013Ǝ',
          cid: 'contemporary-art-day-auction-n09421-185',
        },
        fields: {
          ymonths: [190],
        },
        sort: [1446336000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.12.2015.yayoi-kusama.art-contemporain-pf1515-102',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2015,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'EUR',
          id: 'sothebys.12.2015.yayoi-kusama.art-contemporain-pf1515-102',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/art-contemporain-pf1515/lot.102.html',
          salesTitle: 'art-contemporain-pf1515',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(96600)',
          real: 115920,
          timexcoord: '2015-12-01',
          month: 12,
          name:
            'PUMPKIN (AABT)SIGNED; TITLED; TITLED IN JAPANESE AND DATED 2003 ON THE REVERSE; ACRYLIC ON CANVAS. EXECUTED IN 2003.',
          x: 15,
          y: 22,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                PUMPKIN (AABT)SIGNED; TITLED; TITLED IN JAPANESE AND DATED 2003 ON THE REVERSE; ACRYLIC ON CANVAS. EXECUTED IN 2003.Ǝ                Ǝ                    Ǝ                        signé; titré; titré en japonais et daté 2003 au dosacrylique sur toile15 x 22 cm; 5  7/8  x 8  11/16  in.Exécuté en 2003.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         Cette oeuvre est accompagnée d'un certificat d'authenticité établi par le studio de l'artiste.ƎƎ                        ProvenanceƎ                        Collection particulière; AsieVente: Sotheby's; Hong Kong; Contemporary Asian Art; 6 octobre 2013; lot 901Collection particulière; JaponƎ",
          cid: 'art-contemporain-pf1515-102',
        },
        fields: {
          ymonths: [191],
        },
        sort: [1448928000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2016.11796.262.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59725/yayoi_kusama_the_secret_lake_d5972561g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 262,
          auctionHouse: 'christies',
          high: 105000,
          low: 75000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 50,000 - GBP 70,000',
          birth: 1929,
          realized: 'GBP 80,500',
          real: 120750,
          timexcoord: '2016-02-01',
          sale: 11796,
          month: 2,
          name: 'The Secret Lake\r\n',
          x: 53,
          y: 46,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nThe Secret Lake\r\nsigned, titled in Japanese and dated 'Yayoi Kusama 1988' (on the reverse)\r\nacrylic on canvas\r\n20 7/8 x 18 1/8in. (53 x 46cm.)\r\nPainted in 1988\r\n",
          cid: '11796.262',
        },
        fields: {
          ymonths: [193],
        },
        sort: [1454284800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2016.11795.4.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59730/yayoi_kusama_accreations_i_d5973045g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 4,
          auctionHouse: 'christies',
          high: 1200000,
          low: 900000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Evening Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 600,000 - GBP 800,000',
          birth: 1929,
          realized: 'GBP 662,500',
          real: 993750,
          timexcoord: '2016-02-01',
          sale: 11795,
          month: 2,
          name: 'Accreations I  \r\n',
          x: 80,
          y: 70.5,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nAccreations I  \r\nsigned, titled and dated 'KUSAMA 67 ACCREATIONS I' (on the stretcher)\r\noil on canvas\r\n31 ½ x 27 7/8 in. (80 x 70.5cm.) \r\nPainted in 1967\r\n",
          cid: '11795.4',
        },
        fields: {
          ymonths: [193],
        },
        sort: [1454284800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.2.2016.11795.12.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59730/yayoi_kusama_infinity_nets_oqwws_d5973053g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 12,
          auctionHouse: 'christies',
          high: 1050000,
          low: 750000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Post-War and Contemporary Art Evening Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 500,000 - GBP 700,000',
          birth: 1929,
          realized: 'GBP 626,500',
          real: 939750,
          timexcoord: '2016-02-01',
          sale: 11795,
          month: 2,
          name: 'Infinity Nets OQWWS\r\n',
          x: 91.3,
          y: 70,
          stuff:
            "Yayoi Kusama (b. 1929)\r\nInfinity Nets OQWWS\r\nsigned, titled, and dated 'Yayoi Kusama 2006 INFINITY NETS OQWWS' (on the reverse)\r\noil on canvas\r\n35 7/8 x 27 5/8in. (91.3 x 70cm.)\r\nPainted in 2006\r\n",
          cid: '11795.12',
        },
        fields: {
          ymonths: [193],
        },
        sort: [1454284800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.2.2016.NY010216.6.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010216/6_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 70000,
          provenance:
            'Galerie Thelen, Essen Phillips de Pury & Company, New York, November 11, 2005, lot 395 Private Collection Phillips, New York, March 7, 2014, lot 138 Acquired at the above sale by the present owner',
          low: 50000,
          currency: 'USD',
          id: 'phillips.2.2016.NY010216.6.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010216/6',
          salesTitle: 'new now evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 62500,
          timexcoord: '2016-02-01',
          month: 2,
          name: 'Nets',
          x: 41,
          y: 32,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'Yayoi Kusama 1997 Nets' on the reverse. This work is accompanied by Yayoi Kusama's Art Work Registration Card. ^ 16 1/8 x 12 1/2 in. (41 x 31.8 cm.) ^ Galerie Thelen, Essen Phillips de Pury & Company, New York, November 11, 2005, lot 395 Private Collection Phillips, New York, March 7, 2014, lot 138 Acquired at the above sale by the present owner ^ 2",
          cid: 'NY010216.6',
        },
        fields: {
          ymonths: [193],
        },
        sort: [1454284800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.2.2016.UK010116.4.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010116/4_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 700000,
          provenance:
            'Gagosian Gallery, New York Phillips, New York, Contemporary Art Evening, 10 May 2012, lot 22 Acquired at the above sale by the present owner',
          low: 500000,
          currency: 'GBP',
          id: 'phillips.2.2016.UK010116.4.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010116/4',
          salesTitle: '20th century & contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 1119750,
          timexcoord: '2016-02-01',
          month: 2,
          name: 'Infinity Nets OPQR',
          x: 259,
          y: 194,
          location: 'london',
          stuff:
            'acrylic on canvas ^ null ^ 259.1 x 194 cm (102 x 76 3/8 in.) ^ Gagosian Gallery, New York Phillips, New York, Contemporary Art Evening, 10 May 2012, lot 22 Acquired at the above sale by the present owner ^ 2',
          cid: 'UK010116.4',
        },
        fields: {
          ymonths: [193],
        },
        sort: [1454284800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2016.12513.31.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59821/yayoi_kusama_watermelon_d5982176g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 31,
          auctionHouse: 'christies',
          high: 455000,
          low: 338000,
          currency: 'HKD',
          brand: 'Yayoi Kusama (Japanese, B. 1929)\r\n',
          salesTitle: 'FIRST OPEN | Hong Kong ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 2,600,000 - HKD 3,500,000',
          birth: 1929,
          realized: 'HKD 3,160,000',
          real: 410800,
          timexcoord: '2016-03-01',
          sale: 12513,
          month: 3,
          name: 'Watermelon\r\n',
          x: 72.7,
          y: 91,
          stuff:
            "Yayoi Kusama (Japanese, B. 1929)\r\nWatermelon\r\ntitiled in Japanese; dated '1981'; signed 'Yayoi Kusama' in English (on the stretcher)\r\ncloth, acrylic on canvas\r\n72.7 x 91 cm. (28 5/8 x 35 7/8 in.)\r\nPainted in 1981\r\n",
          cid: '12513.31',
        },
        fields: {
          ymonths: [194],
        },
        sort: [1456790400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2016.12513.37.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D59821/yayoi_kusama_papillion_d5982182g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 37,
          auctionHouse: 'christies',
          high: 15600,
          low: 9100,
          currency: 'HKD',
          brand: 'Yayoi Kusama (Japanese, B. 1929)\r\n',
          salesTitle: 'FIRST OPEN | Hong Kong ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 70,000 - HKD 120,000',
          birth: 1929,
          realized: 'HKD 106,250',
          real: 13812.5,
          timexcoord: '2016-03-01',
          sale: 12513,
          month: 3,
          name: 'Papillon\r\n',
          x: 24,
          y: 27,
          stuff:
            "Yayoi Kusama (Japanese, B. 1929)\r\nPapillon\r\nsigned and dated 'Yayoi Kusama 1980' (upper left); signed, titled in Japanese and dated '1980 Yayoi Kusama ' (on the reverse)\r\nwatercolor on paper\r\n24 x 27 cm. (9 1/2 x 10 5/8 in.)\r\nPainted in 1980.\r\none seal of the artist (upper right)\r\n\r\n",
          cid: '12513.37',
        },
        fields: {
          ymonths: [194],
        },
        sort: [1456790400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2016.yayoi-kusama.contemporary-curated-l16026-19',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 8000,
          low: 6000,
          currency: 'GBP',
          id: 'sothebys.3.2016.yayoi-kusama.contemporary-curated-l16026-19',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-curated-l16026/lot.19.html',
          salesTitle: 'contemporary-curated-l16026',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(9375)',
          real: 14063,
          timexcoord: '2016-03-01',
          month: 3,
          name: 'AUTUMN WAVE',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                AUTUMN WAVEƎ                Ǝ                    Ǝ                        signed and dated 1978; signed; titled in Japanese and dated 1978 on the reverseacrylic and metallic paint on card27 by 24cm.; 10 5/8  by 9 1/2 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; AsiaPrivate Collection; EuropeChristie's; London; Post-War & Contemporary Art; 13 December 2007; Lot 154Acquired from the above by the present ownerƎ",
          cid: 'contemporary-curated-l16026-19',
        },
        fields: {
          ymonths: [194],
        },
        sort: [1456790400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2016.yayoi-kusama.contemporary-curated-l16026-21',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 350000,
          low: 250000,
          currency: 'GBP',
          id: 'sothebys.3.2016.yayoi-kusama.contemporary-curated-l16026-21',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-curated-l16026/lot.21.html',
          salesTitle: 'contemporary-curated-l16026',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(305000)',
          real: 457500,
          timexcoord: '2016-03-01',
          month: 3,
          name: 'FEAR OF DEATH',
          x: 194,
          y: 194,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                FEAR OF DEATHƎ                Ǝ                    Ǝ                        signed; titled and dated 2008 on the reverseacrylic on canvas194.3 by 194.3cm.; 76 1/2  by 76 1/2 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        David Zwirner; New York Private Collection; South AmericaPhillips; New York; Contemporary Art; 13 November 2014; Lot 35Acquired from the above by the present ownerƎ                    ExhibitedƎ                        New York; Gagosian Gallery; Yayoi Kusama; 2009; n.p.; illustrated in colourƎ',
          cid: 'contemporary-curated-l16026-21',
        },
        fields: {
          ymonths: [194],
        },
        sort: [1456790400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2016.yayoi-kusama.contemporary-curated-l16026-18',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 8000,
          low: 6000,
          currency: 'GBP',
          id: 'sothebys.3.2016.yayoi-kusama.contemporary-curated-l16026-18',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-curated-l16026/lot.18.html',
          salesTitle: 'contemporary-curated-l16026',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(9375)',
          real: 14063,
          timexcoord: '2016-03-01',
          month: 3,
          name: 'PACIFIC OCEAN IN THE EARLY SPRING',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PACIFIC OCEAN IN THE EARLY SPRINGƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1978 on the reverseacrylic and metallic paint on card26.9 by 23.9cm.; 10 5/8  by 9 3/8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; TokyoChristie's; London; Post-War & Contemporary Art; 13 December 2007; Lot 153Acquired from the above by the present ownerƎ",
          cid: 'contemporary-curated-l16026-18',
        },
        fields: {
          ymonths: [194],
        },
        sort: [1456790400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2016.NY030116.44.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030116/44_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 12000,
          low: 8000,
          currency: 'USD',
          id: 'phillips.4.2016.NY030116.44.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030116/44',
          salesTitle: 'evening & day editions including works on paper',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 21250,
          timexcoord: '2016-04-01',
          month: 4,
          name: 'Infinity Nets; Infinity Nets; Accumulation; and Accumulation',
          x: 46,
          y: 63,
          location: 'new york',
          stuff:
            "Four etchings, on Arches paper, with full margins, ^ all signed, titled, dated `1953-1984' and numbered 24/30 in pencil (there were also 3 artist's proofs), all unframed. ^ all I. 10 5/8 x 17 3/4 in. (27 x 45 cm) all S. 17 7/8 x 24 3/4 in. (45.6 x 63 cm) ^ null ^ 4",
          cid: 'NY030116.44',
        },
        fields: {
          ymonths: [195],
        },
        sort: [1459468800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2016.12516.129.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60009/yayoi_kusama_pumpkin_d6000920g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 129,
          auctionHouse: 'christies',
          high: 52000,
          low: 39000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japan, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 300,000 - HKD 400,000',
          birth: 1929,
          realized: 'HKD 1,060,000',
          real: 137800,
          timexcoord: '2016-05-01',
          sale: 12516,
          month: 5,
          name: 'Pumpkin (HOZ)\r\n',
          x: 27.3,
          y: 22,
          stuff:
            "YAYOI KUSAMA (Japan, B. 1929)\r\nPumpkin (HOZ)\r\nsigned 'Yayoi Kusama' in English; dated '2002'; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n27.3 x 22 cm. (10 3/4 x 8 5/8 in.)\r\nPainted in 2002\r\n",
          cid: '12516.129',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2016.12516.101.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60008/yayoi_kusama_flower_d6000892g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 101,
          auctionHouse: 'christies',
          high: 52000,
          low: 39000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japan, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 300,000 - HKD 400,000',
          birth: 1929,
          realized: 'HKD 562,500',
          real: 73125,
          timexcoord: '2016-05-01',
          sale: 12516,
          month: 5,
          name: 'Flower (Aower)\r\n',
          x: 33.3,
          y: 24.2,
          stuff:
            "YAYOI KUSAMA (Japan, B. 1929)\r\nFlower (Aower)\r\nsigned 'Yayoi Kusama' in English; dated '2003'; titled 'FLOWER (AOWER)' in English; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n33.3 x 24.2 cm. (13 1/8 x 9 1/2 in.)\r\nPainted in 2003\r\n",
          cid: '12516.101',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2016.12516.103.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60008/yayoi_kusama_pumpkin_d6000894g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 103,
          auctionHouse: 'christies',
          high: 624000,
          low: 494000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 3,800,000 - HKD 4,800,000',
          birth: 1929,
          realized: 'HKD 5,320,000',
          real: 691600,
          timexcoord: '2016-05-01',
          sale: 12516,
          month: 5,
          name: 'Pumpkin\r\n',
          x: 91,
          y: 72.7,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nPumpkin\r\nsigned 'Yayoi Kusama' in English, dated '2005'; titled 'Pumpkin' ; signed and titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n91 x 72.7 cm. (35 7/8 x 28 5/8 in.)\r\nPainted in 2005\r\n",
          cid: '12516.103',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2016.12720.3010.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60000/yayoi_kusama_sex-obsession_c_d6000013g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 3010,
          auctionHouse: 'christies',
          high: 1560000,
          low: 1170000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: '30 Years: The Sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 9,000,000 - HKD 12,000,000',
          birth: 1929,
          realized: 'HKD 15,640,000',
          real: 2033200,
          timexcoord: '2016-05-01',
          sale: 12720,
          month: 5,
          name: 'Sex-Obsession C\r\n',
          x: 194,
          y: 130.3,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nSex-Obsession C\r\nsigned 'Yayoi Kusama' in English; dated '1992'; titled \"SEX-OBSESSION C' in English (on the reverse)\r\nacrylic on canvas\r\n194 x 130.3 cm. (76 3/8 x 51 1/4 in.)\r\nPainted in 1992\r\n",
          cid: '12720.3010',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2016.12515.6.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60017/yayoi_kusama_cloud_considering_d6001736g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 6,
          auctionHouse: 'christies',
          high: 3380000,
          low: 2340000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 18,000,000 - HKD 26,000,000',
          birth: 1929,
          realized: 'HKD 19,720,000',
          real: 2563600,
          timexcoord: '2016-05-01',
          sale: 12515,
          month: 5,
          name: 'Cloud Considering\r\n',
          x: 162,
          y: 130,
          stuff:
            "YAYOI KUSAMA (Japanese, B. 1929)\r\nCloud Considering\r\ntitled in Japanese; signed 'Yayoi Kusama' and 'YAYOI KUSAMA' in English; dated '1991-92' (on the reverse of each)\r\nacrylic on canvas, diptych\r\neach 162 x 130 cm. (63 x 51 1/8 in.)\r\noverall 162 x 260 cm. (63 x 102 3/8 in.)\r\n(2)Painted in 1991-1992\r\n",
          cid: '12515.6',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2016.12515.1.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60017/yayoi_kusama_the_sea_in_the_evening_glow_d6001731g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 1,
          auctionHouse: 'christies',
          high: 715000,
          low: 455000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japanese, B. 1929)\r\n',
          salesTitle: 'Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 3,500,000 - HKD 5,500,000',
          birth: 1929,
          realized: 'HKD 6,640,000',
          real: 863200,
          timexcoord: '2016-05-01',
          sale: 12515,
          month: 5,
          name: 'The Sea in the Evening Glow (Facing the Imminent Death)\r\n',
          x: 161.9,
          y: 130.5,
          stuff:
            'YAYOI KUSAMA (Japanese, B. 1929)\r\nThe Sea in the Evening Glow (Facing the Imminent Death)\r\nsigned ‘Yayoi Kusama’ in English; titled in Japanese; dated ‘1988’ (on the reverse)\r\nacrylic on canvas\r\n161.9 x 130.5 cm. (63 ¾ x 51 1/8 in.)\r\nPainted in 1988\r\n',
          cid: '12515.1',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2016.12516.130.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60009/yayoi_kusama_pumpkin_d6000921g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 130,
          auctionHouse: 'christies',
          high: 52000,
          low: 39000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (Japan, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 300,000 - HKD 400,000',
          birth: 1929,
          realized: 'HKD 1,120,000',
          real: 145600,
          timexcoord: '2016-05-01',
          sale: 12516,
          month: 5,
          name: 'Pumpkin\r\n',
          x: 27.3,
          y: 22,
          stuff:
            "YAYOI KUSAMA (Japan, B. 1929)\r\nPumpkin\r\nsigned 'Yayoi Kusama' in English; dated '2003'; titled 'PUMPKIN' in English; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n27.3 x 22 cm. (10 3/4 x 8 5/8 in.)\r\nPainted in 2003\r\n",
          cid: '12516.130',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2016.NY010316.24.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010316/24_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 3000000,
          provenance:
            'Ota Fine Arts, Tokyo Victoria Miro, London Acquired from the above by the present owner',
          low: 2000000,
          currency: 'USD',
          id: 'phillips.5.2016.NY010316.24.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010316/24',
          salesTitle: '20th century & contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 2045000,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'INFINITY-NETS (QRTWE)',
          x: 194,
          y: 259,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'Yayoi Kusama 2007 INFINITY-NETS QRTWE' on the reverse. ^ 76 3/8 x 101 7/8 in. (194 x 259 cm) ^ Ota Fine Arts, Tokyo Victoria Miro, London Acquired from the above by the present owner ^ 5",
          cid: 'NY010316.24',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2016.NY010416.194.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010416/194_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 18000,
          provenance:
            'Elizabeth Leach Gallery, Portland Robert Miller Gallery, New York Acquired directly from the above by the present owner',
          low: 12000,
          currency: 'USD',
          id: 'phillips.5.2016.NY010416.194.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010416/194',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 43750,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'The Cosmos',
          x: 40,
          y: 33,
          location: 'new york""',
          stuff:
            "ink and pastel on paper ^ Signed and dated 'Yayoi Kusama 2002' lower right. Further signed, titled and dated 'The Cosmos 2002 Yayoi Kusama' on the reverse. This artwork is registered with the KUSAMA Enterprise/YAYOI KUSAMA STUDIO registration database. ^ 15 3/4 x 12 7/8 in. (40 x 32.7 cm) ^ Elizabeth Leach Gallery, Portland Robert Miller Gallery, New York Acquired directly from the above by the present owner ^ 5",
          cid: 'NY010416.194',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2016.NY010416.200.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010416/200_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 90000,
          provenance:
            'Robert Miller Gallery, New York Acquired from the above by the present owner',
          low: 70000,
          currency: 'USD',
          id: 'phillips.5.2016.NY010416.200.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010416/200',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 269000,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'Nets 19',
          x: 53,
          y: 65,
          location: 'new york""',
          stuff:
            "acrylic on canvas ^ Signed, titled and dated 'Yayoi Kusama 1998 Nets 19' on the reverse. This artwork is registered with the KUSAMA Enterprise/YAYOI KUSAMA STUDIO registration database. ^ 21 x 25 3/4 in. (53.3 x 65.4 cm) ^ Robert Miller Gallery, New York Acquired from the above by the present owner ^ 5",
          cid: 'NY010416.200',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2016.NY010416.203.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010416/203_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 150000,
          provenance: 'Private Collection, New York',
          low: 100000,
          currency: 'USD',
          id: 'phillips.5.2016.NY010416.203.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010416/203',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 281000,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'Lemon-Squash',
          x: 41,
          y: 32,
          location: 'new york""',
          stuff:
            "oil on canvas ^ Signed and titled in English and Japanese and dated 'Lemon-Squash Yayoi Kusama 2004' on the reverse. This artwork is registered with the KUSAMA Enterprise/YAYOI KUSAMA STUDIO registration database. ^ 16 1/8 x 12 5/8 in. (41 x 32.1 cm) ^ Private Collection, New York ^ 5",
          cid: 'NY010416.203',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-118',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 400000,
          low: 300000,
          currency: 'USD',
          id:
            'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-118',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-n09501/lot.118.html',
          salesTitle: 'contemporary-art-day-auction-n09501',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(586000)',
          real: 586000,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'NUMA',
          x: 117,
          y: 73,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                NUMAƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1992 on the reverse acrylic on canvas46 by 28 3/4  in.   116.8 by 73 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gallery Ham; NagoyaAcquired from the above by the present owner Ǝ',
          cid: 'contemporary-art-day-auction-n09501-118',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-179',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 800000,
          low: 600000,
          currency: 'USD',
          id:
            'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-179',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-n09501/lot.179.html',
          salesTitle: 'contemporary-art-day-auction-n09501',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(850000)',
          real: 850000,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'INFINITY NETS (TWOWQ)',
          x: 194,
          y: 194,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETS (TWOWQ)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2006 on the reverseacrylic on canvas76 3/8  by 76 3/8  in.   194 by 194 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkAcquired from the above by the present owner in 2006Ǝ',
          cid: 'contemporary-art-day-auction-n09501-179',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-180',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 45000,
          low: 35000,
          currency: 'USD',
          id:
            'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-180',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-n09501/lot.180.html',
          salesTitle: 'contemporary-art-day-auction-n09501',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(125000)',
          real: 125000,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'YELLOW FLOWER ',
          x: 51,
          y: 66,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                YELLOW FLOWER Ǝ                Ǝ                    Ǝ                        signed and dated 1979; signed; titled in Japanese and dated 1979 on the reverse gouache and felt tip pen on paper 20 1/4  by 25 7/8  in.   51.4 by 65.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        D'Amelio Terras; New York Private Collection (acquired from the above)Christie's; London; 28 March 2012; Lot 31 (consigned by the above)Acquired from the above sale by the present owner Ǝ",
          cid: 'contemporary-art-day-auction-n09501-180',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-181',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id:
            'sothebys.5.2016.yayoi-kusama.contemporary-art-day-auction-n09501-181',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2015/contemporary-art-day-auction-n09501/lot.181.html',
          salesTitle: 'contemporary-art-day-auction-n09501',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 106250,
          timexcoord: '2016-05-01',
          month: 5,
          name: 'LEMON TEA',
          x: 23,
          y: 16,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                LEMON TEAƎ                Ǝ                    Ǝ                        signed; titled and dated 1982 on the reverseacrylic and graphite on canvas9 by 6 1/4  in.   22.9 by 15.9 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-art-day-auction-n09501-181',
        },
        fields: {
          ymonths: [196],
        },
        sort: [1462060800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.6.2016.12688.17.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60056/yayoi_kusama_universe_d6005616g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 17,
          auctionHouse: 'christies',
          high: 264000,
          low: 192000,
          currency: 'EUR',
          brand: 'YAYOI KUSAMA (NÉE EN 1929)\r\n',
          salesTitle: 'Art Contemporain Vente du Soir ',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'EUR 160,000 - EUR 220,000',
          birth: 1929,
          realized: 'EUR 253,500',
          real: 304200,
          timexcoord: '2016-06-01',
          sale: 12688,
          month: 6,
          name: 'Universe\r\n',
          x: 91,
          y: 73,
          stuff:
            "YAYOI KUSAMA (NÉE EN 1929)\r\nUniverse\r\nsigné, titré en japonais et daté 'Yayoi Kusama 1994' (au dos)\r\nacrylique sur toile\r\n91 x 73 cm. (35 7/8 x 28 ¾ in.)\r\nPeint en 1994.\r\n",
          cid: '12688.17',
        },
        fields: {
          ymonths: [197],
        },
        sort: [1464739200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2016.UK010516.117.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010516/117_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 60000,
          provenance: 'Acquired directly from the artist by the present owner',
          low: 40000,
          currency: 'GBP',
          id: 'phillips.6.2016.UK010516.117.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010516/117',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 93750,
          timexcoord: '2016-06-01',
          month: 6,
          name: 'Pumpkin',
          x: 23,
          y: 16,
          location: 'london',
          stuff:
            "acrylic on canvas ^ Signed and dated 'Yayoi Kusama 1991' on the reverse. ^ 22.6 x 15.9 cm (8 7/8 x 6 1/4 in.) ^ Acquired directly from the artist by the present owner ^ 6",
          cid: 'UK010516.117',
        },
        fields: {
          ymonths: [197],
        },
        sort: [1464739200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2016.UK010516.118.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010516/118_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 60000,
          provenance: 'Acquired directly from the artist by the present owner',
          low: 40000,
          currency: 'GBP',
          id: 'phillips.6.2016.UK010516.118.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010516/118',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 131250,
          timexcoord: '2016-06-01',
          month: 6,
          name: 'Pumpkin',
          x: 23,
          y: 16,
          location: 'london',
          stuff:
            'acrylic on canvas ^ null ^ 22.9 x 16 cm (9 x 6 1/4 in.) ^ Acquired directly from the artist by the present owner ^ 6',
          cid: 'UK010516.118',
        },
        fields: {
          ymonths: [197],
        },
        sort: [1464739200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.6.2016.yayoi-kusama.contemporary-art-evening-l16022-1',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 300000,
          low: 200000,
          currency: 'GBP',
          id: 'sothebys.6.2016.yayoi-kusama.contemporary-art-evening-l16022-1',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-evening-l16022/lot.1.html',
          salesTitle: 'contemporary-art-evening-l16022',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(677000)',
          real: 1015500,
          timexcoord: '2016-06-01',
          month: 6,
          name: 'INFINITY NETS',
          x: 91,
          y: 73,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        signed; titled and dated 2006 on the reverse; titled in Japanese on the reverseacrylic on canvas91 by 73 cm.   35 7/8  by 28 3/4  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        MOMA Contemporary; FukuokaPrivate Collection; JapanSotheby's; London; 29 June 2010; Lot 132Acquired from the above by the late ownerƎ",
          cid: 'contemporary-art-evening-l16022-1',
        },
        fields: {
          ymonths: [197],
        },
        sort: [1464739200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2016.NY010616.64.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010616/64_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 100000,
          provenance:
            'Private Collection The Market Auction, Inc., Toyko, October 25, 2007, lot 21 Private Collection Augur Auction, Tokyo, March 24, 2012, lot 62 Private Collection (acquired at the above sale) Phillips, New York, November 14, 2014, lot 166 Acquired at the above sale by the present owner',
          low: 70000,
          currency: 'USD',
          id: 'phillips.9.2016.NY010616.64.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010616/64',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 225000,
          timexcoord: '2016-09-01',
          month: 9,
          name: 'Infinity Nets (TWWP)',
          x: 46,
          y: 53,
          location: 'new york',
          stuff:
            "acrylic on canvas ^ Painted in 2006, this work is accompanied by Yayoi Kusama's Art Work Registration Card. ^ 18 x 21 in. (45.7 x 53.3 cm.) ^ Private Collection The Market Auction, Inc., Toyko, October 25, 2007, lot 21 Private Collection Augur Auction, Tokyo, March 24, 2012, lot 62 Private Collection (acquired at the above sale) Phillips, New York, November 14, 2014, lot 166 Acquired at the above sale by the present owner ^ 9",
          cid: 'NY010616.64',
        },
        fields: {
          ymonths: [200],
        },
        sort: [1472688000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-l16027-13',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 8000,
          low: 6000,
          currency: 'GBP',
          id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-l16027-13',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-curated-l16027/lot.13.html',
          salesTitle: 'contemporary-curated-l16027',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(11875)',
          real: 17813,
          timexcoord: '2016-09-01',
          month: 9,
          name: 'SEAFLOOR LEGEND',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                SEAFLOOR LEGENDƎ                Ǝ                    Ǝ                        signed and dated 1978; signed; titled in Japanese and dated 1978 on the reverseacrylic and spraypaint on card27.2 by 24.2 cm.   10 3/4  by 9 1/2  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanChristie's; London; 13 December 2007; Lot 147Acquired from the above by the present ownerƎ",
          cid: 'contemporary-curated-l16027-13',
        },
        fields: {
          ymonths: [200],
        },
        sort: [1472688000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-l16027-12',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 8000,
          low: 6000,
          currency: 'GBP',
          id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-l16027-12',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-curated-l16027/lot.12.html',
          salesTitle: 'contemporary-curated-l16027',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(11250)',
          real: 16875,
          timexcoord: '2016-09-01',
          month: 9,
          name: 'SEA AT SUNSET',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                SEA AT SUNSETƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1980 on the reverseacrylic and spraypaint on card27.2 by 24.2 cm.   10 3/4  by 9 1/2  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanChristie's; London; 13 December 2007; Lot 147Acquired from the above by the present ownerƎ",
          cid: 'contemporary-curated-l16027-12',
        },
        fields: {
          ymonths: [200],
        },
        sort: [1472688000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-n09553-13',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 70000,
          low: 50000,
          currency: 'USD',
          id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-n09553-13',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-curated-n09553/lot.13.html',
          salesTitle: 'contemporary-curated-n09553',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(75000)',
          real: 75000,
          timexcoord: '2016-09-01',
          month: 9,
          name: 'AN EYE',
          x: 26,
          y: 19,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                AN EYEƎ                Ǝ                    Ǝ                        signed and dated 1952; signed in English and Japanese; titled twice; dated 1952 and stamped multiple times in Japanese with the artist's name and title on the reverse gouache; ink and pastel on paper10 3/8  by 7 3/8  in.   26.3 by 18.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; New YorkRoland Auctions; New York; February 7; 2015; lot 423Acquired by the present owner from the above saleƎ",
          cid: 'contemporary-curated-n09553-13',
        },
        fields: {
          ymonths: [200],
        },
        sort: [1472688000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-n09553-92',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 30000,
          low: 20000,
          currency: 'USD',
          id: 'sothebys.9.2016.yayoi-kusama.contemporary-curated-n09553-92',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-curated-n09553/lot.92.html',
          salesTitle: 'contemporary-curated-n09553',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(25000)',
          real: 25000,
          timexcoord: '2016-09-01',
          month: 9,
          name: 'DIRTY HAND',
          x: 38,
          y: 30,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                DIRTY HANDƎ                Ǝ                    Ǝ                        signed and dated 1954; titled in English and Japanese; dated 1954 and dedicated to Mr. Takachiyo Uemura August 10; 1954 on the reversegraphite; gouache and pastel on paper14 7/8  by 11 7/8  in.   37.8 by 30.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; New YorkPrivate Collection; New York (gift of the above)Private Collection; New YorkƎ',
          cid: 'contemporary-curated-n09553-92',
        },
        fields: {
          ymonths: [200],
        },
        sort: [1472688000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2016.13430.68.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60232/yayoi_kusama_path_to_the_future_d6023238g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 68,
          auctionHouse: 'christies',
          high: 120000,
          low: 90000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929)\r\n',
          salesTitle: 'Asobi: Japanese & Korean Postwar Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 60,000 - GBP 80,000',
          birth: 1929,
          realized: 'GBP 100,000',
          real: 150000,
          timexcoord: '2016-10-01',
          sale: 13430,
          month: 10,
          name: 'Path to the Future\r\n',
          x: 45.5,
          y: 53,
          stuff:
            'Yayoi Kusama (b. 1929)\r\nPath to the Future\r\nsigned, titled and dated Yayoi Kusama, Mirai e no michi, 1988 (on the reverse)\r\noil on canvas\r\n45.5 x 53 cm.\r\nPainted in 1988\r\n',
          cid: '13430.68',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2016.NY030216.390.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030216/390_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 9000,
          low: 6000,
          currency: 'USD',
          id: 'phillips.10.2016.NY030216.390.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030216/390',
          salesTitle: 'evening & day editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 17500,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'High Heels (1)',
          x: 60,
          y: 68,
          location: 'new york',
          stuff:
            "Screenprint in colors with Lamé, on Kakita-shi paper, with full margins. ^ Signed, titled in Japanese, dated and numbered 5/60 (there were also 11 artist's proofs), framed. ^ I. 17 5/8 x 21 in. (44.8 x 53.3 cm) S. 23 3/4 x 26 5/6 in. (60.3 x 68.2 cm) ^ null ^ 10",
          cid: 'NY030216.390',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2016.NY030216.391.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030216/391_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 9000,
          low: 6000,
          currency: 'USD',
          id: 'phillips.10.2016.NY030216.391.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030216/391',
          salesTitle: 'evening & day editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 17500,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'High Heels (4)',
          x: 60,
          y: 68,
          location: 'new york',
          stuff:
            "Screenprint in colors with Lamé, on Kakita-shi paper, with full margins. ^ Signed, titled in Japanese, dated and numbered 5/60 in pencil (there were also 6 artist's proofs), framed. ^ I. 17 3/4 x 21 in. (45.1 x 53.3 cm) S. 23 5/8 x 26 3/4 in. (60 x 67.9 cm) ^ null ^ 10",
          cid: 'NY030216.391',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2016.NY030216.392.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030216/392_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 2000,
          low: 1000,
          currency: 'USD',
          id: 'phillips.10.2016.NY030216.392.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030216/392',
          salesTitle: 'evening & day editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 5000,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'Heart',
          x: 55,
          y: 40,
          location: 'new york',
          stuff:
            "Etching, on Arches paper, with full margins. ^ Signed, titled in Japanese, dated and numbered 16/20 in pencil (there were also 2 artist's proofs), unframed. ^ I. 11 5/8 x 7 5/8 in. (29.5 x 19.5 cm) S. 21 5/8 x 15 3/4 in. (55 x 40 cm) ^ null ^ 10",
          cid: 'NY030216.392',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2016.NY030216.393.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY030216/393_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 2500,
          low: 1500,
          currency: 'USD',
          id: 'phillips.10.2016.NY030216.393.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY030216/393',
          salesTitle: 'evening & day editions',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 4375,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'Infinity',
          x: 46,
          y: 63,
          location: 'new york',
          stuff:
            "Etching, on Arches paper, with full margins. ^ Signed, titled, dated and numbered 24/30 in pencil (there were also 3 artist's proofs), unframed. ^ I. 7 5/8 x 14 1/4 in. (19.5 x 36.3 cm) S. 17 7/8 x 24 3/4 in. (45.5 x 63 cm) ^ null ^ 10",
          cid: 'NY030216.393',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2016.UK010716.118.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010716/118_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 150000,
          provenance:
            'Gallery Deco, São Paulo Acquired from the above by the present owner',
          low: 100000,
          currency: 'GBP',
          id: 'phillips.10.2016.UK010716.118.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010716/118',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 295500,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'Infinity Nets (OHWNT)',
          x: 61,
          y: 73,
          location: 'london',
          stuff:
            'acrylic on canvas ^ Painted in 2006, this work is accompanied by a Registration Card issued by the Yayoi Kusama studio. ^ 60.6 x 72.7 cm (23 7/8 x 28 5/8 in.) ^ Gallery Deco, São Paulo Acquired from the above by the present owner ^ 10',
          cid: 'UK010716.118',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2016.UK010716.119.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010716/119_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'GBP',
          id: 'phillips.10.2016.UK010716.119.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010716/119',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 140625,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'The Ripple',
          x: 53,
          y: 46,
          location: 'london',
          stuff:
            "acrylic on canvas in artist's frame ^ Executed in 1985, this work is accompanied by a Registration Card issued by the Yayoi Kusama studio. ^ 53 x 45.8 cm (20 7/8 x 18 in.) ^ null ^ 10",
          cid: 'UK010716.119',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2016.UK010716.197.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010716/197_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 8000,
          provenance:
            'Private Collection Paris, Tajan, 26 October 2009, lot 361 Acquired at the above sale by the present owner',
          low: 6000,
          currency: 'GBP',
          id: 'phillips.10.2016.UK010716.197.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010716/197',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 24375,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'Where the Illusion Hides',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'spraypaint and ink on paperboard ^ Executed in 1978, this work is accompanied by a Registration Card issued by the Yayoi Kusama studio. ^ 27 x 24 cm (10 5/8 x 9 1/2 in.) ^ Private Collection Paris, Tajan, 26 October 2009, lot 361 Acquired at the above sale by the present owner ^ 10',
          cid: 'UK010716.197',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2016.yayoi-kusama.contemporary-art-day-auction-l16025-115',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 300000,
          low: 200000,
          currency: 'GBP',
          id:
            'sothebys.10.2016.yayoi-kusama.contemporary-art-day-auction-l16025-115',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-l16025/lot.115.html',
          salesTitle: 'contemporary-art-day-auction-l16025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(413000)',
          real: 619500,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'INFINITY NETS (0902F)',
          x: 73,
          y: 61,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETS (0902F)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2000 on the reverseacrylic on canvas73 by 60.9 cm.   28 3/4  by 24 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts; TokyoStudio Guenzani; MilanPrivate CollectionChristie's; London; 12 October 2012; Lot 219Acquired from the above by the present ownerƎ",
          cid: 'contemporary-art-day-auction-l16025-115',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2016.yayoi-kusama.contemporary-art-day-auction-l16025-171',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'GBP',
          id:
            'sothebys.10.2016.yayoi-kusama.contemporary-art-day-auction-l16025-171',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-l16025/lot.171.html',
          salesTitle: 'contemporary-art-day-auction-l16025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(161000)',
          real: 241500,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'LANDSCAPE WITH PUMPKIN',
          x: 51,
          y: 66,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                LANDSCAPE WITH PUMPKINƎ                Ǝ                    Ǝ                        signed and dated 1979; inscribed in Japanese and dated 1979 on the reverse; inscribed in Japanese and dated 1979 on the backing boardgouache and pastel on paper51.1 by 66.4 cm.   20 1/8  by 26 1/8  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanƎ',
          cid: 'contemporary-art-day-auction-l16025-171',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2016.yayoi-kusama.contemporary-art-day-auction-l16025-170',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'GBP',
          id:
            'sothebys.10.2016.yayoi-kusama.contemporary-art-day-auction-l16025-170',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-l16025/lot.170.html',
          salesTitle: 'contemporary-art-day-auction-l16025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(653000)',
          real: 979500,
          timexcoord: '2016-10-01',
          month: 10,
          name: 'PUMPKIN (A.H.T)',
          x: 73,
          y: 91,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                PUMPKIN (A.H.T)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1991 on the reverseacrylic on canvas72.8 by 91.1 cm.   28 5/8  by 35 7/8  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; JapanAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-l16025-170',
        },
        fields: {
          ymonths: [201],
        },
        sort: [1475280000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.101.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_shoe_d6046729g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 101,
          auctionHouse: 'christies',
          high: 39000,
          low: 26000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 200,000 - HKD 300,000',
          birth: 1929,
          realized: 'HKD 475,000',
          real: 61750,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'SHOE\r\n',
          x: 36.4,
          y: 51.3,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\r\nSHOE\r\ndated ‘1994’; signed ‘Yayoi Kusama’ in English; titled in Japanese (on the reverse)\r\npastel, felt pen and collage on paper\r\n36.4 x 51.3 cm. (14 3/8 x 20 1/4 in.)\r\nPainted in 1994\r\n',
          cid: '12521.101',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.102.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_cockscomb_d6046730g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 102,
          auctionHouse: 'christies',
          high: 260000,
          low: 156000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,200,000 - HKD 2,000,000',
          birth: 1929,
          realized: 'HKD 2,820,000',
          real: 366600,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'COCKSCOMB\r\n',
          x: 45.5,
          y: 38,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nCOCKSCOMB\r\nsigned 'YAYOI.KUSAMA' in English; dated '1989'; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n45.5 x 38 cm. (18 x 15 in.)\r\nPainted in 1989\r\n",
          cid: '12521.102',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.107.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_wind_d6046735g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 107,
          auctionHouse: 'christies',
          high: 52000,
          low: 39000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 300,000 - HKD 400,000',
          birth: 1929,
          realized: 'HKD 750,000',
          real: 97500,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'WIND\r\n',
          x: 30,
          y: 22.4,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nWIND\r\nsigned 'YAYOI KUSAMA' in English; dated '1953' (lower left); titled in Japanese; stamped (on the reverse)\r\nink, gouache and pastel on paper\r\n30 x 22.4 cm. (11 3/4 x 8 7/8 in.)\r\nPainted in 1953\r\n",
          cid: '12521.107',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12520.14.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60466/yayoi_kusama_no_aa2_d6046689g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 14,
          auctionHouse: 'christies',
          high: 1040000,
          low: 715000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 5,500,000 - HKD 8,000,000',
          birth: 1929,
          realized: 'HKD 10,260,000',
          real: 1333800,
          timexcoord: '2016-11-01',
          sale: 12520,
          month: 11,
          name: 'NO. AA2\r\n',
          x: 60.8,
          y: 72.4,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\r\nNO. AA2\r\nsigned, titled and dated ‘1960 YAYOI KUSAMA NO. AA2’ (on the reverse)\r\noil on canvas\r\n60.8 x 72.4 cm. (24 x 28 ½ in.)\r\nPainted in 1960\r\n',
          cid: '12520.14',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.109.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_acumuretion_earth_d6046737g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 109,
          auctionHouse: 'christies',
          high: 58500,
          low: 45500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 350,000 - HKD 450,000',
          birth: 1929,
          realized: 'HKD 812,500',
          real: 105625,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'ACUMURETION EARTH\r\n',
          x: 30.3,
          y: 35.2,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nACUMURETION EARTH\r\nsigned 'Yayoi Kusama' in English; dated '1952’; titled in Japanese; titled 'ACUMURETION EARTH’ in English (on the reverse)\r\npastel and gouache on paper\r\n30.3 x 35.2 cm. (11 7/8 x 13 7/8 in.)\r\nPainted in 1952\r\n",
          cid: '12521.109',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.105.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_pumpkin_d6046733g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 105,
          auctionHouse: 'christies',
          high: 260000,
          low: 195000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,500,000 - HKD 2,000,000',
          birth: 1929,
          realized: 'HKD 3,420,000',
          real: 444600,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'PUMPKIN (T. WA)\r\n',
          x: 38,
          y: 45.5,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nPUMPKIN (T. WA)\r\nsigned 'Yayoi Kusama' in English; dated '2003'; titled 'Pumpkin (T. WA); titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n38 x 45.5 cm. (15 x 17 7/8 in.)\r\nPainted in 2003 \r\n",
          cid: '12521.105',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.103.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_queens_of_the_night_d6046731g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 103,
          auctionHouse: 'christies',
          high: 78000,
          low: 52000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 400,000 - HKD 600,000',
          birth: 1929,
          realized: 'HKD 1,980,000',
          real: 257400,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'QUEENS OF THE NIGHT\r\n',
          x: 66.3,
          y: 51.3,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nQUEENS OF THE NIGHT\r\nsigned 'Yayoi Kusama' in English; dated '1979' (upper left); signed and titled in Japanese; dated '1979’ (on the reverse)\r\nacrylic on paper\r\n66.3 x 51.3 cm. (26 1/8 x 20 1/4 in.)\r\nPainted in 1979\r\n",
          cid: '12521.103',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.111.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_a_dutchwoman_d6046739g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 111,
          auctionHouse: 'christies',
          high: 45500,
          low: 32500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 250,000 - HKD 350,000',
          birth: 1929,
          realized: 'HKD 350,000',
          real: 45500,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'A DUTCHWOMAN\r\n',
          x: 65.1,
          y: 51.1,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nA DUTCHWOMAN\r\nsigned 'Yayoi Kusama' in English; dated '1977' (lower right); signed 'Yayoi Kusama' in English; dated '1977'; titled in Japanese (on the reverse)\r\nink and pastel on paper\r\n65.1 x 51.1 cm. (25 5/8 x 20 1/8 in.)\r\nPainted in 1977\r\n",
          cid: '12521.111',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.108.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_hat_d6046736g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 108,
          auctionHouse: 'christies',
          high: 91000,
          low: 65000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 500,000 - HKD 700,000',
          birth: 1929,
          realized: 'HKD 1,125,000',
          real: 146250,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'HAT\r\n',
          x: 15.8,
          y: 22.7,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nHAT\r\nsigned 'Yayoi Kusama' in English; titled in Japanese; dated '1982’ (on the reverse)\r\nacrylic and cloth on canvas\r\n15.8 x 22.7 cm. (6 1/4 x 8 7/8 in.)\r\nPainted in 1982\r\n",
          cid: '12521.108',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2016.12521.106.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'http://www.christies.com/lotfinderimages/D60467/yayoi_kusama_polka_dots_d6046734g.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          lot: 106,
          auctionHouse: 'christies',
          high: 130000,
          low: 78000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\r\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 600,000 - HKD 1,000,000',
          birth: 1929,
          realized: 'HKD 937,500',
          real: 121875,
          timexcoord: '2016-11-01',
          sale: 12521,
          month: 11,
          name: 'POLKA DOTS\r\n',
          x: 45.5,
          y: 38,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\r\nPOLKA DOTS\r\nsigned 'Yayoi Kusama' in English; dated '1990'; titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n45.5 x 38 cm. (17 7/8 x 15 in.)\r\nPainted in 1990\r\n",
          cid: '12521.106',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2016.NY010716.20.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010716/20_001.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 2000000,
          provenance:
            'OTA FINE ARTS, Tokyo Gagosian Gallery, New York Acquired from the above by the present owner',
          low: 1500000,
          currency: 'USD',
          id: 'phillips.11.2016.NY010716.20.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010716/20',
          salesTitle: '20th century & contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 1810000,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'Infinity Nets (OZEH)',
          x: 194,
          y: 259,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 2011. ^ 76 3/8 x 102 in. (194 x 259.1 cm) ^ OTA FINE ARTS, Tokyo Gagosian Gallery, New York Acquired from the above by the present owner ^ 11',
          cid: 'NY010716.20',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-141',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 1500000,
          low: 1000000,
          currency: 'USD',
          id:
            'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-141',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-n09573/lot.141.html',
          salesTitle: 'contemporary-art-day-auction-n09573',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(1452500)',
          real: 1452500,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'INFINITY-NETS OPYTSDB (GOLD)',
          x: 200,
          y: 200,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY-NETS OPYTSDB (GOLD)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2007 on the reverseacrylic on canvas78 3/4  by 78 3/4  in.   200 by 200 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkLeanne Hull Fine Art; La JollaAcquired from the above by the present owner in February 2007Ǝ',
          cid: 'contemporary-art-day-auction-n09573-141',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-167',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 600000,
          low: 400000,
          currency: 'USD',
          id:
            'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-167',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-n09573/lot.167.html',
          salesTitle: 'contemporary-art-day-auction-n09573',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(744500)',
          real: 744500,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'INFINITY-NETS ',
          x: 130,
          y: 130,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY-NETS Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2004 on the reverseacrylic on canvas51 1/4  by 51 1/4  in.   130.2 by 130.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New York Acquired from the above by the present owner in 2004Ǝ',
          cid: 'contemporary-art-day-auction-n09573-167',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-174',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 350000,
          low: 250000,
          currency: 'USD',
          id:
            'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-174',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-n09573/lot.174.html',
          salesTitle: 'contemporary-art-day-auction-n09573',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(480500)',
          real: 480500,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'DOTS (TWPA)',
          x: 100,
          y: 100,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                DOTS (TWPA)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2006 on the reverseacrylic on canvas39 3/8  by 39 3/8  in.   100 by 100 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Umi Gallery; JapanAcquired from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-n09573-174',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-175',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 350000,
          low: 250000,
          currency: 'USD',
          id:
            'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-175',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-n09573/lot.175.html',
          salesTitle: 'contemporary-art-day-auction-n09573',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(492500)',
          real: 492500,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'DOTS (TOPQ)',
          x: 100,
          y: 100,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                DOTS (TOPQ)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 2006 on the reverseacrylic on canvas39 3/8  by 39 3/8  in.   100 by 100 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Umi Gallery; JapanAcquired from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-n09573-175',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-192',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 300000,
          low: 200000,
          currency: 'USD',
          id:
            'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-192',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-n09573/lot.192.html',
          salesTitle: 'contemporary-art-day-auction-n09573',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(468500)',
          real: 468500,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'INFINITY NETS (XAZ)',
          x: 76,
          y: 91,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETS (XAZ)Ǝ                Ǝ                    Ǝ                        signed; titled and dated 1999 on the reverseacrylic on canvas29 3/4  by 35 3/4  in.   75.6 by 90.8 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection; Japan (acquired directly from the artist)Sotheby's; New York; 10 November 2005; Lot 178Private Collection; Denver (acquired from the above sale)Acquired from the above by the present ownerƎ",
          cid: 'contemporary-art-day-auction-n09573-192',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-207',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-207',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-n09573/lot.207.html',
          salesTitle: 'contemporary-art-day-auction-n09573',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(162500)',
          real: 162500,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'LEMON SQUASH',
          x: 23,
          y: 17,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                LEMON SQUASHƎ                Ǝ                    Ǝ                        signed; titled in Japanese and dated 1991 on the reverseacrylic on canvas 9 by 6 1/4  in.   22.9 by 16.5 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Kumquat Gallery; Hong KongAcquired directly from the above by the present ownerƎ',
          cid: 'contemporary-art-day-auction-n09573-207',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-222',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 100000,
          low: 70000,
          currency: 'USD',
          id:
            'sothebys.11.2016.yayoi-kusama.contemporary-art-day-auction-n09573-222',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/contemporary-art-day-auction-n09573/lot.222.html',
          salesTitle: 'contemporary-art-day-auction-n09573',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(125000)',
          real: 125000,
          timexcoord: '2016-11-01',
          month: 11,
          name: 'TO THE 21ST CENTURY',
          x: 46,
          y: 38,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                TO THE 21ST CENTURYƎ                Ǝ                    Ǝ                        signed; titled and dated 1988 on the reverseacrylic and graphite on canvas18 by 15 in.   45.7 by 38.1 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Robert Miller Gallery; New YorkMichael Rosenfeld Gallery; New YorkJeff Shankman; Houston (acquired from the above in 1998)Michael Rosenfeld Gallery; New YorkAcquired from the above by the present owner in March 2011Ǝ',
          cid: 'contemporary-art-day-auction-n09573-222',
        },
        fields: {
          ymonths: [202],
        },
        sort: [1477958400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.12.2016.yayoi-kusama.art-contemporain-pf1615-136',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 9000,
          low: 7000,
          currency: 'EUR',
          id: 'sothebys.12.2016.yayoi-kusama.art-contemporain-pf1615-136',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/art-contemporain-pf1615/lot.136.html',
          salesTitle: 'art-contemporain-pf1615',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(16250)',
          real: 19500,
          timexcoord: '2016-12-01',
          month: 12,
          name:
            'WHEREABOUTS OF SILVER WAVESSIGNED AND DATED 1978; SIGNED; TITLED IN JAPANESE AND DATED 1978 ON THE REVERSE; ENAMEL PAINTS AND INK ON JAPAN PAPER. EXECUTED IN 1978.',
          x: 27,
          y: 24,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                WHEREABOUTS OF SILVER WAVESSIGNED AND DATED 1978; SIGNED; TITLED IN JAPANESE AND DATED 1978 ON THE REVERSE; ENAMEL PAINTS AND INK ON JAPAN PAPER. EXECUTED IN 1978.Ǝ                Ǝ                    Ǝ                        signé et daté 1978; signé; titré en japonais et daté 1978 au dospeintures à l'émail et encre sur papier Japon27 x 24 cm; 10 5/8 x 9 7/16 in.Exécuté en 1978.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Collection particulière; Etats-UnisVente: Sotheby's; New York; Contemporary Art; 26 février 2007; lot 180Acquis lors de cette vente par le propriétaire actuelƎ",
          cid: 'art-contemporain-pf1615-136',
        },
        fields: {
          ymonths: [203],
        },
        sort: [1480550400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.12.2016.yayoi-kusama.art-contemporain-pf1615-137',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2016,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 9000,
          low: 7000,
          currency: 'EUR',
          id: 'sothebys.12.2016.yayoi-kusama.art-contemporain-pf1615-137',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2016/art-contemporain-pf1615/lot.137.html',
          salesTitle: 'art-contemporain-pf1615',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(18750)',
          real: 22500,
          timexcoord: '2016-12-01',
          month: 12,
          name:
            'RAIN ON A LAKESIGNED AND DATED 1979; SIGNED; TITLED IN JAPANESE AND DATED 1979 ON THE REVERSE; ENAMEL PAINTS AND INK ON JAPAN PAPER. EXECUTED IN 1979.',
          x: 27,
          y: 24,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                RAIN ON A LAKESIGNED AND DATED 1979; SIGNED; TITLED IN JAPANESE AND DATED 1979 ON THE REVERSE; ENAMEL PAINTS AND INK ON JAPAN PAPER. EXECUTED IN 1979.Ǝ                Ǝ                    Ǝ                        signé et daté 1979; signé; titré en japonais et daté 1979 au dospeintures à l'émail et encre sur papier Japon27 x 24 cm; 10  5/8  x 9  7/16  in.Exécuté en 1979.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Collection particulière; Etats-UnisVente: Sotheby's; New York; Contemporary Art; 26 février 2007; lot 180Acquis lors de cette vente par le propriétaire actuelƎ",
          cid: 'art-contemporain-pf1615-137',
        },
        fields: {
          ymonths: [203],
        },
        sort: [1480550400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.2.2017.NY010117.20.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010117/20_001.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 220000,
          provenance:
            'Robert Miller Gallery, New York Acquired from the above by the present owner',
          low: 160000,
          currency: 'USD',
          id: 'phillips.2.2017.NY010117.20.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010117/20',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 225000,
          timexcoord: '2017-02-01',
          month: 2,
          name: 'The Galaxy',
          x: 61,
          y: 91,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 1991, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 24 x 36 in. (61 x 91.4 cm.) ^ Robert Miller Gallery, New York Acquired from the above by the present owner ^ 2',
          cid: 'NY010117.20',
        },
        fields: {
          ymonths: [205],
        },
        sort: [1485907200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2017.13375.30.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'https://www.christies.com/img/LotImages/2017/HGK/2017_HGK_13375_0030_000(yayoi_kusama_town_hhh).jpg?height=400',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 30,
          auctionHouse: 'christies',
          high: 46800,
          low: 31200,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B.1929)\r\n',
          salesTitle: 'FIRST OPEN | Hong Kong',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 240,000 - HKD 360,000',
          birth: 1929,
          realized: 'HKD 812,500',
          real: 105625,
          timexcoord: '2017-03-01',
          sale: 13375,
          month: 3,
          name: 'Town HHH\r\n',
          x: 22,
          y: 27.5,
          stuff:
            "YAYOI KUSAMA (JAPAN, B.1929)\r\nTown HHH\r\nsigned 'yayoi kusama', titled 'TOWN HHH', dated '2004' (on the reverse)\r\nacrylic on canvas\r\n22 x 27.5 cm. (8 5/8 x 10 7/8 in.)\r\nPainted in 2004\r\n",
          cid: '13375.30',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2017.13375.31.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'https://www.christies.com/img/LotImages/2017/HGK/2017_HGK_13375_0031_000(yayoi_kusama_field).jpg?height=400',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 31,
          auctionHouse: 'christies',
          high: 234000,
          low: 156000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B.1929)\r\n',
          salesTitle: 'FIRST OPEN | Hong Kong',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,200,000 - HKD 1,800,000',
          birth: 1929,
          realized: 'HKD 3,180,000',
          real: 413400,
          timexcoord: '2017-03-01',
          sale: 13375,
          month: 3,
          name: 'Field\r\n',
          x: 38,
          y: 45,
          stuff:
            "YAYOI KUSAMA (JAPAN, B.1929)\r\nField\r\nsigned ‘Yayoi Kusama', dated ‘1989', titled in Japanese (on the reverse)\r\nacrylic on canvas\r\n38 x 45 cm. (15 x 17 3/4 in.)\r\nPainted in 1989\r\n",
          cid: '13375.31',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2017.13375.29.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img:
            'https://www.christies.com/img/LotImages/2017/HGK/2017_HGK_13375_0029_000(yayoi_kusama_infinity_nets).jpg?height=400',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 29,
          auctionHouse: 'christies',
          high: 97500,
          low: 71500,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B.1929)\r\n',
          salesTitle: 'FIRST OPEN | Hong Kong',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 550,000 - HKD 750,000',
          birth: 1929,
          realized: 'HKD 1,740,000',
          real: 226200,
          timexcoord: '2017-03-01',
          sale: 13375,
          month: 3,
          name: 'Infinity Nets\r\n',
          x: 45.5,
          y: 53,
          stuff:
            "YAYOI KUSAMA (JAPAN, B.1929)\r\nInfinity Nets\r\nsigned and dated 'yayoi Kusama 1999'; inscribed in Japanese (on the reverse)\r\nacrylic on canvas\r\n45.5 x 53 cm. (17 7/8 x 20 7/8 in.)\r\nPainted in 1999\r\n",
          cid: '13375.29',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.3.2017.yayoi-kusama.contemporary-art-evening-auction-l17020-21',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 700000,
          low: 500000,
          currency: 'GBP',
          id:
            'sothebys.3.2017.yayoi-kusama.contemporary-art-evening-auction-l17020-21',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-evening-auction-l17020/lot.21.html',
          salesTitle: 'contemporary-art-evening-auction-l17020',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(818750)',
          real: 1228125,
          timexcoord: '2017-03-01',
          month: 3,
          name: 'INFINITY-NETS (FCPR)',
          x: 162,
          y: 131,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY-NETS (FCPR)Ǝ                Ǝ                    Ǝ                        signed twice, titled and dated 2007 on the reverseacrylic on canvas162 by 130.5 cm.   63 3/4  by 51 3/8  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by a certificate of authenticity from the Yayoi Kusama Studio and recorded under number KUS 0620. ƎƎ                        ProvenanceƎ                        Galerie Piéce Unique, ParisƎAcquired from the above by the present owner in 2007Ǝ                    ',
          cid: 'contemporary-art-evening-auction-l17020-21',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2017.yayoi-kusama.contemporary-curated-n09622-69',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 150000,
          low: 100000,
          currency: 'USD',
          id: 'sothebys.3.2017.yayoi-kusama.contemporary-curated-n09622-69',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-curated-n09622/lot.69.html',
          salesTitle: 'contemporary-curated-n09622',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(384500)',
          real: 384500,
          timexcoord: '2017-03-01',
          month: 3,
          name: 'SUN GREEN ',
          x: 41,
          y: 34,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                SUN GREEN Ǝ                Ǝ                    Ǝ                        signed; signed, titled and dated 1957 on the reverseacrylic and pastel on paper 16 by 13 1/4  in.   40.6 by 33.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        D'Amelio Terras, New YorkAcquired from the above by the present owner in May 1998Ǝ                    ",
          cid: 'contemporary-curated-n09622-69',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2017.yayoi-kusama.contemporary-curated-n09622-71',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 5000,
          low: 3000,
          currency: 'USD',
          id: 'sothebys.3.2017.yayoi-kusama.contemporary-curated-n09622-71',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-curated-n09622/lot.71.html',
          salesTitle: 'contemporary-curated-n09622',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(68750)',
          real: 68750,
          timexcoord: '2017-03-01',
          month: 3,
          name: 'UNTITLED (TIE)',
          x: 55,
          y: 10,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                UNTITLED (TIE)Ǝ                Ǝ                    Ǝ                        signedacrylic on fabric tie21 3/4  by 4 in.   55.2 by 10.2 cm.Executed circa 1968.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection, Connecticut (acquired directly from the artist circa 1968)Rita Krauss Fine Art, New YorkAcquired from the above by the present owner in 2013Ǝ                    ',
          cid: 'contemporary-curated-n09622-71',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.3.2017.yayoi-kusama.contemporary-curated-n09622-72',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 10000,
          low: 8000,
          currency: 'USD',
          id: 'sothebys.3.2017.yayoi-kusama.contemporary-curated-n09622-72',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-curated-n09622/lot.72.html',
          salesTitle: 'contemporary-curated-n09622',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(187500)',
          real: 187500,
          timexcoord: '2017-03-01',
          month: 3,
          name: 'UNTITLED (DRESS)',
          x: 74,
          y: 47,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                UNTITLED (DRESS)Ǝ                Ǝ                    Ǝ                        signedacrylic on handmade dress29 1/4  by 18 5/8  in.   74.3 by 47.3 cm.Executed circa 1968.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Judd Foundation, New York (acquired directly from the artist)Christie's, New York, 13 September 2006, Lot 185Acquired from the above sale by the present owner in 2006Ǝ                    ",
          cid: 'contemporary-curated-n09622-72',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.3.2017.UK010117.2.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/UK010117/2_001.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 500000,
          provenance:
            'OTA Fine Arts, Tokyo Acquired from the above by the present owner',
          low: 300000,
          currency: 'GBP',
          id: 'phillips.3.2017.UK010117.2.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010117/2',
          salesTitle: '20th century & contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 907500,
          timexcoord: '2017-03-01',
          month: 3,
          name: 'Infinity Nets (BSGK)',
          x: 162,
          y: 162,
          location: 'london',
          stuff:
            'acrylic on canvas ^ Painted in 2015. This work is registered with the Yayoi Kusama Studio under number No. 2576. ^ 162 x 162 cm (63 3/4 x 63 3/4 in.) ^ OTA Fine Arts, Tokyo Acquired from the above by the present owner ^ 3',
          cid: 'UK010117.2',
        },
        fields: {
          ymonths: [206],
        },
        sort: [1488326400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2017.14422.66.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 66,
          high: 22500,
          low: 15000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (B. 1929)\n',
          salesTitle: 'First Open',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 10,000 - GBP 15,000',
          birth: 1929,
          realized: 'GBP 62,500',
          real: 93750,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-infinity-dots-6065942-details.aspx?from=salesummery&intobjectid=6065942&sid=a99479b4-2b36-4697-8941-4cd5108ee7b4',
          timexcoord: '2017-04-01',
          sale: 14422,
          month: 4,
          name: 'Infinity Dots\n',
          x: 29,
          y: 29.3,
          stuff:
            "Yayoi Kusama (B. 1929)\nInfinity Dots\nsigned, titled and dated 'YAYOI KUSAMA 1995 INFITY-DOTS' (on the reverse)\npastel on paper\n11 3/8 x 11 3/8in. (29 x 29.3cm.)\nExecuted in 1995\n",
          cid: '14422.66',
        },
        fields: {
          ymonths: [207],
        },
        sort: [1491004800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.4.2017.14078.223.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          edition: 46,
          auctionHouse: 'christies',
          lot: 223,
          high: 8400,
          low: 6000,
          currency: 'EUR',
          brand: 'Yayoi Kusama (b. 1929)\n',
          salesTitle: 'Post-War and Contemporary Art',
          editionOf: 60,
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'EUR 5,000 - EUR 7,000',
          birth: 1929,
          realized: 'EUR 25,000',
          real: 30000,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-pumpkin-6066284-details.aspx?from=salesummery&intobjectid=6066284&sid=b5ce049c-14e4-45c7-b675-a5ed6d2c2e79',
          timexcoord: '2017-04-01',
          sale: 14078,
          month: 4,
          name: 'Pumpkin (Blue)\n',
          x: 48,
          y: 60,
          stuff:
            "Yayoi Kusama (b. 1929)\nPumpkin (Blue)\ntitled in Japanese, inscribed, numbered and dated '46/60 B 1999' (lower left), signed 'Yayoi Kusama' (lower right)\nscreenprint and glitter on paper\nimage: 48 x 60cm.\nsheet: 60 x 76cm.\nExecuted in 1999, this work is number forty-six from an edition of sixty\n",
          cid: '14078.223',
        },
        fields: {
          ymonths: [207],
        },
        sort: [1491004800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.4.2017.yayoi-kusama.contemporary-curated-l17026-2',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 8000,
          low: 6000,
          currency: 'GBP',
          id: 'sothebys.4.2017.yayoi-kusama.contemporary-curated-l17026-2',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-curated-l17026/lot.2.html',
          salesTitle: 'contemporary-curated-l17026',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(21250)',
          real: 31875,
          timexcoord: '2017-04-01',
          month: 4,
          name: 'WHILE THE WIND BLOWS THROUGH',
          x: 24,
          y: 27,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                WHILE THE WIND BLOWS THROUGHƎ                Ǝ                    Ǝ                        signed and dated 1979; signed, titled in Japanese and dated 1979 on the reverseenamel on paperboard23.8 by 27 cm.   9 3/8  by 10 5/8  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection, TokyoMallet Japan, Tokyo, 20 November 2009, Lot 224Private CollectionAcquired from the above by the present ownerƎ                    ',
          cid: 'contemporary-curated-l17026-2',
        },
        fields: {
          ymonths: [207],
        },
        sort: [1491004800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.14188.560.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 560,
          high: 80000,
          low: 60000,
          currency: 'USD',
          brand: 'Yayoi Kusama (B. 1929)\n',
          salesTitle: 'Post-War and Contemporary Art Morning Session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 60,000 - USD 80,000',
          birth: 1929,
          realized: 'USD 125,000',
          real: 125000,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-no-20-6076331-details.aspx?from=salesummery&intobjectid=6076331&sid=aaef2491-0e0f-4b63-b59f-08934f86804b',
          timexcoord: '2017-05-01',
          sale: 14188,
          month: 5,
          name: 'No. 20\n',
          x: 33.3,
          y: 24.4,
          stuff:
            "Yayoi Kusama (B. 1929)\nNo. 20\nsigned, titled and dated 'Yayoi Kusama 1997 No 20' (on the reverse)\noil on canvas\n13 1/8 x 9 5/8 in. (33.3 x 24.4 cm.)\nPainted in 1997.\n",
          cid: '14188.560',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.13267.1.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 1,
          high: 1040000,
          low: 715000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle:
            'Contemporaries: Voices from East and West / Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 5,500,000 - HKD 8,000,000',
          birth: 1929,
          realized: 'HKD 16,860,000',
          real: 2191800,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-nets-blue-6081075-details.aspx?from=salesummery&intobjectid=6081075&sid=353cb9d0-d55b-451f-a6e2-5705537a25f1',
          timexcoord: '2017-05-01',
          sale: 13267,
          month: 5,
          name: 'Nets Blue\n',
          x: 51.8,
          y: 41.9,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nNets Blue\nsigned, titled, inscribed and dated ‘KUSAMA 1960 NETS BLUE’ (on the reverse)\noil on board\n51.8 x 41.9 cm. (20 3/8 x 16 ½ in.)\nPainted in 1960\n',
          cid: '13267.1',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.13269.115.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 115,
          high: 45500,
          low: 26000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 200,000 - HKD 350,000',
          birth: 1929,
          realized: 'HKD 625,000',
          real: 81250,
          url:
            'https://www.christies.com/lotfinder/prints-multiples/yayoi-kusama-waking-up-in-the-morning-6078291-details.aspx?from=salesummery&intobjectid=6078291&sid=29710ec0-490d-4cc7-966a-6ca9c3c874fd',
          timexcoord: '2017-05-01',
          sale: 13269,
          month: 5,
          name: 'WAKING UP IN THE MORNING (TQSTW)\n',
          x: 130,
          y: 162,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nWAKING UP IN THE MORNING (TQSTW)\ntitled in Japanese, titled, dated, signed and numbered 'WAKING UP IN THE MORNING 2007 yayoi Kusama 4/5' (on the reverse)\nsilkscreen on canvas\n130 x 162 cm. (51 1/8 x 63 ¾ in.)\nExecuted in 2007\nedition 4/5\n",
          cid: '13269.115',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.13269.113.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 113,
          high: 91000,
          low: 65000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 500,000 - HKD 700,000',
          birth: 1929,
          realized: 'HKD 875,000',
          real: 113750,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-beyond-the-nebulae-6078289-details.aspx?from=salesummery&intobjectid=6078289&sid=29710ec0-490d-4cc7-966a-6ca9c3c874fd',
          timexcoord: '2017-05-01',
          sale: 13269,
          month: 5,
          name: 'BEYOND THE NEBULAE\n',
          x: 65.7,
          y: 51.2,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nBEYOND THE NEBULAE\nsigned and dated ‘YAYOI KUSAMA 1978’ (lower left); titled and signed in Japanese, signed and dated ‘yayoi Kusama 1978’ (on the reverse)\nenamel on paper\n65.7 x 51.2 cm. (25 7/8 x 20 1/8 in.)\nExecuted in 1978\n',
          cid: '13269.113',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.13269.111.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 111,
          high: 260000,
          low: 195000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,500,000 - HKD 2,000,000',
          birth: 1929,
          realized: 'HKD 5,820,000',
          real: 756600,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-dots-obsession-6078287-details.aspx?from=salesummery&intobjectid=6078287&sid=29710ec0-490d-4cc7-966a-6ca9c3c874fd',
          timexcoord: '2017-05-01',
          sale: 13269,
          month: 5,
          name: 'DOTS-OBSESSION (TOWE)\n',
          x: 91,
          y: 116.7,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nDOTS-OBSESSION (TOWE)\nsigned, dated and titled ‘Yayoi Kusama 2005 DOTS-OBESSION TOWE’; titled in Japanese (on the reverse)\nacrylic on canvas\n91 x 116.7 cm. (35 7/8 x 46 in.) \nPainted in 2005\n',
          cid: '13269.111',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.13267.55.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 55,
          high: 1560000,
          low: 1040000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle:
            'Contemporaries: Voices from East and West / Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 8,000,000 - HKD 12,000,000',
          birth: 1929,
          realized: 'HKD 10,260,000',
          real: 1333800,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-infinity-nets-6081127-details.aspx?from=salesummery&intobjectid=6081127&sid=353cb9d0-d55b-451f-a6e2-5705537a25f1',
          timexcoord: '2017-05-01',
          sale: 13267,
          month: 5,
          name: 'INFINITY-NETS (FUMW)\n',
          x: 161.9,
          y: 130.1,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nINFINITY-NETS (FUMW)\nsigned twice, titled and dated 'YAYOI KUSAMA Yayoi Kusama INFINITY-NETS FUMW 2007' (on the reverse)\nacrylic on canvas\n161.9 x 130.1 cm. (63 ¾ x 51 ¼ in.)\nPainted in 2007\n",
          cid: '13267.55',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.13269.108.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 108,
          high: 624000,
          low: 494000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929) \n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 3,800,000 - HKD 4,800,000',
          birth: 1929,
          realized: 'HKD 8,700,000',
          real: 1131000,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-lemon-tea-6078284-details.aspx?from=salesummery&intobjectid=6078284&sid=29710ec0-490d-4cc7-966a-6ca9c3c874fd',
          timexcoord: '2017-05-01',
          sale: 13269,
          month: 5,
          name: 'LEMON TEA\n',
          x: 116.7,
          y: 91,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929) \nLEMON TEA\nsigned and dated 'Yayoi Kusama 1981'; signed and titled in Japanese (on the reverse)\ncollage, acrylic on canvas\n116.7 x 91 cm. (46 x 35 7/8 in.)\nPainted in 1981\n",
          cid: '13269.108',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.5.2017.13269.112.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          auctionHouse: 'christies',
          lot: 112,
          high: 130000,
          low: 91000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 700,000 - HKD 1,000,000',
          birth: 1929,
          realized: 'HKD 1,187,500',
          real: 154375,
          url:
            'https://www.christies.com/lotfinder/paintings/yayoi-kusama-dots-6078288-details.aspx?from=salesummery&intobjectid=6078288&sid=29710ec0-490d-4cc7-966a-6ca9c3c874fd',
          timexcoord: '2017-05-01',
          sale: 13269,
          month: 5,
          name: 'DOTS\n',
          x: 45.5,
          y: 38,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nDOTS\nsigned and dated 'Yayoi Kusama 1990'; titled in Japanese (on the reverse)\nacrylic on canvas\n45.5 x 38 cm. (17 7/8 x 15 in.)\nPainted in 1990\n",
          cid: '13269.112',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-101',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'USD',
          id:
            'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-101',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-day-auction-n09762/lot.101.html',
          salesTitle: 'contemporary-art-day-auction-n09762',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(324500)',
          real: 324500,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'MY HART (B)',
          x: 33,
          y: 29,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                MY HART (B)Ǝ                Ǝ                    Ǝ                        signed, titled and dated 1953 on the reversepastel, gouache and ink on paper13 by 11 1/2  in.   33 by 29.2 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Paula Cooper Gallery, New YorkAcquired from the above by the present owner in 1996Ǝ                    ',
          cid: 'contemporary-art-day-auction-n09762-101',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-408',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 1500000,
          low: 1000000,
          currency: 'USD',
          id:
            'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-408',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-day-auction-n09762/lot.408.html',
          salesTitle: 'contemporary-art-day-auction-n09762',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(1932500)',
          real: 1932500,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'INFINITY-NETS [YASOP]',
          x: 194,
          y: 194,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY-NETS [YASOP]Ǝ                Ǝ                    Ǝ                        signed, titled in English and Japanese and dated 2013 on the reverseacrylic on canvas76 3/8  by 76 3/8  in.   194 by 194 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio.ƎƎ                        ProvenanceƎ                        Victoria Miro Gallery, LondonAcquired from the above by the present owner in 2013Ǝ                    ",
          cid: 'contemporary-art-day-auction-n09762-408',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-410',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 600000,
          low: 400000,
          currency: 'USD',
          id:
            'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-410',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-day-auction-n09762/lot.410.html',
          salesTitle: 'contemporary-art-day-auction-n09762',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(852500)',
          real: 852500,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'INFINITY-NETS [PEAA]',
          x: 100,
          y: 100,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY-NETS [PEAA]Ǝ                Ǝ                    Ǝ                        signed, titled and dated 2013 on the reverseacrylic on canvas39 3/8  by 39 3/8  in.   100 by 100 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts, TokyoPrivate Collection, CaliforniaƎ                    ',
          cid: 'contemporary-art-day-auction-n09762-410',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-454',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'USD',
          id:
            'sothebys.5.2017.yayoi-kusama.contemporary-art-day-auction-n09762-454',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-day-auction-n09762/lot.454.html',
          salesTitle: 'contemporary-art-day-auction-n09762',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(150000)',
          real: 150000,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'INFINITY NETS',
          x: 14,
          y: 18,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETSƎ                Ǝ                    Ǝ                        signed and dated 2000 on the reverseacrylic and glitter on canvas5 1/2  by 7 1/8  in.   14 by 18 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private CollectionSBI Art Auction, Tokyo, 25 January 2014, Lot 36Private Collection, JapanArtnet Auctions, 25 March 2014, Lot 96705Private Collection, New York (acquired from the above sale)Acquired from the above by the present ownerƎ                    ',
          cid: 'contemporary-art-day-auction-n09762-454',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2017.yayoi-kusama.contemporary-art-evening-auction-n09761-2',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 3000000,
          low: 2000000,
          currency: 'USD',
          id:
            'sothebys.5.2017.yayoi-kusama.contemporary-art-evening-auction-n09761-2',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-evening-auction-n09761/lot.2.html',
          salesTitle: 'contemporary-art-evening-auction-n09761',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(5300000)',
          real: 5300000,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'LAKE MICHIGAN',
          x: 65,
          y: 80,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B.1929Ǝ                LAKE MICHIGANƎ                Ǝ                    Ǝ                        signed, titled and dated 1960 on the reverseoil on canvas25 1/2  by 31 1/2  in.   64.8 by 80 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio and numbered 2825.ƎƎ                        ProvenanceƎ                        Private Collection (acquired in 1960)Private Collection (thence by descent) Acquired by the present owner from the aboveƎ                    ",
          cid: 'contemporary-art-evening-auction-n09761-2',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2017.NY010417.203.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010417/203_001.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 450000,
          provenance:
            'Gagosian Gallery, London Ben Brown Fine Arts, London Private Collection, Hong Kong Private Collection, New York David Zwirner, New York Acquired from the above by the present owner',
          low: 350000,
          currency: 'USD',
          id: 'phillips.5.2017.NY010417.203.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010417/203',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 670000,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'Meditation',
          x: 194,
          y: 194,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 2008, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 76 3/8 x 76 3/8 in. (194 x 194 cm.) ^ Gagosian Gallery, London Ben Brown Fine Arts, London Private Collection, Hong Kong Private Collection, New York David Zwirner, New York Acquired from the above by the present owner ^ 5',
          cid: 'NY010417.203',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2017.NY010417.205.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010417/205_001.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 120000,
          provenance:
            'Robert Miller Galley, New York Gallery TAGBOAT, Tokyo Private Collection, Tokyo',
          low: 80000,
          currency: 'USD',
          id: 'phillips.5.2017.NY010417.205.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010417/205',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 156250,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'Nets 30',
          x: 46,
          y: 38,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 1998, this work is accompanied by a registration card issued by the Yayoi Kusama Studio. ^ 18 x 15 in. (45.7 x 38.1 cm.) ^ Robert Miller Galley, New York Gallery TAGBOAT, Tokyo Private Collection, Tokyo ^ 5',
          cid: 'NY010417.205',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2017.NY010417.206.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010417/206_001.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 70000,
          provenance:
            'Private Collection (acquired directly from the artist circa 1960) Bonhams, New York, November 12, 2012, lot 107 Acquired at the above sale by the present owner',
          low: 50000,
          currency: 'USD',
          id: 'phillips.5.2017.NY010417.206.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010417/206',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 118750,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'Swamp',
          x: 26,
          y: 34,
          location: 'new york',
          stuff:
            'gouache and pastel on paper ^ Executed in 1956. ^ 10 x 13 1/2 in. (25.5 x 34.3 cm.) ^ Private Collection (acquired directly from the artist circa 1960) Bonhams, New York, November 12, 2012, lot 107 Acquired at the above sale by the present owner ^ 5',
          cid: 'NY010417.206',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2017.NY010417.207.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1/auctions/NY010417/207_001.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 80000,
          provenance:
            'Private Collection, Europe Phillips de Pury & Company, London, October 13, 2011, lot 325 Acquired at the above sale by the present owner',
          low: 60000,
          currency: 'USD',
          id: 'phillips.5.2017.NY010417.207.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010417/207',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 125000,
          timexcoord: '2017-05-01',
          month: 5,
          name: 'Upward Vision (No. 1)',
          x: 41,
          y: 33,
          location: 'new york',
          stuff:
            'graphite, ink, gouache and pastel on paper ^ Executed in 1953. ^ 16 x 13 in. (41 x 33.3 cm.) ^ Private Collection, Europe Phillips de Pury & Company, London, October 13, 2011, lot 325 Acquired at the above sale by the present owner ^ 5',
          cid: 'NY010417.207',
        },
        fields: {
          ymonths: [208],
        },
        sort: [1493596800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.13892.209.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 209,
          auctionHouse: 'christies',
          high: 180000,
          low: 120000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929)\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 120,000 - USD 180,000',
          birth: 1929,
          realized: 'USD 243,750',
          real: 243750,
          timexcoord: '2017-09-01',
          sale: 13892,
          month: 9,
          name: 'Pumpkin\n',
          x: 16.2,
          y: 22.9,
          stuff:
            "Yayoi Kusama (b. 1929)\nPumpkin\nsigned, titled and dated 'YAYOI KUSAMA 1991 Pumpkin' (on the reverse)\nacrylic on canvas\n6 3/8 x 9 in. (16.2 x 22.9 cm.)\nPainted in 1991.\n",
          cid: '13892.209',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.14817.101.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 101,
          auctionHouse: 'christies',
          high: 56000.00000000001,
          low: 39200.00000000001,
          currency: 'CNY',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'First Open | Shanghai',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 280,000 - CNY 400,000',
          birth: 1929,
          realized: 'CNY 420,000',
          real: 58800.00000000001,
          timexcoord: '2017-09-01',
          sale: 14817,
          month: 9,
          name: 'Autumn\n',
          x: 26.4,
          y: 18.6,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nAutumn\nsigned and dated '1952 Yayoi K' (lower center); signed, titled, dated in Japanese, titled and dated twice 'Autumn 1952' in English (on the reverse)\npastel and ink on paper\n26.4 x 18.6 cm. (10 3/8 x 7 3/8 in.)\nExecuted in 1952\n",
          cid: '14817.101',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.13892.7.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 7,
          auctionHouse: 'christies',
          high: 20000,
          low: 15000,
          currency: 'USD',
          brand: 'Yayoi Kusama (B. 1929)\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 15,000 - USD 20,000',
          birth: 1929,
          realized: 'USD 181,250',
          real: 181250,
          timexcoord: '2017-09-01',
          sale: 13892,
          month: 9,
          name: 'Untitled (Tunic)\n',
          x: 81.3,
          y: 118.1,
          stuff:
            "Yayoi Kusama (B. 1929)\nUntitled (Tunic)\nsigned 'KUSAMA' (lower center)\noil on sewn fabric\n32 x 46 ½ in. (81.3 x 118.1 cm.)\nExecuted circa 1968.\n",
          cid: '13892.7',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.14818.202.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 202,
          auctionHouse: 'christies',
          high: 140000,
          low: 95200.00000000001,
          currency: 'CNY',
          brand: 'YAYOI KUSAMA (JAPAN, b. 1929)\n',
          salesTitle: '20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 680,000 - CNY 1,000,000',
          birth: 1929,
          realized: 'CNY 840,000',
          real: 117600.00000000001,
          timexcoord: '2017-09-01',
          sale: 14818,
          month: 9,
          name: 'An Island (13)\n',
          x: 31.8,
          y: 29.2,
          stuff:
            'YAYOI KUSAMA (JAPAN, b. 1929)\nAn Island (13)\nsigned, titled and dated ‘Y. Kusama An Island (13)1955’ in English (on the reverse)\ngouache on paper\n31.8 x 29.2 cm. (12 1/2 x 11 1/2 in.)\nExecuted in 1955\n',
          cid: '14818.202',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.13892.6.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 6,
          auctionHouse: 'christies',
          high: 30000,
          low: 20000,
          currency: 'USD',
          brand: 'Yayoi Kusama (B. 1929)\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 20,000 - USD 30,000',
          birth: 1929,
          realized: 'USD 225,000',
          real: 225000,
          timexcoord: '2017-09-01',
          sale: 13892,
          month: 9,
          name: 'Untitled (Tunic)\n',
          x: 81.3,
          y: 135.3,
          stuff:
            "Yayoi Kusama (B. 1929)\nUntitled (Tunic)\nsigned 'KUSAMA' (lower right)\noil on sewn fabric\n32 x 53 ¼ in. (81.3 x 135.3 cm.)\nExecuted circa 1968.\n",
          cid: '13892.6',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.14818.203.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 203,
          auctionHouse: 'christies',
          high: 168000.00000000003,
          low: 119000.00000000001,
          currency: 'CNY',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: '20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 850,000 - CNY 1,200,000',
          birth: 1929,
          realized: 'CNY 1,440,000',
          real: 201600.00000000003,
          timexcoord: '2017-09-01',
          sale: 14818,
          month: 9,
          name: 'Stars\n',
          x: 53,
          y: 45.5,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nStars\nsigned and dated 'Yayoi Kusama 1990' (on the reverse)\nacrylic on canvas\n53 x 45.5 cm. (20 7/8 x 17 7/8 in.)\nExecuted in 1990\n",
          cid: '14818.203',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.14818.201.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 201,
          auctionHouse: 'christies',
          high: 89600.00000000001,
          low: 47600.00000000001,
          currency: 'CNY',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: '20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'CNY 340,000 - CNY 640,000',
          birth: 1929,
          realized: 'CNY 504,000',
          real: 70560,
          timexcoord: '2017-09-01',
          sale: 14818,
          month: 9,
          name: 'DOTS\n',
          x: 15.8,
          y: 22.7,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nDOTS\nsigned, dated and titled 'Yayoi Kusama 1990 Dots' (on the reverse)\nacrylic on canvas\n15.8 x 22.7 cm. (6 1/4 x 8 7/8 in.)\nPainted in 1990\n",
          cid: '14818.201',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.9.2017.13892.1.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 1,
          auctionHouse: 'christies',
          high: 70000,
          low: 50000,
          currency: 'USD',
          brand: 'Yayoi Kusama (B. 1929)\n',
          salesTitle: 'Post-War & Contemporary Art',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 50,000 - USD 70,000',
          birth: 1929,
          realized: 'USD 162,500',
          real: 162500,
          timexcoord: '2017-09-01',
          sale: 13892,
          month: 9,
          name: 'Ground\n',
          x: 35.9,
          y: 25.7,
          stuff:
            "Yayoi Kusama (B. 1929)\nGround\nsigned twice and dated twice 'YAYOI KUSAMA 1953' (lower right); signed again, titled and dated again 'Yayoi Kusama 1953 GROUND' (on the reverse)\npastel, watercolor and ink on paper\n14 1/8 x 10 1/8 in. (35.9 x 25.7 cm.)\nExecuted in 1953.\n",
          cid: '13892.1',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'sothebys.9.2017.yayoi-kusama.contemporary-curated-n09624-13',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 550000,
          low: 350000,
          currency: 'USD',
          id: 'sothebys.9.2017.yayoi-kusama.contemporary-curated-n09624-13',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-curated-n09624/lot.13.html',
          salesTitle: 'contemporary-curated-n09624',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(732500)',
          real: 732500,
          timexcoord: '2017-09-01',
          month: 9,
          name: 'INFINITY NETS 1960',
          x: 73,
          y: 91,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B.1929Ǝ                INFINITY NETS 1960Ǝ                Ǝ                    Ǝ                        signed and titled on the reverseacrylic on canvas28 3/4  by 35 7/8  in.   73 by 91.1 cm.Executed circa 1979, this work is registered with the artist’s studio and accompanied by an artwork registration card.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        SCAI The Bathhouse, TokyoPrivate CollectionƎ                    ',
          cid: 'contemporary-curated-n09624-13',
        },
        fields: {
          ymonths: [212],
        },
        sort: [1504224000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2017.14443.234.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 234,
          auctionHouse: 'christies',
          high: 525000,
          low: 375000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929)\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 250,000 - GBP 350,000',
          birth: 1929,
          realized: 'GBP 488,750',
          real: 733125,
          timexcoord: '2017-10-01',
          sale: 14443,
          month: 10,
          name: 'Coffee Cup\n',
          x: 91,
          y: 72.5,
          stuff:
            "Yayoi Kusama (b. 1929)\nCoffee Cup\nsigned, titled in Japanese and dated 'YAYOI KUSAMA 1991' (on the reverse)\nacrylic on canvas\n35 7/8 x 28 ½in. (91 x 72.5cm.)\nPainted in 1991\n",
          cid: '14443.234',
        },
        fields: {
          ymonths: [213],
        },
        sort: [1506816000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.10.2017.14890.12.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 12,
          auctionHouse: 'christies',
          high: 48000,
          low: 36000,
          currency: 'EUR',
          brand: 'YAYOI KUSAMA (NÉE EN 1929)\n',
          salesTitle:
            'REGARDS CROISÉS : COLLECTION JEAN-FRANÇOIS & MARIE-ALINE PRAT - EVENING SALE',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'EUR 30,000 - EUR 40,000',
          birth: 1929,
          realized: 'EUR 187,500',
          real: 225000,
          timexcoord: '2017-10-01',
          sale: 14890,
          month: 10,
          name: 'Infinity Nets\n',
          x: 15.8,
          y: 22.7,
          stuff:
            "YAYOI KUSAMA (NÉE EN 1929)\nInfinity Nets\nsigné, titré en japonais et daté 'Yayoi Kusama 1990' (au dos)\nacrylique sur toile\n15.8 x 22.7 cm. (6 ¼ x 8 7/8 in.)\nPeint en 1990.\n",
          cid: '14890.12',
        },
        fields: {
          ymonths: [213],
        },
        sort: [1506816000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.201.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 201,
          auctionHouse: 'christies',
          high: 46800,
          low: 31200,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 240,000 - HKD 360,000',
          birth: 1929,
          realized: 'HKD 625,000',
          real: 81250,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'The Afternoon\n',
          x: 66,
          y: 51.2,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nThe Afternoon\nsigned and dated ‘yayoi Kusama 1981’ (on the lower right)\ncollage, pastel, gouache, ink and fabric on paper\n66 x 51.2 cm. (26 x 20 1/8 in.)\nPainted in 1981\n',
          cid: '13273.201',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.242.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 242,
          auctionHouse: 'christies',
          high: 650000,
          low: 390000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 3,000,000 - HKD 5,000,000',
          birth: 1929,
          realized: 'HKD 7,060,000',
          real: 917800,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Pumpkin\n',
          x: 27.2,
          y: 22.2,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nPumpkin\nsigned, dated and titled 'yayoi Kusama 2003 PUMPKIN'; signed and titled in Japanese (on the reverse of each)\nacrylic on canvas, triptych\neach: 27.2 x 22.2 cm (10 6/8 x 8 6/8 in.) (3)\nPainted in 2003\n",
          cid: '13273.242',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.246.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 246,
          auctionHouse: 'christies',
          high: 780000,
          low: 520000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 4,000,000 - HKD 6,000,000',
          birth: 1929,
          realized: 'HKD 7,300,000',
          real: 949000,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Pumpkin\n',
          x: 53,
          y: 45.5,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nPumpkin\ntitled in Japanese, signed and dated ‘yayoi Kusama 2001’ (on the reverse)\nacrylic on canvas\n53 x 45.5 cm. (20 7/8 x 17 3/4 in.)\nPainted in 2001\n',
          cid: '13273.246',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.205.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 205,
          auctionHouse: 'christies',
          high: 234000,
          low: 156000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,200,000 - HKD 1,800,000',
          birth: 1929,
          realized: 'HKD 3,220,000',
          real: 418600,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Kusama in Flower Garden\n',
          x: 61,
          y: 73,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nKusama in Flower Garden\nsigned, dated and titled \'yayoi Kusama 1996 "KUSAMA" IN FLOWER GARDEN"\', titled in Japanese (on the reverse)\nacrylic and collage on canvas\n61 x 73 cm. (24 x 28 3/4 in.)\nExecuted in 1996\n',
          cid: '13273.205',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13271.38.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 38,
          auctionHouse: 'christies',
          high: 4160000,
          low: 2860000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian 20th Century & Contemporary Art (Evening Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 22,000,000 - HKD 32,000,000',
          birth: 1929,
          realized: 'HKD 33,700,000',
          real: 4381000,
          timexcoord: '2017-11-01',
          sale: 13271,
          month: 11,
          name: 'Untitled\n',
          x: 102,
          y: 142.2,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nUntitled\noil on canvas\n102 x 142.2 cm. (40 1/8 x 56 in.)\nPainted circa 1960\n',
          cid: '13271.38',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.245.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 245,
          auctionHouse: 'christies',
          high: 624000,
          low: 494000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 3,800,000 - HKD 4,800,000',
          birth: 1929,
          realized: 'HKD 4,300,000',
          real: 559000,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Watermelon\n',
          x: 45.5,
          y: 53,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nWatermelon\nsigned and titled in Japanese, dated '1984'(on the stretcher)\nacrylic on canvas\n45.5 x 53 cm. (17 7/8 x 20 7/8 in.)\nPainted in 1984\n",
          cid: '13273.245',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.14996.652.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 652,
          auctionHouse: 'christies',
          high: 180000,
          low: 120000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929)\n',
          salesTitle: 'Post-War & Contemporary Art Morning Session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 120,000 - USD 180,000',
          birth: 1929,
          realized: 'USD 324,500',
          real: 324500,
          timexcoord: '2017-11-01',
          sale: 14996,
          month: 11,
          name: 'Pumpkin (HTT)\n',
          x: 22.8,
          y: 16.1,
          stuff:
            "Yayoi Kusama (b. 1929)\nPumpkin (HTT)\nsigned, titled in Japanese and dated 'YAYOI KUSAMA 1999 (HTT)' (on the reverse)\nacrylic on canvas\n9 x 6 3/8 in. (22.8 x 16.1 cm.)\nPainted in 1999.\n",
          cid: '14996.652',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.247.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 247,
          auctionHouse: 'christies',
          high: 416000,
          low: 312000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 2,400,000 - HKD 3,200,000',
          birth: 1929,
          realized: 'HKD 2,980,000',
          real: 387400,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Galaxy\n',
          x: 117,
          y: 91,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nGalaxy\nsigned and dated 'Yayoi Kusama 1991'; titled in Japanese (on the reverse)\nacrylic on canvas\n117 x 91 cm. (46 1/8 x 35 7/8 in.)\nPainted in 1991\n",
          cid: '13273.247',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.206.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 206,
          auctionHouse: 'christies',
          high: 234000,
          low: 130000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,000,000 - HKD 1,800,000',
          birth: 1929,
          realized: 'HKD 2,125,000',
          real: 276250,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Wave in Venice\n',
          x: 53,
          y: 45,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nWave in Venice\nsigned and dated 'yayoi Kusama 1988', titled in Japanese (on the reverse)\nacrylic on canvas\n53 x 45 cm. (20 7/8 x 17 3/4 in.)\nPainted in 1988\n",
          cid: '13273.206',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.14996.613.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 613,
          auctionHouse: 'christies',
          high: 100000,
          low: 70000,
          currency: 'USD',
          brand: 'Yayoi Kusama (b. 1929)\n',
          salesTitle: 'Post-War & Contemporary Art Morning Session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'USD 70,000 - USD 100,000',
          birth: 1929,
          realized: 'USD 175,000',
          real: 175000,
          timexcoord: '2017-11-01',
          sale: 14996,
          month: 11,
          name: 'A Face\n',
          x: 29.5,
          y: 22.5,
          stuff:
            "Yayoi Kusama (b. 1929)\nA Face\nstamped with the artist's signature, titled and dated '1953 YAYOI KUSAMA A Face' (on the reverse); signed, titled again and dated again 'A Face 1953 Yayoi Kusama' (on the backing board)\ngouache, watercolor, ink and pastel on paper\n11 5/8 x 8 7/8 in. (29.5 x 22.5 cm.)\nExecuted in 1953.\n",
          cid: '14996.613',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.204.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 204,
          auctionHouse: 'christies',
          high: 117000,
          low: 91000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 700,000 - HKD 900,000',
          birth: 1929,
          realized: 'HKD 3,940,000',
          real: 512200,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'No. 10. F.B.\n',
          x: 60,
          y: 70.8,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nNo. 10. F.B.\nsigned, dated, and inscribed 'YAYOI KUSAMA 1959 No. 10. F.B.' (on the reverse)\npastel and India ink on mulberry paper on card\n60 x 70.8 cm. (23 5/8 x 27 7/8 in.)\nPainted in 1959\n",
          cid: '13273.204',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.203.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 203,
          auctionHouse: 'christies',
          high: 195000,
          low: 130000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 1,000,000 - HKD 1,500,000',
          birth: 1929,
          realized: 'HKD 2,980,000',
          real: 387400,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Dots Obsession - OWENG\n',
          x: 38,
          y: 45.5,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nDots Obsession - OWENG\nsigned, dated and titled 'Yayoi Kusama 2004 DOTS OBESSION OWENG' (on the reverse)\nacrylic on canvas\n38 x 45.5 cm. (15 x 17 7/8 in.)\nPainted in 2004\n",
          cid: '13273.203',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.11.2017.13273.202.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2017,
          artist: 'yayoi-kusama',
          lot: 202,
          auctionHouse: 'christies',
          high: 39000,
          low: 26000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'Asian Contemporary Art (Day Sale)',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 200,000 - HKD 300,000',
          birth: 1929,
          realized: 'HKD 375,000',
          real: 48750,
          timexcoord: '2017-11-01',
          sale: 13273,
          month: 11,
          name: 'Old Flame\n',
          x: 15.6,
          y: 22.3,
          stuff:
            "YAYOI KUSAMA (JAPAN, B. 1929)\nOld Flame\ndated and signed '1953 YAYOI KUSAMA' (lower left); titled and signed again 'OLD FLAME Yayoi Kusama' (on the reverse)\npastel and India ink on mulberry paper\n15.6 x 22.3 cm. (6 1/8 x 8 5/8 in.)\nPainted in 1953\n",
          cid: '13273.202',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2017.yayoi-kusama.contemporary-art-day-auction-n09714-443',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 300000,
          low: 200000,
          currency: 'USD',
          id:
            'sothebys.11.2017.yayoi-kusama.contemporary-art-day-auction-n09714-443',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-day-auction-n09714/lot.443.html',
          salesTitle: 'contemporary-art-day-auction-n09714',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(325000)',
          real: 325000,
          timexcoord: '2017-11-01',
          month: 11,
          name: 'THE ISLAND',
          x: 31,
          y: 41,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                THE ISLANDƎ                Ǝ                    Ǝ                        signed and dated 1953; signed, titled and dated 1953 on the reversepastel and watercolor on paper12 by 16 1/4  in.   30.5 by 41.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        R. Castellane Gallery, New YorkAcquired from the above by the present ownerƎ                    ',
          cid: 'contemporary-art-day-auction-n09714-443',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2017.yayoi-kusama.contemporary-art-day-auction-n09714-444',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 120000,
          low: 80000,
          currency: 'USD',
          id:
            'sothebys.11.2017.yayoi-kusama.contemporary-art-day-auction-n09714-444',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-day-auction-n09714/lot.444.html',
          salesTitle: 'contemporary-art-day-auction-n09714',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(187500)',
          real: 187500,
          timexcoord: '2017-11-01',
          month: 11,
          name: 'ASA NI AKERU MICHI (ROAD THAT AWAKENS AT DAWN)',
          x: 55,
          y: 40,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                ASA NI AKERU MICHI (ROAD THAT AWAKENS AT DAWN)Ǝ                Ǝ                    Ǝ                        signed and dated 1975; signed in English and Japanese, titled in Japanese and dated 1975 on the reversepastel, ink and printed paper collage on paper21 5/8  by 15 5/8  in.   54.9 by 40 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Ota Fine Arts, TokyoPrivate Collection, JapanSotheby's, New York, 14 December 2010, Lot 118Acquired from the above sale by the present ownerƎ                    ",
          cid: 'contemporary-art-day-auction-n09714-444',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2017.yayoi-kusama.contemporary-art-day-auction-n09714-476',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 110000,
          low: 90000,
          currency: 'USD',
          id:
            'sothebys.11.2017.yayoi-kusama.contemporary-art-day-auction-n09714-476',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/contemporary-art-day-auction-n09714/lot.476.html',
          salesTitle: 'contemporary-art-day-auction-n09714',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(423000)',
          real: 423000,
          timexcoord: '2017-11-01',
          month: 11,
          name: 'HACHIGATSU NO GEKKO (AUGUST MOON)',
          x: 46,
          y: 53,
          location: 'new york',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                HACHIGATSU NO GEKKO (AUGUST MOON)Ǝ                Ǝ                    Ǝ                        signed, titled in Japanese and dated 1988 on the reverseacrylic on canvas18 by 21 in.   45.7 by 53.3 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection, Asia (acquired directly from the artist)Ǝ                    ',
          cid: 'contemporary-art-day-auction-n09714-476',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.11.2017.NY010817.155.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1526398049/auctions/NY010817/155_001.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 150000,
          provenance:
            'Private Collection, Japan Private Collection, Austria Dorotheum, Vienna, November 26, 2008, lot 385 Acquired at the above sale by the present owner',
          low: 120000,
          currency: 'USD',
          id: 'phillips.11.2017.NY010817.155.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010817/155',
          salesTitle:
            '20th century & contemporary art day sale morning session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 325000,
          timexcoord: '2017-11-01',
          month: 11,
          name: 'Crowd of Stars',
          x: 66,
          y: 53,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 1992, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 25 3/4 x 20 7/8 in. (65.5 x 53 cm.) ^ Private Collection, Japan Private Collection, Austria Dorotheum, Vienna, November 26, 2008, lot 385 Acquired at the above sale by the present owner ^ 11',
          cid: 'NY010817.155',
        },
        fields: {
          ymonths: [214],
        },
        sort: [1509494400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.12.2017.yayoi-kusama.art-contemporain-vente-du-jour-pf1735-159',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2017,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 50000,
          low: 30000,
          currency: 'EUR',
          id:
            'sothebys.12.2017.yayoi-kusama.art-contemporain-vente-du-jour-pf1735-159',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2017/art-contemporain-vente-du-jour-pf1735/lot.159.html',
          salesTitle: 'art-contemporain-vente-du-jour-pf1735',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(291000)',
          real: 349200,
          timexcoord: '2017-12-01',
          month: 12,
          name: 'SOUL OF THE NIGHT',
          x: 66,
          y: 51,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                SOUL OF THE NIGHTƎ                Ǝ                    Ǝ                        signed and dated 1981; signed in Japanese, signed and dated 1981 on the reversepastel, acrylic, ink and collage on paper66 x 51,3 cm; 26 x 20  3/16  in.Executed in 1981.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio and numbered 0954.ƎƎ                        ProvenanceƎ                        Sale: Est-Ouest Auctions Co., Ltd., 30th Anniversary Hong Kong Auction, 1 June 2014, lot 845Acquired from the above sale by the current ownerƎ                    ",
          cid: 'art-contemporain-vente-du-jour-pf1735-159',
        },
        fields: {
          ymonths: [215],
        },
        sort: [1512086400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2018.15474.274.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2018,
          artist: 'yayoi-kusama',
          lot: 274,
          auctionHouse: 'christies',
          high: 22500,
          low: 15000,
          currency: 'GBP',
          brand: 'Yayoi Kusama (b. 1929)\n',
          salesTitle: 'Post-War and Contemporary Art Day Auction',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'GBP 10,000 - GBP 15,000',
          birth: 1929,
          realized: 'GBP 27,500',
          real: 41250,
          timexcoord: '2018-03-01',
          sale: 15474,
          month: 3,
          name: 'Butterfly\n',
          x: 24.3,
          y: 27.2,
          stuff:
            "Yayoi Kusama (b. 1929)\nButterfly\nsigned and dated 'YAYOI KUSAMA 1980' (lower right)\nwatercolour on paper\n9 5/8 x 10 ¾in. (24.3 x 27.2cm.)\nExecuted in 1980\n",
          cid: '15474.274',
        },
        fields: {
          ymonths: [218],
        },
        sort: [1519862400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2018.15906.71.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2018,
          artist: 'yayoi-kusama',
          lot: 71,
          auctionHouse: 'christies',
          high: 117000,
          low: 78000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'First Open | Hong Kong',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 600,000 - HKD 900,000',
          birth: 1929,
          realized: 'HKD 1,625,000',
          real: 211250,
          timexcoord: '2018-03-01',
          sale: 15906,
          month: 3,
          name: 'Flowers\n',
          x: 22.7,
          y: 15.8,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\nFlowers\nsigned and dated ‘YAYOI KUSAMA 1991’; titled in Japanese (on the reverse)\nacrylic on canvas\n22.7 x 15.8 cm. (8 7/8 x 6 1/4 in.)\nPainted in 1991\n',
          cid: '15906.71',
        },
        fields: {
          ymonths: [218],
        },
        sort: [1519862400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'christies.3.2018.15906.70.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          img: '',
          year: 2018,
          artist: 'yayoi-kusama',
          lot: 70,
          auctionHouse: 'christies',
          high: 143000,
          low: 117000,
          currency: 'HKD',
          brand: 'YAYOI KUSAMA (JAPAN, B. 1929)\n',
          salesTitle: 'First Open | Hong Kong',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          estimated: 'HKD 900,000 - HKD 1,100,000',
          birth: 1929,
          realized: 'HKD 1,125,000',
          real: 146250,
          timexcoord: '2018-03-01',
          sale: 15906,
          month: 3,
          name: '(Hymn of Life) (1) Infinity Nets\n',
          x: 72.7,
          y: 60.6,
          stuff:
            'YAYOI KUSAMA (JAPAN, B. 1929)\n(Hymn of Life) (1) Infinity Nets\nsigned, dated and titled ‘YAYOI KUSAMA 1988 HYMN OF LIFE (1) INFINITY NETS’, titled in Japanese (on the reverse); signed ‘yayoi Kusama’ (on the stretcher)\nacrylic on canvas\n72.7 x 60.6 cm. (28 5/8 x 23 7/8 in.)\nPainted in 1988\n',
          cid: '15906.70',
        },
        fields: {
          ymonths: [218],
        },
        sort: [1519862400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.3.2018.UK010218.113.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1519926388/auctions/UK010218/113_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 120000,
          provenance:
            'Peter Blum Gallery, New York Anthony Meier Fine Arts, San Francisco Acquired from the above by the present owner in 2009',
          low: 90000,
          currency: 'GBP',
          id: 'phillips.3.2018.UK010218.113.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010218/113',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 159375,
          timexcoord: '2018-03-01',
          month: 3,
          name: 'Spring Mars Studay',
          x: 34,
          y: 30,
          location: 'london""',
          stuff:
            'pastel and gouache on paper ^ Executed in 1953, this work is accompanied by a registration card from the Yayoi Kusama Studio. ^ 34.3 x 29.8 cm (13 1/2 x 11 3/4 in.) ^ Peter Blum Gallery, New York Anthony Meier Fine Arts, San Francisco Acquired from the above by the present owner in 2009 ^ 3',
          cid: 'UK010218.113',
        },
        fields: {
          ymonths: [218],
        },
        sort: [1519862400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.3.2018.UK010218.159.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1519926404/auctions/UK010218/159_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 120000,
          provenance:
            "Private Collection Sotheby's, Olympia, 18 June 2007, lot 694 Acquired at the above sale by the present owner",
          low: 80000,
          currency: 'GBP',
          id: 'phillips.3.2018.UK010218.159.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010218/159',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 243750,
          timexcoord: '2018-03-01',
          month: 3,
          name: 'Fallen Blossoms',
          x: 41,
          y: 32,
          location: 'london""',
          stuff:
            "acrylic on canvas ^ Painted in 1990, this work is registered with the Yayoi Kusama Studio under no. 0171. ^ 41 x 31.6 cm (16 1/8 x 12 1/2 in.) ^ Private Collection Sotheby's, Olympia, 18 June 2007, lot 694 Acquired at the above sale by the present owner ^ 3",
          cid: 'UK010218.159',
        },
        fields: {
          ymonths: [218],
        },
        sort: [1519862400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.3.2018.yayoi-kusama.contemporary-art-evening-auction-l18020-37',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 1200000,
          low: 800000,
          currency: 'GBP',
          id:
            'sothebys.3.2018.yayoi-kusama.contemporary-art-evening-auction-l18020-37',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-evening-auction-l18020/lot.37.html',
          salesTitle: 'contemporary-art-evening-auction-l18020',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(1269000)',
          real: 1903500,
          timexcoord: '2018-03-01',
          month: 3,
          name: 'INFINITY NETS (OQ4)',
          x: 163,
          y: 131,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                INFINITY NETS (OQ4)Ǝ                Ǝ                    Ǝ                        signed, titled and dated 2000 on the reverseacrylic on canvas162.5 by 130.8 cm.   64 by 51 1/2  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by a certificate of authenticity from the Yayoi Kusama Studio.ƎƎ                        ProvenanceƎ                        Ota Fine Arts, TokyoƎPrivate Collection, TokyoƎPhillips de Pury & Company, New York, 13 May 2010, Lot 124ƎAcquired from the above by the present ownerƎ                    ',
          cid: 'contemporary-art-evening-auction-l18020-37',
        },
        fields: {
          ymonths: [218],
        },
        sort: [1519862400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2018.UK010318.3.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1522252620/auctions/UK010318/3_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance:
            'Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner',
          low: 15000,
          currency: 'GBP',
          id: 'phillips.4.2018.UK010318.3.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010318/3',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 28125,
          timexcoord: '2018-04-01',
          month: 4,
          name: 'Rain on Red Poppies',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'spray paint on cardboard ^ Executed in 1978, this work is accompanied by a registration card from YAYOI KUSAMA Inc. ^ 27 x 24.4 cm (10 5/8 x 9 5/8 in.) ^ Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner ^ 4',
          cid: 'UK010318.3',
        },
        fields: {
          ymonths: [219],
        },
        sort: [1522540800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.4.2018.UK010318.4.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1522252621/auctions/UK010318/4_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance:
            'Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner',
          low: 15000,
          currency: 'GBP',
          id: 'phillips.4.2018.UK010318.4.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010318/4',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 39375,
          timexcoord: '2018-04-01',
          month: 4,
          name: 'Late Autumn Skies',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'spray paint on cardboard ^ Executed in 1978, this work is accompanied by a registration card from YAYOI KUSAMA Inc. ^ 27.3 x 24.1 cm (10 3/4 x 9 1/2 in.) ^ Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner ^ 4',
          cid: 'UK010318.4',
        },
        fields: {
          ymonths: [219],
        },
        sort: [1522540800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2018.NY010418.162.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1524689314/auctions/NY010418/162_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 600000,
          provenance:
            'David Zwirner, New York Acquired from the above by the present owner',
          low: 400000,
          currency: 'USD',
          id: 'phillips.5.2018.NY010418.162.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010418/162',
          salesTitle:
            '20th century & contemporary art day sale morning session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 555000,
          timexcoord: '2018-05-01',
          month: 5,
          name: 'Far End of Disappointment',
          x: 194,
          y: 194,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 2015, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 76 3/8 x 76 3/8 in. (194 x 194 cm.) ^ David Zwirner, New York Acquired from the above by the present owner ^ 5',
          cid: 'NY010418.162',
        },
        fields: {
          ymonths: [220],
        },
        sort: [1525132800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2018.NY010418.163.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1524689318/auctions/NY010418/163_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 25000,
          provenance:
            'Ikon Ltd. Contemporary Art, Los Angeles Acquired from the above by the present owner',
          low: 18000,
          currency: 'USD',
          id: 'phillips.5.2018.NY010418.163.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010418/163',
          salesTitle:
            '20th century & contemporary art day sale morning session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 50000,
          timexcoord: '2018-05-01',
          month: 5,
          name: 'Fires by the Beach',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            'ink and enamel on paperboard ^ Executed in 1978, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 10 3/4 x 9 1/2 in. (27.3 x 24.1 cm.) ^ Ikon Ltd. Contemporary Art, Los Angeles Acquired from the above by the present owner ^ 5',
          cid: 'NY010418.163',
        },
        fields: {
          ymonths: [220],
        },
        sort: [1525132800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2018.NY010418.164.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1524689314/auctions/NY010418/164_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 25000,
          provenance:
            'Mainichi Gallery, Tokyo Private Collection, Japan Ikon Ltd. Contemporary Art, Los Angeles Acquired from the above by the present owner',
          low: 18000,
          currency: 'USD',
          id: 'phillips.5.2018.NY010418.164.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010418/164',
          salesTitle:
            '20th century & contemporary art day sale morning session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 65000,
          timexcoord: '2018-05-01',
          month: 5,
          name: 'Looking at Silver Clouds',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            'acrylic spray paint on paperboard ^ Executed in 1978, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 10 3/4 x 9 1/2 in. (27.3 x 24.1 cm.) ^ Mainichi Gallery, Tokyo Private Collection, Japan Ikon Ltd. Contemporary Art, Los Angeles Acquired from the above by the present owner ^ 5',
          cid: 'NY010418.164',
        },
        fields: {
          ymonths: [220],
        },
        sort: [1525132800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.5.2018.NY010418.165.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1524689315/auctions/NY010418/165_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 600000,
          provenance:
            'Fuji Television Gallery, Tokyo Acquired from the above by the present owner',
          low: 400000,
          currency: 'USD',
          id: 'phillips.5.2018.NY010418.165.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010418/165',
          salesTitle:
            '20th century & contemporary art day sale morning session',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 423000,
          timexcoord: '2018-05-01',
          month: 5,
          name: 'Nebula',
          x: 91,
          y: 73,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 1990, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 35 7/8 x 28 3/4 in. (91.1 x 73 cm.) ^ Fuji Television Gallery, Tokyo Acquired from the above by the present owner ^ 5',
          cid: 'NY010418.165',
        },
        fields: {
          ymonths: [220],
        },
        sort: [1525132800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2018.yayoi-kusama.contemporary-art-day-auction-n09859-213',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09859/1083N09859_9T9XJ.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 80000,
          low: 60000,
          currency: 'USD',
          id:
            'sothebys.5.2018.yayoi-kusama.contemporary-art-day-auction-n09859-213',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-auction-n09859/lot.213.html',
          salesTitle: 'contemporary-art-day-auction-n09859',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(87500)',
          real: 87500,
          timexcoord: '2018-05-01',
          month: 5,
          name: 'DEATH AT NIGHT',
          x: 26,
          y: 19,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                DEATH AT NIGHTƎ                Ǝ                    Ǝ                        signed and dated 1952; stamped with the artist's name in Japanese, titled in English and in Japanese and dated 1952 in English and in Japanese on the reverse; signed, titled, and dated 1952 on the backing boardink and gouache on paper10 3/8  by 7 3/8  in.   26.4 by 18.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection, New York (acquired directly from the artist)Robert Miller Gallery, New YorkAcquired from the above by the present ownerƎ                    ",
          cid: 'contemporary-art-day-auction-n09859-213',
        },
        fields: {
          ymonths: [220],
        },
        sort: [1525132800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2018.yayoi-kusama.contemporary-art-day-auction-n09859-421',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09859/1055N09859_9SSCM_web2.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 1800000,
          low: 1200000,
          currency: 'USD',
          id:
            'sothebys.5.2018.yayoi-kusama.contemporary-art-day-auction-n09859-421',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-auction-n09859/lot.421.html',
          salesTitle: 'contemporary-art-day-auction-n09859',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(2535000)',
          real: 2535000,
          timexcoord: '2018-05-01',
          month: 5,
          name: 'NETS - OBSESSION [OPR]',
          x: 194,
          y: 259,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                NETS - OBSESSION [OPR]Ǝ                Ǝ                    Ǝ                        signed, titled and dated 2003 on the reverseacrylic on canvas76 3/8  by 102 in.   194 by 259 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Hiromi Yoshii Gallery, TokyoPrivate Collection, TokyoSotheby's, New York, 15 November 2006, Lot 225Acquired from the above sale by the present owner Ǝ                    ",
          cid: 'contemporary-art-day-auction-n09859-421',
        },
        fields: {
          ymonths: [220],
        },
        sort: [1525132800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.5.2018.yayoi-kusama.contemporary-art-day-auction-n09859-487',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09859/522N09859_9RTKM.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 180000,
          low: 120000,
          currency: 'USD',
          id:
            'sothebys.5.2018.yayoi-kusama.contemporary-art-day-auction-n09859-487',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-auction-n09859/lot.487.html',
          salesTitle: 'contemporary-art-day-auction-n09859',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(300000)',
          real: 300000,
          timexcoord: '2018-05-01',
          month: 5,
          name: 'ASA NO UMI NI HITARU (BATHING IN THE MORNING SEA)',
          x: 55,
          y: 39,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                ASA NO UMI NI HITARU (BATHING IN THE MORNING SEA)Ǝ                Ǝ                    Ǝ                        signed and dated 1975; signed, signed in Kanji and dated 1975 on the reverse watercolor and ink on paper21 1/2  by 15 1/2  in.   54.6 by 39.4 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection, TokyoSotheby's, New York, 11 October 2006, Lot 171Private Collection, Brussels (acquired from the above sale) Acquired from the above by the present owner Ǝ                    ",
          cid: 'contemporary-art-day-auction-n09859-487',
        },
        fields: {
          ymonths: [220],
        },
        sort: [1525132800000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2018.UK010518.163.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1531423435/auctions/UK010518/163_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance:
            'Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner',
          low: 15000,
          currency: 'GBP',
          id: 'phillips.6.2018.UK010518.163.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010518/163',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 71250,
          timexcoord: '2018-06-01',
          month: 6,
          name: 'Where to, Silver Clouds',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'enamel on paperboard ^ Executed in 1978, this work is accompanied by a registration card issued by the Yayoi Kusama studio and registered under no. 3425. ^ 27 x 24.2 cm (10 5/8 x 9 1/2 in.) ^ Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner ^ 6',
          cid: 'UK010518.163',
        },
        fields: {
          ymonths: [221],
        },
        sort: [1527811200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2018.UK010518.164.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1531423435/auctions/UK010518/164_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance:
            'Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner',
          low: 15000,
          currency: 'GBP',
          id: 'phillips.6.2018.UK010518.164.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010518/164',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 65625,
          timexcoord: '2018-06-01',
          month: 6,
          name: 'Rain in Broad Day Light',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'enamel and ink on paperboard ^ Executed in 1978, this work is accompanied by a registration card issued by the Yayoi Kusama studio and registered under no. 3424. ^ 27 x 24.3 cm (10 5/8 x 9 5/8 in.) ^ Robert Miller Gallery, New York Victoria Miro, London Acquired from the above by the present owner ^ 6',
          cid: 'UK010518.164',
        },
        fields: {
          ymonths: [221],
        },
        sort: [1527811200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.6.2018.yayoi-kusama.contemporary-art-day-auction-l18023-192',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 280000,
          low: 220000,
          currency: 'GBP',
          id:
            'sothebys.6.2018.yayoi-kusama.contemporary-art-day-auction-l18023-192',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-auction-l18023/lot.192.html',
          salesTitle: 'contemporary-art-day-auction-l18023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(274000)',
          real: 411000,
          timexcoord: '2018-06-01',
          month: 6,
          name: 'THE GALAXY',
          x: 117,
          y: 91,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                THE GALAXYƎ                Ǝ                    Ǝ                        signed, titled in Japanese and dated 1994 on the reverseacrylic on canvas116.5 by 91 cm.   45 7/8  by 35 3/4  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by a certificate of authenticity from the Yayoi Kusama Studio.ƎƎ                        ProvenanceƎ                        OTA Fine Arts, TokyoRoslyn Oxley9 Gallery, SydneyAcquired from the above by the present ownerƎ                    ExhibitedƎ                        Sydney, Roslyn Oxley9 Gallery, Yayoi Kusama, April - May 2002Ǝ                    ',
          cid: 'contemporary-art-day-auction-l18023-192',
        },
        fields: {
          ymonths: [221],
        },
        sort: [1527811200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.6.2018.yayoi-kusama.contemporary-art-day-auction-l18023-212',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 60000,
          low: 40000,
          currency: 'GBP',
          id:
            'sothebys.6.2018.yayoi-kusama.contemporary-art-day-auction-l18023-212',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-auction-l18023/lot.212.html',
          salesTitle: 'contemporary-art-day-auction-l18023',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(75000)',
          real: 112500,
          timexcoord: '2018-06-01',
          month: 6,
          name: 'UNTITLED',
          x: 27,
          y: 20,
          location: 'london',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                UNTITLEDƎ                Ǝ                    Ǝ                        signed and dated 1953; stamped with the artist's name in English and dated 1953 and stamped in Japanese on the reversegouache, ink and charcoal on paper27.3 by 20.3 cm.   10 3/4  by 8 in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by a certificated of authenticity from the Yayoi Kusama Studio.ƎƎ                        ProvenanceƎ                        Private Collection (a gift from the artist)Bonhams, New York, 12 November 2012, Lot 106Acquired from the above by the present ownerƎ                    ",
          cid: 'contemporary-art-day-auction-l18023-212',
        },
        fields: {
          ymonths: [221],
        },
        sort: [1527811200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.6.2018.yayoi-kusama.art-contemporain-evening-sale-pf1805-1',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 500000,
          low: 300000,
          currency: 'EUR',
          id:
            'sothebys.6.2018.yayoi-kusama.art-contemporain-evening-sale-pf1805-1',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/art-contemporain-evening-sale-pf1805/lot.1.html',
          salesTitle: 'art-contemporain-evening-sale-pf1805',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(597000)',
          real: 716400,
          timexcoord: '2018-06-01',
          month: 6,
          name: 'PUMPKIN',
          x: 38,
          y: 46,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed, titled and dated 1998 on the reverse acrylic on canvas38 x 45,5 cm; 15 x 17  7/8  in.Executed in 1998.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio.ƎƎ                        ProvenanceƎ                        Barbara Mathes Gallery, New YorkPrivate Collection, EuropeƎ                    ",
          cid: 'art-contemporain-evening-sale-pf1805-1',
        },
        fields: {
          ymonths: [221],
        },
        sort: [1527811200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.6.2018.UK010418.12.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1528477592/auctions/UK010418/12_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 2000000,
          provenance:
            'Ota Fine Arts, Tokyo Victoria Miro, London Private Collection, Europe (acquired from the above) Phillips, New York, 8 May 2016, lot 24 Acquired at the above sale by the present owner',
          low: 1500000,
          currency: 'GBP',
          id: 'phillips.6.2018.UK010418.12.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010418/12',
          salesTitle: '20th century & contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 2533500,
          timexcoord: '2018-06-01',
          month: 6,
          name: 'INFINITY-NETS (QRTWE)',
          x: 194,
          y: 259,
          location: 'london',
          stuff:
            'acrylic on canvas ^ Painted in 2007, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 194 x 259 cm (76 3/8 x 101 7/8 in.) ^ Ota Fine Arts, Tokyo Victoria Miro, London Private Collection, Europe (acquired from the above) Phillips, New York, 8 May 2016, lot 24 Acquired at the above sale by the present owner ^ 6',
          cid: 'UK010418.12',
        },
        fields: {
          ymonths: [221],
        },
        sort: [1527811200000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2018.NY010618.38.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1535490654/auctions/NY010618/38_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 250000,
          provenance:
            'Ota Fine Arts, Tokyo Acquired from the above by the present owner',
          low: 180000,
          currency: 'USD',
          id: 'phillips.9.2018.NY010618.38.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010618/38',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 375000,
          timexcoord: '2018-09-01',
          month: 9,
          name: 'Butterfly',
          x: 38,
          y: 46,
          location: 'new york',
          stuff:
            'acrylic on canvas ^ Painted in 1982, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 14 7/8 x 17 7/8 in. (38 x 45.5 cm.) ^ Ota Fine Arts, Tokyo Acquired from the above by the present owner ^ 9',
          cid: 'NY010618.38',
        },
        fields: {
          ymonths: [224],
        },
        sort: [1535760000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2018.NY010618.39.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1535490653/auctions/NY010618/39_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance: 'Private Collection',
          low: 15000,
          currency: 'USD',
          id: 'phillips.9.2018.NY010618.39.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010618/39',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 22500,
          timexcoord: '2018-09-01',
          month: 9,
          name: 'Wave',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            'enamel on paperboard ^ Executed in 1980, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 10 1/2 x 9 1/2 in. (26.7 x 24.1 cm.) ^ Private Collection ^ 9',
          cid: 'NY010618.39',
        },
        fields: {
          ymonths: [224],
        },
        sort: [1535760000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2018.NY010618.40.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1535490655/auctions/NY010618/40_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 10000,
          provenance:
            'Ota Fine Arts, Tokyo Acquired from the above by the present owner',
          low: 7000,
          currency: 'USD',
          id: 'phillips.9.2018.NY010618.40.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010618/40',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 15000,
          timexcoord: '2018-09-01',
          month: 9,
          name: 'Butterfly',
          x: 33,
          y: 40,
          location: 'new york',
          stuff:
            "screenprint ^ Executed in 1982, this work is a printer's proof from an edition of 50 plus 5 artist's proofs and 3 printer's proofs.  This work has been authenticated by the Yayoi Kusama studio. ^ 13 x 15 3/4 in. (33 x 40 cm.) ^ Ota Fine Arts, Tokyo Acquired from the above by the present owner ^ 9",
          cid: 'NY010618.40',
        },
        fields: {
          ymonths: [224],
        },
        sort: [1535760000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.9.2018.NY010618.41.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1535490653/auctions/NY010618/41_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 25000,
          provenance:
            'Greenberg Van Doren Gallery, New York Acquired from the above by the present owner in 2006',
          low: 18000,
          currency: 'USD',
          id: 'phillips.9.2018.NY010618.41.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/NY010618/41',
          salesTitle: 'new now',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 32500,
          timexcoord: '2018-09-01',
          month: 9,
          name: 'Village in Rainy Season',
          x: 27,
          y: 24,
          location: 'new york',
          stuff:
            'ink and enamel on paperboard ^ Executed in 1978, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 10 1/2 x 9 1/2 in. (26.7 x 24.1 cm.) ^ Greenberg Van Doren Gallery, New York Acquired from the above by the present owner in 2006 ^ 9',
          cid: 'NY010618.41',
        },
        fields: {
          ymonths: [224],
        },
        sort: [1535760000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2018.yayoi-kusama.contemporary-art-day-auction-l18025-107',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 250000,
          low: 180000,
          currency: 'GBP',
          id:
            'sothebys.10.2018.yayoi-kusama.contemporary-art-day-auction-l18025-107',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-auction-l18025/lot.107.html',
          salesTitle: 'contemporary-art-day-auction-l18025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(250000)',
          real: 375000,
          timexcoord: '2018-10-01',
          month: 10,
          name: 'NETS 8',
          x: 61,
          y: 73,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                NETS 8Ǝ                Ǝ                    Ǝ                        signed, titled and dated 1997 on the reverseacrylic on canvas60.6 by 72.5 cm.   23 7/8  by 28 1/2  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by a certificate of authenticity from the Yayoi Kusama Studio.ƎƎ                        ProvenanceƎ                        Robert Miller Gallery, New YorkAcquired from the above by the present owner in 2007Ǝ                    ',
          cid: 'contemporary-art-day-auction-l18025-107',
        },
        fields: {
          ymonths: [225],
        },
        sort: [1538352000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.10.2018.yayoi-kusama.contemporary-art-day-auction-l18025-145',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/default/underCopyright.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 100000,
          low: 70000,
          currency: 'GBP',
          id:
            'sothebys.10.2018.yayoi-kusama.contemporary-art-day-auction-l18025-145',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-auction-l18025/lot.145.html',
          salesTitle: 'contemporary-art-day-auction-l18025',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(100000)',
          real: 150000,
          timexcoord: '2018-10-01',
          month: 10,
          name: 'DOTS',
          x: 28,
          y: 22,
          location: 'london',
          stuff:
            'Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                DOTSƎ                Ǝ                    Ǝ                        signed, titled and dated 1998 on the reverseacrylic on canvas27.5 by 22.2 cm.   10 7/8  by 8 3/4  in.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by a certificate of authenticity from the Yayoi Kusama Studio.ƎƎ                        ProvenanceƎ                        Galerie Piece Unique, ParisAcquired from the above by the present ownerƎ                    ',
          cid: 'contemporary-art-day-auction-l18025-145',
        },
        fields: {
          ymonths: [225],
        },
        sort: [1538352000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2018.UK010718.233.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1537290666/auctions/UK010718/233_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance:
            'The Market Auction Inc., Tokyo, 22 April 2010, lot 52 Acquired at the above sale by the present owner',
          low: 15000,
          currency: 'GBP',
          id: 'phillips.10.2018.UK010718.233.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010718/233',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 45000,
          timexcoord: '2018-10-01',
          month: 10,
          name: 'Cloud',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'enamel and ink on paperboard ^ Executed in 1980, this work is accompanied by a registration card issued by the Yayoi Kusama studio and registered under no. 3469. ^ 27 x 24 cm (10 5/8 x 9 1/2 in.) ^ The Market Auction Inc., Tokyo, 22 April 2010, lot 52 Acquired at the above sale by the present owner ^ 10',
          cid: 'UK010718.233',
        },
        fields: {
          ymonths: [225],
        },
        sort: [1538352000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2018.UK010718.234.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1538151079/auctions/UK010718/234_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 20000,
          provenance:
            'Jason Rulnick Inc., New York Acquired from the above by the present owner',
          low: 15000,
          currency: 'GBP',
          id: 'phillips.10.2018.UK010718.234.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010718/234',
          salesTitle: '20th century & contemporary art day sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 48750,
          timexcoord: '2018-10-01',
          month: 10,
          name: 'Early Spring',
          x: 27,
          y: 24,
          location: 'london',
          stuff:
            'enamel and ink on paperboard ^ Executed in 1983, this work is accompanied by a registration card issued by the Yayoi Kusama studio and registered under no. 1255. ^ 27.3 x 24.2 cm (10 3/4 x 9 1/2 in.) ^ Jason Rulnick Inc., New York Acquired from the above by the present owner ^ 10',
          cid: 'UK010718.234',
        },
        fields: {
          ymonths: [225],
        },
        sort: [1538352000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id: 'phillips.10.2018.UK010618.27.yayoi-kusama',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            'https://assets.phillips.com/image/upload/t_Website_LotDetailMainImage/v1537307533/auctions/UK010618/27_001.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'phillips',
          lot: 0,
          high: 600000,
          provenance:
            "Private Collection Sotheby's, New York, 6 October 2005, lot 109 Acquired at the above sale by the present owner",
          low: 400000,
          currency: 'GBP',
          id: 'phillips.10.2018.UK010618.27.yayoi-kusama',
          loturl: 'https://www.phillips.com/detail/YAYOI-KUSAMA/UK010618/27',
          salesTitle: '20th century & contemporary art evening sale',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          real: 697500,
          timexcoord: '2018-10-01',
          month: 10,
          name: 'Infinity Nets (APQ)',
          x: 100,
          y: 81,
          location: 'london',
          stuff:
            "acrylic on canvas ^ Painted in 1999, this work is accompanied by a registration card issued by the Yayoi Kusama studio. ^ 100 x 80.5 cm (39 3/8 x 31 3/4 in.) ^ Private Collection Sotheby's, New York, 6 October 2005, lot 109 Acquired at the above sale by the present owner ^ 10",
          cid: 'UK010618.27',
        },
        fields: {
          ymonths: [225],
        },
        sort: [1538352000000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2018.yayoi-kusama.contemporary-art-day-sale-n09933-437',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09933/605N09933_9Z937.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 450000,
          low: 350000,
          currency: 'USD',
          id:
            'sothebys.11.2018.yayoi-kusama.contemporary-art-day-sale-n09933-437',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-sale-n09933/lot.437.html',
          salesTitle: 'contemporary-art-day-sale-n09933',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(999000)',
          real: 999000,
          timexcoord: '2018-11-01',
          month: 11,
          name: 'PUMPKIN',
          x: 38,
          y: 46,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                PUMPKINƎ                Ǝ                    Ǝ                        signed, titled in Kanji and dated 1990 on the reverseacrylic on canvas15 by 18 in.   38.1 by 45.7 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Private Collection, KawasakiSotheby's, New York, 15 March 2006, Lot 192Acquired from the above sale by the present ownerƎ                    ",
          cid: 'contemporary-art-day-sale-n09933-437',
        },
        fields: {
          ymonths: [226],
        },
        sort: [1541030400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.11.2018.yayoi-kusama.contemporary-art-day-sale-n09933-516',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/N09/N09933/600N09933_9YXNJ.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 200000,
          low: 150000,
          currency: 'USD',
          id:
            'sothebys.11.2018.yayoi-kusama.contemporary-art-day-sale-n09933-516',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/contemporary-art-day-sale-n09933/lot.516.html',
          salesTitle: 'contemporary-art-day-sale-n09933',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(187500)',
          real: 187500,
          timexcoord: '2018-11-01',
          month: 11,
          name: 'FLAME 2',
          x: 53,
          y: 46,
          location: 'new york',
          stuff:
            "Ǝ                Yayoi KusamaƎ                B. 1929Ǝ                FLAME 2Ǝ                Ǝ                    Ǝ                        signed, titled in Kanji and dated 1990 on the reverseacrylic on canvas21 by 18 1/8  in.   53.3 by 46 cm.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                ProvenanceƎ                        Gallery Te, TokyoPrivate Collection (acquired from the above)Sotheby's SƎ2, Hong Kong, Yayoi Kusama - Hong Kong Blooms in My Mind, May 2012Acquired from the above sale by the present ownerƎ                    ExhibitedƎ                        Hong Kong, Sotheby's SƎ2, Yayoi Kusama - Hong Kong Blooms in My Mind, May 2012Ǝ                    ",
          cid: 'contemporary-art-day-sale-n09933-516',
        },
        fields: {
          ymonths: [226],
        },
        sort: [1541030400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.12.2018.yayoi-kusama.art-contemporain-eve-sale-pf1815-15',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/PF1/PF1815/154PF1815_9ZT6M_1.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 100000,
          low: 70000,
          currency: 'EUR',
          id:
            'sothebys.12.2018.yayoi-kusama.art-contemporain-eve-sale-pf1815-15',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/art-contemporain-eve-sale-pf1815/lot.15.html',
          salesTitle: 'art-contemporain-eve-sale-pf1815',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(87500)',
          real: 105000,
          timexcoord: '2018-12-01',
          month: 12,
          name: 'DOTS A. CB',
          x: 27,
          y: 22,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                DOTS A. CBƎ                Ǝ                    Ǝ                        signed, titled and dated 2001 on the reverseacrylic on canvas27,3 x 22 cm; 10  3/4  x 8  11/16  in.Executed in 2001.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio.ƎƎ                        ProvenanceƎ                        Private Collection, FranceƎ                    ",
          cid: 'art-contemporain-eve-sale-pf1815-15',
        },
        fields: {
          ymonths: [227],
        },
        sort: [1543622400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.12.2018.yayoi-kusama.art-contemporain-eve-sale-pf1815-16',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img: '/content/dam/stb/lots/PF1/PF1815/152PF1815_9ZSRY_1.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 100000,
          low: 70000,
          currency: 'EUR',
          id:
            'sothebys.12.2018.yayoi-kusama.art-contemporain-eve-sale-pf1815-16',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/art-contemporain-eve-sale-pf1815/lot.16.html',
          salesTitle: 'art-contemporain-eve-sale-pf1815',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(106250)',
          real: 127500,
          timexcoord: '2018-12-01',
          month: 12,
          name: 'KUSAMA, DOTS [BBC], ACRYLIC ON CANVAS, 2001',
          x: 27,
          y: 22,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N. 1929Ǝ                KUSAMA, DOTS [BBC], ACRYLIC ON CANVAS, 2001Ǝ                Ǝ                    Ǝ                        signed, titled and dated 2001 on the reverseacrylic on canvas27,3 x 22 cm; 10  3/4  x 8  11/16  in.Executed in 2001.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio.ƎƎ                        ProvenanceƎ                        Private Collection, FranceƎ                    ",
          cid: 'art-contemporain-eve-sale-pf1815-16',
        },
        fields: {
          ymonths: [227],
        },
        sort: [1543622400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.12.2018.yayoi-kusama.art-contemporain-day-sale-pf1835-140',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            '/content/dam/stb/lots/PF1/PF1835/233PF1835_9ZT6K_1_Uncropped.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'EUR',
          id:
            'sothebys.12.2018.yayoi-kusama.art-contemporain-day-sale-pf1835-140',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/art-contemporain-day-sale-pf1835/lot.140.html',
          salesTitle: 'art-contemporain-day-sale-pf1835',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(21250)',
          real: 25500,
          timexcoord: '2018-12-01',
          month: 12,
          name: 'SEA',
          x: 24,
          y: 27,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N.1929Ǝ                SEAƎ                Ǝ                    Ǝ                        signed and dated 1991 on the reversemixed media on cardboard24 x 27 cm; 9  1/2  x 10  5/8  in.Executed in 1991.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio.ƎƎ                        ProvenanceƎ                        Galerie Piece Unique, ParisAcquired from the above by the present ownerƎ                    ",
          cid: 'art-contemporain-day-sale-pf1835-140',
        },
        fields: {
          ymonths: [227],
        },
        sort: [1543622400000],
      },
      {
        _index: 'artists_and_products_wed_feb_13_2019',
        _type: '_doc',
        _id:
          'sothebys.12.2018.yayoi-kusama.art-contemporain-day-sale-pf1835-139',
        _score: null,
        _routing: 'yayoi-kusama',
        _source: {
          artistDisplayName: 'yayoi-kusama',
          img:
            '/content/dam/stb/lots/PF1/PF1835/230PF1835_9Z6TN_1_Uncropped.jpg',
          year: 2018,
          artist: 'yayoi-kusama',
          unsold: false,
          auctionHouse: 'sothebys',
          lot: 0,
          high: 15000,
          low: 10000,
          currency: 'EUR',
          id:
            'sothebys.12.2018.yayoi-kusama.art-contemporain-day-sale-pf1835-139',
          loturl:
            'https://www.sothebys.com/en/auctions/ecatalogue/2018/art-contemporain-day-sale-pf1835/lot.139.html',
          salesTitle: 'art-contemporain-day-sale-pf1835',
          crecre: {
            parent: 'yayoi-kusama',
            name: 'creation',
          },
          birth: 0,
          realized: 'Some(25000)',
          real: 30000,
          timexcoord: '2018-12-01',
          month: 12,
          name: 'RAIN ON THE SEA',
          x: 24,
          y: 27,
          location: 'paris',
          stuff:
            "Ǝ                Yayoi KusamaƎ                N.1929Ǝ                RAIN ON THE SEAƎ                Ǝ                    Ǝ                        signed and dated 1991 on the reversemixed media on cardboard24 x 27 cm; 9  1/2  x 10  5/8  in.Executed in 1991.Ǝ                    Ǝ                        Read Condition ReportƎ                        Read Condition ReportƎ                                Register or Log-in to view condition reportƎ                                Ǝ                                OrƎ                                Ǝ                          Ǝ                        Ǝ                    Ǝ                        Saleroom NoticeƎ                    Ǝ                Ǝ                Ǝ                         This work is accompanied by an artwork registration card issued by the artist's studio.ƎƎ                        ProvenanceƎ                        Galerie Pièce Unique, ParisAcquired from the above by the present ownerƎ                    ",
          cid: 'art-contemporain-day-sale-pf1835-139',
        },
        fields: {
          ymonths: [227],
        },
        sort: [1543622400000],
      },
    ],
  },
}

export { YAYOI }
