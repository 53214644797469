import React, { Component } from 'react'
import { Arthistog } from './arthistog'
import { Isalivecheck } from './is-alive-check'
import { PrintsOnlyCheck } from './prints-only-check'
import { PriceLimit } from './price-limit'

import { MetricControl } from './metric-control'
import { MetricBar } from './metric-bar'
import { Yearbrush } from './yearbrush'
import { TimeBrush } from './timebrush'

import {
  setAliveOnly,
  setPrintsOnly,
  fetchArtists,
  setPriceLimit,
  setInterval,
  setOneArtist,
} from '../../state/actions'

import { LAYOUT_WIDTH, LAYOUT_HEIGHT } from './layout-constants'

const width = LAYOUT_WIDTH 
const height = LAYOUT_HEIGHT 

class ControllableArthistog extends Component {
  constructor() {
    super()
    this.state = {
      realWidth: window.innerWidth,
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
    this.props.dispatch(fetchArtists(this.props.state))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  // udpdate dimenssion is done at the controllable arthistog level, and the dimesion as passed down as props down to the component tree
  // as needed
  updateDimensions() {
      this.setState({ realWidth: window.innerWidth }) 
  }

  render() {

    const refetchArtists = () =>
      this.props.dispatch(fetchArtists(this.props.state))

    const dispatchIsAliveCheckbox = checked =>
      this.props.dispatch(setAliveOnly(checked))

    const dispatchPrintsOnlyCheckbox = checked =>
      this.props.dispatch(setPrintsOnly(checked))

    const dispatchPriceLimit = pricelim => {
      let intlimit = parseInt(pricelim, 10)
      if (intlimit) {
        this.props.dispatch(setPriceLimit(intlimit))
      } else {
        this.props.dispatch(setPriceLimit('none'))
      }
    }

    const dispatchInterval = interval => {
      this.props.dispatch(setInterval(interval))
    }

    const dispatchOneArtist = artistProfile => {
      this.props.dispatch(setOneArtist(artistProfile))
    }

    return (
      <div id="container">
        <div className="Controllable-Arthistog">
          <form>
            <div className="row mb-4 mt-4">
              <div className="col">
                <MetricBar
                  dispatch={this.props.dispatch}
                  checked={this.props.state.metric}
                />
              </div>
              <div className="col">
                <PriceLimit
                  dispatch={dispatchPriceLimit}
                  priceLimit={this.props.state.priceLimit}
                  reactTo={refetchArtists}
                />
              </div>
              <div className="col">
                
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Isalivecheck
                  checked={this.props.state.aliveOnly}
                  dispatch={dispatchIsAliveCheckbox}
                  reactTo={refetchArtists}
                />
              </div>
              <div className="col">
                <PrintsOnlyCheck
                  checked={this.props.state.printsOnly}
                  dispatch={dispatchPrintsOnlyCheckbox}
                  reactTo={refetchArtists}
                />
              </div>
            </div>
            <Yearbrush
              className="mb-4"
              dispatch={dispatchInterval}
              interval={this.props.state.interval}
              reactTo={refetchArtists}
              width={this.state.realWidth}
              height={height}
            />
            {/* <TimeBrush
              className="mb-4"
              dispatch={dispatchInterval}
              interval={this.props.state.interval}
              reactTo={refetchArtists}
            /> */}
          </form>

          <Arthistog
            dispatch={dispatchOneArtist}
            dataset={this.props.state.dataset}
            metric={this.props.state.metric}
            width={this.state.realWidth}
            height={height}
          />
        </div>
      </div>
    )
  }
}

export { ControllableArthistog }
