import React, { Component } from 'react'
import { Checkbox } from './checkbox'
import './checkbox.css'

class Isalivecheck extends Component {

  // This is the semantic action of the checkbox, first the redux transition is done, the next redux transution  that cause the netowrk calls
  // As we manage the network call state in the main store
  componentDidUpdate(prevProps, prevState) {
    let isCheckedBefore = prevProps.checked
    let isCheckedNow = this.props.checked
    if (isCheckedBefore !== isCheckedNow) {
      this.props.reactTo() // network call, we chain state transitions without user input
    }
  }

  render() {
    return <div id="isalivecheckbox" className="Small-filter">
      <Checkbox checked={this.props.checked} dispatch={this.props.dispatch} lab={"is alive"} />
    </div>
  }
}


export { Isalivecheck }