/**
 * Created by alaindemour on 1/21/17.
 */

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './main.css'
import './index.css'
import { Provider } from 'react-redux'
import { store } from './state/store'
import { BrowserRouter } from 'react-router-dom'


ReactDOM.render(
  <div>
    <BrowserRouter>
      <Provider store={store}>
        <App/>
      </Provider>
    </BrowserRouter>
  </div>,
  document.getElementById('reactRootMountingPoint'))
