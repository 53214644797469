// import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ControllableOneArtist } from './controllable-one-artist'

// Here is the Glue between Redux and React
// This will be called every time the store is updated
// This version if brute force we pass the whole state
const mapStateToProps = state => {
  return {
    state: state.oneArtist,
  }
}

// Connection between Redux and React here.
// connect "injects" all or some "stuff" from the store into the props of the components
// Here apparently we are doing something inefficient as we are injecting the dispatch and all the properies of the state, TBD what to do.
const OneArtistContainer = connect(
  mapStateToProps,
  // mapDispatchToProps, // not needed, the default dispatch is passed in by connect
)(ControllableOneArtist)

export { OneArtistContainer }
