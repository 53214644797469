// The code below uses a change of coordinate for the regression lines
// if a line is defined by y = ax + b and we do a translation x' = x - k and y' = y -m that is  a moving the origin up and to the right
// the transformed line is defined by the equation  y' = (ax' + b) + (ak - m)
// proof:
// y = ax + b
// => = (y' + m) = a(x'+ k) + b  (substitution)
// => y' = ax' + ak + b - m
// => y' = (ax' + b) + (ak - m)

// All regressions are computed using a number of months as x  the unit, with the t0 at Jan 1 2000 and dollars as y units

import * as d3 from 'd3'
import React, { Component } from 'react'
import './oneartist.css'
import { DateTime } from 'luxon'
import SimpleLinearRegression from 'ml-regression-simple-linear'
import legend from 'd3-svg-legend'

const legendColor = legend.legendColor

const margin = { top: 40, right: 20, bottom: 20, left: 30 }

class OneArtistScatterplot extends Component {
  render() {
    let name = this.props.name
    this.displayName = name.replace(/-/, ' ')

    return (
      <svg
        id="artistplot"
        height={this.props.height}
        width={this.props.width}
        ref={membrane => {
          this.membrane = membrane
          if (membrane) {
            this.renderOutsideOfReact(membrane)
          }
        }}
      >
        <g transform={`translate( ${margin.left} , ${margin.top} )`} />
      </svg>
    )
  }

  renderOutsideOfReact(membrane) {

    // var width = membrane.parentNode.getBoundingClientRect().width;
    let parentWidth = membrane.parentNode.getBoundingClientRect().width
    let dataset = this.props.dataset
    let width = parentWidth * 0.8
    let height = this.props.height
    let mindotsize =  width <= 576 ? 3: 7
    let maxdotsize = width <= 576 ? 7: 15

    let data = dataset.hits.hits.map(curr => curr._source)
    let bulk = lot => {
      let zdim = lot.z ? lot.z : 1
      let ydim = lot.y ? lot.y : 0
      let xdim = lot.x ? lot.x : 0
      let bulk = xdim * ydim * zdim // should be either zero, one or int greater than 1
      return bulk
    }

    // constants needed for rendering
    const conventionalDatesOriginInLuxon = DateTime.utc(2000, 1, 1) // luxon DateTime object
    const datesInterval = d3.extent(
      data,
      d => new Date(Date.UTC(d.year, d.month)),
    )
    const startDateInLuxon = DateTime.fromJSDate(datesInterval[0])
    const endDateInLuxon = DateTime.fromJSDate(datesInterval[1])
    const interceptDelta = startDateInLuxon.diff(
      conventionalDatesOriginInLuxon,
      'months',
    ).values.months // moving the origin

    // // stats in the state coming in from Redux
    // // When accessing the URL /<artistsname> directly the part of the state containing various stats for the artist will be null/missing
    // // and will have to be computed locally from the data
    // const genericTrend = this.props.state.trend
    // const bottomTrend = this.props.state.bottomTrend
    // const sumX = this.props.state.sumX
    // const sumY = this.props.state.totalValue
    // const N = this.props.state.cardinality
    // const interceptInMonths = (sumY - genericTrend * sumX) / N

    // Local generic regression
    const xcoordInMonths = lot => {
      let d = DateTime.utc(lot.year, lot.month)
      return d.diff(conventionalDatesOriginInLuxon, 'months').values.months
    }
    const timeAsX = data.map(lot => xcoordInMonths(lot)).sort((a, b) => a - b)

    const dollarAsY = data.map(lot => {
      return lot.real
    })
    const regr = new SimpleLinearRegression(timeAsX, dollarAsY)
    const slope = regr.slope
    const intercept = regr.intercept

    // Local Min regression

    // First we only keep in the data set to minimum price (record low) on each month
    let hashOfMins = data.reduce((accu, currlot) => {
      let x = xcoordInMonths(currlot)
      let candidatemin = accu.get(x) ? accu.get(x).real : null
      let price = currlot.real
      if (candidatemin) {
        if (price < candidatemin) {
          accu.set(x, currlot)
        }
      } else {
        accu.set(x, currlot)
      }
      return accu
    }, new Map()) // intial accumulator

    // This is just the list of months where we we had at least one lot sold, can be used fro mins and maxs
    let xTimesWithDataIter = hashOfMins.keys()
    let xTimesWithData = [...xTimesWithDataIter]

    let minsDollarAsY = xTimesWithData.map(key =>
      Math.floor(hashOfMins.get(key).real),
    ) // because of quirk of elasticsearch which ingested some of my integer as floats

    const minsRegr = new SimpleLinearRegression(xTimesWithData, minsDollarAsY)
    const minsSlope = minsRegr.slope
    const minsIntercept = minsRegr.intercept
    let sommeMinsX = xTimesWithData.reduce((accu, curr) => accu + curr)
    let sommeMinsY = minsDollarAsY.reduce(
      (accu, curr) => accu + Math.floor(curr),
    )
    let minsN = xTimesWithData.length
    const minsInterceptInMonths = (sommeMinsY - minsSlope * sommeMinsX) / minsN

    // Local Max Regression
    let hashOfMaxs = data.reduce((accu, currlot) => {
      let x = xcoordInMonths(currlot)
      let candidatemax = accu.get(x) ? accu.get(x).real : null
      let price = currlot.real
      if (candidatemax) {
        if (price > candidatemax) {
          accu.set(x, currlot)
        }
      } else {
        accu.set(x, currlot)
      }
      return accu
    }, new Map()) // intial accumulator

    let maxsDollarAsY = xTimesWithData.map(key =>
      Math.floor(hashOfMaxs.get(key).real),
    ) // because of quirk of elasticsearch which ingested some of my integer as floats

    const maxsRegr = new SimpleLinearRegression(xTimesWithData, maxsDollarAsY)
    const maxsSlope = maxsRegr.slope
    const maxsIntercept = maxsRegr.intercept
    let sommeMaxsX = xTimesWithData.reduce((accu, curr) => accu + curr)
    let sommeMaxsY = minsDollarAsY.reduce(
      (accu, curr) => accu + Math.floor(curr),
    )
    let maxsN = xTimesWithData.length
    const maxsInterceptInMonths = (sommeMaxsY - maxsSlope * sommeMaxsX) / maxsN

    // computing a scale for the dot size based on on surface or volume of the works
    // we are not using d3.extent as we want to add the bulk metric to the lot as we are iterating
    // just to do it in one pass
    let bulkmax = data.reduce((accu, lot) => {
      let b = bulk(lot)
      lot.bulk = b
      return b > accu ? b : accu
    }, bulk(data[0]))

    // Creating all the scales
    const xScaleInJSDates = d3
      .scaleTime()
      .range([0, width])
      .nice()
    xScaleInJSDates.domain(datesInterval)

    const newInterceptInMonths = slope * interceptDelta + intercept // y' = (ax' + b) + (ak - m)
    const newMinsInterceptInMonths =
      minsSlope * interceptDelta + minsInterceptInMonths // y' = (ax' + b) + (ak - m)
    const newMaxsInterceptInMonths =
      maxsSlope * interceptDelta + maxsInterceptInMonths // y' = (ax' + b) + (ak - m)
    const durationFromAbsoluteDatesOrigin = endDateInLuxon.diff(
      conventionalDatesOriginInLuxon,
      'months',
    ) // luxon duration
    const endXCoordinateInMonths = durationFromAbsoluteDatesOrigin.months // number of months since Jan 1 2000

    //  0 0 coordinate top left corner, so the scale in inversed it goes from height (minimum value of y) to zero (maximum value for y  )
    const yScaleInDollars = d3
      .scaleLinear()
      .range([height, 0])
      .nice()
    const priceInterval = d3.extent(data, d => d.real)
    yScaleInDollars.domain(priceInterval)

    const z = d3.scaleLinear().domain([0, bulkmax])
    z.range([mindotsize, maxdotsize])

    // Scale the range of the data

    let tooltip = d3.select(membrane).select('.tooltip')
    // attaching d3 select to the svg node

    // setup fill color
    // color is a scaleOrdinal d3 has a default behavior that if the domain is not specified it will construct it at lookup time
    // so in this case color will be assigned to auction houses a bit radomly as they are looked up.
    const cValue = d => d.auctionHouse
    const color = d3.scaleOrdinal(d3.schemeCategory10)

    let svg = d3.select(membrane)

    // clearing the chart when are rendering over an old one
    // svg.remove()
    let theoldG = svg.select('g')
    theoldG.remove()

    let theG = svg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left})`)

    let circles = theG
      .selectAll('.anyGabargeClassNameWillWorkHere') // empty selection BUT since selectAll does not collapse the tree  it maintain the parent relationship, so that when filled later teh nodes are children of g
      .data(data) // the update selection will be empty , everything is in the enter selection
      .enter() // get the enter selection, a bunch of placehoder so called "EnterNodes" in d3 each with a datapoints in it
      .append('circle')
      .attr('class', 'datapoint') // CSS will be applied to .datapoint
      .attr('r', d => {
        let rad = z(d.bulk)
        return rad
      })
      .attr('cx', d => xScaleInJSDates(new Date(d.year, d.month)))
      .attr('cy', d => yScaleInDollars(d.real))
      .style('fill', d => {
        return color(cValue(d))
      })
      .on('mouseover', d => {
        tooltip
          .transition()
          .duration(200)
          .style('opacity', 0.9)
        tooltip
          .html(this.presentationOfLotOnMouseOver(d))
          .style('left', d3.event.pageX + 'px')
          .style('top', d3.event.pageY - 28 + 'px')
      })
      .on('mouseout', function(d) {
        tooltip
          .transition()
          .duration(500)
          .style('opacity', 0)
      })
      // .on('click', function(d) {
      //   window.open(d.img)
      // })

    // Tracing the line for the main regression
    let xorigin = xScaleInJSDates(startDateInLuxon.toJSDate()) // result should be zero, this just a kind of assert
    let yorigin = yScaleInDollars(slope * 0 + newInterceptInMonths)
    let xend = xScaleInJSDates(endDateInLuxon.toJSDate())
    let yEndInMonthDollars =
      slope * endXCoordinateInMonths + newInterceptInMonths
    let yend = yScaleInDollars(yEndInMonthDollars)

    
    //Tracing the line for the bottom regression
    let minsXOrigin = xScaleInJSDates(startDateInLuxon.toJSDate()) // result should be zero, this just a kind of assert
    let minsYOrigin = yScaleInDollars(minsSlope * 0 + newMinsInterceptInMonths)
    let minsYEndInMonthsDollars =
      minsSlope * endXCoordinateInMonths + newMinsInterceptInMonths
    let minsYend = yScaleInDollars(minsYEndInMonthsDollars)

    

    //Tracing the line for the top (maxs) regression
    let maxsXOrigin = xScaleInJSDates(startDateInLuxon.toJSDate()) // result should be zero, this just a kind of assert
    let maxsYOrigin = yScaleInDollars(maxsSlope * 0 + newMaxsInterceptInMonths)
    let maxsYEndInMonthsDollars =
      maxsSlope * endXCoordinateInMonths + newMaxsInterceptInMonths
    let maxsYend = yScaleInDollars(maxsYEndInMonthsDollars)

    // let maxsRegression = theG
    //   .append('line')
    //   .attr('class', 'trendline')
    //   .attr('x1', maxsXOrigin)
    //   .attr('y1', maxsYOrigin)
    //   .attr('x2', xend)
    //   .attr('y2', maxsYend)
    //   .attr('stroke', 'red')
    //   .attr('stroke-width', 1)
    //   .on('mouseover', d => {
    //     tooltip
    //       .transition()
    //       .duration(200)
    //       .style('opacity', 0.9)
    //     tooltip
    //       .html(
    //         `mins a: ${Math.floor(maxsSlope)} mins b : ${Math.floor(
    //           maxsInterceptInMonths,
    //         )}`,
    //       )
    //       .style('left', `${d3.event.pageX}px`)
    //       .style('top', `${d3.event.pageY}px`)
    //   })
    //   .on('mouseout', d => {
    //     tooltip
    //       .transition()
    //       .duration(500)
    //       .style('opacity', 0)
    //   })

    // create axis objects
    let xAxis = d3
      .axisBottom(xScaleInJSDates)
      .tickSize(-height)
      .tickPadding(10) // white space above the label
      .tickFormat(d3.timeFormat('%y'))

    let yAxis = d3
      .axisLeft(yScaleInDollars)
      .tickFormat(d3.format('.2s')) // SI units i.e. M, K,etc.
      .ticks(5)
      .tickSize(-width)

    // Add the X Axis
    // Axis by default are drawn from the origin which is upper left, this is bringing hte x axis to the bottom
    let gX = theG
      .append('g')
      .attr("class", "axis")
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)

    // Add the Y Axis
    let gY = theG.append('g')
    .attr("class", "axis")
      .call(yAxis)

    // draw legend

    theG
      .append('g')
      .attr('class', 'legend')
      .attr('transform', 'translate(20,20)')

    const legend = legendColor()
      .title(this.displayName)
      .titleWidth(200)
      .scale(color)

    svg.select('.legend')
      .call(legend)

    const zoom = d3.zoom().on('zoom', zoomed)
    // preventing the zoom to go smaller as we start with all the data display on the scatterplot
    zoom.scaleExtent([1, Infinity])
    // var panExtent = [[Infinity,Infinity],[Infinity,Infinity]]
    // zoom.translateExtent(panExtent)

    function rescaleYandPanToPreserveOrigin(y, transform) {
      let neoRange = yScaleInDollars.range().map(transform.invertY, transform)
      let neoDomain = neoRange.map(yScaleInDollars.invert, y)

      let diff = neoDomain[0] - yScaleInDollars.domain()[0] // should be positive
      let adjustedDomain = [neoDomain[0] - diff, neoDomain[1] - diff]
      return yScaleInDollars.copy().domain(adjustedDomain)
    }

    function zoomed() {
      let transform = d3.event.transform
      transform.x = 0
      transform.y = 0
      let new_yScale = rescaleYandPanToPreserveOrigin(
        yScaleInDollars,
        transform,
      )

      // gX.call(xAxis.scale(new_xScale));
      gY.call(yAxis.scale(new_yScale))
      circles.attr('cy', d => {
        return new_yScale(d.real)
      })
      // circles.attr("transform", transform)
      // regression.attr('y1', new_yScale(newInterceptInMonths))
      // regression.attr('y2', new_yScale(yEndInMonthDollars))
      // minsRegression.attr('y1', new_yScale(newMinsInterceptInMonths))
      // minsRegression.attr('y2', new_yScale(minsYEndInMonthsDollars))
      // maxsRegression.attr('y1', new_yScale(newMaxsInterceptInMonths))
      // maxsRegression.attr('y2', new_yScale(maxsYEndInMonthsDollars))

      // console.log(`x1 : ${minsXOrigin} y1: ${minsYOrigin} x2 : ${xend} y2: ${new_yScale(minsYEndInMonthsDollars)}`)
    }
    svg.call(zoom)
    zoom.scaleBy(svg, 9)
    
    setTimeout(function() {
      svg.transition().duration(3000).call(zoom.scaleBy, -9)
    })
  }

  presentationOfLotOnMouseOver(d) {
    if (d.auctionHouse === 'sothebys') {
      let arr = d.stuff.split('Ǝ').slice(0, 4)
      return `$${d.real.toLocaleString()}<br/>${arr[1]}<br/>${arr[2]}<br/>${
        arr[3]
      }`
    } else if (d.auctionHouse === 'phillips') {
      let arr = d.stuff.split('^').slice(0, 5)
      return `$${d.real.toLocaleString()}<br/>${arr[0]}<br/>${arr[1]}<br/>${
        arr[2]
      }<br/>${arr[3]}<br/>${arr[4]}`
    } else {
      return `$${d.real.toLocaleString()}<br/>${d.stuff}<br/>`
    }
  }
}

export { OneArtistScatterplot }
