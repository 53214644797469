import React from 'react'
import NavItem from './nav-item'
import Search from './search'

import './navigation.css'

const onSelect = suggestion => {
  console.log(suggestion)
}

class Navigation extends React.Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
          <a className="navbar-brand" href="/">
            Pryph
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <Search onSelect={onSelect} />
            </ul>
            {/*  the ml-auto bootstrap calss is what pushes the nav item to the right by creating a margin on the left */}
            {/* <ul className="navbar-nav"> */}
            <ul className="navbar-nav ml-auto">
              <NavItem name="about" path="/about"></NavItem>
              <NavItem name="insights" path="/insights"></NavItem>
              <NavItem name="contact" path="/contact"></NavItem>
              {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown</a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="/">Action</a>
                                <a className="dropdown-item" href="/">Another action</a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="/">Something else here</a>
                            </div>
                        </li> */}
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

export default Navigation
