import React from 'react'

function contactView(props) {
    return (
        <div className="container">
            <div className="row mt-5 text-left homeText"></div>
            <div className="row mt-5 text-left homeText"></div>
            <div className="row mt-5 text-left homeText"></div>
            <div className="row mt-5 text-left homeText">
                <div className="col-sm">
                </div>
                <div className="col-sm">
                   If you are interested, have ideas and want to contribute you can contact us at <a href="mailto:info@pryph.org">info@pryph.org</a>
                </div>
            </div>
        </div>         
    )
}

const MemoizedVersion = React.memo(contactView)
export { MemoizedVersion as ContactView}