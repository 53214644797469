import React from 'react'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import './search.css'
import { URL_PREFIX } from '../remote/urlpaths'
import { withRouter } from 'react-router-dom'
import {
  setAliveOnly,
  setPrintsOnly,
  fetchArtists,
  setPriceLimit,
  setInterval,
  setOneArtist,
} from '../../state/actions'

const REQUEST_URL = `${URL_PREFIX}/autocomplete`

const getSuggestionValue = suggestion => {
  return suggestion.displayName
}

const renderSuggestion = suggestion => {
  return <span>{suggestion.displayName}</span>
}

const renderSectionTitle = section => {
  return <strong>{section.name}</strong>
}

const getSectionSuggestions = section => {
  if (section) {
    return section.results
  } else {
    return []
  }
}

class Search extends React.Component {
  state = {
    value: '',
    suggestions: [],
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    })
  }

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method },
  ) => {
    console.log(suggestion)
    console.log(suggestionValue)
    console.log(suggestionIndex)
    console.log(sectionIndex)
    console.log(method)
    this.props.dispatch(
      setOneArtist({
        slug: suggestion.keyName,
        cardinality: 0,
        totalValue: 0,
        averageValue: 0,
        trend: 0,
        bottomTrend: 0,
        sumX: 0,
      }),
    ) // state change for redux
    this.props.history.push(`/artist/${suggestion.keyName}`) // state change for the router, if we used react-redux-router we could keep the URL state in the redux store
  }

  // onSuggestionsFetchRequested = ({ value }) => {
  //   this.setState({
  //     suggestions: getSuggestions(value),
  //   })
  // }

  onSuggestionsFetchRequested = ({ value }) => {
    let allLowerCaseValue = value.toLowerCase()
    let req = new Request(`${REQUEST_URL}/${allLowerCaseValue}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    fetch(req)
      .then(resp => {
        return resp.json()
      })
      .then(json => {
        if (json) {
          this.setState({
            suggestions: formatToDisplay(json),
          })
        }
      })
      .catch(err => {
        console.log('badabing')
        console.log(err)
      })
  }

  // onSuggestionsFetchRequested = ({ value }) => {
  //   axios.get(`/autocomplete2/${value}`).then(data => {
  //     if (data.data.length) {
  //       this.setState({
  //         suggestions: formatMultiSection(data.data)
  //       });
  //     }
  //   });
  // };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  renderInputComponent = inputProps => {
    const { className, ...other } = inputProps

    return (
      <input
        className="form-control mr-sm-2"
        type="search"
        placeholder="Search..."
        {...other}
      />
    )
  }

  render() {
    const { value, suggestions } = this.state

    const inputProps = {
      value,
      onChange: this.onChange,
    }

    return (
      <Autosuggest
        multiSection={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={this.renderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={inputProps}
      />
    )

    // TBD better model
    // if (this.state.toDashboard === true) {
    //   return <Redirect to='/dashboard' />
    // }

    // return (
    //   <div>
    //     <h1>Register</h1>
    //     <Form onSubmit={this.handleSubmit} />
    //   </div>
    // )
  }
}

function formatToDisplay(jsonpayload) {
  const result = [
    {
      name: 'Last Names',
      results: jsonpayload.lastNamesList,
    },
    {
      name: 'First Names',
      results: jsonpayload.firstNamesList,
    },
  ]
  return result
}

const SearchContainer = connect()(Search)

export default withRouter(SearchContainer)
