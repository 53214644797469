import React, { Component } from "react";

class DropDownPicker extends Component {
  render() {
    // this is actually doing the store dispatch
    const handleChange = event => this.props.dispatch(event.target.value);

    return (
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor="inputGroupSelect01">
            price limit:{" "}
          </label>
        </div>
        <select
          className="custom-select"
          id="inputGroupSelect01"
          onChange={handleChange}
          value={this.props.value}
        >
          <option value="none">none</option>
          <option value="1000">1K</option>
          <option value="5000">5K</option>
          <option value="10000">10K</option>
          <option value="100000">100K</option>
          <option value="1000000">1M</option>
          <option value="10000000">10M</option>
          <option value="100000000">100M</option>
        </select>
      </div>
    );
  }
}

export { DropDownPicker };
