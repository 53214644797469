import React, { Component } from 'react'
import { ArthistogContainer } from './main-histogram/arthistog-container'
import { Switch, Route, Redirect } from 'react-router-dom'
import { OneArtistContainer } from './artistview/one-artist-container'
import { HomeView } from './home/home-view'
import { ContactView } from './contact/contact-view'
import { withRouter } from 'react-router-dom'

class MainView extends Component {
  render() {
    return (
      <Switch>
        <Route exact={true} path="/insights" component={ArthistogContainer} />
        <Route exact={true} path="/about" component={HomeView} />
        <Route exact={true} path="/contact" component={ContactView} />
        <Route
          exact={true}
          path="/artist/:name"
          component={OneArtistContainer}
        />
        {/* <Route
          path="/artist/:name"
          render={props => <OneArtistContainer {...props} />}
        /> */}
        <Redirect from="/" to="/about" />
      </Switch>
    )
  }
}

export default withRouter(MainView)
