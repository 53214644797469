import React, { Component } from 'react'
import { Metrics, setMetric } from '../../state/actions'
import './metric-bar.css'

class MetricBar extends Component {
  // each checked boolean expression set the radio button, and as a group they should be mutually exclusive

  // slightly non stanard way to process the target, as tehre is is event bug when mixing up bootrap js libraries with react  
  handleChange(e) {
    switch (e.target.getAttribute('id')) {
      case Metrics.PER_SQ_CM:
        this.props.dispatch(setMetric(Metrics.PER_SQ_CM))
        break
      case Metrics.UNITS:
        this.props.dispatch(setMetric(Metrics.UNITS))
        break
      case Metrics.TOTAL_VALUE:
        this.props.dispatch(setMetric(Metrics.TOTAL_VALUE))
        break
      case Metrics.AVG:
        this.props.dispatch(setMetric(Metrics.AVG))
        break
      case Metrics.SLOPE:
        this.props.dispatch(setMetric(Metrics.SLOPE))
        break
      case Metrics.BSLOPE:
        this.props.dispatch(setMetric(Metrics.BSLOPE))
        break
      default:
        break
    }
  }

  render() {

    return (
      <div className="btn-group btn-group-toggle mb-4" data-toggle="buttons">
        <label
          id={Metrics.SLOPE}
          className={`btn btn-secondary ${
            this.props.checked === Metrics.SLOPE ? ' active' : ''
          } `}
          onClick={this.handleChange.bind(this)}
        >
          <input
            type="radio"
            name="appreciation"
            value={Metrics.SLOPE}
            id={Metrics.SLOPE}
            checked={this.props.checked === Metrics.SLOPE}
            onChange={this.handleChange.bind(this)} // bug it react + boostrap, this never fires
          />
          appreciation
        </label>
        <label
          id={Metrics.BSLOPE}
          className={`btn btn-secondary ${
            this.props.checked === Metrics.BSLOPE ? ' active' : ''
          } `}
          onClick={this.handleChange.bind(this)} // work aroud bug below
        >
          <input
            type="radio"
            name="low"
            value={Metrics.BSLOPE}
            id={Metrics.BSLOPE}
            checked={this.props.checked === Metrics.BSLOPE}
            onChange={this.handleChange.bind(this)} // bug it react + boostrap, this never fires
          />
          low
        </label>
        <label
          id={Metrics.UNITS}
          className={`btn btn-secondary ${
            this.props.checked === Metrics.UNITS ? ' active' : ''
          } `}
          onClick={this.handleChange.bind(this)}
        >
          <input
            type="radio"
            name="units"
            id={Metrics.UNITS}
            checked={this.props.checked === Metrics.UNITS}
            onChange={this.handleChange.bind(this)} // bug it react + boostrap, this never fires
          />
          units
        </label>
        <label
          id={Metrics.TOTAL_VALUE}
          className={`btn btn-secondary ${
            this.props.checked === Metrics.TOTAL_VALUE ? ' active' : ''
          } `}
          onClick={this.handleChange.bind(this)}
        >
          <input
            type="radio"
            name="units"
            id={Metrics.TOTAL_VALUE}
            checked={this.props.checked === Metrics.TOTAL_VALUE}
            onChange={this.handleChange.bind(this)} // bug it react + boostrap, this never fires
          />
          gross
        </label>
        <label
          id={Metrics.AVG}
          className={`btn btn-secondary ${
            this.props.checked === Metrics.AVG ? ' active' : ''
          } `}
          onClick={this.handleChange.bind(this)}
        >
          <input
            type="radio"
            name="units"
            id={Metrics.AVG}
            checked={this.props.checked === Metrics.AVG}
            onChange={this.handleChange.bind(this)} // bug it react + boostrap, this never fires
          />
          average
        </label>        
        
      </div>
    )
  }
}

export { MetricBar }
