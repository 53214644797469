/**
 * Created by alaindemour on 5/17/17.
 */

import React, { Component } from 'react'
import { Metrics, setMetric } from '../../state/actions'
import './metric-control.css'



class MetricControl extends Component {


  // each checked boolean expression set the radio button, and as a group they should be mutually exclusive
  render() {

    // The metric control only uses local data so it nevers triggers a network call
    // TBD use the public class field syntax, supposedly more efficient
    const handleChange = (e) => {
      switch (e.target.value) {
        case Metrics.PER_SQ_CM:
          this.props.dispatch(setMetric(Metrics.PER_SQ_CM))
          break
        case Metrics.UNITS:
          this.props.dispatch(setMetric(Metrics.UNITS))
          break
        case Metrics.TOTAL_VALUE:
          this.props.dispatch(setMetric(Metrics.TOTAL_VALUE))
          break
        case Metrics.AVG:
          this.props.dispatch(setMetric(Metrics.AVG))
          break
        case Metrics.SLOPE:
          this.props.dispatch(setMetric(Metrics.SLOPE))
          break
          case Metrics.BSLOPE:
          this.props.dispatch(setMetric(Metrics.BSLOPE))
          break
        default:
          break
      }
    }


    return <ul className="segmented-control">
      <li className="segmented-control__item">
        <input className="segmented-control__input"
          type="radio" value={Metrics.SLOPE}
          id={Metrics.SLOPE}
          checked={this.props.checked === Metrics.SLOPE}
          onChange={handleChange} />
        <label className="segmented-control__label" htmlFor={Metrics.SLOPE}>appreciation</label>
      </li>
      <li className="segmented-control__item">
        <input className="segmented-control__input"
          type="radio" value={Metrics.BSLOPE}
          id={Metrics.BSLOPE}
          checked={this.props.checked === Metrics.BSLOPE}
          onChange={handleChange} />
        <label className="segmented-control__label" htmlFor={Metrics.BSLOPE}>low</label>
      </li>
      <li className="segmented-control__item">
        <input className="segmented-control__input"
          type="radio" value={Metrics.UNITS}
          id={Metrics.UNITS}
          checked={this.props.checked === Metrics.UNITS}
          onChange={handleChange} />
        <label className="segmented-control__label" htmlFor={Metrics.UNITS}>units</label>
      </li>
      <li className="segmented-control__item">
        <input className="segmented-control__input"
          type="radio" value={Metrics.TOTAL_VALUE}
          id={Metrics.TOTAL_VALUE}
          checked={this.props.checked === Metrics.TOTAL_VALUE}
          onChange={handleChange} />
        <label className="segmented-control__label" htmlFor={Metrics.TOTAL_VALUE}>gross</label>
      </li>
      <li className="segmented-control__item">
        <input className="segmented-control__input"
          type="radio" value={Metrics.AVG}
          id={Metrics.AVG}
          checked={this.props.checked === Metrics.AVG}
          onChange={handleChange} />
        <label className="segmented-control__label" htmlFor={Metrics.AVG}>average</label>
      </li>
    </ul>

  }
}


export { MetricControl }