/**
* Created by alaindemour on 3/11/17.
*/

import * as d3 from 'd3'
import './arthisto.css'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

const DefaultDisplaySetCardinality = 70
const minRegressionCardinality = 30


// functional equivalent of a switch statement
const caseof = {
  SLOPE: doc => {
    return Math.round(doc.trend.value)
  },
  BSLOPE: doc => {
    return Math.round(doc.bottomtrend.value)
  },
  PER_SQ_CM: doc => {
    return Math.round(doc.averagepersquare.value)
  },
  TOTAL_VALUE: doc => {
    return Math.round(doc.totalvalue.value)
  },
  UNITS: doc => {
    return doc.doc_count
  },
  AVG: doc => {
    if (doc.valueavg.value > 1000) {
      return Math.round(doc.valueavg.value / 1000)
    }
    else {
      return doc.valueavg.value / 1000
    }
  },
}

const prettyCaseof = {
  SLOPE: doc => {
    return `trend: ${Math.round(doc.trend.value)} card: ${doc.doc_count}`
  },
  BSLOPE: doc => {
    return `trend: ${Math.round(doc.bottomtrend.value)} card: ${doc.doc_count}`
  },
  PER_SQ_CM: doc => {
    return `${Math.round(doc.averagepersquare.value)} cm2 per USD`
  },
  TOTAL_VALUE: doc => {
    return `${Math.round(doc.totalvalue.value / 1000).toLocaleString('en-US')} K USD`
  },
  UNITS: doc => {
    return `${doc.doc_count}`
  },
  AVG: doc => {
    if (doc.valueavg.value > 1000) {
      return `${Math.round(doc.valueavg.value / 1000).toLocaleString('en-US')} K USD`
    }
    else {
      return `${(doc.valueavg.value / 1000).toLocaleString('en-US')} K USD`
    }
  },
}

const margin = { top: 20, right: 100, bottom: 20, left: 20 }

// The render just renders the "frame" so it it always the same, react should optimize it as a noop
// The real rendering is done in the componentDidUpdate (post update hook) and is done by d3
class ArtHistogram extends Component {  

  render() {

    const width = this.props.width - margin.left - margin.right
    const height = this.props.height - margin.top - margin.bottom

    return (
      <div id="html-membrane-for-svg" ref={(membrane) => {
        // if membrane is null, this is an umounting action no need to render anything
        if (membrane) {
          this.renderOutsideOfReact(membrane)
        }
      }} >
        <div className='tooltip' style={{ opacity: 0 }}>
        </div>
        <svg id="histoframe" height={height} width={width}>
          <g transform={`translate( ${margin.left} , ${margin.top} )`}>
          </g>
        </svg>

        </div>
    )
  }

  // the argument is the "membrane" div, we have to go find the svg node passed it
  renderOutsideOfReact(membrane) {


    const width =
    this.props.width > 576
      ? 0.9 * this.props.width - margin.left - margin.right
      : this.props.width - margin.left - margin.right

    const height = this.props.height

    let metric = this.props.metric
    let dataset = this.props.dataset

    if (membrane && dataset) {
      // membrane.previousWidth = width

      // let width = width

      let svg = d3.select(membrane).select('svg')
      // let tooltip = d3.select(membrane).select('.tooltip')

      // we only show regression if there are enough points in the data
      let aggr = (metric === 'SLOPE' ||metric === 'BSLOPE' ) ? dataset.filter(d => d.doc_count > minRegressionCardinality) : dataset
    
      // The array.sort takes a function that should return < 0 if x precedes y
      // Because we want a descreasing historgram from bigger, more expensive, more frequent etc ... to less
      // caseof.metric(y) - caseof.metric(x) will remain positive if caseof.metric(y) > caseof.metric(x)
      aggr.sort((x, y) => caseof[metric](y) - caseof[metric](x))

      try {
        console.log(' trying rendering the histo outside of react')

        let data
        if (aggr.length > DefaultDisplaySetCardinality) {
          data = aggr.slice(0, DefaultDisplaySetCardinality)
        }
        else {
          data = aggr
        }

        // list of all the artists to be displayed
        // key is the artist name slug
        // key is the elaticsearch bucket aggregation "key" field, we are getting it straight from elasticsearch
        let artistsNamesList = data.map(d => d.key)

      
        // SCALES
        // the y scale is vertical and maps the list of artists to [0 to pixel height] of the display
        // hack to compute the pixel length of the longest artist name, which we will use to contrain the histogram bar size
        let longestName = artistsNamesList.reduce((accu, aname) => {
          let l = aname.length
          return (l > accu.length ? aname : accu)
        }, '')
        let boo = svg.append('text')
          .attr('class', 'label')
          .text(longestName)
        let foo = boo.node().getBBox()
        let minwidth = foo.width
        boo.remove()


        // d3 scale for the y axis, maps a given artist name (a slug) to a position on the y axis
        let y = d3.scaleBand()
          .range([0, height])
          .padding(0.1)
          .domain(artistsNamesList)
        
        // the x scale is horizontal and maps value from [max of the metric chosen to 0] to [width of the display to zero]
        let x = d3.scaleLinear()
          .domain([d3.max(data, d => {
            let ret = caseof[metric](d)
            return ret
          }), 0])
          .range([width, minwidth])

        // ANIMATIONS
        // creating some transition to be used later
        let fast = d3.transition()
          .duration(1000)
          .ease(d3.easeLinear)
        let medium = d3.transition()
          .duration(2000)
          .ease(d3.easeLinear)

        // LOADING THE DATA IN D3
        // here we are reattaching the data to the barchart, which also compute the enter and exit
        let histogram = svg.selectAll('.bar')
          .data(data, d => d.key)

        // ENTER SECTION  
        // We make the new bar enter the chart
        let barEnter = histogram
          .enter()
          .append('g')
          .attr('class', 'bar')
          .attr('transform', d => `translate(0,${y(d.key)})`)

        barEnter.append('rect')
          .attr('height', y.bandwidth())
          .on('click', (d, i) => {
            this.props.dispatch({slug: d.key, cardinality : d.doc_count, totalValue: d.totalvalue.value, averageValue: d.valueavg.value, trend : d.trend.value, bottomTrend : d.bottomtrend.value, sumX : d.sumX.value}) // state change for redux
            this.props.history.push(`/artist/${d.key}`) // state change for the router, if we used react-redux-router we could keep the URL state in the redux store
          })

        barEnter
          .select('rect')
          .transition(fast) // animation to change the length of the bar
          .attr('width', d => x(caseof[metric](d)))

        barEnter.append('text')
          .attr('class', 'label')
          .attr('x', 5)
          .attr('y', y.bandwidth() / 2)
          .attr('dy', '.35em')
          .attr('text-anchor', 'start')
          .text(d => {
            let newStr = d.key.replace(/-/g, ' ');
            return newStr
          })

        barEnter.append('text')
          .attr('class', 'blacklabel')
          .transition(fast) // needed so that the number moves with the bar
          .attr('x', d => x(caseof[metric](d)) + 5)
          .attr('y', y.bandwidth() / 2)
          .attr('dy', '.35em')
          .attr('text-anchor', 'start')
          .text(d => {
            let bar = prettyCaseof[metric](d)
            return `${bar} `
          })

        // EXIT SECTION
        // We remove the bars in the exit
        // TBD bug in d3 with fill opacity transition and exit section
        histogram
          .exit()
          .transition(fast)
          .style('fill-opacity', 0)
          .remove()

        // UPDATE SECTION
        // We reshuffle the bars which stay
        let updateTransition = histogram
          .transition(medium)
          .delay(1000)
          .attr('transform', d => `translate(0,${y(d.key)})`)

        // We change the length and height of the bars which remain (update section)
        updateTransition.select('rect')
          .attr('width', d => x(caseof[metric](d)))
          .attr('height', y.bandwidth())

        updateTransition.select('.label')
          .attr('y', y.bandwidth() / 2)

        updateTransition.select('.blacklabel')
          .attr('x', d => x(caseof[metric](d)) + 5)
          .attr('y', y.bandwidth() / 2)
          .attr('text-anchor', 'start')
          .text(d => {
            let bar = prettyCaseof[metric](d)
            return `${bar} `
          })

      } catch (e) {
        console.log(e)
      }
    }
  }
}

const Arthistog = withRouter(ArtHistogram)

export { Arthistog }

