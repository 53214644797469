import React, { Component } from 'react'
// import { setPriceLimit, fetchArtists } from '../../state/actions'
import { DropDownPicker } from './drop-down-picker'
// import { store } from '../../state/store'

class PriceLimit extends Component {

  componentDidUpdate(prevProps, prevState) {

    let oldLimit = prevProps.priceLimit
    let currentLimit = this.props.priceLimit
    if (oldLimit !== currentLimit) {
      this.props.reactTo() // network call, we chain state transitions without user input
    }
  }

  // this is meant to be passed as a black box action to the sub components which are more generic like a generic checkbox and don't know about the semantics of their action    

  render() {



    let priceLimitStringValue = this.props.priceLimit.toString()

    return <div id="pricelimit" className="Small-filter">
      <div>
          <DropDownPicker value={priceLimitStringValue} dispatch={this.props.dispatch} />
      </div>
    </div>

  }
}


export { PriceLimit }