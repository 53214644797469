
import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './reducers'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'
import {
  Metrics,
} from './actions'


const initialState = {
  histo: {
    metric: Metrics.SLOPE,
    aliveOnly: true,
    printsOnly: false,
    interval: [2000, 2019],
    isFetching: false,
    dataset: [],
    bod : {},
    receivedAt : 0,
    priceLimit : "none",
  },
  oneArtist: {
    dataset : null,
    slug: 'nobody-pryph-initial-state',
    cardinality: 0,
    totalValue : 0,
    sumX : 0,
    trend: 1, // least square regression on all data points
    bottomTrend: 1, // least square regression on all min data point (per month)
    interval: [2000, 20019],
    priceLimit : "none"
  }
}


// This is redux store
// It has only one interface that allows changing its state "dispatch" that displatches redux actions
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(
    thunkMiddleware, // intercept every action transition in the state machine, nad if the action is a function dispatch it
    logger // neat middleware that logs actions
  )
)


export {store}

