import React from 'react'
import { useState, useEffect } from 'react'
import { OneArtistScatterplot } from '../artistview/one-artist-scatterplot'
import { YAYOI } from './yayoi'

function HomeView(props) {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // TBD width is not used any more, as I am grabbing hte parent node bounding box later
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-sm" id="pryphHeroPlot">
          <OneArtistScatterplot
            name="yayoi-kusama"
            width={(width / 2) * 0.7}
            height={height / 2 < 320 ? 320 : height / 2}
            dataset={YAYOI}
          />
        </div>
        <div className="col-sm">
          <div id="homeblurb" className="homeText">
            <p>
              Pryph is an experiment in collecting art market datasets and
              applying modern data science to them.
            </p>
            <p>
              We are interested in comparing anecdotal insights gleaned from art
              advisors, collectors, galleries and auction houses with what can
              be inferred from the data, at scale, and using state-of-the art
              machine learning algorithms.
            </p>
            <p>
              The datasets we have collected so far are solely about modern and
              contemporary artists.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const MemoizedVersion = React.memo(HomeView)

export { MemoizedVersion as HomeView }
