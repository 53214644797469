import React, { Component } from 'react'
// import MCheckbox from '@material-ui/core/Checkbox'
import './checkbox.css'

class Checkbox extends Component {
  
  // render() {
  //   // we use an auxilliary lambda to avoid to have the redux action deal with DOM events to access the "checked" boolean
  //   const handleChange = event => this.props.dispatch(event.target.checked)
  //   return (<input type="checkbox"
  //     checked={this.props.checked}
  //     onChange={handleChange} />)
  // }
  
  render() {
    // we use an auxilliary lambda to avoid to have the redux action deal with DOM events to access the "checked" boolean
    const handleChange = event => this.props.dispatch(event.target.checked)
    // return (<MCheckbox 
    //   checked={this.props.checked}
    //   onChange={handleChange} />)
    return (
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id={this.props.lab}
          value={this.props.lab}
          checked={this.props.checked}
          onChange={handleChange} />
        <label className="form-check-label" htmlFor={this.props.lab}> {this.props.lab} </label>
      </div>
    )
  }
}

export { Checkbox }