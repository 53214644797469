import React, { Component } from "react";
import "./App.css";
import MainView from "./components/mainview";
import Navigation from "./components/navigation/navigation";

class App extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <div className="container">
          <MainView />
        </div>
      </div>
    )
  }
}

export default App;
