/**
 * Created by alaindemour on 3/12/17.
 */

import * as d3 from 'd3'
import React, { Component } from 'react'

import './year-brush.css'

import { LAYOUT_WIDTH } from './layout-constants'

const margin = {
  top: 20,
  right: 50,
  bottom: 20,
  left: 4,
}

// const years = ['all', '2016', '2015', '2004']

function recordPosition(d, i, gr) {
  let ev = d3.event
  if (ev.sourceEvent) {
    // we are recording the mouse position so that if it has not moved on mouseup later we can perform specific actions for click without movment
    this.cx = ev.sourceEvent.clientX
    this.cy = ev.sourceEvent.clientY
  }
  // this contains the current DOM element (as per d3 manual)
  // we are recording the start position for the case when the end user does NOT drag at all, in which case
  // the selection will be null on the end event, so we need some kind of pixel position to react then
  this.clickPosition = ev.selection[0]
}

class Yearbrush extends Component {
  componentDidMount() {
    this.renderOutsideOfReact()
  }


  // THIS IS NOT JUST AN OPTIMIZATION, without that the rendering will go into an infinite loop as the ref cause a renredering,
  // with the same props, but react by default does not check that props are the same and keeps on looping
  shouldComponentUpdate(nextProps) {
    return (
      this.props.interval[0] !== nextProps.interval[0] ||
      this.props.interval[1] !== nextProps.interval[1] ||
      this.props.width !== nextProps.width
    )
  }

  // This is never called by React on the first component mounting
  componentDidUpdate(prevProps, prevState) {
    let beforeInterval = prevProps.interval
    let currentInterval = this.props.interval

    if (
      beforeInterval[0] !== currentInterval[0] ||
      beforeInterval[1] !== currentInterval[1]
    ) {
      this.props.reactTo() // network call, we chain state transitions without user input
    }
  }

  render() {
    const width =
      this.props.width > 576
        ? 0.9 * this.props.width - margin.left - margin.right
        : this.props.width - margin.left - margin.right
    const height = 70 - margin.top - margin.bottom
    return (
      <div id="yearbrushslide">
        <svg
          width={`${width + margin.left + margin.right}`}
          height={`${height + margin.top + margin.bottom}`}
          ref={selfdom => {
            this.selfdom = selfdom // this is to keep the component abstract and not have to do a lookup by CSS id in didMount
            this.renderOutsideOfReact()
          }}
        />
      </div>
    )
  }

  renderOutsideOfReact() {
    const width =
      this.props.width > 576
        ? 0.8 * this.props.width - margin.left - margin.right
        : this.props.width - margin.left - margin.right
    const height = 70 - margin.top - margin.bottom

    // Creating the horizontal scale for the brush
    let x = d3
      .scaleTime()
      .domain([new Date(2000, 0, 0), new Date(2019, 0, 0)])
      .rangeRound([0, width])

    // using "self" to bind this is unusual in ES6 but we do for the d3 "brushed" callback below, it needs a this bound dynamically to the svg node but also access
    // to the "this" of the reactjs component, so we need a way to distinguish them
    const self = this
    let svg = d3.select(this.selfdom)
    // .append('svg')
    // .attr('width', width + margin.left + margin.right)
    // .attr('height', height + margin.top + margin.bottom)

    // first we clear whaver old brush was there, brushes have to be redrawn each time
    let theoldG = svg.select('g')
    theoldG.remove()

    const theNewG =svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    // add the  x axis with its ticks
    theNewG
      .append('g')
      .attr('class', 'axis axis--grid')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
        d3
          .axisBottom(x)
          .ticks(d3.timeYear)
          .tickSize(-height)
          .tickFormat(function() {
            return null
          }),
      )
      .selectAll('.tick')

    // add the years on the ticks
    theNewG
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + height + ')')
      .call(
        d3
          .axisBottom(x)
          .ticks(d3.timeYear)
          .tickPadding(0)
          .tickFormat(d3.timeFormat('%y'))
      )
      .attr('text-anchor', null)
      .selectAll('text')
      .attr('x', 6)

    // add the d3 brush proper
    let theBrush = d3
      .brushX()
      .extent([[0, 0], [width, height]])
      .on('start', recordPosition)
      .on('end', brushed)
    let theSelectionForTheBrush = theNewG.append('g').attr('class', 'brush')
    theSelectionForTheBrush.call(theBrush)

    let low = this.props.interval[0]
    let high = this.props.interval[1]
    // Boostrap Magic, we operate the bursh programmatically which will cause the Redux State Change
    let dinit = [new Date(low, 0, 0), new Date(high, 0, 0)]
    let positions = dinit.map(x)
    theSelectionForTheBrush.call(theBrush.move, positions)

    // it is important to use a old school javascript function here not a fat arrow function/lambda in order NOT to bind "this", d3 relies on dynamic binding later in the call back
    function brushed(d, i, gr) {
      let ev = d3.event
      if (ev.sourceEvent) {
        let cx = ev.sourceEvent.clientX
        let cy = ev.sourceEvent.clientY
        console.log(`cx : ${cx} cy : ${cy}`)
      }
      // this contains the current DOM elem
      // that's when the user does NOT drag at all
      // faking a 0 pixel drag
      // the conditin is either a click without movement outside of seletion (null) or a click without movment inside the selection (x and y haven not moved)
      if (
        ev.selection === null ||
        (ev.selection &&
          this.cx &&
          ev.sourceEvent.clientX === this.cx &&
          this.cy === ev.sourceEvent.clientY)
      ) {
        if (ev.selection === null) {
          ev.selection = [this.clickPosition, this.clickPosition]
        } else {
          // transform the mouse click coordinate into coordinate relative to the brush itself, only keep the x coordinate
          let p = d3.mouse(this)[0]
          ev.selection = [p, p]
        }
      }

      // This is a bit cryptic but has to do with how d3 raises events
      // because we adjust the brush to end on scale boundary, a second " brush end" event is generated
      // but it has a different source, it is the brush itself, the first as "mouse up" as type, the second "end".
      // The second event will cause a network call to fetch the data
      let d0 = ev.selection.map(x.invert)
      let d1 = d0.map(d3.timeYear.round)

      // If empty when rounded, use floor instead.
      if (d1[0] >= d1[1]) {
        d1[0] = d3.timeYear.floor(d0[0])
        d1[1] = d3.timeYear.offset(d1[0])
      }

      // even more cryptic:
      // ev.sourceEvent == null is the initial setting of the brush, we do it programmatically so there is no source event at all
      // ev.sourceEvent.type === end is after a mouse up in the middle of the scale, which causes a secondary brush event when we adjust the brush
      // the secondary event has type end instead of mouseup

      if (ev.sourceEvent === null || ev.sourceEvent.type === 'end') {
        let fy = d1[0].getFullYear()
        let ty = d1[1].getFullYear()

        // "self" is bound to the component
        self.props.dispatch([fy, ty])
        // return to avoid infinite event loop
        return
      }

      // It is important this call stays here other an invite loop (same event retriggering again and again will happen)
      // "this" here will be bound to the svg node, NOT THE COMPONENT
      d3.select(this).call(d3.event.target.move, d1.map(x))
    }
  }
}

export { Yearbrush }
